import { CsButton } from '@csinstruments/cs-react-theme'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalFormProps = {
  children: React.ReactNode
  headingText: string
  formClose: () => void
  submitForm: () => void
  formOpen: boolean
  disableSubmit?: boolean
  confirmButtonLabel?: string
  disableClose?: boolean
  headingType?: 'error' | 'inherit' | 'initial' | 'secondary' | 'primary' | 'textPrimary' | 'textSecondary'
  cancelButtonLabel?: string
}

export const ModalForm: React.FC<ModalFormProps> = ({
  headingText,
  formClose,
  formOpen,
  children,
  submitForm,
  disableSubmit,
  confirmButtonLabel,
  disableClose,
  cancelButtonLabel,
  headingType = 'secondary',
}: ModalFormProps) => {
  const { t } = useTranslation('common')
  return (
    <>
      <Dialog
        open={formOpen}
        onClose={formClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle id="form-dialog-title" disableTypography>
          <Grid item xs md lg>
            <Typography variant="h5" color={headingType} align="center" gutterBottom>
              {headingText}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <Divider variant="fullWidth" />
        <DialogActions>
          <Grid item container justifyContent="center" alignContent="stretch" spacing={2}>
            <Grid item xs lg md>
              <CsButton fullwidth solid disabled={disableClose} onClick={formClose} color="primary">
                {cancelButtonLabel ? cancelButtonLabel : t('modals.cancel')}
              </CsButton>
            </Grid>
            <Grid item xs lg md>
              <CsButton fullwidth solid disabled={disableSubmit} onClick={submitForm} color="secondary">
                {confirmButtonLabel ? confirmButtonLabel : t('modals.confirm')}
              </CsButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
