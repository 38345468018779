import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next/'

import { useGetUserInfo } from '../../hooks/userHooks'
import { Grid, Typography } from '@material-ui/core'
import { getTranslatedValue, getUserName } from '../../helpers/utils'
import { paddingLeftRight, paddingTopBottom, standardContentPadding } from '../../../styles/Shared'
import { useStyles } from './User.Styles'
import {
  AccessPermission,
  AppStateSimple,
  AsAppRole,
  FilePermissionExSimple,
  SingleUserLicense,
  UserState,
} from '../../../api/iam.models'
import { useAssignableApps } from '../../hooks/organizationHooks'
import { ProgressBar, PushBack, ResultAlert } from '../../components/partials'
import { transformToFileHierarchy } from '../../hooks/helperhooks/transformToFileHierarchy'

const ViewUserPermissions: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation('common')
  const classes = useStyles()
  const { userInfo, userInfoError, userInfoLoading } = useGetUserInfo(id)
  const [viewUserState, setViewUserState] = React.useState<UserState>()

  const { appsList } = useAssignableApps()
  useEffect(() => {
    if (!userInfoLoading && userInfo) {
      //console.log('userInfo', userInfo.appStates)
      setViewUserState({
        ...userInfo,
      })
    }
  }, [userInfoLoading])

  const displayRoles = (appID: string, appRoles: AsAppRole[]): string => {
    return appRoles
      .map((rl) => (rl?.displayNameKey ? getTranslatedValue(appID, `${rl?.displayNameKey}`, t) : rl?.roleName))
      .slice()
      .join(', ') as string
  }

  const displayIamRoles = (permission: AccessPermission[]): string => {
    return (
      permission
        .map((p) => (p.roleDisplayKey ? getTranslatedValue('iam', p.roleDisplayKey, t) : ''))
        .slice()
        .join(', ') ?? ''
    )
  }

  if (viewUserState) {
    //const iamAppState = viewUserState.appStates.find((ap) => ap?.appID === 'iam') as AppStateSimple

    const leakAppState = viewUserState?.appStates.find((ap) => ap?.appID === 'leak-reporter') as AppStateSimple

    const files = transformToFileHierarchy(leakAppState.filePermission as FilePermissionExSimple[])

    const licenseState = (): SingleUserLicense => {
      const _lic = appsList?.find((l) => leakAppState.appID === l?.groupname)
      return {
        licenseGroupID: _lic?.displayNameKey ?? '',
        licenseTypeID:
          _lic?.licenseTypes?.find((lt) =>
            viewUserState?.licenses?.some((sl) => sl?.licenseTypeID === lt?.licenseTypeId),
          )?.displayNameKey ?? '',
      } as SingleUserLicense
    }

    const iamAppRole = displayIamRoles(viewUserState.userPermission)

    const iamAppAssignRoles = displayIamRoles(viewUserState.assignableUserPermission)

    const leakAppRoles = displayRoles(leakAppState.appID, leakAppState.roles as AsAppRole[])

    const leakAppAssignRoles = displayRoles(leakAppState.appID, leakAppState.assignableRoles as AsAppRole[])

    const leakAppEditRoles = displayRoles(leakAppState.appID, leakAppState.editableRoles as AsAppRole[])
    return (
      <>
        <div style={{ ...paddingLeftRight, ...paddingTopBottom, ...standardContentPadding }}>
          <ResultAlert severity="error" alertText={`${userInfoError}`} showAlert={!!userInfoError} />
          <PushBack />
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <Typography variant="h4" color="secondary" align="center">
                {t('heading.overview', { user: viewUserState?.userInfo?.email })}
              </Typography>
            </Grid>
            <Grid item lg={12} />

            <Grid item lg={10} container spacing={2}>
              <Grid item container spacing={2} alignItems="center">
                <Grid item lg={3}>
                  <Typography variant="h6" color="secondary">
                    {t('heading.loginCredentials')}
                  </Typography>
                </Grid>
                <Grid item container spacing={2} xs lg={8} className={classes.overview}>
                  <Grid item lg={4} container spacing={1} direction="column">
                    <Grid item>
                      <Typography variant="subtitle2" color="textSecondary">
                        {'Email: ' + ' '}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="subtitle2" color="primary">
                        {viewUserState?.userInfo?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container lg={4} spacing={1} direction="column">
                    <Grid item>
                      <Typography variant="subtitle2" color="textSecondary">
                        {'Origin: ' + ' '}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="subtitle2" color="primary">
                        {viewUserState?.userInfo?.extern === 'extern'
                          ? t('labels.externLabel')
                          : t('labels.internLabel')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <br />

              <Grid item container spacing={2} alignItems="center">
                <Grid item xs lg={3}>
                  <Typography variant="h6" color="secondary">
                    {t('labels.accessRights')}
                  </Typography>
                </Grid>
                <Grid item container spacing={2} lg={8} direction="column" className={classes.overview}>
                  <Grid item container spacing={2}>
                    <Grid item lg={4} container spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.appLicense') + ' '}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {t(`license:${licenseState().licenseGroupID}`)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container lg={4} spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.appLicenseType')}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {licenseState().licenseTypeID ? t(`license:${licenseState().licenseTypeID}`) : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg />
                  <Grid item container spacing={2}>
                    <Grid item lg={4} container spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.superior') + ' '}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {viewUserState.superior?.email}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container lg={4} spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.iamEditableRights', {
                            username: getUserName(viewUserState?.userInfo?.email as string),
                          }) + ' '}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {iamAppRole}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg />
                  <Grid item container spacing={2}>
                    <Grid item lg={4} container spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.subordinates') + ' '}
                        </Typography>
                      </Grid>
                      {viewUserState.subordinates.map((subordinate) => (
                        <Grid item key={subordinate.orgUserID}>
                          <Typography variant="subtitle2" color="primary">
                            {subordinate.email}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item container lg={4} spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.iamAssignableRights', {
                            username: getUserName(viewUserState?.userInfo?.email as string),
                          }) + ' '}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {iamAppAssignRoles}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container lg={4} spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.packetTypes')}
                        </Typography>
                      </Grid>

                      <Grid item>
                        {/* {viewUserState.assignableLicenses?.licensePackets?.map((pkt, ix) => (
                          <Typography key={pkt?.packetName} variant="subtitle2" color="primary">
                            {`${ix + 1}. ${pkt?.packetName}  ${
                              pkt?.licenseGroups?.length
                                ? '//' +
                                  pkt?.licenseGroups.map((pktGrp) => {
                                    return (
                                      t(`license:${pktGrp?.displayNameKey}`) +
                                      `// ${pktGrp?.licenseTypes?.map((lt) => {
                                        return t(`license:${lt?.displayNameKey}`) + ' '
                                      })} `
                                    )
                                  })
                                : ''
                            }`}
                          </Typography>
                        ))} */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs lg={3}>
                  <Typography variant="h6" color="secondary">
                    {t('labels.resources')}
                  </Typography>
                </Grid>
                <Grid item container spacing={2} lg={8} direction="column" className={classes.overview}>
                  <Grid item container spacing={2}>
                    <Grid item lg={4} container spacing={1} direction="column">
                      <Grid item>
                        {/* <Typography variant="subtitle2" color="textPrimary">
                          {'App:' + ' ' + t(`license:${licenseState().groupname}`)}
                        </Typography> */}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('labels.role', { username: getUserName(viewUserState?.userInfo?.email as string) }) + ' '}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="subtitle2" color="primary">
                        {leakAppRoles}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item lg />
                  <Grid item container spacing={2}>
                    <Grid item container lg={4} spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.assignableRoles', {
                            username: getUserName(viewUserState?.userInfo?.email as string),
                          }) + ' '}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {leakAppAssignRoles}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container lg={4} spacing={1} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.editableRoles', {
                            username: getUserName(viewUserState?.userInfo?.email as string),
                          }) + ' '}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2" color="primary">
                          {leakAppEditRoles}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg />
                  <Grid item container spacing={2}>
                    <Grid item lg={4} spacing={1} container direction="column">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('labels.company')}
                        </Typography>
                      </Grid>
                      {files?.map((comp, cx) => (
                        <Grid key={comp.fileName + cx} item container>
                          <Grid item>
                            <Typography variant="subtitle2" color={!comp.inTrash ? 'primary' : 'error'}>
                              {comp.fileName ? comp.fileName : comp.fileType === 'root-id' ? t('labels.all') : ''}
                              {comp.inTrash ? t('warning.inTrash') : ''}
                            </Typography>
                          </Grid>
                          <Grid item container spacing={2} direction="column">
                            {comp.subFiles ? (
                              <Grid item>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                  {t('labels.projects')}
                                </Typography>
                              </Grid>
                            ) : (
                              <></>
                            )}
                            {comp?.subFiles?.map((sb, ix) => (
                              <Grid item key={sb.fileID + ix}>
                                <Typography
                                  variant="subtitle2"
                                  color={!sb.inTrash ? 'textSecondary' : 'error'}
                                  gutterBottom
                                >
                                  {sb.fileName} {sb.inTrash ? t('warning.inTrash') : ''}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
  return <ProgressBar show={userInfoLoading} />
}

export default ViewUserPermissions
