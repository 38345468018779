import { useApolloClient } from '@apollo/client'
import { AppTop, AppTopUserInfo, CsButtonText } from '@csinstruments/cs-react-theme'
import { Grid } from '@material-ui/core'
import React from 'react'
import { useAuthContext } from '..'
import { environment } from '../../../helpers/environment'
import { deleteTokens } from '../../../helpers/manageTokens'
import { ROUTES } from '../../../helpers/routes'
import SelectOrganization from '../SelectOrganization'
import { LanguageSelection } from '../inputElements/LanguageSelection'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

const IAMAppTop: React.FC = () => {
  const { keyCloak, username } = useAuthContext()
  const client = useApolloClient()
  const userProfileClick = () => {
    window.open(environment.USER_SERVICE, '_blank')
  }
  const clickLogo = () => {
    window.open(environment.CSHOME, '_blank')
  }
  return (
    <>
      <AppTop onLogoClick={clickLogo}>
        <AppTopUserInfo clickAction={userProfileClick} username={username} />
        <Grid item xs={1} />

        <SelectOrganization />

        <LanguageSelection />

        <CsButtonText
          id="logout"
          color="inherit"
          title="Logout"
          onClick={() => {
            client
              .resetStore()
              .then(() => {
                deleteTokens()
                keyCloak?.logout({ redirectUri: environment.WEB_URL + ROUTES.home })
              })
              .catch(() => {
                console.log('failed to reset client store')
                deleteTokens()
                keyCloak?.logout({ redirectUri: environment.WEB_URL + ROUTES.home })
              })
          }}
          small
        >
          <ExitToAppIcon />
        </CsButtonText>
      </AppTop>
    </>
  )
}

export default IAMAppTop
