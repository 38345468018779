import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { prepareSentry } from './app/helpers/sentryUtils'
import common_de from './translations/de/common.json'
import common_en from './translations/en/common.json'
import common_cs from './translations/cs/common.json'
import common_es from './translations/es/common.json'
import common_fr from './translations/fr/common.json'
import common_it from './translations/it/common.json'
import common_jp from './translations/jp/common.json'
import common_pl from './translations/pl/common.json'
import common_pt from './translations/pt/common.json'
import common_ro from './translations/ro/common.json'
import common_ru from './translations/ru/common.json'
import common_sl from './translations/sl/common.json'
import common_sv from './translations/sv/common.json'
import common_zh from './translations/zh/common.json'
import common_tr from './translations/tr/common.json'
import common_ua from './translations/uk/common.json'
import common_zho from './translations/zh-Hant/common.json'

import csleakage_en from './translations/en/leak_reporter.json'
import csleakage_de from './translations/de/leak_reporter.json'
import csleakage_cs from './translations/cs/leak_reporter.json'
import csleakage_es from './translations/es/leak_reporter.json'
import csleakage_fr from './translations/fr/leak_reporter.json'
import csleakage_it from './translations/it/leak_reporter.json'
import csleakage_jp from './translations/jp/leak_reporter.json'
import csleakage_pl from './translations/pl/leak_reporter.json'
import csleakage_pt from './translations/pt/leak_reporter.json'
import csleakage_ro from './translations/ro/leak_reporter.json'
import csleakage_ru from './translations/ru/leak_reporter.json'
import csleakage_sl from './translations/sl/leak_reporter.json'
import csleakage_sv from './translations/sv/leak_reporter.json'
import csleakage_zh from './translations/zh/leak_reporter.json'
import csleakage_tr from './translations/tr/leak_reporter.json'
import csleakage_ua from './translations/uk/leak_reporter.json'
import csleakage_zho from './translations/zh-Hant/leak_reporter.json'

import iam_en from './translations/en/iam.json'
import iam_de from './translations/de/iam.json'
import iam_cs from './translations/cs/iam.json'
import iam_es from './translations/es/iam.json'
import iam_fr from './translations/fr/iam.json'
import iam_it from './translations/it/iam.json'
import iam_jp from './translations/jp/iam.json'
import iam_pl from './translations/pl/iam.json'
import iam_pt from './translations/pt/iam.json'
import iam_ro from './translations/ro/iam.json'
import iam_ru from './translations/ru/iam.json'
import iam_sl from './translations/sl/iam.json'
import iam_sv from './translations/sv/iam.json'
import iam_zh from './translations/zh/iam.json'
import iam_tr from './translations/tr/iam.json'
import iam_ua from './translations/uk/iam.json'
import iam_zho from './translations/zh-Hant/iam.json'

import csnetwork_de from './translations/de/csnetwork_de.json'
import csnetwork_en from './translations/en/csnetwork_en.json'

import common from './translations/key/common.json'
import csleakage_key from './translations/key/csleakage_key.json'
import csnetwork_key from './translations/key/csnetwork_key.json'
import iam_key from './translations/key/iam_key.json'
import license_en from './translations/en/license.json'
import license_de from './translations/de/license.json'
import license_cs from './translations/cs/license.json'
import license_es from './translations/es/license.json'
import license_fr from './translations/fr/license.json'
import license_it from './translations/it/license.json'
import license_jp from './translations/jp/license.json'
import license_pl from './translations/pl/license.json'
import license_pt from './translations/pt/license.json'
import license_ro from './translations/ro/license.json'
import license_ru from './translations/ru/license.json'
import license_sl from './translations/sl/license.json'
import license_sv from './translations/sv/license.json'
import license_zh from './translations/zh/license.json'
import license_tr from './translations/tr/license.json'
import license_ua from './translations/uk/license.json'
import license_zho from './translations/zh-Hant/license.json'

import { Main } from './app/Main'
import { AuthProvider } from './app/components/partials'
import detector from 'i18next-browser-languagedetector'
import ThemeProvider from './app/components/ThemeProvider'

i18next.use(detector).init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  resources: {
    en: {
      common: common_en,
      license: license_en,
      leakage: csleakage_en,
      iam: iam_en,
      network: csnetwork_en,
    },
    de: {
      common: common_de,
      license: license_de,
      leakage: csleakage_de,
      iam: iam_de,
      network: csnetwork_de,
    },
    cs: {
      common: common_cs,
      license: license_cs,
      leakage: csleakage_cs,
      iam: iam_cs,
    },
    es: {
      common: common_es,
      license: license_es,
      leakage: csleakage_es,
      iam: iam_es,
    },
    fr: {
      common: common_fr,
      license: license_fr,
      leakage: csleakage_fr,
      iam: iam_fr,
    },
    it: {
      common: common_it,
      license: license_it,
      leakage: csleakage_it,
      iam: iam_it,
    },
    jp: {
      common: common_jp,
      license: license_jp,
      leakage: csleakage_jp,
      iam: iam_jp,
    },
    pl: {
      common: common_pl,
      license: license_pl,
      leakage: csleakage_pl,
      iam: iam_pl,
    },
    pt: {
      common: common_pt,
      license: license_pt,
      leakage: csleakage_pt,
      iam: iam_pt,
    },
    ro: {
      common: common_ro,
      license: license_ro,
      leakage: csleakage_ro,
      iam: iam_ro,
    },
    ru: {
      common: common_ru,
      license: license_ru,
      leakage: csleakage_ru,
      iam: iam_ru,
    },
    sl: {
      common: common_sl,
      license: license_sl,
      leakage: csleakage_sl,
      iam: iam_sl,
    },
    sv: {
      common: common_sv,
      license: license_sv,
      leakage: csleakage_sv,
      iam: iam_sv,
    },
    zh: {
      common: common_zh,
      license: license_zh,
      leakage: csleakage_zh,
      iam: iam_zh,
    },
    tr: {
      common: common_tr,
      license: license_tr,
      leakage: csleakage_tr,
      iam: iam_tr,
    },
    ua: {
      common: common_ua,
      license: license_ua,
      leakage: csleakage_ua,
      iam: iam_ua,
    },
    zho: {
      common: common_zho,
      license: license_zho,
      leakage: csleakage_zho,
      iam: iam_zho,
    },
    key: {
      common: common,
      leakage: csleakage_key,
      iam: iam_key,
      network: csnetwork_key,
    },
  },
})
prepareSentry()

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ThemeProvider>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById('root'),
)
