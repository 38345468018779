import React from 'react'
import { Grid, Container, Typography } from '@material-ui/core'
import { CsButtonSecondary } from '@csinstruments/cs-react-theme'
import { ROUTES } from '../helpers/routes'
import Dashboard from './Dashboard'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { KCinstance } from '../helpers/cache'
import { useAuthContext } from '../components/partials'

const SingleSignOn: React.FC = () => {
  const { isAuthenticated, keyCloak } = useAuthContext()

  const history = useHistory()

  const { t } = useTranslation('common')

  const handleSSO = () => {
    keyCloak?.login() ?? history.push(ROUTES.serverError)
  }

  if (isAuthenticated) {
    console.log('keycloak possible functions', keyCloak)
    keyCloak && KCinstance(keyCloak)
    return <Dashboard />
  } else {
    return (
      <>
        <Container>
          <Typography variant="h3" color="primary" align="center" gutterBottom>
            {t('welcome.title')}
          </Typography>

          <Grid item container spacing={3} alignContent="center" direction="column">
            <Grid item>
              <CsButtonSecondary id="singleSignon" color="secondary" label="Sign in" onClick={handleSSO} fullwidth />
            </Grid>

            {/* <Grid item>
              <CsButtonSecondary
                id="loginPage"
                color="secondary"
                label="Login Page"
                onClick={handleLoginPage}
                fullwidth
              />
            </Grid>

            <Grid item>
              <CsButtonSecondary
                id="register-organization"
                color="secondary"
                label="Activate Organization"
                onClick={handleOrganization}
                fullwidth
              />
            </Grid> */}
          </Grid>
        </Container>
      </>
    )
  }
}

export default SingleSignOn
