export const NAVIGATION = {
  HOME: '',
  REGISTER_ORGANIZATION: 'register-organization',
  FORGOT_PASSWORD: 'reset-password',
  DASHBOARD: 'dashboard',
  SINGLESIGNIN: 'singlesignin',
  LOGIN: 'login',
  SERVER_ERROR_PAGE: 'server-error',
  NO_ACCESS_PAGE: 'no-access',
  SESSION_EXPIRED: 'session-expired',

  MANGAGEUSERS: 'manageusers',
  MANAGERESOURCES: 'manageresources',
  MANAGEAPPS: 'manageapps',
  INFO: 'appinfo',
  MANAGEORGANIZATION: 'manageorganization',
  MANAGEROLES: 'manageroles',
  RECYCLEBIN: 'bin',

  LISTUSERS: 'manageusers/userslist',
  ADDUSER: 'manageusers/adduser',
  CREATEROLE: 'manageroles/addrole',
}
export const ROUTES = {
  home: `/${NAVIGATION.HOME}`,
  dashboard: `/${NAVIGATION.DASHBOARD}`,
  registerOrganization: `/${NAVIGATION.REGISTER_ORGANIZATION}`,
  login: `/${NAVIGATION.LOGIN}`,
  forgotpassword: `/${NAVIGATION.FORGOT_PASSWORD}`,
  serverError: `/${NAVIGATION.SERVER_ERROR_PAGE}`,
  singleSignIn: `/${NAVIGATION.SINGLESIGNIN}`,
  sessionExpired: `/${NAVIGATION.SESSION_EXPIRED}`,
  accessDenied: `/${NAVIGATION.NO_ACCESS_PAGE}`,

  manageapps: `/${NAVIGATION.MANAGEAPPS}`,
  manageresources: `/${NAVIGATION.MANAGERESOURCES}`,
  manageusers: `/${NAVIGATION.MANGAGEUSERS}`,
  manageroles: `/${NAVIGATION.MANAGEROLES}`,
  manageorganization: `/${NAVIGATION.MANAGEORGANIZATION}`,
  info: `/${NAVIGATION.INFO}`,
  bin: `/${NAVIGATION.RECYCLEBIN}`,
  usersTash: `${NAVIGATION.HOME}/userstrash`,
  roleTrash: `${NAVIGATION.HOME}/rolestrash`,
  licenseExpired: `${NAVIGATION.HOME}/expiredlicense`,

  listUsers: `/${NAVIGATION.LISTUSERS}`,
  addUser: `/${NAVIGATION.ADDUSER}`,
  viewUser: `/${NAVIGATION.MANGAGEUSERS}/:id&view=true`,
  updateUser: `/${NAVIGATION.MANGAGEUSERS}/updateuser/:userid`,
  usermaster: `/${NAVIGATION.MANGAGEUSERS}/:id`,

  rolemaster: `/${NAVIGATION.MANAGEROLES}/:id`,
  roleview: `/${NAVIGATION.MANAGEROLES}/:id&view=true`,
  createRole: `/${NAVIGATION.CREATEROLE}/:appName`,
  viewRole: `/${NAVIGATION.MANAGEROLES}/:roleid/:appName`,
  viewRoleInfo: `/${NAVIGATION.MANAGEROLES}/`,
  updateRole: `/${NAVIGATION.MANAGEROLES}/updaterole/:roleid/:appName`,
  updateRoleInfo: `/${NAVIGATION.MANAGEROLES}/updaterole/`,
}
