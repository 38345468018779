import { TFunction } from 'i18next'

export const convertToLocaleDate = (dateToConvert: string, t: TFunction): string => {
  const locale = getIsoCode(t)
  return dateToConvert ? new Date(dateToConvert as string).toLocaleString(locale) : '-'
}

export const getCurrentUnixTime = (): number => {
  const unixTimeStamp = Math.round(new Date().getTime() / 1000)
  return unixTimeStamp
}

export const getUserName = (email: string): string => {
  return email.split('@')[0]
}

export const getRemainingDays = (dateToConvert?: string): number => {
  if (dateToConvert) {
    const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = expiryDate - currentDate
    return Math.ceil(diffTime / (1000 * 3600 * 24))
  }
  return 0
}
export const isLicenseExpired = (date?: string): boolean => {
  return date ? getRemainingDays(date) <= 0 : true
  //return true
}

export const getIsoCode = (t?: TFunction, locale?: string): string => {
  //console.log({ t })
  return locale || (t && t('i18n.local')) || 'de-DE'
}

export const getTranslatedValue = (appName: string, key: string, t: TFunction): string => {
  switch (appName) {
    case 'iam':
      return t(`iam:${key}`)
    case 'leak-reporter':
      return t(`leakage:${key}`)
    case 'cs-network':
      return t(`network:${key}`)
    case 'license':
      return t(`license:${key}`)
    default:
      return ''
  }
}
