import React from 'react'
import {
  AppRole,
  AppUserAssignRole,
  RoleInput,
  StateOfRole,
  UserLicenseGroupInput,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useGetAccessibleRolesQuery,
  useGetAppRoleQuery,
  useGetAppUserRolesQuery,
  useUpdateRoleMutation,
  useUpdateRoleStateMutation,
} from '../../api/iam.models'
import { useAuthContext } from '../components/partials'
import { getOrganization } from '../helpers/manageTokens'
import { useGetLicenseList } from './organizationHooks'
import { getErrorMessage } from './TranslationHelperHook'
import { useOrganizations } from './useOranizations'
import { useMemo } from 'react'

export type RolesListResp = {
  rolesList: AppRole[]
  rolesListLoading?: boolean
  rolesListError?: string
  reload: () => void
}
export const useRolesList = (application: string, roleState: StateOfRole, userName?: string): RolesListResp => {
  const { t } = useAuthContext()

  const { data, loading, refetch } = useGetAccessibleRolesQuery({
    variables: {
      realmName: getOrganization(),
      appName: application,
      state: roleState,
    },
    skip: !getOrganization() || !application,
  })

  // const accessRolesList = React.useMemo(() =>  {
  //   if (data?.getAccessibleRoles.length) return data.getAccessibleRoles
  //   return []
  // }, [data?.getAccessibleRoles])

  // const { data, error, loading, refetch } = useGetAccessibleRolesQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     appName: application,
  //     username: userName ? userName : username,
  //     state: roleState,
  //   },
  //   skip: !application || !roleState || !getOrganization() || !username,
  // })
  //const errorMessage = useGetErrorMessage(error) ?? error?.message

  const rolesList = useMemo(() => {
    if (!data?.getAccessibleRoles.length) return []
    return data.getAccessibleRoles as AppRole[]
  }, [data?.getAccessibleRoles.length])

  return {
    rolesList: rolesList,
    rolesListLoading: loading,
    //rolesListError: error ? getErrorMessage(error, t) ?? error.message : undefined,
    reload: refetch,
  }
}

export type RolesListExResp = {
  rolesAssignable?: AppUserAssignRole[]
  rolesAssignableLoading?: boolean
  rolesAssignableError?: string
}
export const useAssignableRolesList = (
  application: string,
  roleState: StateOfRole,
  subordinates: string[],
  userName: string,
  userLicenses: UserLicenseGroupInput[],
): RolesListExResp => {
  const { username, t } = useAuthContext()

  const { data, error, loading } = useGetAppUserRolesQuery({
    variables: {
      realmName: getOrganization(),
      appName: application,
      subordinateIDs: subordinates,
      superiorID: userName,
      userLicenses: userLicenses,
      //username: !userName ? username : userName,
      // state: roleState
      //licenseTypes: licenseTypes,
    },
    skip: !application || !getOrganization() || !userName,
  })
  const userAppRoles = React.useMemo(() => {
    if (data?.getAppUserRoles.length) return data.getAppUserRoles
  }, [data?.getAppUserRoles])
  return {
    rolesAssignable: userAppRoles,
    rolesAssignableLoading: loading,
    rolesAssignableError: error?.message,
  }
}

export type GetRoleResponse = {
  roleInfo?: AppRole
  roleInfoError?: string
  roleInfoLoading?: boolean
}

export const useRoleInfo = (application: string, roleName: string): GetRoleResponse => {
  const { t } = useAuthContext()

  const { data, error, loading } = useGetAppRoleQuery({
    variables: {
      realmName: getOrganization(),
      appName: application,
      roleID: roleName,
    },
    skip: !roleName || !application || !getOrganization(),
  })

  // const { data, error, loading } = useGetRoleQuery({
  //   variables: { realmName: getOrganization(), roleName: roleName, appName: application },
  //   skip: !getOrganization() || !roleName || !application,
  // })
  //const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    roleInfo: data?.getAppRole ? (data.getAppRole as AppRole) : undefined,
    roleInfoError: error ? getErrorMessage(error, t) ?? error.message : undefined,
    roleInfoLoading: loading,
  }
}

export type SpecialResourcesResponse = {
  specialResourcesList?: undefined
  specialResourcesListError?: string
  specialResourcesLoading?: boolean
}

export const useSpecialResources = (application: string): SpecialResourcesResponse => {
  // const { data, error, loading } = useGetSpecialResourcesQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     appName: application,
  //   },
  //   skip: !application || !getOrganization(),
  // })
  // const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    // specialResourcesList: data,
    // specialResourcesListError: errorMessage,
    // specialResourcesLoading: loading,
  }
}

export const useMetaSpecialResources = (application: string): SpecialResourcesResponse => {
  const { t } = useAuthContext()
  // const { data, error, loading } = useGetMetaAndSpecialResourcesQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     appName: application,
  //   },
  //   skip: !application || !getOrganization(),
  // })
  //const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    // specialResourcesList: data,
    // specialResourcesListError: error ? getErrorMessage(error, t) ?? error.message : undefined,
    // specialResourcesLoading: loading,
  }
}

export type CreateUpdateRoleResponse = {
  confirmCreateUpdateRole: (application: string, orgRolePermmissions: RoleInput) => Promise<void>
  roleCreatedUpdated?: boolean | null
  creatingUpdateRole?: boolean
  createUpdateRoleError?: string
}

export const useCreateRole = (): CreateUpdateRoleResponse => {
  const [updateRoleFn, { data, loading, error }] = useCreateRoleMutation()
  const { username, t } = useAuthContext()
  //const errorMessage = error ? getErrorMessage(error, t) ?? error?.message : ''
  const submitRole = async (application: string, orgRolePermmissions: RoleInput) => {
    try {
      await updateRoleFn({
        variables: {
          realmName: getOrganization(),
          appName: application,
          orgRolePerm: orgRolePermmissions,
        },
        // refetchQueries: [
        //   {
        //     query: GetAccessibleRolesDocument,
        //     variables: {
        //       realmName: getOrganization(),
        //       appName: application,
        //       username: username,
        //       state: StateOfRole.Active,
        //     },
        //   },
        // ],
      })
    } catch (error) {
      console.error('error creating role', error)
    }
  }

  return {
    confirmCreateUpdateRole: submitRole,
    roleCreatedUpdated: data?.createRole,
    creatingUpdateRole: loading,
    createUpdateRoleError: error ? getErrorMessage(error, t) ?? error.message : undefined,
  }
}

export type UpdateRoleResp = {
  confirmUpdate: (application: string, orgRolePermmissions: RoleInput) => Promise<void>
  roleupdated?: boolean | null
  updatingRole?: boolean
  UpdateRoleError?: string
}

export const useUpdateRole = (roleName: string): UpdateRoleResp => {
  const [updateRoleFn, { data, error }] = useUpdateRoleMutation()
  const { t } = useAuthContext()

  //const textErrorMessage = error ? getErrorMessage(error, t) : ''

  //console.log({ textErrorMessage })

  //const errorMessage = useGetErrorMessage(error) ?? error?.message
  const submitRole = async (application: string, orgRolePermmissions: RoleInput) => {
    //console.log('----orgedit state', orgRolePermmissions)
    try {
      await updateRoleFn({
        variables: {
          realmName: getOrganization(),
          appName: application,
          orgRolePerm: orgRolePermmissions,
        },
      })
      //   refetchQueries: [
      //     {
      //       query: GetAccessibleRolesDocument,
      //       variables: {
      //         realmName: getOrganization(),
      //         appName: application,
      //         username: username,
      //         state: 'active' as StateOfRole,
      //       },
      //     },
      //   ],
      // })
    } catch (error) {
      console.error('error creating role', error)
    }
  }

  return {
    confirmUpdate: submitRole,
    roleupdated: data?.updateRole,
    // updatingRole: loading,
    UpdateRoleError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
  }
}

export type MoveRoleResp = {
  roleMoved?: boolean
  roleMoving?: boolean
  roleMovingError?: string
  confirmRoleState: (roleName: string) => Promise<void>
}

export const useRoleStateChange = (appName: string, state: StateOfRole): MoveRoleResp => {
  const [updateRoleState, { data, loading, error }] = useUpdateRoleStateMutation()
  const { username, t } = useAuthContext()

  const submitMutation = async (roleName: string) => {
    try {
      await updateRoleState({
        variables: {
          realmName: getOrganization(),
          state: state,
          roleID: roleName,
          appName: appName,
        },
      })
    } catch (err) {
      console.log(`err`, err)
    }
  }
  return {
    // roleMoved: !!data?.updateRoleState,
    // roleMoving: loading,
    // roleMovingError: error ? getErrorMessage(error, t) ?? error.message : undefined,
    confirmRoleState: submitMutation,
  }
}

export type DeleteRoleResponse = {
  confirmDeleteRole: (roleName: string) => Promise<void>
  roleDeleted?: boolean | null
  roleDeleting?: boolean
  roleDeletingError?: string
}

export const useDeleteRole = (appName: string): DeleteRoleResponse => {
  const { username, t } = useAuthContext()
  const [deleteRoleFn, { data, error, loading }] = useDeleteRoleMutation()

  const submitToDelete = async (roleID: string) => {
    try {
      await deleteRoleFn({
        variables: {
          realmName: getOrganization(),
          appName: appName,
          roleID: roleID,
        },
      })
      //   refetchQueries: [
      //     {
      //       query: GetAccessibleRolesDocument,
      //       variables: {
      //         realmName: getOrganization(),
      //         appName: appName,
      //         username: username,
      //         state: StateOfRole.Intrash,
      //       },
      //     },
      //   ],
      // })
    } catch (error) {
      console.error('error deleting role', error)
    }
  }
  return {
    confirmDeleteRole: submitToDelete,
    // roleDeleted: data?.deleteRole,
    // roleDeleting: loading,
    // roleDeletingError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
  }
}

export const useCanCreateRole = (): boolean => {
  const accessibleOrgs = useOrganizations()
  const { daysRemaining } = useGetLicenseList()
  const createUserRoles = ['adduserandrole']
  const ifCanCreateRole = React.useMemo(() => {
    const canCreateRole = false
    if (accessibleOrgs.length && getOrganization() && daysRemaining && daysRemaining > 0) {
      const userAppAccessRoles = accessibleOrgs.find((o) => o.realmName === getOrganization())?.appRoles
      return !!userAppAccessRoles
        ?.find((app) => app.appName === 'am')
        ?.roles.some((role) => createUserRoles.includes(role.roleName))
    }

    return canCreateRole
  }, [accessibleOrgs])
  //console.log('ifCancreateUser', ifCanCreateUser)
  return ifCanCreateRole
}
