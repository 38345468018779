import React, { FormEvent, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Button, FormHelperText, InputLabel, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import constants from '../../constants/constants'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../helpers/routes'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflow: 'hidden',
      padding: theme.spacing(0, 3),
    },
    paper: {
      maxWidth: 400,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
    },
  }),
)

const { EMAIL_PATTERN } = constants.USER_CREDENTIAL_PATTERN

const ForgotPassword: React.FC = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const { t } = useTranslation('common')
  const history = useHistory()

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    console.log('email :>> ', email)
    history.push(ROUTES.home)
  }
  return (
    <>
      <Grid container direction="column">
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                <Typography>Forgot Password</Typography>
              </Grid>
              <Grid>
                <form onSubmit={handleSubmit}>
                  <InputLabel htmlFor="reset-password" shrink>
                    email
                  </InputLabel>
                  <TextField
                    id="reset-password"
                    placeholder={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    aria-describedby="user-email"
                  />
                  {!email.match(EMAIL_PATTERN) && (
                    <FormHelperText id="reset-password">{t('login.username')}</FormHelperText>
                  )}
                  <Button
                    variant="contained"
                    /*  disabled={!email.match(EMAIL_PATTERN)} */ color="secondary"
                    type="submit"
                  >
                    {t('login.resetPassword')}
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Grid>
    </>
  )
}
export default ForgotPassword
