import React, { useEffect } from 'react'

import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  Badge,
  Grid,
  Tooltip,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next/'
import { LicenseGroup } from '../../api/iam.models'
import { OrganizationPermissions, useOrganizations } from '../hooks/useOranizations'
import ActionWithDescription from '../components/partials/ActionWithDescription/ActionWithDescription'
import { useGetAssignablePackets, useGetLicenseList } from '../hooks/organizationHooks'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded'
import { getRemainingDays, getUserName } from '../helpers/utils'
import ExampleTrans from '../components/partials/ExampleTrans'
import RenewLicense from '../components/partials/RenewLicense'
import { ProgressBar, ResultAlert, useAuthContext } from '../components/partials'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(0, 3),
      width: '100%',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing(3),
      minWidth: 300,
    },
    overview: {
      margin: theme.spacing(2),
      padding: theme.spacing(3),
      border: '2px #212121 solid',
      borderRadius: 4,
    },
  }),
)

export interface SelectOrganizationProps {
  open: boolean
  onClose: () => void
}

function SelectOrganization({ onClose, open }: SelectOrganizationProps) {
  const { t } = useTranslation('common')
  const { selectRealm } = useAuthContext()

  const Organizations: OrganizationPermissions[] = useOrganizations()

  const handleListItemClick = (value: string) => {
    selectRealm(value)
  }

  return (
    <Dialog onClose={onClose} disableEscapeKeyDown aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t('labels.selectOrganization')}</DialogTitle>
      <List>
        {Organizations?.map((org: OrganizationPermissions) => (
          <ListItem
            divider
            button
            disabled={org.licenseExpired}
            onClick={() => handleListItemClick(org.realmName)}
            key={org.realmName}
          >
            <ListItemText primary={org.displayName} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export type PacketGroup = {
  packetId?: string
  packetName?: string
  expiryDate?: string
  groupNameKey?: string
  groupName?: string
  typeSizes?: {
    licenseType?: string
    typeKey?: string
    assigned?: number
    total?: number
  }[]
}

const Home: React.FC = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const { selectRealm, globalRealmName, username } = useAuthContext()
  const { t } = useTranslation('common')
  const [lg, setLg] = React.useState<LicenseGroup[]>([])
  const [packetGroups, setPacketGroups] = React.useState<PacketGroup[]>([{}])
  const Organizations = useOrganizations()
  const { licenseGroups, licensesLoading, error, daysRemaining } = useGetLicenseList()
  const { assignableLicensePacket } = useGetAssignablePackets()

  useEffect(() => {
    if (Organizations.length === 1) {
      selectRealm(Organizations[0].realmName)
    }
  }, [Organizations])

  const organizationName = React.useMemo(() => {
    return Organizations.find((o) => o.realmName === globalRealmName)?.displayName ?? ''
  }, [Organizations, globalRealmName])

  const offerRequested = React.useMemo(() => {
    return Organizations.find((o) => o.realmName === globalRealmName)?.licenseOfferRequestStatus ?? true
  }, [Organizations, globalRealmName])

  React.useEffect(() => {
    setPacketGroups([])
    if (licenseGroups?.length && assignableLicensePacket?.length) {
      let packetNames = licenseGroups.flatMap((licGrp) =>
        licGrp.licenseTypes.flatMap((ltp) =>
          ltp?.licensePackets.flatMap((pkt) => ({
            packetId: pkt?.packetId,
            packetName: pkt?.packetName,
          })),
        ),
      )
      packetNames = packetNames.filter((p, ix) => packetNames.findIndex((pn) => pn?.packetId === p?.packetId) === ix)
      // let packts: { packetId?: string; packetName?: string }[] = []
      // new Set(packetNames).forEach((pkt) => (packts = [...packts, { ...pkt }]))
      const licPackets = licenseGroups.flatMap((lgrp) =>
        packetNames.map((pkt) => ({
          packetId: pkt?.packetId,
          packetName: pkt?.packetName,
          groupName: lgrp.groupname,
          groupNameKey: lgrp.displayNameKey,
          typeSizes: lgrp.licenseTypes.map((lp) => ({
            licenseType: lp?.licenseType ?? '',
            typeKey: lp?.displayNameKey ?? '',
            assigned: lp?.licensePackets.find((pks) => pks?.packetId === pkt?.packetId)?.assignedLicenses ?? 0,
            total: lp?.licensePackets.find((pks) => pks?.packetId === pkt?.packetId)?.numberOfLicenses ?? 0,
          })),
        })),
      )
      const showLicPackets = licPackets
        .filter((p) => assignableLicensePacket.some((asp) => asp.packetId === p.packetId))
        .map((pk) => ({
          ...pk,
          expiryDate: assignableLicensePacket.find((asp) => asp.packetId === pk.packetId)?.expiryDate,
        }))
      setPacketGroups(showLicPackets)
      setLg(licenseGroups)
    }
  }, [licenseGroups, assignableLicensePacket, globalRealmName])

  return (
    <div className={classes.margin}>
      <ActionWithDescription description="" heading={t('heading.homeScreen')} />
      <ExampleTrans name={getUserName(username)} organization={organizationName} />
      <br />
      <ResultAlert showAlert={!!error} alertText={`${error}`} severity="error" />
      <ProgressBar show={licensesLoading} />
      <Grid item container spacing={2}>
        {lg?.map((l) => (
          <Grid
            key={l.groupname}
            item
            xs={10}
            md={5}
            lg={6}
            container
            justifyContent="center"
            //className={classes.overview}
            style={{ border: '0.7px solid', borderRadius: '1.2em' }}
            spacing={2}
          >
            <Grid item container spacing={2}>
              <Grid item>
                <Typography gutterBottom>
                  {t('labels.licenseOverview', {
                    app: l.groupname.toLocaleUpperCase(),
                    organization: organizationName,
                  })}
                </Typography>
                <RenewLicense days={daysRemaining ?? 0} appName={l.groupname} offerRequested={offerRequested} />
              </Grid>

              {/* <Grid item>
                <Typography align="justify" gutterBottom>
                  {t('labels.expiresOn')}: {expiresOn}
                </Typography>
              </Grid> */}
              {/*  <Grid item lg={2} /> */}
              {/* <Grid item>
                <RenewLicense days={daysRemaining ?? 0} appName={l.groupname} />
              </Grid> */}
            </Grid>
            <Grid item />

            {packetGroups.map((pg) => (
              <Grid
                item
                container
                key={pg.packetName}
                spacing={2}
                style={{ border: '0.2px dotted', borderRadius: '1.2em', margin: '2rem 2rem 2rem 2rem' }}
              >
                <Grid item xs={5} lg={4}>
                  <Typography variant="inherit" align="center" gutterBottom>
                    <strong>{pg?.packetName?.toUpperCase() + ':'}</strong>
                    <strong> {t('labels.expiresIn', { days: getRemainingDays(pg?.expiryDate) })}</strong>
                  </Typography>
                </Grid>
                {pg?.typeSizes?.map((lt, idx) => (
                  <Grid
                    item
                    container
                    key={`${lt?.licenseType}` + idx}
                    spacing={2}
                    alignItems="center"
                    alignContent="space-between"
                  >
                    <Grid item xs={4} lg={4}>
                      <Typography variant="inherit" align="center" gutterBottom>
                        <strong>{t(`license:${lt?.typeKey}`).toUpperCase()}</strong>
                      </Typography>
                    </Grid>

                    <Grid item xs={2} lg={3}>
                      <Badge
                        badgeContent={lt?.total}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        showZero
                        color="secondary"
                      >
                        <Tooltip
                          title={
                            t('toolTip.totalLicenses', {
                              type: t(`license:${lt?.typeKey}`),
                            }) as string
                          }
                          placement="right"
                        >
                          <LocalOfferIcon fontSize="large" />
                        </Tooltip>
                      </Badge>
                    </Grid>

                    <br />

                    <Grid item xs={2} lg={3}>
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        showZero
                        badgeContent={lt?.assigned}
                        color="primary"
                      >
                        <Tooltip
                          title={
                            t('toolTip.assignedLicenses', {
                              type: t(`license:${lt?.typeKey}`),
                            }) as string
                          }
                          placement="right"
                        >
                          <AssignmentRoundedIcon fontSize="large" />
                        </Tooltip>
                      </Badge>
                    </Grid>
                  </Grid>
                ))}

                {/* <Divider orientation="horizontal" /> */}
                <Grid item xs={12} lg={12} md={12} />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>

      {Organizations.length > 1 && !globalRealmName && (
        <SelectOrganization open={open} onClose={() => setOpen(false)} />
      )}
    </div>
  )
}

export default Home
