import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Alert from '@material-ui/lab/Alert'

export type ResultAlertProps = {
  alertText: string
  showAlert: boolean
  severity?: 'error' | 'warning' | 'success' | 'info'
  autoHideDuration?: number
}

export const ResultAlert: React.FC<ResultAlertProps> = ({
  showAlert,
  severity,
  autoHideDuration,
  alertText,
}: ResultAlertProps) => {
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false)

  useEffect(() => {
    if (showAlert) {
      setShowAlertMessage(true)
    }
  }, [showAlert])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showAlertMessage}
      autoHideDuration={autoHideDuration ?? 6000}
      onClose={(e?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          setShowAlertMessage(false)
          setShowAlertMessage(true)
          // if doubleclick ('clickaway') we want the message to be shown again. Do not leave with modifyShowAlert(false)
          // => return here:
          return
        }
        setShowAlertMessage(false)
        e?.preventDefault()
      }}
    >
      <Alert onClose={() => setShowAlertMessage(false)} severity={severity ?? 'success'}>
        {alertText}
      </Alert>
    </Snackbar>
  )
}
