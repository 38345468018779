import { FilePermissionExSimple, StateOfResource } from '../../../api/iam.models'

export type FileSystemType = {
  fileID: string
  fileName: string
  parentFileID?: string
  superiorHasAccess: boolean
  required: boolean
  fileType: string
  inTrash: boolean
  selectable: boolean
  subFiles?: FileSystemType[]
}

export const fileExToFileSystemType = (files?: FilePermissionExSimple[]): FileSystemType[] | undefined => {
  return files
    ?.map((f) => ({
      fileID: f.fileID,
      fileName: f.filename,
      parentFileID: f.parentFileID ? f.parentFileID : undefined,
      selectable: f.selectable,
      required: f.required,
      superiorHasAccess: f.superiorHasAccess,
      inTrash: f.metaInfo?.state === StateOfResource.Intrash,
      fileType: f.filetypeID,
    }))
    .sort((f1, f2) => (f1.fileName > f2.fileName ? 1 : -1))
}

export const transformToFileHierarchy = (files?: FilePermissionExSimple[]): FileSystemType[] | undefined => {
  if (files?.length === 1) {
    const sFile = files[0]
    return [
      {
        fileID: sFile.fileID,
        fileName: sFile.filename,
        parentFileID: sFile.parentFileID ? sFile.parentFileID : undefined,
        selectable: sFile.selectable,
        required: sFile.required,
        superiorHasAccess: sFile.superiorHasAccess,
        inTrash: sFile.metaInfo?.state === StateOfResource.Intrash,
        fileType: sFile.filetypeID,
      },
    ]
  }
  return fileExToTree(files)
}

const fileExToTree = (files?: FilePermissionExSimple[]): FileSystemType[] => {
  const toTransform =
    files?.map((fl) => ({
      fileID: fl.fileID,
      fileName: fl.filename,
      parentFileID: fl.parentFileID ? fl.parentFileID : undefined,
      inTrash: fl.metaInfo?.state === StateOfResource.Intrash,
      selectable: fl.selectable,
      required: fl.required,
      superiorHasAccess: fl.superiorHasAccess,
      fileType: fl.filetypeID,
    })) ?? []

  return toTree(toTransform.sort((f1, f2) => (f1.fileName > f2.fileName ? 1 : -1)))
}
export const toTree = (items: FileSystemType[], fileID?: string): FileSystemType[] => {
  const res = items
    .filter((item) => item.parentFileID === fileID)
    .map((item) => ({
      ...item,
      subFiles: toTree(items, item.fileID),
    }))
  if (res.length) {
    return res
  }
  return []
}
