import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { /* OrganizationRole, */ AppRole, StateOfRole } from '../../../api/iam.models'
import { ConfirmationModal, FixedActionBar } from '@csinstruments/cs-react-theme'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../helpers/routes'
import { Grid, Typography } from '@material-ui/core'
import RoleTable from './RoleTable'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../components/partials/DataTable/DataTableTypes'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import { useCanCreateRole, useRolesList, useRoleStateChange } from '../../hooks/roleHooks'
import { ProgressBar, ResultAlert } from '../../components/partials'
import { getTranslatedValue } from '../../helpers/utils'
import { i18n, TFunction } from 'i18next'

type AppRolesProps = {
  appName: string
}
const ViewRolePermissions: React.FC<AppRolesProps> = ({ appName }: AppRolesProps) => {
  const { t, i18n } = useTranslation('common')

  const [deleteRoleID, setDeleteRoleID] = useState<string>('')
  const [deleteAction, setDeleteAction] = useState(false)
  const { rolesList, rolesListError, rolesListLoading, reload } = useRolesList(appName, StateOfRole.Active)
  const [tableRow, setTableRow] = useState<DataTableRow[]>([])

  const { confirmRoleState, roleMoved, roleMoving, roleMovingError } = useRoleStateChange(appName, StateOfRole.Intrash)

  const columns: DataTableColumn[] = [
    { label: t('role'), alignment: 'left' },
    { label: t('description'), alignment: 'left' },
    { label: t('labels.preset'), alignment: 'left' },
  ]

  const updateRoleTable = (t: TFunction, i18n: i18n, orgRole?: AppRole[]): DataTableRow[] => {
    let tempRows: DataTableRow[] = []
    //console.log('accessibleRoles', getAccessibleRoles)
    const sortedRoles = orgRole?.slice().sort((a, b) => (a.role.roleName > b.role.roleName ? 1 : -1))
    tempRows =
      (sortedRoles?.map((item) => ({
        id: item?.role.roleId,
        values: !item.isCustomRole
          ? [
              getTranslatedValue(appName, `${item?.role.roleDisplayKey}`, t),
              getTranslatedValue(appName, `${item?.role.roleDescriptionKey}`, t),
              t('labels.yes'),
            ]
          : [
              item.appRoleInfo?.reduce(
                (crRoleinfo, roleDispVal) =>
                  roleDispVal.langKey === i18n.language && roleDispVal.roleDisplayName
                    ? roleDispVal.roleDisplayName
                    : crRoleinfo,
                item.role.roleName,
              ),
              item.appRoleInfo?.reduce(
                (crRoleinfo, roleDispVal) =>
                  roleDispVal.langKey === i18n.language && roleDispVal.roleDescription
                    ? roleDispVal.roleDescription
                    : crRoleinfo,
                '',
              ),
              t('labels.custom'),
            ],

        // : [
        //     item?.appRoleInfo?.reduce((dn, s) => (s? === i18n.language ? s.value : dn), item.roleName),
        //     item?.description?.find((ds) => ds?.language === i18n.language && ds.value)?.value ??
        //       `${
        //         item?.description?.find((ds) => ds?.language !== i18n.language && ds?.value && ds.value)?.value ?? ''
        //       }`,
        //     t('labels.custom'),
        //   ],
        scopes: item?.userAccessScopes ? item.userAccessScopes : [],
      })) as DataTableRow[]) || []
    return tempRows
  }

  useEffect(() => {
    setTableRow(updateRoleTable(t, i18n, rolesList))
  }, [rolesList, appName, roleMoved, roleMoving, t, i18n])

  const ifCanCreateRole = useCanCreateRole()

  const history = useHistory()

  const handleCreateRole = () => {
    history.push(generatePath(ROUTES.rolemaster, { id: '_role' }) + `?app=${appName}`)
  }

  const handleClick = (id: string, action: DataItemAction): void => {
    // const isPreconfig = rolesList?.getAccessibleRoles?.some(
    //   (role) => role?.roleName === id && role.preConfigured,
    // ) as boolean
    const isPreconfig = false
    const roleid = tableRow?.reduce((clickedID, row) => {
      return row.id === id ? row.id : clickedID
    }, '')
    switch (action) {
      case 'delete':
        setDeleteRoleID(roleid)
        setDeleteAction(!isPreconfig)
        break
      case 'view':
        history.push(generatePath(ROUTES.roleview, { id: roleid }) + `?app=${appName}`)
        break
      case 'edit':
        history.push(generatePath(ROUTES.rolemaster, { id: roleid }) + `?app=${appName}`)
        break
    }
  }

  const confirmDeleteRole = () => {
    confirmRoleState(deleteRoleID)
      .then(() => reload())
      .catch(() => console.log('moving to trash error'))
  }

  return (
    <>
      <ProgressBar show={rolesListLoading || roleMoving} />

      <ResultAlert
        showAlert={!!rolesListError || !!roleMovingError}
        alertText={`${rolesListError || roleMovingError}`}
        severity="error"
      />
      {deleteAction && (
        <ConfirmationModal
          handleConfirm={confirmDeleteRole}
          handleClose={() => setDeleteAction(false)}
          open={true}
          textCancel={t('actions.cancel')}
          textConfirm={'OK'}
          textMain={t('confirmation.textmain')}
          textHeading={t('confirmation.textheading')}
        />
      )}
      <RoleTable rowData={tableRow} columnData={columns} edit view remove actionCall={handleClick} />
      {/* {!tableRow.length && (
        <Grid item container alignContent="center" justifyContent="center">
          <Typography variant="subtitle1" gutterBottom>
            {t('labels.tableEmpty')}
          </Typography>
        </Grid>
      )} */}
      <FixedActionBar
        actions={[handleCreateRole]}
        labels={[t('actions.add')]}
        largeButtons
        fixedButtonSizes={[2]}
        icons={[LibraryAddIcon]}
        buttonProps={[{ solid: true }]}
        conditions={[ifCanCreateRole]}
      />
    </>
  )
}

export default ViewRolePermissions
