import { Fade, Grid, Tooltip, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import { AppState, FilePermission } from '../../../../api/iam.models'
import SelectAppRoles from './SelectRoles'
import { ActionType } from '../UserContext/userActions'
import { useManageUserContext } from '../UserContext/UserProvider'

import UserResources from './UserResources'
import { useTranslation } from 'react-i18next'
import { SelectedSingleUserLicense } from '../UserContext/initialState'

interface UserAppResProps {
  appName: string
  userAppState?: AppState[]
  selectedLicenseInfo?: SelectedSingleUserLicense
}

const UserAppResources: React.FC<UserAppResProps> = ({
  appName,
  userAppState,
  selectedLicenseInfo,
}: UserAppResProps) => {
  const { t } = useTranslation('common')

  const { state, dispatch, editUserId } = useManageUserContext()

  function handleResourceFile(fp: FilePermission): void {
    const currentFilePerms = state.appStates.find((ap) => ap?.appID === appName)?.filePermission ?? []
    const selectedIds = currentFilePerms.some((cf) => cf.fileID === fp.fileID)
      ? currentFilePerms.filter((cfid) => cfid.fileID != fp.fileID)
      : [...currentFilePerms, fp]
    dispatch({
      type: ActionType.UpdateAppStateInput,
      payload: {
        ...(state.appStates.find((ap) => ap?.appID === appName) as AppState),
        filePermission: selectedIds.some((p) => !p.parentFileID)
          ? selectedIds.filter((f) => !f.parentFileID)
          : selectedIds,
      },
    })
  }

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item container xs={9} spacing={1}>
          <Grid item>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: { enter: 400 } }}
              title={<Typography variant="subtitle1">{t('descriptions.appResources')}</Typography>}
              placement="top-start"
            >
              <Typography display="inline">
                {t('labels.appResources') + ' '} <InfoOutlinedIcon fontSize="small" />
              </Typography>
            </Tooltip>
          </Grid>

          <UserResources handleFiles={handleResourceFile} appName={appName} />
        </Grid>

        <Grid item container xs={3} justifyContent="flex-start" alignContent="flex-start" spacing={1}>
          <Grid item>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: { enter: 400 } }}
              title={<Typography variant="subtitle1">{t('descriptions.appRoles')}</Typography>}
              placement="top-start"
            >
              <Typography display="inline">
                {t('labels.appRoles') + ' '}
                <InfoOutlinedIcon fontSize="small" />
              </Typography>
            </Tooltip>
          </Grid>
          <SelectAppRoles appName={appName} selectedLicenseInfo={selectedLicenseInfo} />
        </Grid>
      </Grid>
    </>
  )
}

export default UserAppResources
