import { Breadcrumbs as MUIBreadcrumbs, createStyles, makeStyles, Theme, Link, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
//import { navigationLinkStyle } from './partials/Navigation/NavigationStyle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      marginTop: theme.spacing(4),
    },
  }),
)

const NavBreadcrumbs: React.FC = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const history = useHistory()
  //don't show query parameters
  const filterPath = pathname.split('/').map((s) => (s.includes('&') ? s.substring(0, s.indexOf('&' || '?')) : s))
  const pathnames = filterPath.some((somePath) => somePath.includes('https'))
    ? ([] as string[])
    : filterPath.filter((path) => path)
  const { t } = useTranslation('common')
  return (
    <div className={classes.breadcrumb}>
      <MUIBreadcrumbs aria-label="breadcrumb">
        {pathnames.length > 0 ? (
          <Link variant="subtitle2" onClick={() => history.push('/')} /* style={navigationLinkStyle} */>
            <HomeOutlinedIcon fontSize="small" /> Home
          </Link>
        ) : (
          <Typography variant="subtitle2">
            <HomeOutlinedIcon fontSize="small" />
            Home
          </Typography>
        )}

        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
          const isLast = index === pathnames.length - 1
          return isLast ? (
            <Typography key={index} variant="subtitle2">
              {t(name)}{' '}
            </Typography>
          ) : (
            <Link
              key={index}
              variant="subtitle2"
              onClick={() => history.push(routeTo)} /* style={navigationLinkStyle} */
            >
              {t(name)}
            </Link>
          )
        })}
      </MUIBreadcrumbs>
    </div>
  )
}

export default withRouter(NavBreadcrumbs)
