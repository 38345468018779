import { accessTokenVar, userId } from './cache'

export const setAccessToken = (accToken: string): void => {
  localStorage.setItem('accessToken', accToken)
  accessTokenVar(accToken)
}

export const getAccessToken = (): string => {
  return localStorage.getItem('accessToken') || ''
}

export const setOrganization = (org: string): void => {
  localStorage.setItem('_regRealm', org)
}

export const getOrganization = (): string => {
  return localStorage.getItem('_regRealm') || ''
}

export const setDisplayOrg = (dispName?: string): void => {
  dispName && localStorage.setItem('_disp', dispName)
}

export const getDispName = (): string => {
  return localStorage.getItem('_disp') ?? ''
}

export const deleteTokens = (): void => {
  localStorage.clear()
}

export const setIdToken = (idToken?: string): void => {
  idToken && localStorage.setItem('_id', idToken)
}
export const getIdToken = (): string => {
  return localStorage.getItem('_id') || ''
}
