import { Grid } from '@material-ui/core'
import React from 'react'

import ApplicationLicenses from './ApplicationLicenses'
import UserExpertPermissions from './ExpertPermissions'
import { SelectedSingleUserLicense } from '../UserContext/initialState'
import { MinimalUserInfo } from '../../../../api/iam.models'
//import SubordinatePermissions from './SubordinatePermissions'
export type AccessRightProps = {
  updateSingleUserLicenseInfo?: (singleUserLic: SelectedSingleUserLicense) => void
  selectedSingleUserLicenseInfo?: SelectedSingleUserLicense
  superiorInfo?: MinimalUserInfo
  updateSuperiorInfo?: (supInfo: MinimalUserInfo) => void
  subordinatesInfo?: MinimalUserInfo[]
  selectedAssignableLicense: SelectedSingleUserLicense[]
  updatedSelAssignLicense: (asl: SelectedSingleUserLicense[]) => void
  updateSubordinatesInfo?: (userInfo: MinimalUserInfo[]) => void
}
const AccessRights: React.FC<AccessRightProps> = ({
  updateSingleUserLicenseInfo,
  selectedSingleUserLicenseInfo,
  subordinatesInfo,
  superiorInfo,
  updatedSelAssignLicense,
  selectedAssignableLicense,
  updateSubordinatesInfo,
  updateSuperiorInfo,
}: AccessRightProps) => {
  return (
    <>
      <Grid item container justifyContent="space-between" alignItems="flex-start" spacing={4}>
        <ApplicationLicenses
          updateSelectedSingleUserLicense={updateSingleUserLicenseInfo}
          selectedSingleUserLicense={selectedSingleUserLicenseInfo}
        />
        <UserExpertPermissions
          selectedSingleUserLicInfo={selectedSingleUserLicenseInfo}
          superiorInfo={superiorInfo}
          subordinatesInfo={subordinatesInfo}
          updateSubordinatesInfo={updateSubordinatesInfo}
          updateSuperiorInfo={updateSuperiorInfo}
          updatedSelAssignLicense={updatedSelAssignLicense}
          selectedAssignableLicense={selectedAssignableLicense}
        />
        {/*  <SubordinatePermissions /> */}
      </Grid>
    </>
  )
}

export default AccessRights
