import React from 'react'
import { Box, SvgIcon } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { buttonContainer, descriptionContainer } from './ActionWithDescription.styles'
import { CsButtonPrimary } from '@csinstruments/cs-react-theme'

export type ActionWithDescriptionProps = {
  heading: string
  description: string
  buttonText?: string
  onClick?: () => void
  icon?: typeof SvgIcon
}

export const ActionWithDescription: React.FC<ActionWithDescriptionProps> = ({
  heading,
  description,
  buttonText,
  onClick,
  icon,
}: ActionWithDescriptionProps) => {
  const shownIcon = icon ? React.createElement(icon, { style: { marginRight: 5 } }) : <></>

  return (
    <>
      <Box color="secondary.main">
        <Typography variant="h5">{heading}</Typography>
      </Box>
      <div style={descriptionContainer}>
        <Typography variant="body1">{description}</Typography>
      </div>
      {(buttonText || icon) && onClick && (
        <div style={buttonContainer}>
          <CsButtonPrimary onClick={onClick} label={buttonText} style={{ paddingLeft: 50, paddingRight: 50 }}>
            {shownIcon}
          </CsButtonPrimary>
        </div>
      )}
    </>
  )
}

export default ActionWithDescription
