import { Checkbox, Tooltip, Typography, Fade, FormControlLabel, Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useGetSpecMetaResources } from '../../../hooks/rolePermissionhooks'
import { useRoleManagementContext } from '../RoleContext/RoleProvider'
import { RoleActionType } from '../RoleContext/roleActions'
import { useGetQueryParams } from '../../../hooks/helperhooks/routingHooks'
import { useStyles } from '../../UserManagement/User.Styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { ProgressBar } from '../../../components/partials'
import { useTranslation } from 'react-i18next'
import { getTranslatedValue } from '../../../helpers/utils'
import { Permission, PermissionInput } from '../../../../api/iam.models'

const SpecialPermissions: React.FC = () => {
  const classes = useStyles()
  const { state, dispatch, editRoleId } = useRoleManagementContext()
  const { singleParam } = useGetQueryParams([], 'app')
  const appName = singleParam ?? ''
  const { t } = useTranslation('common')
  const uAssignby = state.userPermissions?.assignableBy.length ? (state.userPermissions.assignableBy as string[]) : []
  const uEditby = state.userPermissions?.editableBy.length ? (state.userPermissions.editableBy as string[]) : []
  const { specialResourcesData, dataLoading } = useGetSpecMetaResources(false, appName, editRoleId, uEditby, uAssignby)
  const [metaSpecPerms, setMetaSpecPerms] = useState<Permission[]>([])

  const [metaSpecPermInp, setMetaSpecPermInp] = useState<PermissionInput[]>([])

  const [lockResDep, setLockResDep] = useState<string[]>([])
  const restrictedScopes = ['read-id', 'noaccess-id']

  // const changeMetaScopes = useCallback(
  //   (scopeID: string, specPerm: Permission) => {
  //     //const scopeName = value as string
  //     const prevSpecialPermission = metaSpecPermInp
  //     const _tempMetaSpecialPermInput = metaSpecPermInp.map((sp) =>
  //       specPerm.resourceId === sp.resourceId
  //         ? {
  //             ...sp,
  //             scopeId: sp.scopeId?.includes(scopeID)
  //               ? sp.scopeId.filter((sc) => sc !== scopeID)
  //               : [...(sp.scopeId as string[]), scopeID],
  //           }
  //         : sp,
  //     )
  //     setMetaSpecPermInp(_tempMetaSpecialPermInput)
  //     dispatch({
  //       type: RoleActionType.UpdateMetaSpecialPermInput,
  //       payload: _tempMetaSpecialPermInput,
  //     })

  //     const hasDependencies = _tempMetaSpecialPermInput.some(
  //       (minp) =>
  //         minp.resourceId === specPerm.resourceId &&
  //         minp.scopeId?.includes(scopeID) &&
  //         specPerm.resourceScopes?.some(
  //           (dp) =>
  //             dp?.scopeID === scopeID &&
  //             dp.scopeDependencies?.some((met) => met.resourceID && met.requiredScopes.length),
  //         ),
  //     )
  //     if (hasDependencies) {
  //       const reqMetaScopes = specPerm.resourceScopes?.find((spd) => spd?.scopeID === scopeID)?.scopeDependencies ?? []
  //       const reqMetaSpecialPermInputs = _tempMetaSpecialPermInput.map((mperm) =>
  //         reqMetaScopes?.some((reqMet) => reqMet.resourceID === mperm.resourceId)
  //           ? {
  //               ...mperm,
  //               scopeId: reqMetaScopes
  //                 ?.find((rs) => rs.resourceID === mperm.resourceId)
  //                 ?.requiredScopes?.every((scop) => scop && mperm.scopeId?.includes(scop))
  //                 ? mperm.scopeId ?? []
  //                 : reqMetaScopes.find((rs) => mperm.resourceId === rs?.resourceID)?.requiredScopes,
  //             }
  //           : mperm,
  //       ) as PermissionInput[]
  //       const crSpecialDepNames = reqMetaScopes?.map((meta) => meta?.resourceID as string) ?? []
  //       const filterNames = crSpecialDepNames.includes(specPerm.resourceId)
  //         ? crSpecialDepNames.filter((sx) => sx !== specPerm.resourceId)
  //         : crSpecialDepNames
  //       const tempGrpNames = groupDeps.length
  //         ? groupDeps.some((grp) => grp.group !== specPerm.groupID)
  //           ? [
  //               ...groupDeps,
  //               {
  //                 group: specPerm.groupID,
  //                 dependencies: filterNames,
  //               },
  //             ].filter((p) => p.dependencies.length)
  //           : groupDeps.map((gp) =>
  //               gp.group === specPerm.groupID
  //                 ? {
  //                     ...gp,
  //                     dependencies: gp.dependencies.includes(specPerm.resourceId)
  //                       ? gp.dependencies.filter((d) => d !== specPerm.resourceId)
  //                       : filterNames,
  //                   }
  //                 : gp,
  //             )
  //         : [
  //             {
  //               group: specPerm.groupID,
  //               dependencies: filterNames,
  //             },
  //           ]
  //       setGroupDeps(tempGrpNames)
  //       setMetaSpecPermInp(reqMetaSpecialPermInputs)
  //       dispatch({
  //         type: RoleActionType.UpdateMetaSpecialPermInput,
  //         payload: reqMetaSpecialPermInputs,
  //       })
  //     } else {
  //       /** uncheck all dependent specialPermissionScope */
  //       const disableReqMetaScopes = specPerm.resourceScopes?.find((spd) => spd?.scopeID === scopeID)?.scopeDependencies
  //       const filteredMetaScopes = disableReqMetaScopes?.map((mt) => ({
  //         metaResourceID: mt?.resourceID ?? '',
  //         scopes: mt?.requiredScopes.filter((sc) => sc && restrictedScopes.includes(sc)),
  //       }))
  //       const filterGrps = groupDeps.some((grp) => grp.group === specPerm.groupID)
  //         ? groupDeps.filter((x) => x.group !== specPerm.groupID)
  //         : groupDeps
  //       setGroupDeps(filterGrps)
  //       const disMetaSpecialPermInputs = prevSpecialPermission.map((mperm) =>
  //         filteredMetaScopes?.some((reqMet) => reqMet?.metaResourceID === mperm.resourceId)
  //           ? {
  //               ...mperm,
  //               accessibleScopes: filteredMetaScopes
  //                 .find((rs) => mperm.resourceId === rs?.metaResourceID)
  //                 ?.scopes?.every((scop) => scop && !mperm.scopeId?.includes(scop))
  //                 ? mperm.scopeId
  //                 : filteredMetaScopes?.find((rs) => mperm.resourceId === rs?.metaResourceID)?.scopes,
  //             }
  //           : mperm,
  //       ) as PermissionInput[]
  //       setMetaSpecPermInp(disMetaSpecialPermInputs)
  //       dispatch({
  //         type: RoleActionType.UpdateMetaSpecialPermInput,
  //         payload: disMetaSpecialPermInputs,
  //       })
  //     }
  //   },
  //   [metaSpecPermInp],
  // )

  const changeMetaScopes = (scopeID: string, perm: Permission): void => {
    const prvPermInput = metaSpecPermInp

    const updPerm = prvPermInput.map((minp) =>
      minp.resourceId === perm.resourceId
        ? {
            ...minp,
            scopeId: minp.scopeId?.includes(scopeID)
              ? minp.scopeId.filter((sp) => sp !== scopeID)
              : [...(minp?.scopeId as string[]), scopeID],
          }
        : minp,
    )

    const hasDependencies = updPerm.some(
      (cp) =>
        cp.resourceId === perm.resourceId &&
        cp.scopeId?.some(
          (cps) =>
            cps === scopeID && perm.resourceScopes.some((prs) => prs.scopeID === cps && prs.scopeDependencies?.length),
        ),
    )
    const depResourcePermInp: PermissionInput[] = perm.resourceScopes
      .flatMap((prs) =>
        prs.scopeDependencies?.length
          ? (prs.scopeDependencies?.map((pds) => ({
              resourceId: pds.resourceID,
              scopeId: pds.requiredScopes.filter((rs) => rs && !restrictedScopes.includes(rs)),
            })) as PermissionInput[])
          : [],
      )
      .filter((p) => p.scopeId?.length)
    //console.log({ hasDependencies }, { depResourcePermInp })
    if (hasDependencies) {
      setLockResDep(depResourcePermInp.map((p) => p.resourceId).filter((p) => perm.resourceId !== p))
      const refreshDepMinp = updPerm.map((p) => {
        if (depResourcePermInp.some((dp) => dp.resourceId === p.resourceId)) {
          const dpresScopes = depResourcePermInp.find((dp) => dp.resourceId === p.resourceId)?.scopeId ?? []
          return {
            ...p,
            scopeId: p?.scopeId?.some((ps) => dpresScopes?.includes(ps))
              ? p.scopeId
              : [...(p.scopeId ?? []), ...dpresScopes],
          }
        }
        return p
      })
      setMetaSpecPermInp(refreshDepMinp)
      dispatch({
        type: RoleActionType.UpdateMetaSpecialPermInput,
        payload: refreshDepMinp,
      })
    } else {
      const unlockDep = lockResDep.filter((p) => !depResourcePermInp.some((dp) => dp.resourceId === p))
      setLockResDep(unlockDep)
      const uncheckResDep = updPerm.map((p) => {
        if (depResourcePermInp.some((dp) => dp.resourceId === p.resourceId && dp.scopeId?.length)) {
          const dpScopes = depResourcePermInp.find((dpi) => dpi.resourceId === p.resourceId)?.scopeId
          return {
            ...p,
            scopeId: p?.scopeId?.some((cps) => dpScopes?.includes(cps))
              ? p.scopeId.filter((psi) => psi && !dpScopes?.includes(psi))
              : p.scopeId,
          }
        }
        return p
      })
      setMetaSpecPermInp(uncheckResDep)
      dispatch({
        type: RoleActionType.UpdateMetaSpecialPermInput,
        payload: uncheckResDep,
      })
    }
  }

  useEffect(() => {
    if (state.rolePermissions?.length && !metaSpecPermInp.length) {
      setMetaSpecPermInp(state.rolePermissions)
      // editRoleId
      //   ? setSpecPermEditInputs(state.metaSpecialResPerms as MetaSpecialResourcePermissionInput[])
      //   : setMetaSpecPermInp(state.metaSpecialResPerms as MetaSpecialResourcePermissionInput[])
    }
  }, [])

  useEffect(() => {
    if (specialResourcesData?.length) {
      setMetaSpecPerms(specialResourcesData)
      if (!state.rolePermissions?.length) {
        const initRolePermsissions = specialResourcesData.map((p) => ({
          resourceId: p.resourceId,
          scopeId: p.resourceScopes.reduce(
            (scopeAcc: string[], rsc) =>
              ['noaccess-id', 'read-id'].includes(rsc.scopeID) ? [...scopeAcc, rsc.scopeID] : scopeAcc,
            [],
          ),
        })) as PermissionInput[]
        setMetaSpecPermInp(initRolePermsissions)
        dispatch({
          type: RoleActionType.UpdateMetaSpecialPermInput,
          payload: initRolePermsissions,
        })
      }
      // if (editRoleId) {
      //   setMetaSpecPermsEditReq(specialResourcesData as MetaSpecialResourcePermissionEx[])
      // } else {
      //   const _tempMetaSpecialPermInput = (specialResourcesData as MetaSpecialResourcePermission[]).map((sp) => ({
      //     name: sp.name,
      //     accessibleScopes: sp.accessibleScopes.filter((scope) => ['read', 'noaccess'].includes(`${scope}`)),
      //     type: sp.type,
      //   }))
      //   dispatch({
      //     type: RoleActionType.UpdateMetaSpecialPermInput,
      //     payload: _tempMetaSpecialPermInput,
      //   })
      //   setMetaSpecPermInp(_tempMetaSpecialPermInput)
      //   setMetaSpecPerms(specialResourcesData as MetaSpecialResourcePermission[])
      // }
    }
  }, [specialResourcesData?.length])

  if (metaSpecPerms.length) {
    return (
      <Grid item xs md lg spacing={2} container alignContent="center" className={classes.overview}>
        {metaSpecPerms
          ?.slice()
          .sort((a, b) => (a.groupID > b.groupID ? 1 : -1))
          .sort((a, b) => (a.indexID > b.indexID ? 1 : -1))
          .map((si) =>
            metaSpecPermInp.map(
              (pi, idx) =>
                pi.resourceId === si.resourceId && (
                  <Grid key={si?.resourceId + idx} item container xs={8} lg={6} alignItems="center">
                    <Grid item xs lg={7}>
                      <Typography variant="body1" gutterBottom>
                        {getTranslatedValue(appName, `${si.resourceDisplayKey}`, t)}
                      </Typography>
                    </Grid>
                    <Grid item xs lg={2}>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: { enter: 400 } }}
                        title={
                          <Typography variant="subtitle1">
                            {getTranslatedValue(appName, `${si?.resourceDescriptionKey}`, t)}
                          </Typography>
                        }
                        placement="right-end"
                      >
                        <Typography display="inline">
                          <InfoOutlinedIcon fontSize="small" />
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item container alignContent="space-between">
                      {si.resourceScopes
                        .slice()
                        .sort((a, b) => (a.scopeID > b.scopeID ? 1 : -1))
                        .map((resSp) => (
                          <Grid item key={resSp.scopeID}>
                            {resSp.scopeID != 'noaccess-id' ? (
                              <FormControlLabel
                                label={getTranslatedValue(appName, `${resSp.scopeDisplayKey}`, t)}
                                control={
                                  <Checkbox
                                    checked={pi.scopeId?.includes(resSp.scopeID)}
                                    value={resSp.scopeID}
                                    required={!!resSp.scopeAssignRequired}
                                    disabled={
                                      restrictedScopes.includes(resSp.scopeID) ||
                                      lockResDep.includes(pi.resourceId) ||
                                      si.resourceForbidden
                                    }
                                    onChange={({ target }) => changeMetaScopes(target.value, si)}
                                    disableRipple
                                    disableTouchRipple
                                  />
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                ),
            ),
          )}
      </Grid>
    )
  }
  // if (metaSpecPermsEditReq.length) {
  //   return (
  //     <>
  //       <Grid item spacing={2} container alignContent="center" className={classes.overview}>
  //         {metaSpecPermsEditReq
  //           .slice()
  //           .sort((a, b) =>
  //             (a?.groupId as number) * 100 + (a?.index as number) > (b?.groupId as number) * 100 + (b?.index as number)
  //               ? 1
  //               : -1,
  //           )
  //           .map((sr) =>
  //             specPermEditInputs?.map(
  //               (si) =>
  //                 si.name === sr.name && (
  //                   <Grid
  //                     key={sr.groupId * 2 + sr.index * 2}
  //                     item
  //                     alignContent="center"
  //                     container
  //                     spacing={2}
  //                     direction="column"
  //                   >
  //                     <Grid item container xs={2} lg={8}>
  //                       <Grid item xs lg={7}>
  //                         <Typography variant="body1" gutterBottom>
  //                           {getTranslatedValue(appName, `${sr.displayNameKey}`, t)}
  //                         </Typography>
  //                       </Grid>
  //                       <Grid item xs lg={2}>
  //                         <Tooltip
  //                           arrow
  //                           TransitionComponent={Fade}
  //                           TransitionProps={{ timeout: { enter: 500 } }}
  //                           title={
  //                             <Typography variant="subtitle1">
  //                               {getTranslatedValue(appName, `${sr.descriptionKey}`, t)}
  //                             </Typography>
  //                           }
  //                           placement="top-start"
  //                         >
  //                           <Typography display="inline">
  //                             <InfoOutlinedIcon fontSize="small" />
  //                           </Typography>
  //                         </Tooltip>
  //                       </Grid>
  //                       <Grid item container alignContent="space-between">
  //                         {sr.scopeDescriptions
  //                           ?.slice()
  //                           .sort((a, b) => {
  //                             return (a?.scope as string) > (b?.scope as string) ? 1 : -1
  //                           })
  //                           .map((srd, idx) => (
  //                             <Grid key={idx} item xs={10} lg={4}>
  //                               {srd?.scope !== 'noaccess' && (
  //                                 <FormControlLabel
  //                                   label={
  //                                     <>
  //                                       {getTranslatedValue(appName, `${srd?.displayNameKey}`, t)}

  //                                       {sr.accessibleScopes.some(
  //                                         (sc) =>
  //                                           sc?.scopeName === srd?.scope && sc?.required === MsrRequirement.Required,
  //                                       ) && <> *</>}
  //                                     </>
  //                                   }
  //                                   control={
  //                                     <Checkbox
  //                                       disabled={
  //                                         /* sr.accessibleScopes.some(
  //                                           (sc) =>
  //                                             sc?.scopeName &&
  //                                             si.accessibleScopes.includes(sc?.scopeName) &&
  //                                             sc?.required === MsrRequirement.Forbidden,
  //                                         ) || */
  //                                         ['read', 'noaccess'].includes(`${srd?.scope}`) ||
  //                                         groupDeps.some(
  //                                           (g) => g.group === sr?.groupId && g.dependencies.includes(si?.name),
  //                                         )
  //                                       }
  //                                       required={sr.accessibleScopes.some(
  //                                         (sc) =>
  //                                           sc?.scopeName === srd?.scope &&
  //                                           sc?.required === ('REQUIRED' as MsrRequirement),
  //                                       )}
  //                                       value={srd?.scope}
  //                                       checked={si.accessibleScopes.includes(srd?.scope as string)}
  //                                       onChange={({ target }) => editMetaScopes(target.value, sr)}
  //                                       name={sr.name}
  //                                     />
  //                                   }
  //                                 />
  //                               )}
  //                             </Grid>
  //                           ))}
  //                       </Grid>
  //                     </Grid>

  //                     <Divider orientation="horizontal" />
  //                   </Grid>
  //                 ),
  //             ),
  //           )}
  //       </Grid>
  //     </>
  //   )
  // }
  return (
    <>
      <ProgressBar show={dataLoading} />
    </>
  )
}
export default SpecialPermissions
