import { Box, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TextInputField } from '../../../components/partials/inputElements/TextInputField'
import { ActionType } from '../UserContext/userActions'
import { useManageUserContext } from '../UserContext/UserProvider'

import constants from '../../../../constants/constants'
import {
  ExternType,
  LicenseUser,
  RegisteredUserInfo,
  UserInfo,
  useValidateUserInfoLazyQuery,
  useValidateUserInfoQuery,
} from '../../../../api/iam.models'
import { useTranslation } from 'react-i18next'
//import { useUserExists } from '../../../hooks/helperhooks/errorHooks'
import { ActionWithDescription } from '../../../components/partials/ActionWithDescription/ActionWithDescription'

const {
  USER_CREDENTIAL_PATTERN: { EMAIL_PATTERN },
} = constants

const checkValidEmail = (emailString?: string) => {
  return !emailString || !EMAIL_PATTERN.test(emailString)
}

const AddUserInfo: React.FC = () => {
  const { state, editUserId, dispatch, registeredUser } = useManageUserContext()
  const { t } = useTranslation('common')
  const [validEmail, setValidEmail] = useState<string>()

  useEffect(() => {
    if (state.userInfo?.email && !validEmail) {
      updateValidEmail(state.userInfo.email)
    }
  }, [])

  const updateValidEmail = (inpEmail?: string) => {
    setTimeout(() => {
      setValidEmail(inpEmail)
    }, 300)
  }

  const isInValidEmail = useMemo(() => {
    return checkValidEmail(validEmail)
  }, [validEmail])

  const { data } = useValidateUserInfoQuery({
    variables: {
      username: validEmail ?? '',
    },
    skip: isInValidEmail,
  })

  const currentUserInfo = useMemo(() => {
    if (data?.getUserInfo) {
      return data.getUserInfo
    }
  }, [data?.getUserInfo])

  const userInfoHandle = useCallback(
    (name: string, value: string) => {
      //console.log('name', name, 'val', value)

      if (name === 'email') {
        const emailVal = value.toLowerCase()

        dispatch({
          type: ActionType.UpdateUserInfoIput,
          payload: {
            ...state.userInfo,
            email: emailVal,
          } as UserInfo,
        })
        updateValidEmail(emailVal)
      }
      if (name === 'extern') {
        dispatch({
          type: ActionType.UpdateUserInfoIput,
          payload: {
            ...state.userInfo,
            extern: value as ExternType,
          } as UserInfo,
        })
      }
      if (name === 'note') {
        dispatch({
          type: ActionType.UpdateUserInfoIput,
          payload: {
            ...state.userInfo,
            note: value,
          } as UserInfo,
        })
      }
    },
    [state],
  )

  const changeLogin = (checked: boolean) => {
    dispatch({
      type: ActionType.UpdateLoginInput,
      payload: checked,
    })
  }
  //console.log('state', state.userInfo)

  return (
    <>
      <Grid container>
        <Grid container item xs={6} lg={4} spacing={2}>
          <Grid item>
            <Box pr={4}>
              <ActionWithDescription
                heading={t('heading.loginCredentials')}
                description={t('descriptions.loginCredentials')}
              />
            </Box>
          </Grid>

          <TextInputField
            columns={6}
            mdColumns={11}
            variant="outlined"
            name={'email'}
            required
            label={editUserId && !registeredUser ? t('labels.newUsername') : t('labels.username')}
            //displayOnly={registeredUser}
            error={isInValidEmail}
            helperText={currentUserInfo?.licenseUser === LicenseUser.Csuser ? <i>{t('labels.validCSUser')}</i> : <></>}
            value={state.userInfo?.email}
            changeHandler={userInfoHandle}
          />
          <Grid item xs={6} md={11}>
            <TextField
              variant="outlined"
              type="text"
              name="extern"
              label={t('labels.origin')}
              value={state.userInfo?.extern as string}
              required={!!state.userInfo?.extern}
              onChange={({ target }) => userInfoHandle(target.name, target.value)}
              fullWidth
              select
            >
              <MenuItem value="extern">{t('labels.externLabel')}</MenuItem>
              <MenuItem value="intern">{t('labels.internLabel')}</MenuItem>
            </TextField>
          </Grid>
          {/* <Grid item xs={6} md={11}>
            <TextField
              variant="outlined"
              type="text"
              name="note"
              label={'Note'}
              value={state.userInfo.note ?? ''}
              onChange={({ target }) => userInfoHandle(target.name, target.value)}
              fullWidth
            />
          </Grid> */}
        </Grid>
        <Grid item xs={6} lg={4} />
        <Grid item xs={6} lg={4}>
          <Box pr={4}>
            <ActionWithDescription heading={t('heading.loginEnabled')} description={t('descriptions.loginEnabled')} />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                //value={inviteUser}
                checked={state.enabled}
                onChange={({ target }) => changeLogin(target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={t('labels.loginEnabled')}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AddUserInfo
