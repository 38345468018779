import { Grid } from '@material-ui/core'

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppState, FilePermission } from '../../../../api/iam.models'
import { ProgressBar } from '../../../components/partials/ProgressBar/ProgressBar'
import { ResultAlert } from '../../../components/partials/ResultAlert'
import { getUserName } from '../../../helpers/utils'
import { useCanCreateCompany } from '../../../hooks/userHooks'
import { CompanyFileSystem, useDirResources } from '../../../hooks/userResourceHooks'
import { useManageUserContext } from '../UserContext/UserProvider'

import CreateCompany from './CreateCompany'
import { FileSystemType } from '../../../hooks/helperhooks/transformToFileHierarchy'

import { FileHierarchy } from '../../../components/partials/FileHierarch/FileHierarchy'
import { CsButtonPrimary } from '@csinstruments/cs-react-theme'

interface ResourceTreeProps {
  handleFiles: (fileID: FilePermission) => void
  appName: string
}
const UserResources: React.FC<ResourceTreeProps> = ({ handleFiles, appName }: ResourceTreeProps) => {
  const { state } = useManageUserContext()
  const [unAssignedRes, setUniassignedRes] = useState<string[]>([])
  const [showWarning, setWarning] = useState<boolean>(false)
  const [data, setData] = useState<FileSystemType[]>()
  //const [allCompanies, setAllCompanies] = useState<CompanyFileSystem[]>([])

  const { t } = useTranslation('common')
  const allowedToCreateCompany = useCanCreateCompany(appName)
  //console.log({ appName })
  const editableRes = React.useMemo(() => {
    return state.appStates.find((currAp) => currAp?.appID === appName)
  }, [state.appStates]) as AppState

  const {
    dirResourcesList: dirData,
    groups,
    companies,
    dirResourcesLoading: loading,
    dirResourcesError,
    refetch,
  } = useDirResources(
    appName,
    state.superior?.orgUserID as string,
    state.subordinates.map((u) => u.orgUserID) as string[],
  )

  const [createDir, setCreateDir] = React.useState<boolean>(false)

  useEffect(() => {
    if (dirData?.length) {
      //console.log({ dirData })
      setData(dirData)
    }
  }, [dirData])

  const handleSelectedFile = (fl: FileSystemType) => {
    const transformedFile: FilePermission = {
      fileID: fl.fileID,
      filename: fl.fileName,
      parentFileID: fl.parentFileID ?? '',
      filetypeID: fl.fileType,
    }
    handleFiles(transformedFile)
    !fl.superiorHasAccess && updateWarningInfo(fl.fileName)
  }

  const updateWarningInfo = (fileName: string) => {
    const tempRes = !unAssignedRes.includes(fileName)
      ? [...unAssignedRes, fileName]
      : unAssignedRes.filter((x) => x !== fileName)
    setUniassignedRes(tempRes)

    tempRes.includes(fileName) && setWarning(true)
  }

  return (
    <>
      <CreateCompany
        create={createDir}
        createSuccess={(s) => {
          refetch()
          setCreateDir(s)
        }}
        cancelCreate={(c) => {
          setCreateDir(c)
        }}
      />
      <ResultAlert
        showAlert={!!unAssignedRes.length && showWarning}
        alertText={t('warning.assignToSuperior', {
          superior: getUserName(`${state.superior?.email}`),
          resource: unAssignedRes.slice().join(', '),
        })}
        severity="info"
      />
      <ProgressBar show={loading} />

      <ResultAlert showAlert={!!dirResourcesError} alertText={dirResourcesError ?? ''} severity="error" />

      <Grid item xs={12} container>
        <Grid item xs={2} />
        <Grid item xs={10} container spacing={1}>
          <Grid item xs={6}>
            <CsButtonPrimary
              label={t('labels.createCompany')}
              solid
              fullwidth
              disabled={!allowedToCreateCompany}
              onClick={() => setCreateDir(true)}
            />
          </Grid>

          <FileHierarchy
            handleresourceSelection={handleSelectedFile}
            selectedFiles={editableRes.filePermission}
            resourceFiles={data?.length ? data : []}
            companyFiles={companies ?? []}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default UserResources
