import { environment } from './environment'

const userPreferenceOperations: string[] = ['changeOrgPreference', 'updateLangaugePreference']
const { LD_SERVICE, API, USER_PREFERENCE_API, ORG_API } = environment

const organizationOperations: string[] = ['GetOrganizationAccess']

/* getApolloClientURI returns clientURI based on operationName */
export const getApolloClientURI = (operationName: string): string => {
  if (['CreateOrUpdateCompany', 'GetTagAttributes'].includes(operationName)) {
    return LD_SERVICE
  }
  if (organizationOperations.includes(operationName)) {
    return ORG_API
  }
  if (userPreferenceOperations.includes(operationName)) {
    return USER_PREFERENCE_API
  }
  return API.GQL_HTTP_URI
}
