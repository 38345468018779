import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/'
import { Grid, Typography } from '@material-ui/core'
import { deleteTokens } from '../../helpers/manageTokens'
import { useAuthContext } from '.'
import { environment, redirectToHome } from '../../helpers/environment'

import { UserProfile } from '../../../interfaces/KeyCloaktypes'
import { ModalForm } from './ModalForm'

const AccessDenied: React.FC = () => {
  const { keyCloak } = useAuthContext()
  const { t, i18n } = useTranslation('common')

  useEffect(() => {
    if (keyCloak?.authenticated && keyCloak?.userInfo) {
      i18n.changeLanguage((keyCloak.userInfo as UserProfile).locale)
    }
  }, [keyCloak?.userInfo])

  const clickCancel = () => {
    deleteTokens()
    keyCloak?.logout({ redirectUri: redirectToHome })
    //keyCloak?.clearToken()
  }

  const clickActivateLicense = () => {
    window.open(`${environment.USER_SERVICE}/activatelicense`, '_self')
  }

  return (
    <>
      <ModalForm
        formOpen={true}
        formClose={clickCancel}
        headingText={t('error.access_denied')}
        submitForm={clickActivateLicense}
        confirmButtonLabel={t('actions.activate')}
        headingType="error"
      >
        <Grid item container justifyContent="flex-start" alignContent="stretch" spacing={2} direction="column">
          <Grid item xs md lg>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {t('error.access_denied_message')}
            </Typography>
          </Grid>
          <Grid item xs md lg>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {t('error.selectTrial')}
            </Typography>
          </Grid>
          {/* <Grid item>
              <Typography variant="subtitle1" align="center" gutterBottom>
                {t('error.activateOrganization')}
              </Typography>
            </Grid> */}
        </Grid>
      </ModalForm>
      {/* <Dialog
        open
        onClose={clickCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h4" align="center" color="error" gutterBottom>
            {t('error.access_denied')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid item container justifyContent="flex-start" alignContent="stretch" spacing={2} direction="column">
            <Grid item>
              <Typography variant="subtitle1" align="center" gutterBottom>
                {t('error.access_denied_message')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center" gutterBottom>
                {t('error.selectTrial')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container lg alignItems="center" alignContent="flex-start" spacing={2}>
            <Grid item xs lg md>
              <CsButton onClick={clickCancel} solid fullwidth>
                {t('actions.cancel')}
              </CsButton>
            </Grid>

            <Grid item xs lg md>
              <CsButton onClick={clickActivateLicense} solid fullwidth color="secondary">
                {t('actions.activate')}
              </CsButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog> */}
    </>
  )
}

export default AccessDenied
