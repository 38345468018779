const constants = {
  DATE: {
    DEFAULT_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    DATE_FORMAT: 'yyyy-MM-dd',
    GERMAN_DATE_TIME: 'dd.MM.yyyy HH:mm:ss',
    ZONED_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
  },
  LOGGING: {
    LOGGERS: {
      BASE: 'sighnhere-management.base',
    },
    LEVELS: {
      TRACE: { index: 0, key: 'trace' },
      DEBUG: { index: 1, key: 'DEBUG' },
      INFO: { index: 2, key: 'info' },
      WARN: { index: 3, key: 'warn' },
      ERROR: { index: 4, key: 'error' },
    },
  },
  NAVIGATION: {
    HOME: 'iamfrontend',
    REGISTER_ORGANIZATION: 'iamfrontend/register-organization',
    FORGOT_PASSWORD: 'iamfrontend/reset-password',
    DASHBOARD: 'iamfrontend/dashboard',
    SINGLESIGNIN: 'iamfrontend/singlesignin',
    LOGIN: 'iamfrontend/login',

    SERVER_ERROR_PAGE: 'iamfrontend/server-error',
    NO_ACCESS_PAGE: 'iamfrontend/no-access',
    SESSION_EXPIRED: 'iamfrontend/session-expired',
  },
  USER_CREDENTIAL_PATTERN: {
    // ref: https://stackoverflow.com/questions/69477123/regex-test-causes-too-much-recursion-error-in-javascript
    // avoid complex email pattern to prevent too much recursion and backtracing error in the frontend
    EMAIL_PATTERN: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PASSWORD_PATTERN: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}$/,
    USERNAME_PATTERN: /^\w+$/,
  },
  LANGUAGES: [
    'de',
    'en',
    'cs',
    'es',
    'fr',
    'it',
    'jp',
    'pl',
    'pt',
    'ro',
    'ru',
    'sl',
    'sv',
    'zh',
    'zho',
    'ua',
    /* 'key', */ 'tr',
  ].sort(),
}

export default constants
