import React from 'react'
import { Typography } from '@material-ui/core'

import GetUsers from './GetUsers'

import { useTranslation } from 'react-i18next/'

const ManageUsers: React.FC = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <Typography variant="h3" align="center" color="secondary" gutterBottom>
        {t('manageusers')}
      </Typography>
      <GetUsers />
    </>
  )
}

export default ManageUsers
