import Keycloak, { KeycloakConfig } from 'keycloak-js'
import React, { createContext, useEffect, useState, useContext, ReactNode, useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import { environment } from '../../../helpers/environment'
import { getIdToken, getOrganization, setOrganization } from '../../../helpers/manageTokens'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { UserProfile } from '../../../../interfaces/KeyCloaktypes'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

export interface AuthContextData {
  t?: TFunction
  isAuthenticated: boolean
  keyCloak?: Keycloak
  selectRealm: (realmName: string) => void
  globalRealmName: string
  username: string
  userId?: string
  userLanguage?: string
}
export const authContextDefault: AuthContextData = {
  isAuthenticated: false,
  keyCloak: undefined,
  selectRealm: () => null,
  globalRealmName: getOrganization(),
  username: '',
}
export const AuthContext = createContext<AuthContextData>(authContextDefault)

export const useAuthContext = (): AuthContextData => useContext(AuthContext)

const { URL, REALM, CLIENT_ID, ADAPTER_SCRIPT } = environment.OPENID

interface AuthProps {
  children: ReactNode
}
export const AuthProvider: React.FC<AuthProps> = ({ children }: AuthProps): React.ReactElement => {
  const [authenticated, setAuthenticated] = useState(false)
  const [kcInstance, setKCInstance] = useState<Keycloak>()
  const [kcRealm, setKcRealm] = useState<string>(getOrganization())
  const [loggedUser, setLoggedUser] = useState<string>('')
  //const [loggedUserId, setLoggedUserId] = useState<string>()
  const [userLang, setUserLang] = useState<string>()
  const { t, i18n } = useTranslation('common')

  const keycloakConfig: KeycloakConfig = {
    url: URL,
    realm: REALM,
    clientId: CLIENT_ID,
  }
  const history = useHistory()

  const singleSignOnException = (reason: string) => {
    try {
      Sentry.captureException(new Error('Single Sign on exception'), (scope) => {
        scope.setTransactionName('failed to check-SSO')
        scope.setExtra('authentication failed', reason)
        console.log('unable to run SSO')
        return scope
      })
    } catch (e) {
      console.error('failed to send event to sentry', e)
    }
  }
  const adapterLoadingError = (reason: unknown) => {
    try {
      Sentry.captureException(new Error('Adapter Loading Error'), (scope) => {
        scope.setTransactionName('failed to initialise adapter')
        scope.setExtra('unable to fetch remote keycloakjs script', reason)
        console.log('unable to load remote script')
        return scope
      })
    } catch (e) {
      console.error('failed to send event to sentry', e)
    }
  }

  const scriptAlreadyExists = (): boolean => document.querySelector('script#kcadapter') !== null

  const loadAdapterPlugin = () => {
    const script = document.createElement('script')
    script.id = 'kcadapter'
    script.src = ADAPTER_SCRIPT
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      try {
        const _KC = new Keycloak(keycloakConfig)
        _KC
          .init({
            onLoad: 'login-required',
            silentCheckSsoFallback: false,
          })
          .then((_auth: boolean) => {
            setAuthenticated(_auth)
          })
          .catch((_err: unknown) => {
            singleSignOnException(_err as string)
          })
        setKCInstance(_KC)
      } catch (err) {
        console.error('error loading remote script', err)
        adapterLoadingError(err)
        history.push(ROUTES.serverError)
      }
    }
  }

  const setSelectedRealm = useCallback(
    (realmName: string) => {
      setKcRealm(realmName)
      setOrganization(realmName)
      history && history.push(ROUTES.home)
    },
    [history],
  )

  //console.log('accessToken', getAccessToken())
  useEffect(() => {
    if (!authenticated || !scriptAlreadyExists()) {
      loadAdapterPlugin()
    }
    if (authenticated) {
      kcInstance
        ?.loadUserInfo()
        .then((u: UserProfile) => {
          //console.log({ u })
          //setIdToken(u.sub)
          //setLoggedUserId(u.sub)
          u.preferred_org && setSelectedRealm(u.preferred_org)
          setUserLang(u.locale ?? 'en')
          i18n.changeLanguage(u.locale)
          setLoggedUser(u.email as string)
        })
        .catch((e) => console.error(e))
    }
  }, [authenticated])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authenticated,
        keyCloak: kcInstance,
        selectRealm: setSelectedRealm,
        globalRealmName: kcRealm,
        username: loggedUser,
        userLanguage: userLang,
        userId: getIdToken(),
        t: t,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
