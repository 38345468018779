import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useGetOrganizationalLicenseQuery } from '../../../api/iam.models'
import { useTranslation } from 'react-i18next/'
import { getOrganization } from '../../helpers/manageTokens'
import { ProgressBar, ResultAlert } from '../../components/partials'

const ManageOrganization: React.FC = () => {
  const { t } = useTranslation('common')
  const { data, loading, error } = useGetOrganizationalLicenseQuery({ variables: { realmName: getOrganization() } })
  if (!loading && data) {
    //console.log('license info', data.getOrganizationLicense)

    const licenseArray = data?.getOrganizationLicense?.licenseGroups.map((licensegroup) => `${licensegroup?.groupname}`)
    return (
      <>
        <Typography variant="h3" align="center" color="secondary" gutterBottom>
          {t('manageorganization')}
        </Typography>

        <ResultAlert showAlert={!!error?.message} alertText={`${error?.message}`} />

        <Grid item xs={12}>
          <Grid item xs={2} />

          <Typography variant="h5" color="textPrimary" gutterBottom align="center">
            Licence Information
          </Typography>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={4} />
            <Typography variant="subtitle2" color="textPrimary" align="justify" gutterBottom>
              Oraganization Name: {JSON.stringify(licenseArray)} <br />
            </Typography>
            <Grid item xs={12} />
            <Grid item xs={4} />
          </Grid>
        </Grid>
        <Grid item xs={12} />
      </>
    )
  }
  return <ProgressBar show={loading} />
}

export default ManageOrganization
