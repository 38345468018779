import React, { useReducer, createContext, useContext } from 'react'
import { UserState, UserStateInput } from '../../../../api/iam.models'
import { useAuthContext } from '../../../components/partials'
import { initUserState, intialSimpleUser } from './initialState'
import { UserStateInputActions } from './userActions'
import { userInputFormReducer } from './userReducer'

interface UserManagementContextProps {
  children: React.ReactNode
  editUserId?: string
  registeredUser?: boolean
  loginEnabled?: boolean
  currentSimpleUserState?: UserState
}

interface UserManagementContextType {
  state: UserState
  loginEnabled?: boolean
  registeredUser?: boolean
  editUserId?: string
  editableState?: UserState
  dispatch: React.Dispatch<UserStateInputActions>
}

export const UserManagement = createContext<UserManagementContextType>({
  state: initUserState,
  loginEnabled: false,
  registeredUser: false,
  editableState: undefined,
  dispatch: () => undefined,
})

export const useManageUserContext = (): UserManagementContextType =>
  useContext<UserManagementContextType>(UserManagement)

export const UserManagementProvider: React.FC<UserManagementContextProps> = ({
  children,
  editUserId,
  registeredUser,
  loginEnabled,
  currentSimpleUserState,
}: UserManagementContextProps) => {
  //const { username } = useAuthContext()
  const reducerState: UserState = React.useMemo(() => {
    if (currentSimpleUserState) return currentSimpleUserState
    return { ...initUserState }
  }, [currentSimpleUserState])
  //console.log('currentReduce', reducerState)
  const [formstate, dispatch] = useReducer(userInputFormReducer, reducerState)
  return (
    <UserManagement.Provider
      value={{
        state: formstate,
        loginEnabled: loginEnabled,
        registeredUser: registeredUser,
        editUserId: editUserId,
        editableState: reducerState,
        dispatch: dispatch,
      }}
    >
      {children}
    </UserManagement.Provider>
  )
}
