import { PermissionInput, RoleDisplayInput, RoleInput } from '../../../../api/iam.models'

export enum RoleActionType {
  UpdateOrgRoleInput,
  UpdateMetaSpecialPermInput,
  UpdateEditableBy,
  UpdateAssignableBy,
  UpdateAppDisplayInfo,
  UpdateOrgRolePermissionInput,
  UpdateRoleName,
}

export interface UpdateOrgRolePermissionInput {
  type: RoleActionType.UpdateOrgRolePermissionInput
  payload: RoleInput
}
export interface UpdateOrgRoleInput {
  type: RoleActionType.UpdateOrgRoleInput
  payload: RoleInput
}

export interface UpdateRoleName {
  type: RoleActionType.UpdateRoleName
  payload: string
}

export interface UpdateMetaSpecialPermInput {
  type: RoleActionType.UpdateMetaSpecialPermInput
  payload: PermissionInput[]
}

export interface UpdateEditableBy {
  type: RoleActionType.UpdateEditableBy
  payload: string[]
}

export interface UpdateAssignableBy {
  type: RoleActionType.UpdateAssignableBy
  payload: string[]
}

export interface UpdateAppDisplayInfo {
  type: RoleActionType.UpdateAppDisplayInfo
  payload: RoleDisplayInput[]
}

export type RoleStateInputActions =
  | UpdateOrgRoleInput
  | UpdateAssignableBy
  | UpdateAppDisplayInfo
  | UpdateEditableBy
  | UpdateMetaSpecialPermInput
  | UpdateOrgRolePermissionInput
  | UpdateRoleName
