import {
  UserInfoInput,
  SingleUserLicenseInput,
  UserStateInput,
  AppStateInput,
  ActivatedCloudLicenseInput,
  UserInfo,
  SingleUserLicense,
  MinimalUserInfo,
  AppState,
  UserState,
  AssignableLicense,
} from '../../../../api/iam.models'

export interface AddUserInfoInput {
  type: ActionType.AddUserInfoInput
  payload: UserInfo
}

export interface UpdateUserInfoIput {
  type: ActionType.UpdateUserInfoIput
  payload: UserInfo
}

export interface UpdateLicenseInput {
  type: ActionType.UpdateLicenseInput
  payload: SingleUserLicense[]
}

export interface AddLicenseInput {
  type: ActionType.AddLicenseInput
  payload: SingleUserLicense[]
}

export interface UpdateSupInput {
  type: ActionType.UpdateSupInput
  payload: MinimalUserInfo
}

export interface UpdateSubInput {
  type: ActionType.UpdateSubInput
  payload: MinimalUserInfo[]
}

export interface UpdateLoginInput {
  type: ActionType.UpdateLoginInput
  payload: boolean
}

export interface AddAppState {
  type: ActionType.AddAppState
  payload: AppState[]
}

export interface UpdateAppStateInput {
  type: ActionType.UpdateAppStateInput
  payload: AppState
}

export interface EditUserInput {
  type: ActionType.EditUserSimpleInput
  payload: UserState
}
export interface ResetUserSimpleInput {
  type: ActionType.ResetUserSimpleInput
}

export interface UpdateAssignLicesnesInput {
  type: ActionType.UpdateAssingableLicense
  payload: AssignableLicense[]
}

export enum ActionType {
  AddUserInfoInput,
  EditUserSimpleInput,
  UpdateUserInfoIput,
  AddLicenseInput,
  UpdateLicenseInput,
  UpdateSupInput,
  UpdateSubInput,
  UpdateLoginInput,
  AddAppState,
  UpdateAssingableLicense,
  UpdateAppStateInput,
  ResetUserSimpleInput,
}

export type UserStateInputActions =
  | AddUserInfoInput
  | EditUserInput
  | UpdateUserInfoIput
  | AddLicenseInput
  | UpdateLicenseInput
  | UpdateSupInput
  | UpdateSubInput
  | UpdateLoginInput
  | AddAppState
  | UpdateAppStateInput
  | ResetUserSimpleInput
  | UpdateAssignLicesnesInput
