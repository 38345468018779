import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { getOrganization } from '../../helpers/manageTokens'
import { environment } from '../../helpers/environment'
import { CsButton } from '@csinstruments/cs-react-theme'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type LicenseOfferProps = {
  daysRemaining: number
  isOfferRequested: boolean
}

const LicenseOfferPopUp: React.FC<LicenseOfferProps> = ({ daysRemaining, isOfferRequested }: LicenseOfferProps) => {
  const { t } = useTranslation('common')
  const [showOffer, setShowOffer] = useState<boolean>(false)

  useEffect(() => {
    if (daysRemaining <= 30 && !isOfferRequested) {
      setShowOffer(true)
    }
  }, [daysRemaining, isOfferRequested])

  const handleClickOpen = () => {
    const redirectLink = environment.USER_SERVICE + `/offer-request?realmId=${getOrganization()}`
    window.open(redirectLink, '_blank')
    setShowOffer(false)
  }

  const handleClose = () => {
    setShowOffer(false)
  }
  return (
    <Dialog
      open={showOffer}
      onClose={handleClose}
      aria-labelledby="license-notify-title"
      aria-describedby="license-notify-description"
      disableEscapeKeyDown
    >
      <DialogTitle id="license-notify-dialog-title">{t('license.offerRequestHeading') + '?'}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="justify" color="error" gutterBottom>
          {t('license.extendLicense', { days: daysRemaining })}
        </Typography>
        <Typography variant="body2" align="justify" color="secondary" gutterBottom>
          {t('license.extendLicenseInfo')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CsButton onClick={handleClose} fullwidth color="primary">
          {t('modals.cancel')}
        </CsButton>
        <CsButton onClick={handleClickOpen} fullwidth color="primary" solid>
          {t('modals.confirm')}
        </CsButton>
      </DialogActions>
    </Dialog>
  )
}

export default LicenseOfferPopUp
