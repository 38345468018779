import { DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { ProgressBar } from '../../components/partials'
import { useCompanyStats } from '../../hooks/organizationHooks'
import ResourcesTable from './ResourcesTable'

interface AppStatsProps {
  appName: string
}

const ViewAppStatistics: React.FC<AppStatsProps> = ({ appName }: AppStatsProps) => {
  const { t } = useTranslation('common')
  const { companyStatsList, companyStatsError, companyStatsLoading } = useCompanyStats(appName)
  const [statsRows, setStatsRows] = useState<DataTableRow[]>([])

  const columns: DataTableColumn[] = useMemo(() => {
    return [
      { label: t('company'), alignment: 'left' },
      { label: t('labels.userCount'), alignment: 'left', numeric: true },
      { label: t('labels.projectCount'), alignment: 'left', numeric: true },
      { label: t('labels.buildingCount'), alignment: 'left', numeric: true },
    ]
  }, [t])

  useEffect(() => {
    if (companyStatsList?.length) {
      companyStatsList
      const rows = companyStatsList.map((s, idx) => ({
        id: idx * 101 + `${s?.companyName}`,
        values: [`${s?.companyName}`, `${s?.noOfUsers ?? 0}`, `${s.noOfProjects ?? 0}`, `${s?.noOfBuildings ?? 0}`],
      })) as unknown as DataTableRow[]
      setStatsRows(rows)
    }
  }, [companyStatsList])

  if (companyStatsError) return <>{companyStatsError}</>
  return (
    <>
      <ProgressBar show={companyStatsLoading} />
      <ResourcesTable rowData={statsRows} columnData={columns} />
    </>
  )
}

export default ViewAppStatistics
