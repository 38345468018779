import { Grid, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles } from '@material-ui/core'
import { useOrganizations } from '../../hooks/useOranizations'
import { getOrganization, setDisplayOrg } from '../../helpers/manageTokens'
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded'
import { useAuthContext } from '.'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../helpers/routes'
import { useChangeOrgPreferenceMutation } from '../../../api/organization.models'

export const contrast = {
  primary: '#e4f6ed',
  secondary: '#ffffff',
  greyText: '#888888',
}

export const useStyles = makeStyles(() =>
  createStyles({
    select: {
      color: contrast.secondary,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input.MuiInputBase-input': {
        paddingRight: 30,
        paddingLeft: 0,
        paddingTop: -1,
        paddingBottom: -1,
      },
    },
    icon: {
      fill: contrast.primary,
    },
    box: {
      paddingLeft: 60,
    },
  }),
)

const SelectOrganization: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const { globalRealmName, selectRealm } = useAuthContext()

  const [changeOrg] = useChangeOrgPreferenceMutation()

  const history = useHistory()

  const organizationOptions = useOrganizations()
  const changeRealm = useCallback(
    (company: string) => {
      changeOrg({
        variables: {
          realmName: company,
        },
      })
        .then((updated) => console.log({ updated }))
        .catch(() => console.log('failed to update org preference'))
      selectRealm(company)
      setDisplayOrg(organizationOptions.find((o) => o.realmName === company)?.displayName)
      history.push(ROUTES.home)
    },
    [selectRealm],
  )

  return (
    <>
      <Grid item xs lg={1} />
      <BusinessCenterRoundedIcon fontSize="medium" />
      <Select
        className={classes.select}
        variant={'outlined'}
        //defaultValue={getOrganization() || ''}
        onChange={({ target }) => changeRealm(target.value as string)}
        value={
          organizationOptions.some((o) => o.realmName === globalRealmName ?? getOrganization()) ? getOrganization() : ''
        }
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {organizationOptions.map((option) => (
          <MenuItem key={option.realmName} value={option.realmName} disabled={option.licenseExpired} dense>
            <Typography
              component={'p'}
              variant={option.licenseExpired ? 'subtitle2' : 'subtitle1'}
              color={option.licenseExpired ? 'error' : 'inherit'}
            >
              {option.displayName} <br /> {option.licenseExpired ? `(${t('labels.licenseExpired')})` : ''}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default SelectOrganization
