import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
type URLQueryParams = {
  id: string
  params?: string[] | null
  singleParam?: string | null
}
export const useGetQueryParams = (queryStrings?: string[], queryString?: string): URLQueryParams => {
  const location = useLocation().search
  const query = new URLSearchParams(location)
  const getParam = useParams<{ id: string }>()
  //console.log('id', getParam, 'query', query.get(queryString ?? ''))
  const params = useMemo(() => {
    return queryStrings?.map((s) => query.get(s) as string)
  }, [queryStrings])
  return {
    id: getParam.id,
    params: params,
    singleParam: query.get(queryString ?? ''),
  }
}
