import { paletteColors } from '@csinstruments/cs-react-theme'
export const completeContainer = {
  overflowX: 'hidden' as const,
  backgroundColor: paletteColors().primary,
}

export const outerPageContainer = {
  backgroundColor: paletteColors().primary,
}

export const fullHeight = {
  height: '100%',
  lineHeight: '100%',
  verticalAlign: 'center',
  display: 'flex',
  alignItems: 'center',
}

export const innerPageContainer = {
  backgroundColor: '#fff',
  borderTopLeftRadius: 50,
}

export const mainPageContainer = {
  width: '90%',
  margin: 'auto',
  maxHeight: '100%',
  paddingBottom: '20px',
  paddingTop: '10px',
  flexGrow: 1,
} as React.CSSProperties

export const paddingBottomActionBar = {
  paddingBottom: '80px',
}
