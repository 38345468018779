import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Role, UpdateCompany } from '../../../../api/ld.models'
import { TextInputField } from '../../../components/partials/inputElements/TextInputField'
import { useCreateUpdateCompany } from '../../../hooks/organizationHooks'
type CompanyProps = {
  create: boolean
  createSuccess: (success: boolean) => void
  cancelCreate: (cancel: boolean) => void
}
const CreateCompany: React.FC<CompanyProps> = ({ create, createSuccess, cancelCreate }: CompanyProps) => {
  const companyInit: UpdateCompany = {
    company: '',
    contact: {
      email: '',
      phone: '',
      prename: '',
      surname: '',
      role: 'CUSTOMER' as Role,
    },
    image: '',
    address: {
      street: '',
      number: '',
      zip: '',
      city: '',
    },
  }
  const [companyNew, setnewCompany] = React.useState<UpdateCompany>(companyInit)
  const [showError, setShowError] = React.useState<boolean>(false)
  //const [open, setOpen] = React.useState<boolean>(create)

  /* React.useEffect(() => {
    if (!open) {
      setOpen(true)
    }
  }, []) */
  const { t } = useTranslation('common')
  const { newCompany, creatingCompany, confirmCreateCompany, createCompanyerror } = useCreateUpdateCompany(
    companyNew as UpdateCompany,
  )
  const handlenewCompany = (name: string, value: string) => {
    switch (name) {
      case 'company':
        setnewCompany({
          ...companyNew,
          company: value,
        })
        break
      case 'phone':
        setnewCompany({
          ...companyNew,
          contact: {
            ...companyNew.contact,
            phone: value,
          },
        })
        break
      case 'prename':
        setnewCompany({
          ...companyNew,
          contact: {
            ...companyNew.contact,
            prename: value,
          },
        })
        break
      case 'surname':
        setnewCompany({
          ...companyNew,
          contact: {
            ...companyNew.contact,
            surname: value,
          },
        })
        break
      case 'email':
        setnewCompany({
          ...companyNew,
          contact: {
            ...companyNew.contact,
            email: value,
          },
        })
        break
      case 'street':
        setnewCompany({
          ...companyNew,
          address: {
            ...companyNew.address,
            street: value,
          },
        })
        break
      case 'city':
        setnewCompany({
          ...companyNew,
          address: {
            ...companyNew.address,
            city: value,
          },
        })
        break
      case 'zip':
        setnewCompany({
          ...companyNew,
          address: {
            ...companyNew.address,
            zip: value,
          },
        })
        break
      case 'image':
        setnewCompany({
          ...companyNew,
          image: value,
        })
        break
      default:
        return
    }
  }
  const handleSubmit = async () => {
    try {
      await confirmCreateCompany()
    } catch (er) {
      console.log('error submitting form', er)
    }
  }
  React.useEffect(() => {
    if (create) {
      setShowError(false)
    }
  }, [create])

  React.useEffect(() => {
    if (!creatingCompany && newCompany?.createOrUpdateCompany.id) {
      createSuccess(!create)
    } else {
      setnewCompany(companyInit)
    }
    if (createCompanyerror) {
      setShowError(true)
    }
  }, [newCompany, creatingCompany, createCompanyerror])
  return (
    <>
      <div>
        <Dialog open={create} onClose={() => cancelCreate(false)} aria-labelledby="form-dialog-title">
          {!showError ? <DialogTitle id="form-dialog-title">{t('labels.createCompany')}</DialogTitle> : <></>}
          {showError ? <DialogTitle id="form-dialog-title">{createCompanyerror}</DialogTitle> : <></>}
          <DialogContent>
            {/* <DialogContentText>Update the Form fields to Create Company</DialogContentText> */}
            <Grid item container spacing={2}>
              <TextInputField
                name="company"
                label={t('company')}
                required
                value={companyNew.company}
                fullWidth
                mdColumns={12}
                changeHandler={handlenewCompany}
              />
              {/* <TextInputField
                name="prename"
                label={t('labels.firstname')}
                value={companyNew.contact?.prename ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              />
              <TextInputField
                name="surname"
                label={t('labels.lastname')}
                value={companyNew.contact?.surname ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              />
              <TextInputField
                name="email"
                label={'email'}
                value={companyNew.contact?.email ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              />
              <TextInputField
                name="phone"
                label={t('labels.telephone')}
                value={companyNew.contact?.phone ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              />
              <TextInputField
                name="city"
                label={t('labels.city')}
                value={companyNew.address?.city ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              />
              <TextInputField
                name="street"
                label={t('labels.street')}
                value={companyNew.address?.street ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              />
              <TextInputField
                name="zip"
                label={t('labels.zip')}
                value={companyNew.address?.zip ?? ''}
                fullWidth
                changeHandler={handlenewCompany}
              /> */}
            </Grid>
            {/* <Grid container direction="column">
              <Grid item xs={12}>
                <ImageUpload
                  image={companyNew.image as string}
                  imageChanged={(image: string) => handlenewCompany('image', image)}
                />
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelCreate(!create)} color="primary">
              {t('actions.cancel')}
            </Button>
            <Button onClick={handleSubmit} disabled={!companyNew.company || !!creatingCompany} color="primary">
              {t('actions.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default CreateCompany
