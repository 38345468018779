import { ExternType, StateOfUser, UserState, UserStateInput } from '../../../../api/iam.models'

export const transformToUserStateInput = (userState: UserState): UserStateInput => {
  const transformedState: UserStateInput = {
    userInfo: {
      email: userState.userInfo?.email ?? '',
      note: userState.userInfo?.note,
      username: userState.userInfo?.username ?? ``,
      extern: userState.userInfo?.extern as ExternType,
    },
    state: userState.state,
    superior: userState.superior?.orgUserID ?? '',
    subordinates: userState.subordinates.map((u) => u.orgUserID) ?? [],
    licenses: userState.licenses.map((l) => ({
      licensePacketID: l.licensePacketID,
      licenseGroupID: l.licenseGroupID,
      licenseTypeID: l.licenseTypeID,
      regularLicense: l.regularLicense,
    })),
    userPermission: userState.userPermission.map((r) => r.roleID) ?? [],
    userAssignablePermission: userState.assignableUserPermission.map((a) => a.roleID) ?? [],
    enabled: userState.enabled,
    assignableLicenses: !userState.userPermission.some((rl) => rl.roleID === 'none-id')
      ? !userState.assignableLicenses.length
        ? [{ licensePacketId: userState.licenses[0].licensePacketID }]
        : userState.assignableLicenses
      : [],
    appStates: userState.appStates.map((app) => ({
      appID: app.appID,
      roles: app.roles.map((ar) => ar.roleID) ?? [],
      assignableRoles: app.assignableRoles.map((ar) => ar.roleID) ?? [],
      editableRoles: app.editableRoles.map((ar) => ar.roleID) ?? [],
      filePermission: app.filePermission.map((f) => f.fileID) ?? [],
    })),
  }
  return transformedState
}
