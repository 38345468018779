import React, { useEffect, useState } from 'react'
import { MinimalUserInfo, UserState } from '../../../api/iam.models'
import { ProgressBar, ResultAlert } from '../../components/partials'
import { useGetQueryParams } from '../../hooks/helperhooks/routingHooks'
import { useGetUserInfo } from '../../hooks/userHooks'
import CreateUserSimple from './CreateUserSimple'
import EditUserSimple from './EditUserSimple'
import { UserManagementProvider } from './UserContext/UserProvider'

const UserMaster: React.FC = () => {
  const { id } = useGetQueryParams()
  const [currUserSimpleInput, setCurrUserSimpleInput] = useState<UserState>()
  const [registeredUser, setRegisteredUser] = useState<boolean | undefined>()
  const [currUserSuperiorInfo, setCurrentUserSuperiorInfo] = useState<MinimalUserInfo>()
  const [currentUserSubordintatesInfo, setCurrentUserSubordintatesInfo] = useState<MinimalUserInfo[]>([])
  const userid = React.useMemo(() => {
    return id !== 'user' ? id : ''
  }, [id])

  const { userInfo, userInfoError, userInfoLoading } = useGetUserInfo(userid)

  useEffect(() => {
    if (userInfo && userid) {
      setCurrUserSimpleInput(userInfo)
      setRegisteredUser(userInfo?.userInfo?.registered)
    }
  }, [userInfo])

  //console.log('registered', registeredUser)
  return (
    <>
      <UserManagementProvider
        registeredUser={registeredUser}
        currentSimpleUserState={currUserSimpleInput}
        editUserId={userid}
        loginEnabled={currUserSimpleInput?.enabled}
      >
        <ProgressBar show={userInfoLoading} />
        <ResultAlert showAlert={!!userInfoError} alertText={`${userInfoError}`} severity="error" />
        {userid ? (
          <EditUserSimple
            loading={userInfoLoading}
            error={userInfoError}
            exSubordinatesInfo={currentUserSubordintatesInfo}
            exSuperiorInfo={currUserSuperiorInfo}
          />
        ) : (
          <CreateUserSimple />
        )}
      </UserManagementProvider>
    </>
  )
}

export default UserMaster
