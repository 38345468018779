import { FixedActionBar } from '@csinstruments/cs-react-theme'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SimpleActionPage from '../../components/partials/SimpleActionPage'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined'
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined'
import { standardContentPadding } from '../../../styles/Shared'
import AddUserInfo from './UserForm/AddUserInfo'
import { useTranslation } from 'react-i18next/'
import ManageUserAppResources from './UserForm/ManageUserAppResources'
import CreateUpdateSummary from './UserForm/CreateUpdateSummary'
import { useManageUserContext } from './UserContext/UserProvider'
import { useCreateSimpleUser } from '../../hooks/userHooks'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../helpers/routes'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { ActionType } from './UserContext/userActions'
import { StepperWizard } from '../../components/partials/StepperWizard/StepperWizard'
import AccessRights from './UserForm/AccessRights'
import UserCreateEditIntro from './UserForm/UserCreateEditIntro'
import { PushBack, ResultAlert } from '../../components/partials'
import { MinimalUserInfo, UserState } from '../../../api/iam.models'
import { SelectedSingleUserLicense } from './UserContext/initialState'
import { transformToUserStateInput } from './UserUtil/transformUserStateToUserStateInput'

const CreateUserSimple: React.FC = () => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const initialSteps = [
    t('labels.introduction'),
    'Email',
    t('labels.accessRights'),
    t('labels.resources'),
    t('labels.confirm'),
  ]
  const steps = useMemo(() => {
    return initialSteps
  }, [t])
  const [sendEmail, setSendEmail] = useState<boolean>(true)
  const [selectedSingleUserLicenseInfo, setSelectedSingleUserLicenseInfo] = useState<SelectedSingleUserLicense>({
    licensePacketID: '',
    licensePacketName: '',
    licenseGroupID: '',
    licenseGroupName: '',
    licenseTypeID: '',
    licenseTypeName: '',
    licenseTypeKey: '',
  })
  const [superiorInfo, setSuperiorInfo] = useState<MinimalUserInfo>({ orgUserID: '', email: '' })
  const [subordintatesInfo, setSubordintates] = useState<MinimalUserInfo[]>([{ orgUserID: '', email: '' }])
  const [selectedAssignableLicense, setSelectedAssignableLicense] = useState<SelectedSingleUserLicense[]>([])
  const { state, dispatch } = useManageUserContext()

  const { createOrUpdateUserResp, userCreateUpdateError, updatingUser, confirmUserCreateOrUpdate } =
    useCreateSimpleUser()

  useEffect(() => {
    if (!updatingUser && createOrUpdateUserResp) {
      history.push(ROUTES.manageusers)
    }
  }, [createOrUpdateUserResp])

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [activeStep])

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 < 0 ? 0 : prevActiveStep - 1))
  }, [activeStep])

  const onStepClick = useCallback(
    (step: number) => {
      setActiveStep(step)
    },
    [activeStep],
  )

  const handleReset = useCallback(() => {
    setActiveStep(0)
    //onStepClick(0)
    dispatch({
      type: ActionType.ResetUserSimpleInput,
    })
  }, [activeStep])

  const changeSendEmail = useCallback(
    (send: boolean) => {
      setSendEmail(send)
    },
    [sendEmail],
  )

  const clickSave = async () => {
    const userStateWithAssignLic: UserState = {
      ...state,
      assignableLicenses: selectedAssignableLicense.map((sl) => ({
        licensePacketId: !sl.licensePacketID ? undefined : sl.licensePacketID,
        licenseGroupID: !sl.licenseGroupID ? undefined : sl.licenseGroupID,
        licenseTypeID: !sl.licenseTypeID ? undefined : sl.licenseTypeID,
      })),
    }
    const saveUser = transformToUserStateInput(userStateWithAssignLic)
    try {
      activeStep === steps.length - 1 &&
        (await confirmUserCreateOrUpdate({ userStateInput: saveUser, inviteUser: sendEmail }))
    } catch (er) {
      console.error('error', er)
    }
  }

  const disableNext = useMemo(() => {
    switch (activeStep) {
      case 1:
        return !state?.userInfo?.email || !state.userInfo.extern
      // case 2:
      //   return (
      //     !state.licenses.some((l) => l?.groupname && l?.licenseType) ||
      //     !state.superior ||
      //     !state.appStates.some((appstate) => appstate?.clientName === 'iam' && appstate.roles.length)
      //   )
      // case 3:
      //   return state.appStates.some((appState) => appState?.clientName !== 'iam' && !appState?.roles.length)
      default:
        return false
    }
  }, [state.appStates, state.licenses, state.userInfo, activeStep])

  const disabledStepper = useMemo(() => {
    return [
      false,
      !state?.userInfo?.email,
      !state?.userInfo?.email || !state.userInfo.extern,
      !state.licenses.some((l) => l?.licenseGroupID && l?.licenseTypeID) ||
        !state.superior ||
        !state.appStates.some((appstate) => appstate?.appID === 'iam' && appstate.roles.length),
      state.appStates.length < 1,
    ]
  }, [state])

  return (
    <>
      <SimpleActionPage>
        <PushBack textHeading={t('manageusers')} />
        <StepperWizard labels={steps} step={activeStep} disabled={disabledStepper} onStepClicked={onStepClick} />
        <>
          <div style={standardContentPadding}>
            <ResultAlert showAlert={!!userCreateUpdateError} alertText={`${userCreateUpdateError}`} severity="error" />
            {activeStep === 0 && <UserCreateEditIntro />}
            {activeStep === 1 && <AddUserInfo />}
            {activeStep === 2 && (
              <AccessRights
                updateSingleUserLicenseInfo={setSelectedSingleUserLicenseInfo}
                selectedSingleUserLicenseInfo={selectedSingleUserLicenseInfo}
                updateSuperiorInfo={setSuperiorInfo}
                superiorInfo={superiorInfo}
                subordinatesInfo={subordintatesInfo}
                updateSubordinatesInfo={setSubordintates}
                selectedAssignableLicense={selectedAssignableLicense}
                updatedSelAssignLicense={setSelectedAssignableLicense}
              />
            )}

            {activeStep === 3 && <ManageUserAppResources selectedLicenseInfo={selectedSingleUserLicenseInfo} />}
            {activeStep === 4 && (
              <CreateUpdateSummary
                sendEmail={sendEmail}
                changeSendEmail={changeSendEmail}
                editStep={onStepClick}
                superiorUserInfo={superiorInfo}
                subordintateUserInfo={subordintatesInfo}
                selectedLicenseInfo={selectedSingleUserLicenseInfo}
                assingableLicenses={selectedAssignableLicense}
              />
            )}
          </div>
        </>

        <FixedActionBar
          actions={[handleReset, handleBack, activeStep !== 4 ? handleNext : clickSave]}
          labels={['Reset', t('labels.back'), activeStep !== 4 ? t('labels.next') : t('labels.save')]}
          largeButtons
          fixedButtonSizes={[2, 2, 2]}
          icons={[
            RotateLeftOutlinedIcon,
            KeyboardBackspaceOutlinedIcon,
            activeStep !== 4 ? ArrowForwardOutlinedIcon : PersonAddIcon,
          ]}
          buttonProps={[{}, {}, { solid: true }]}
          disabled={[activeStep === 0, activeStep === 0, updatingUser || disableNext]}
        />
      </SimpleActionPage>
    </>
  )
}

export default CreateUserSimple
