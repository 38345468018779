import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { StateOfUser } from '../../../../api/iam.models'
import { useDeletedUsers, useDeleteUser, useMoveUserToFromTrash } from '../../../hooks/userHooks'
import { DataTable, ProgressBar, ResultAlert } from '..'
import { DataItemAction, DataTableColumn, DataTableRow } from '../DataTable/DataTableTypes'

const UsersTrash: React.FC = () => {
  const { t } = useTranslation('common')
  const [rows, setRows] = useState<DataTableRow[]>([])
  const { accessibleUsers, usersFetchingError, usersFetching, refetch } = useDeletedUsers()

  //const [userId, setUserId] = React.useState<string>('')

  const { confirmUserToFromTrash, userMoved, userMovingError, userMovingToOrFrom } = useMoveUserToFromTrash(
    StateOfUser.Active,
  )
  const { confirmUserDeletion, deletedUser, deleteUserError, deletingUser } = useDeleteUser()

  const userColumns: DataTableColumn[] = [
    { label: t('user'), alignment: 'left' },
    //{ label: 'Email', alignment: 'left' },
    { label: t('labels.origin'), alignment: 'left' },
    /* { label: t('company'), alignment: 'left' },
    { label: 'Role', alignment: 'left' }, */
  ]

  //let rows: DataTableRow[] = []
  const updateUserRows = () => {
    //console.log('get users:', userData)
    const tempRows: DataTableRow[] =
      accessibleUsers?.map((item) => ({
        id: item?.userInfo.orgUserID || '-',
        values: [
          //item?.userInfo.username || '-',
          item?.userInfo.email || '-',
          item?.userInfo.extern || '-',
          /* item?.userInfo.company || '-',
          item?.roles[0] as string, */
        ],
        scopes: !item.scopes.length
          ? undefined
          : item.scopes.includes('edit')
          ? ([...item.scopes, 'delete'] as string[])
          : ([...item.scopes] as string[]),
      })) || []
    setRows(tempRows)
  }

  /* if (deletedUser) {
    console.log('deleted user', userId)
  } */

  const actionCall = (id: string, action: DataItemAction): void => {
    switch (action) {
      case 'delete':
        confirmUserDeletion(id)
        refetch()
        break
      case 'restore':
        confirmUserToFromTrash(id)
        refetch()
        break
    }
  }

  useEffect(() => {
    if ((!usersFetching && accessibleUsers) || (!deletingUser && deletedUser) || (!userMovingToOrFrom && userMoved)) {
      updateUserRows()
    }
  }, [accessibleUsers, userMoved, deletedUser])

  /* useEffect(() => {
    if (!userMovingToOrFrom) {
      userMoved ? console.log('----user Restore Success----') : console.log('error', userMovingError)
    }
  }, [userMoved])

  useEffect(() => {
    if (!deletingUser) {
      deletedUser
        ? console.log('----delete user Success -----')
        : console.log('---error deleting user---', deleteUserError)
    }
  }, [deletedUser]) */

  return (
    <>
      <ResultAlert
        showAlert={!!deleteUserError || !!userMovingError}
        alertText={`${deleteUserError}` || `${userMovingError}`}
      />
      <ProgressBar show={usersFetching || deletingUser || userMovingToOrFrom} />
      {usersFetchingError && (
        <Typography color="error" variant="subtitle2">
          {usersFetchingError}
        </Typography>
      )}

      <DataTable
        rows={rows}
        columns={userColumns}
        allowedActions={{ sortable: true, deletable: true, restorable: true }}
        onButtonClicked={actionCall}
      />
    </>
  )
}

export default UsersTrash
