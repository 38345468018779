import { AppStateInput, ExternType, StateOfUser, UserState, UserStateInput } from '../../../../api/iam.models'

// export const iamInitInput: AppStateSimpleInput = {
//   appID: 'iam',
//   roles: [],
//   assignableRoles: [],
//   editableRoles: [],
//   filePermission: [],
//   //directories: [],
// }

export const intialSimpleUser: UserStateInput = {
  userInfo: {
    username: 'bob@cs-instruments.com',
    email: 'bob@cs-instruments.com',
    extern: ExternType.Intern,
    note: '',
  },
  appStates: [],
  licenses: [],
  subordinates: [],
  assignableLicenses: [],
  superior: '',
  enabled: true,
  state: StateOfUser.Active,
  userAssignablePermission: [],
}

export const initUserState: UserState = {
  userInfo: {
    ...intialSimpleUser.userInfo,
    orgUserID: '',
    company: '',
    registered: false,
  },
  licenses: [],
  state: StateOfUser.Active,
  subordinates: [],
  appStates: [],
  enabled: true,
  userPermission: [],
  assignableUserPermission: [],
  assignableLicenses: [],
}

export type SelectedSingleUserLicense = {
  licensePacketID: string
  licensePacketName: string
  licenseGroupName: string
  licenseGroupID: string
  licenseTypeID: string
  licenseTypeName: string
  licenseTypeKey: string
}
