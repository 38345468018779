import React, { useEffect, useState } from 'react'
import { Modal, Grid, Typography, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'

import { CsButtonPrimary, CsButtonText } from '@csinstruments/cs-react-theme'
import { TagAttribute } from '../../../api/ld.models'
import { paddingTop } from '../../../styles/Shared'
import { useStyles } from './ModalStyles'
type FilterProps = {
  showFilterOption: boolean
  closeFilterOptions: () => void
  filterAttributes: TagAttribute[]
  tagAttributeOptions: TagAttribute[]
  filterCompanies: (selected: TagAttribute[]) => void
}
export const TagAttributeFilter: React.FC<FilterProps> = ({
  showFilterOption,
  closeFilterOptions,
  filterAttributes,
  filterCompanies,
  tagAttributeOptions,
}: FilterProps) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  //const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [tagAttributesList, setTagAttributesList] = useState<TagAttribute[]>([])

  useEffect(() => {
    if (tagAttributeOptions.length) {
      setTagAttributesList(tagAttributeOptions)
    }
  }, [tagAttributeOptions])

  const handleTagFilter = (ids: string[]) => {
    const resetList = tagAttributesList.filter((tg) => ids.includes(tg.ID))
    filterCompanies(resetList)
  }
  const clearFilter = () => {
    filterCompanies([])
    closeFilterOptions()
  }
  return (
    <>
      <Modal open={showFilterOption} onClose={closeFilterOptions} className={classes.modal}>
        <div className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant={'h4'}>{t('labels.filterCompanies')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TagFilter
                filterHandler={handleTagFilter}
                tagFilterAttributeOptions={tagAttributesList}
                tagFilterValues={filterAttributes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={paddingTop}>
            <Grid item xs={6}>
              <CsButtonText onClick={clearFilter}>{t('modals.cancel')}</CsButtonText>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <CsButtonPrimary onClick={closeFilterOptions}>{t('modals.confirm')}</CsButtonPrimary>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}

type TagFilterProps = {
  tagFilterAttributeOptions: TagAttribute[]
  filterHandler: (id: any) => void
  tagFilterValues: TagAttribute[]
}
export const TagFilter = ({ tagFilterAttributeOptions, filterHandler, tagFilterValues }: TagFilterProps) => {
  const { t } = useTranslation('common')

  return (
    <Autocomplete
      onChange={(event, newValue: any) => {
        const toFilter = newValue.map((nv: any) => nv?.ID).filter((id: string) => id && id)
        //console.log({ newValue })
        filterHandler(toFilter)
      }}
      multiple
      value={tagFilterValues}
      options={tagFilterAttributeOptions}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} placeholder={t('labels.groups')} />}
    />
  )
}
