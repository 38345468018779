import React from 'react'
import { Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
type PushBackProps = {
  textHeading?: string
}
const PushBack: React.FC<PushBackProps> = ({ textHeading }: PushBackProps) => {
  const pushToPrevUrl = () => {
    window.history.back()
  }

  return (
    <div>
      <Button variant="outlined" color="primary" size="small" onClick={pushToPrevUrl}>
        <ArrowBackIcon /> {textHeading}
      </Button>
    </div>
  )
}

export default PushBack
