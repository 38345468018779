import { Button, Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core'
import React, { useCallback /* , useEffect */, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { MenuProps } from '../UserManagement/User.Styles'
import { tableStyles } from '../UserManagement/UsersTables'
import SearchIcon from '@material-ui/icons/Search'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../components/partials/DataTable/DataTableTypes'
import { DataTable } from '../../components/partials/DataTable/DataTable'

interface RoleTableProps {
  rowData: DataTableRow[]
  columnData: DataTableColumn[]
  edit?: boolean
  view?: boolean
  remove?: boolean
  actionCall?: (id: string, action: DataItemAction) => void
}

const RoleTable: React.FC<RoleTableProps> = ({
  rowData,
  columnData,
  edit = false,
  view = false,
  remove = false,
  actionCall,
}: RoleTableProps) => {
  const classes = tableStyles()
  const { t } = useTranslation('common')
  const filterInitialValues = {
    preconfig: '',
  }
  //const [langChanged, setLangChange] = useState<boolean>(false)
  const [selectiveRows, setSelectiveRows] = useState<DataTableRow[]>(rowData)
  const [filterValues, setFilterValues] = useState(filterInitialValues)
  const [searchRole, setSearchRole] = useState<string>('')
  const columnLabels = columnData.map((column) => t(column.label))
  useEffect(() => {
    if (rowData) {
      setSelectiveRows(rowData)
    }
  }, [rowData])
  const uniqueOptions = (index: number) => {
    const someArray = rowData.map((row) => row.values[index])
    return Array.from(new Set(someArray))
  }

  const changeSelect = (selectString: string) => {
    const tempRowData = rowData.filter((rows) => rows.values[2] === selectString)
    const tempSelectiveRows = selectiveRows.filter((rows) => rows.values[2] === selectString)
    const filteredRows = tempSelectiveRows.length !== 0 ? tempSelectiveRows : tempRowData
    setFilterValues({ preconfig: selectString as string })

    setSelectiveRows(filteredRows)
  }

  const resetFilter = useCallback(() => {
    setFilterValues(filterInitialValues)
    setSelectiveRows(rowData)
  }, [rowData, actionCall])

  /* useEffect(() => {
    if (rowData) {
      resetFilter()
    }
  }, [i18n.language]) */
  //console.log('rowdata', rowData)
  const preconfigOptions = uniqueOptions(2)
  /* const renderProps = (selected: unknown) => (
    <div className={classes.chips}>
      <Chip label={selected as string} className={classes.chip} />
    </div>
  ) */
  return (
    <>
      <form>
        <Grid item container justifyContent="flex-start" spacing={3}>
          <Grid item>
            <TextField
              className={classes.textField}
              id="outlined-search"
              label={columnLabels[0]}
              type="search"
              fullWidth
              variant="standard"
              value={searchRole}
              onChange={({ target }) => {
                setSearchRole(target.value as string)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              label={columnLabels[2]}
              select
              value={filterValues.preconfig}
              variant="standard"
              onChange={({ target }) => changeSelect(target.value as string)}
              SelectProps={{
                //renderValue: renderProps,
                MenuProps: MenuProps,
              }}
            >
              {preconfigOptions.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Button
              startIcon={<AutorenewIcon fontSize="large" />}
              variant="outlined"
              title={'reset'}
              color="inherit"
              disableFocusRipple
              onClick={() => resetFilter()}
              style={{ marginTop: '10px' }}
            />
          </Grid>
        </Grid>
      </form>
      <DataTable
        columns={columnData}
        rows={selectiveRows}
        allowedActions={{ sortable: true, viewable: view, deletable: remove, editable: edit }}
        onButtonClicked={actionCall}
        filterColumn={0}
        filterString={searchRole}
      />
    </>
  )
}

export default RoleTable
