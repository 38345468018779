import { paletteColors } from '@csinstruments/cs-react-theme'

export const fullHeight = {
  height: '100%',
  lineHeight: '100%',
  verticalAlign: 'center',
  display: 'flex',
  alignItems: 'center',
}

export const spaceLeftRight = {
  width: '90%',
  margin: 'auto',
}

export const completeContainer = {
  overflowX: 'hidden' as const,
  backgroundColor: paletteColors().primary,
  userSelect: undefined,
}

export const outerPageContainer = {
  backgroundColor: paletteColors().primary,
}

export const innerPageContainer = {
  backgroundColor: '#fff',
  borderTopLeftRadius: 50,
}

export const mainPageContainer = {
  width: '90%',
  margin: 'auto',
  maxHeight: '100%',
  paddingBottom: '20px',
  paddingTop: '10px',
  flexGrow: 1,
} as React.CSSProperties

export const paddingLeft = {
  paddingLeft: '30px',
}

export const paddingRight = {
  paddingRight: '30px',
}

export const paddingForDrawer = {
  paddingLeft: '200px',
  transition: '0.3s',
}

export const paddingForClosedDrawer = {
  paddingLeft: '40px',
  transition: '0.3s',
}

export const chipPadding = {
  paddingRight: '10px',
  paddingLeft: '10px',
  // marginLeft: '5px',
  marginRight: '15px',
  marginTop: '15px',
}

export const paddingLeftRight = {
  ...paddingLeft,
  ...paddingRight,
}

export const standardContentPadding = {
  padding: '30px',
}

export const paddingTop = {
  paddingTop: '20px',
}

export const paddingBottom = {
  paddingBottom: '20px',
}

export const paddingTopBottom = {
  paddingTop: '20px',
  paddingBottom: '30px',
}

export const paddingBottomActionBar = {
  paddingBottom: '80px',
}

export const uppercaseText = {
  textTransform: 'uppercase' as const,
}

export const materialUiWrapper = {
  padding: 20,
}

export const marginLeftRight = {
  marginLeft: 20,
  marginRight: 20,
}
