import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  AccordionDetails,
  Typography,
  FormHelperText,
  makeStyles,
  Grid,
  Button,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@material-ui/core'
import { FileSystemType } from '../../../hooks/helperhooks/transformToFileHierarchy'
import React, { useEffect, useMemo, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { useTranslation } from 'react-i18next'
import { FilePermission } from '../../../../api/iam.models'

import { CompanyFileSystem } from '../../../hooks/userResourceHooks'

import { TagAttribute, useGetTagAttributesQuery } from '../../../../api/ld.models'
import { getOrganization } from '../../../helpers/manageTokens'
import { TagAttributeFilter } from '../../TagAttributes/TagAttributeFilter'
import { Tune } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})
type SubFileHierarchyProps = {
  file: FileSystemType
  selectedFiles: FilePermission[]
  disabledSubSelection: boolean
  handleSelection: (fl: FileSystemType) => void
}
export const SubFileHierarchy: React.FC<SubFileHierarchyProps> = ({
  file,
  selectedFiles,
  handleSelection,
  disabledSubSelection,
}: SubFileHierarchyProps) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  //const [file, setRenderFile] = useState<FileSystemType>({})
  // useEffect(() => {
  //   if (singleFile && !file) {
  //     setRenderFile(singleFile)
  //   }
  // }, [singleFile])
  return (
    <>
      <div className={classes.root}>
        <Grid item container>
          <Accordion>
            <AccordionSummary
              expandIcon={file.subFiles ? <ExpandMoreIcon /> : undefined}
              aria-label="Expand"
              aria-controls={'ResourceSelection content'}
              id={'ResourceSelection'}
            >
              <FormControlLabel
                aria-label="ResourceSelection-check"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    checkedIcon={
                      file.subFiles?.every((sf) => selectedFiles.some((fp) => fp.parentFileID === sf.parentFileID)) ? (
                        <AddBoxIcon />
                      ) : (
                        <IndeterminateCheckBoxIcon />
                      )
                    }
                    disabled={!file.selectable && disabledSubSelection}
                    checked={selectedFiles.some((fp) => fp.fileID === file.fileID)}
                    onChange={() => handleSelection(file)}
                    value={file.fileID}
                  />
                }
                label={
                  <>
                    {file.fileName ? file?.fileName : file.fileType === 'root-id' ? t('labels.all') : ''}
                    {!!file?.required ? (
                      <FormHelperText>
                        {t('labels.required')}
                        {'*'}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                    {file.inTrash ? (
                      <Typography variant="caption" color="error" noWrap gutterBottom>
                        {' ' + t('warning.inTrash')}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={3}>
                {file.subFiles?.length ? (
                  file.subFiles.map((sf) => (
                    <SubFileHierarchy
                      selectedFiles={selectedFiles}
                      handleSelection={handleSelection}
                      file={sf}
                      disabledSubSelection={!sf.selectable && !file.selectable}
                      key={sf.fileID}
                    />
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </div>
    </>
  )
}
export type FileHierarchyProps = {
  resourceFiles: FileSystemType[]
  selectedFiles: FilePermission[]
  companyFiles: CompanyFileSystem[]
  handleresourceSelection: (selectedFile: FileSystemType) => void
}

export const FileHierarchy: React.FC<FileHierarchyProps> = ({
  resourceFiles,
  selectedFiles,
  companyFiles,
  handleresourceSelection,
}: FileHierarchyProps) => {
  const { t } = useTranslation('common')
  const classes = useListStyles()

  // const [groupId, setGroupId] = useState<string>()
  const [allCompanies, setAllCompanies] = useState<CompanyFileSystem[]>([])
  const [companiesByGroup, setCompaniesByGroup] = useState<CompanyFileSystem[]>([])

  const [selectedTags, setSelectedTags] = useState<TagAttribute[]>([])
  const [openFilterModal, setFilterModal] = useState<boolean>(false)
  const { data: tagAttributes } = useGetTagAttributesQuery({
    variables: { realmName: getOrganization() },
    skip: !getOrganization(),
  })
  // const filterGroups =
  //   resourceFiles.some((p) => p.fileType === 'root-id') && resourceFiles.length === 1
  //     ? resourceFiles[0].subFiles ?? []
  //     : resourceFiles.length === 1
  //     ? resourceFiles
  //     : []
  useEffect(() => {
    if (companyFiles.length) {
      setAllCompanies(companyFiles)
      const tmpCompanyIDS = selectedTags
        .map((ta) => ta.attributes)
        .flat()
        .map((aid) => aid.attributeID)
      const filterdComps = companyFiles.filter((c) => tmpCompanyIDS.includes(c.fileID))
      selectedTags.length ? setCompaniesByGroup(filterdComps) : setCompaniesByGroup(companyFiles)
    }
  }, [companyFiles, selectedTags])

  // const handleSelect = (id: string) => {
  //   const subGroupIds: string[] = []
  //   const ids = collectSubGroupIds(filterGroups, subGroupIds, id)
  //   const filteredCompaniesByGroup = allCompanies.filter(
  //     (c) => (c?.parentFileID && ids.includes(c.parentFileID)) || c.parentFileID === id,
  //   )
  //   setCompaniesByGroup(filteredCompaniesByGroup)
  //   setGroupId(id)
  // }

  const handleFilter = (tagAttibs: TagAttribute[]) => {
    setSelectedTags(tagAttibs)
    const tmpCompanyIDS = tagAttibs
      .map((ta) => ta.attributes)
      .flat()
      .map((aid) => aid.attributeID)
    const filterdComps = allCompanies.filter((c) => tmpCompanyIDS.includes(c.fileID))
    setCompaniesByGroup(filterdComps)
  }

  const handleCompaySelect = (cf: CompanyFileSystem): void => {
    handleresourceSelection({
      ...cf,
    })
  }

  const resetFilter = () => {
    setSelectedTags([])
    //setGroupId(undefined)
    setCompaniesByGroup(allCompanies)
  }

  const disbledIDs = useMemo(() => {
    if (selectedFiles.some((f) => f.fileID && !f.parentFileID)) {
      return allCompanies.filter((f) => f.parentFileID && f.parentFileID).map((f) => f.fileID)
    }
  }, [selectedFiles])

  const handleOpenFilterModal = () => {
    setFilterModal(true)
  }
  const closeFilterModal = () => {
    setFilterModal(false)
  }

  const tagOptions = useMemo(() => {
    if (!tagAttributes?.getTagAttributes.length) return []
    return tagAttributes.getTagAttributes
  }, [tagAttributes?.getTagAttributes])

  return (
    <>
      {tagOptions.length ? (
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Button variant="contained" disabled={!selectedTags.length} fullWidth onClick={resetFilter}>
              {t('labels.viewAllCompanies')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Badge badgeContent={selectedTags?.length} color="secondary">
              <Button
                variant="contained"
                color="primary"
                startIcon={<Tune />}
                onClick={handleOpenFilterModal}
                fullWidth
                style={{ borderRadius: 25 }}
              >
                {t('labels.groupFilter')}
              </Button>
            </Badge>
          </Grid>

          {/* <Grid item container xs={12} spacing={1}>
            <GroupFilter selectedGroup={groupId} handleSelect={handleSelect} groups={filterGroups} filterable />
          </Grid> */}
        </Grid>
      ) : null}
      <TagAttributeFilter
        showFilterOption={openFilterModal}
        closeFilterOptions={closeFilterModal}
        filterCompanies={handleFilter}
        filterAttributes={selectedTags}
        tagAttributeOptions={tagOptions}
      />

      <Grid item container xs={12}>
        <List className={classes.root}>
          {companiesByGroup.map((cf) => (
            <AssignableCompany
              key={cf.fileID}
              company={cf}
              onSelect={handleCompaySelect}
              assignedFiles={selectedFiles}
              disabledID={disbledIDs}
            />
          ))}
        </List>
      </Grid>
    </>
  )
}

const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
)
type AssignableCompanyProps = {
  company: CompanyFileSystem
  onSelect: (selectedComp: CompanyFileSystem) => void
  assignedFiles: FilePermission[]
  disabledID?: string[]
}
export const AssignableCompany: React.FC<AssignableCompanyProps> = ({
  company,
  assignedFiles,
  onSelect,
  disabledID,
}: AssignableCompanyProps) => {
  const { t } = useTranslation('common')

  return (
    <ListItem
      dense
      disabled={!company.selectable || disabledID?.includes(company.fileID)}
      button
      onClick={() => onSelect(company)}
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={assignedFiles.some((f) => f.fileID === company.fileID)}
          tabIndex={-1}
          disableRipple
          //inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemIcon>
      <ListItemText
        id={company.fileID}
        primary={
          <Typography noWrap gutterBottom>
            {!company.fileName ? t('labels.all') : company.fileName}
          </Typography>
        }
        secondary={
          <>
            <Typography variant="caption" noWrap gutterBottom>
              {company.path.join('/')}
            </Typography>

            {company.inTrash ? (
              <Typography variant="caption" color="error" noWrap gutterBottom>
                {' ' + t('warning.inTrash')}
              </Typography>
            ) : null}
            {company.required ? (
              <Typography variant="caption" color="error" noWrap gutterBottom>
                {' ' + t('labels.required') + '*'}
              </Typography>
            ) : null}
          </>
        }
      />
    </ListItem>
  )
}
