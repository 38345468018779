import React from 'react'
import SimpleActionPage from './SimpleActionPage'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { ROUTES } from '../../helpers/routes'
import { ActionWithDescription, useAuthContext } from '.'

const SessionExpired: React.FC = () => {
  const { keyCloak } = useAuthContext()

  const { t } = useTranslation('common')
  const handleSignin = (): void => {
    keyCloak?.login({ redirectUri: ROUTES.home })
  }

  return (
    <SimpleActionPage>
      <Grid item container justifyContent="center">
        <Grid item>
          <ActionWithDescription
            heading={t('error.sessionTimeOut')}
            description={t('error.sessionTimeoutMessage')}
            buttonText={t('login.login')}
            onClick={handleSignin}
          />
        </Grid>
      </Grid>
    </SimpleActionPage>
  )
}

export default SessionExpired
