import React from 'react'

import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next/'
import ViewAppRoles from './ViewAppRoles'

const ManageRoles: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <>
      <Typography color="secondary" gutterBottom variant="h3" align="center">
        {t('manageroles')}
      </Typography>

      <ViewAppRoles />
    </>
  )
}

export default ManageRoles
