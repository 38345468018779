import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};


export type AmVersionInformation = {
  __typename?: 'AMVersionInformation';
  /** version of am */
  Version: Scalars['String'];
  /** timestamp of the am build */
  BuildTimestamp: Scalars['String'];
};

export type AsAppRole = {
  __typename?: 'ASAppRole';
  /** id of the role */
  roleID: Scalars['String'];
  /** name of the role */
  roleName: Scalars['String'];
  /** translation key of the role - null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type AsAppRoleInput = {
  /** name of the role */
  roleName: Scalars['String'];
  /** translation key of the role - null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type AccessPermission = {
  __typename?: 'AccessPermission';
  roleID: Scalars['String'];
  roleDisplayKey: Scalars['String'];
  roleDescriptionKey: Scalars['String'];
};

export type ActivatedCloudLicense = {
  __typename?: 'ActivatedCloudLicense';
  /** name of the organization */
  organizationName: Scalars['String'];
  /** licenses of a cloud are grouped in license packets */
  licensePackets?: Maybe<Array<Maybe<ActivatedLicensePacket>>>;
  /** whether the whole cloud license can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole cloud license needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedCloudLicenseInput = {
  /** name of the organization */
  organizationName: Scalars['String'];
  /** licenses of a cloud are grouped in license packets */
  licensePackets?: Maybe<Array<Maybe<ActivatedLicensePacketInput>>>;
};

export type ActivatedLicense = {
  __typename?: 'ActivatedLicense';
  /** unique id of the license */
  id: Scalars['String'];
  /** if not null, name of the user for which the license is reserved */
  reserved?: Maybe<Scalars['String']>;
  /** id of the user to which this license is assigned, null if not assigned */
  userId?: Maybe<Scalars['String']>;
  /** whether the license needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicenseGroup = {
  __typename?: 'ActivatedLicenseGroup';
  /** unique of the license group */
  id: Scalars['String'];
  /** name of the group e.g. cs-leak-reporter */
  groupname: Scalars['String'];
  /** translation key of the license group */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license group */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of license types of the group e.g. user and guest */
  licenseTypes?: Maybe<Array<Maybe<ActivatedLicenseType>>>;
  /** whether the whole license group can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole license group needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicenseGroupInput = {
  /** unique of the license group */
  id: Scalars['String'];
  /** name of the group e.g. cs-leak-reporter */
  groupname: Scalars['String'];
  /** translation key of the license group */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license group */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of license types of the group e.g. user and guest */
  licenseTypes?: Maybe<Array<Maybe<ActivatedLicenseTypeInput>>>;
};

export type ActivatedLicenseInput = {
  /** unique id of the license */
  id: Scalars['String'];
  /** if not null, name of the user for which the license is reserved */
  reserved?: Maybe<Scalars['String']>;
  /** id of the user to which this license is assigned, null if not assigned */
  userId?: Maybe<Scalars['String']>;
};

export type ActivatedLicensePacket = {
  __typename?: 'ActivatedLicensePacket';
  /** license packet id */
  id: Scalars['String'];
  /** packet name */
  packetName: Scalars['String'];
  /** packetExpiryDate */
  expirationDate: Scalars['String'];
  /** apps / licensegroup e.g. cs-leak-reporter */
  licenseGroups?: Maybe<Array<Maybe<ActivatedLicenseGroup>>>;
  /** whether the whole license packet can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole license license needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicensePacketInfo = {
  __typename?: 'ActivatedLicensePacketInfo';
  /** date when the license packet was activated */
  licensePacketActivatedAt: Scalars['String'];
  /** id of the user who activated the license packet */
  licensePacketActivator: Scalars['String'];
  /** expiration date of the license packet */
  expirationDate: Scalars['String'];
};

export type ActivatedLicensePacketInput = {
  /** license packet id */
  id: Scalars['String'];
  /** packet name */
  packetName: Scalars['String'];
  /** apps / licensegroup e.g. cs-leak-reporter */
  licenseGroups?: Maybe<Array<Maybe<ActivatedLicenseGroupInput>>>;
};

export type ActivatedLicenseType = {
  __typename?: 'ActivatedLicenseType';
  /** unique id of the license type */
  id: Scalars['String'];
  /** type of the license e.g. user */
  licenseType: Scalars['String'];
  /** translation key of the license type */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license type */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of single licenses of this type in the group in the packet */
  licenses?: Maybe<Array<Maybe<ActivatedLicense>>>;
  /** whether the whole license type can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole license type needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicenseTypeInput = {
  /** unique id of the license type */
  id: Scalars['String'];
  /** type of the license e.g. user */
  licenseType: Scalars['String'];
  /** translation key of the license type */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license type */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of single licenses of this type in the group in the packet */
  licenses?: Maybe<Array<Maybe<ActivatedLicenseInput>>>;
};

export type ActivatedOrganizationLicenseInfo = {
  __typename?: 'ActivatedOrganizationLicenseInfo';
  /** name of the organization */
  organizationName: Scalars['String'];
  /** given name of the organization */
  displayName: Scalars['String'];
  /** date when the organization was activated */
  organizationActivatedAt: Scalars['String'];
  /** id of the user who activated the cloud license */
  organizationActivator: Scalars['String'];
  /** expiration date of the license packet */
  expirationDate: Scalars['String'];
};

export type AppRole = {
  __typename?: 'AppRole';
  role: Role;
  isCustomRole: Scalars['Boolean'];
  appRoleInfo?: Maybe<Array<RoleDisplayInformation>>;
  editableBy: Array<Scalars['String']>;
  assignableBy: Array<Maybe<Scalars['String']>>;
  /** permissible action scopes: e.g view, edit, delete  */
  userAccessScopes: Array<Maybe<Scalars['String']>>;
  userPermissions: RoleUserPermission;
};

/** resources assigned to the user in a particular app */
export type AppState = {
  __typename?: 'AppState';
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app */
  roles: Array<AsAppRole>;
  /** roles which the user is allowed to assign to other users */
  assignableRoles: Array<AsAppRole>;
  /** roles which the user is allowed to edit */
  editableRoles: Array<AsAppRole>;
  /** explicit permissions on resource of different clients */
  filePermission: Array<FilePermission>;
};

/** resources in a particular app which will be assigned to the user */
export type AppStateInput = {
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app */
  roles: Array<Scalars['String']>;
  /** roles which the user is allowed to assign to other users */
  assignableRoles: Array<Scalars['String']>;
  /** roles which the user is allowed to edit */
  editableRoles: Array<Scalars['String']>;
  /** explicit permissions on resource of different clients */
  filePermission: Array<Scalars['String']>;
};

/** resources assigned to the user in a particular app */
export type AppStateSimple = {
  __typename?: 'AppStateSimple';
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app */
  roles: Array<AsAppRole>;
  /** roles which the user is allowed to assign to other users */
  assignableRoles: Array<AsAppRole>;
  /** roles which the user is allowed to edit */
  editableRoles: Array<AsAppRole>;
  /** explicit permissions on resource of different clients */
  filePermission: Array<FilePermissionSimple>;
};

/** resources in a particular app which will be assigned to the user */
export type AppStateSimpleInput = {
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app. String --> roleIDS */
  roles: Array<Scalars['String']>;
  /** roles which the user is allowed to assign to other users. String --> roleIDS */
  assignableRoles: Array<Scalars['String']>;
  /** roles which the user is allowed to edit. String --> roleIDS */
  editableRoles: Array<Scalars['String']>;
  /** explicit permissions on resource of different clients. String --> fileIDs */
  filePermission: Array<Scalars['String']>;
};

export type AppUserAssignRole = {
  __typename?: 'AppUserAssignRole';
  appUserRole: AppUserRole;
  isCustomRole: Scalars['Boolean'];
  required: Scalars['Boolean'];
  appUserAssignRequired: Array<Maybe<Scalars['String']>>;
  appUserEditableRoles?: Maybe<Array<Scalars['String']>>;
  appUserAssignableRoles: Array<Scalars['String']>;
};

export type AppUserRole = {
  __typename?: 'AppUserRole';
  roleID: Scalars['String'];
  roleName: Scalars['String'];
  roleDisplayKey?: Maybe<Scalars['String']>;
  roleDescriptionKey?: Maybe<Scalars['String']>;
  status: StateOfRole;
  appRoleInfo?: Maybe<Array<RoleDisplayInformation>>;
};

/** license assigned to a user */
export type AssignableLicense = {
  __typename?: 'AssignableLicense';
  /** id of the license packet from which to assign the license */
  licensePacketId?: Maybe<Scalars['String']>;
  /** name of the group (application), e.g. leak-reporter */
  licenseGroupID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseTypeID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseID?: Maybe<Scalars['String']>;
};

/** license assigned to a user */
export type AssignableLicenseInput = {
  /** id of the license packet from which to assign the license */
  licensePacketId?: Maybe<Scalars['String']>;
  /** name of the group (application), e.g. leak-reporter */
  licenseGroupID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseTypeID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseID?: Maybe<Scalars['String']>;
};

export type AssignedRoleInput = {
  /** name of the app, e.g. iam */
  appName: Scalars['String'];
  /** name of the role e.g. adduser */
  roles: Array<Maybe<Scalars['String']>>;
};

export type CloudLicensePacket = {
  __typename?: 'CloudLicensePacket';
  /** unique id of the license packet */
  packetId: Scalars['String'];
  /** name of the packet - can be set by the user */
  packetName: Scalars['String'];
  /** number of licenses of this type e.g. 20 */
  numberOfLicenses: Scalars['Int'];
  /** number of licenses already assigned e.g. 5 (the number of available licenses of this type is LicenseNumber - LicensesAssigned */
  assignedLicenses: Scalars['Int'];
  /** names of the user for whom a license is reserved */
  reservedLicenses: Array<Maybe<Scalars['String']>>;
};

export type CloudLicensePacketGroup = {
  __typename?: 'CloudLicensePacketGroup';
  groupId?: Maybe<Scalars['ID']>;
  groupname: Scalars['String'];
  displayNameKey?: Maybe<Scalars['String']>;
  descriptionKey?: Maybe<Scalars['String']>;
  licenseGroupTypeDetails: Array<Maybe<LicenseGroupTypeDetails>>;
};

export type CompanyStats = {
  __typename?: 'CompanyStats';
  companyName: Scalars['String'];
  noOfUsers: Scalars['Int'];
  noOfProjects: Scalars['Int'];
  noOfBuildings: Scalars['Int'];
};

export enum ExternType {
  /** user belonging to the organization */
  Intern = 'intern',
  /** user extern to the organization */
  Extern = 'extern'
}

export type FileAccessPermissionEx = {
  __typename?: 'FileAccessPermissionEx';
  hasFullAccess: Scalars['Boolean'];
  filePermission?: Maybe<Array<FilePermissionExSimple>>;
};

/** Structure used to represent the permission on a resource */
export type FilePermission = {
  __typename?: 'FilePermission';
  /** arbitrary internal id which might have been set by the application registering the corresponding resource */
  fileID: Scalars['String'];
  /** path where the resource is located e.g. [Bosch, Renningen] */
  parentFileID?: Maybe<Scalars['String']>;
  /** name of the resource (has to be unique in the directory) */
  filename: Scalars['String'];
  /** type of the resource - must correspond to a registered meta resource e.g. project */
  filetypeID: Scalars['String'];
  /** meta information related to the resource */
  metaInfo?: Maybe<ResourceMetaInformation>;
};

export type FilePermissionEx = {
  __typename?: 'FilePermissionEx';
  hasFullAccess: Scalars['Boolean'];
  filePermission?: Maybe<Array<FilePermission>>;
};

/** Structure used to represent the permission on a resource */
export type FilePermissionExSimple = {
  __typename?: 'FilePermissionExSimple';
  /** arbitrary internal id which might have been set by the application registering the corresponding resource */
  fileID: Scalars['String'];
  /** path where the resource is located e.g. [Bosch, Renningen] */
  parentFileID?: Maybe<Scalars['String']>;
  /** name of the resource (has to be unique in the directory) */
  filename: Scalars['String'];
  /** type of the resource - must correspond to a registered meta resource e.g. project */
  filetypeID: Scalars['String'];
  /** meta information related to the resource */
  metaInfo?: Maybe<ResourceMetaInformation>;
  /** whether the resource is required by one of the subordinates */
  required: Scalars['Boolean'];
  /** wheter the given superior has access to this resource */
  superiorHasAccess: Scalars['Boolean'];
  selectable: Scalars['Boolean'];
};

/** Structure used to represent the permission on a resource */
export type FilePermissionSimple = {
  __typename?: 'FilePermissionSimple';
  /** arbitrary internal id which might have been set by the application registering the corresponding resource */
  fileID: Scalars['String'];
  /** path where the resource is located e.g. [Bosch, Renningen] */
  parentFileID?: Maybe<Scalars['String']>;
  /** name of the resource (has to be unique in the directory) */
  filename: Scalars['String'];
  /** type of the resource - must correspond to a registered meta resource e.g. project */
  filetypeID: Scalars['String'];
  /** meta information related to the resource */
  metaInfo?: Maybe<ResourceMetaInformation>;
};

/** License group - only one of the license types in this group can be assigned to a user */
export type LicenseGroup = {
  __typename?: 'LicenseGroup';
  groupId: Scalars['String'];
  /** name of the group e.g. cs-lekage */
  groupname: Scalars['String'];
  /** key for the translation of the license group name */
  displayNameKey: Scalars['String'];
  /** list of license types within this group e.g. leak-reporter-user and leak-reporter-guest */
  licenseTypes: Array<Maybe<LicenseType>>;
};

export type LicenseGroupTypeDetails = {
  __typename?: 'LicenseGroupTypeDetails';
  id?: Maybe<Scalars['String']>;
  licenseType: Scalars['String'];
  displayNameKey?: Maybe<Scalars['String']>;
  descriptionKey?: Maybe<Scalars['String']>;
  numberOfLicenses: Scalars['Int'];
  assignedLicenses?: Maybe<Scalars['Int']>;
  licenseUsers?: Maybe<Array<Maybe<ActivatedLicense>>>;
  numberOfReservedLicenses?: Maybe<Scalars['Int']>;
};

export type LicensePacketInfo = {
  __typename?: 'LicensePacketInfo';
  /** Order ID of the license packet */
  orderId: Scalars['String'];
  /** date when the license packet was created */
  creationDate: Scalars['String'];
  /** null if the organization for this license packet has not yet been activated */
  activatedOrganizationInfo?: Maybe<ActivatedOrganizationLicenseInfo>;
  /** null if the license packet has not been activated */
  activatedLicensePacketInfo?: Maybe<ActivatedLicensePacketInfo>;
  /** license packet information */
  licensePacket: ActivatedLicensePacket;
  assignedGroupLicences?: Maybe<Array<Maybe<CloudLicensePacketGroup>>>;
};

/** License Type - can be assigned to a user and gives the right on an application */
export type LicenseType = {
  __typename?: 'LicenseType';
  licenseTypeId: Scalars['String'];
  /** type of the license, e.g. cs-lekage-user */
  licenseType: Scalars['String'];
  /** key for the translation of the license type */
  displayNameKey: Scalars['String'];
  /** number of licenses of this type e.g. 20 */
  numberOfLicenses: Scalars['Int'];
  /** number of licenses already assigned e.g. 5 (the number of available licenses of this type is LicenseNumber - LicensesAssigned */
  assignedLicenses: Scalars['Int'];
  /** number of reserved licenses (tied to a user) */
  numberOfReservedLicenses: Scalars['Int'];
  /** license packets activated for this cloud */
  licensePackets: Array<Maybe<CloudLicensePacket>>;
};

export enum LicenseUser {
  /** regular user */
  Regular = 'regular',
  /** cs user - no license required */
  Csuser = 'csuser'
}

export enum MsrRequirement {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
  Forbidden = 'FORBIDDEN'
}

export type MinimalUserInfo = {
  __typename?: 'MinimalUserInfo';
  orgUserID: Scalars['String'];
  email: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  forbidden?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  doSome?: Maybe<Scalars['Boolean']>;
  deactivateLicensePacket?: Maybe<Scalars['Boolean']>;
  updateLicensePacket?: Maybe<Scalars['Boolean']>;
  createRole?: Maybe<Scalars['Boolean']>;
  updateRole?: Maybe<Scalars['Boolean']>;
  updateRoleState?: Maybe<Scalars['Boolean']>;
  deleteRole?: Maybe<Scalars['Boolean']>;
  /**
   * create a user with the given user information. This includes username (used for login)
   * as well email address (for passowrd forget). The username must be unique
   * in the organization and the username. The given permissions will be granted to the user.
   */
  createUser?: Maybe<Scalars['Boolean']>;
  /**
   * create a user with the given user information. This includes username (used for login)
   * as well email address (for passowrd forget) and passowrd. The username must be unique
   * in the organization. The given permissions will be granted to the user.
   */
  createUserSimple?: Maybe<Scalars['Boolean']>;
  /**
   * updates a user. The parameter are basically the same as for create user.
   * Note that all permissions previously assigned are removed, i.e. it will
   * only get the permissions specified in this call. If userInfo
   * is not given the corresponding values are not changed.
   */
  updateUser?: Maybe<Scalars['Boolean']>;
  /**
   * updates a user. The parameter are basically the same as for create user.
   * Note that all permissions previously assigned are removed, i.e. it will
   * only get the permissions specified in this call. If userInfo
   * is not given the corresponding values are not changed.
   */
  updateUserSimple?: Maybe<Scalars['Boolean']>;
  /** updates the state of a user (move to trash or activate again) */
  updateUserState?: Maybe<Scalars['Boolean']>;
  /**
   * sets a new user as the root admin. The old user will still exists. If keppLicenses is true
   * and sufficient licenses are available the old admin will basically keep the licenses and
   * can still work with the software. If keepLicenses is false of not enough licenses are
   * available the old admin may not have licenses and can no longer use the software. In this
   * case the user can still be edited by the root admin in the ia.
   */
  setRootAdmin: Scalars['Boolean'];
  /** deletes the user with the given name */
  deleteUser?: Maybe<Scalars['Boolean']>;
};


export type MutationDeactivateLicensePacketArgs = {
  realmId: Scalars['String'];
  licensePacketId: Scalars['String'];
};


export type MutationUpdateLicensePacketArgs = {
  realmId: Scalars['String'];
  licensePacket: ActivatedLicensePacketInput;
};


export type MutationCreateRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  role: RoleInput;
};


export type MutationUpdateRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  role: RoleInput;
};


export type MutationUpdateRoleStateArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
  state: StateOfRole;
};


export type MutationDeleteRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  realmName: Scalars['String'];
  userState: UserStateInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateUserSimpleArgs = {
  realmName: Scalars['String'];
  userState: UserStateSimpleInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  userState?: Maybe<UserStateInput>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserSimpleArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  userState?: Maybe<UserStateSimpleInput>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserStateArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  state: StateOfUser;
};


export type MutationSetRootAdminArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  keepLicenses: Scalars['Boolean'];
};


export type MutationDeleteUserArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
};

/** Information on the license assigned to an organization */
export type OrganizationLicense = {
  __typename?: 'OrganizationLicense';
  /** unique id for the organization license */
  organizationLicenseId: Scalars['String'];
  /** name of the organization corresponding to the license (e.g. Kaeser) */
  organizationName: Scalars['String'];
  /** date when the license was activated */
  activationDate: Scalars['String'];
  /** date when the license expires */
  expirationDate: Scalars['String'];
  /** licenses for applications i.e. leak-reporter, cs-network, ... */
  licenseGroups: Array<Maybe<LicenseGroup>>;
};

export type Permission = {
  __typename?: 'Permission';
  resourceId: Scalars['String'];
  resourceForbidden: Scalars['Boolean'];
  resourceDisplayKey?: Maybe<Scalars['String']>;
  resourceDescriptionKey?: Maybe<Scalars['String']>;
  resourceScopes: Array<ResourceScope>;
  groupID: Scalars['Int'];
  indexID: Scalars['Int'];
};

export type PermissionInput = {
  resourceId: Scalars['String'];
  scopeId?: Maybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** get AM version information */
  getVersion: AmVersionInformation;
  getAppStatistics?: Maybe<Array<CompanyStats>>;
  getAssignableFileResources?: Maybe<Array<FilePermissionExSimple>>;
  /** Returns information on the available licenses of the organization */
  getOrganizationLicense?: Maybe<OrganizationLicense>;
  /**
   * Get the licenses which can be assigned by the invoker, considering
   * the selected superior and subordinates.
   */
  getAllAssignableLicensesEx?: Maybe<ActivatedCloudLicense>;
  /**
   * Get the assignable Packets list which can be assigned by the invoker, considering
   * the selected superior and accessible realms.
   */
  getAllAssignablePacketLicensesEx?: Maybe<Array<Maybe<ActivatedCloudLicense>>>;
  /** get various information on the license packet */
  getLicensePacketInformation?: Maybe<LicensePacketInfo>;
  getRole?: Maybe<Role>;
  getAccessibleRoles: Array<Maybe<AppRole>>;
  getAppUserRoles: Array<AppUserAssignRole>;
  getAppRole?: Maybe<AppRole>;
  getAppAssignableMetaPermission: Array<Permission>;
  /**
   * Return the user info, licenses and permissions (settings) for the given user. The result
   * corresponds exactly to the permissions specified in createUser or updateUser.
   * @fileFilter e.g company-id, project-id, group-id, building-id
   */
  getUser?: Maybe<UserState>;
  /** Returns access manangement roles that determine if a user is allowed to create user or role.  */
  getUserSpecialPermissions?: Maybe<Array<SpecialPermissions>>;
  /**
   * Return the user info, licenses and permissions (settings) for the given user. The result
   * corresponds exactly to the permissions specified in createUser or updateUser.
   */
  getUserSimple?: Maybe<UserStateSimple>;
  /**
   * Returns a list of users (along with the permissions on them) to which
   * the invoker (according to the token) has access
   */
  getAccessibleUsers?: Maybe<Array<Maybe<UserResourcePermission>>>;
  /**
   * Returns a list of users (along with the permissions on them) to which
   * the invoker (according to the token) has access. Is bassically the same as
   * getAccessibleUsers but does not return users without user management rights.
   * If licenses are given only those users are returned which are allowed to
   * assign that licenses.
   */
  getAccessibleSuperiors: Array<Maybe<UserResourcePermission>>;
  getAssignableSubordinates: Array<Maybe<UserResourcePermission>>;
  /**
   * same as getAccessibleSuperiors but for an existing user (with username). This
   * query will also indicate whether it is a full superior in the sense that the
   * accessible user has at least the permission of the existing user (i.e. it is not
   * necessary to reduce the rights of the existing user if that accessible user
   * becomes the superior). Furthermore it is indicated whether the accessible user
   * is a subordinate (or subordiante of a subordinate etc.) of the existing user.
   */
  getAccessibleSuperiorsExUser: Array<Maybe<UserResourcePermissionExUser>>;
  /**
   * For use when editing a role (is not required in role creation). Returns the
   * users to which the invoker (user given) has access. In contrast to
   * getAccessibleUsers there is a field in the structure of every user which
   * indicates whether the user must be allowed (REQUIRED) to assign the role, he
   * can assign the role (OPTIONAL) or cannot be allowerd to assign the role
   * (FORBIDEN). If this indications are not considered it will not be possible to
   * configure a set of consistent permissions and always an error will occur when
   * trying to update the role.
   *
   * Example REQUIRED: If a subordinate of a user has this custom role, then the
   * user is clearly required to be able to assign this role, i.e. REQUIRED.
   *
   * Example FORBIDDEN: This can happen if a user with this custom role has
   * subordinates or assignable / editable roles in this app (otherwise it should
   * not occur). Clearly the permissions of this custom role must include at
   * least the permissions of the roles of all the subordinates. Now if there is
   * a user which has a role with permissions less than that minimum required set
   * the user cannot be allowed to assign this role (this user would require the
   * role to have less permissions then the minimum according to the subordinates
   * (or editable / assignable roles) which is not possible).
   *
   * Note that it is very well possible that a user is OPTIONAL which has less
   * permissions then the existing role has (but of course only if there are no
   * subordinates of a user with this role which require them). That is if the
   * user is allowed to assign this role then some permissions which the role
   * previously had will not be allowed any more (this is considered then in
   * getSpecialResourcesRoleReq).
   *
   * Note also that there are potentially more users who are allowed to assign
   * this role, however they will not be returned as the invoker does not have
   * access to them.
   */
  getAccessibleUsersRole?: Maybe<Array<Maybe<UserResourcePermissionRole>>>;
  /**
   * returns true if the invoker is the root admin (false otherwise). Only the root
   * admin should be allowed to clear database.
   */
  isRootAdmin: Scalars['Boolean'];
  /** returns true for every realmName for which the user is root admin (false otherwise), */
  isRootAdminEx: Array<Maybe<Scalars['Boolean']>>;
  getUserInfo: RegisteredUserInfo;
};


export type QueryGetAppStatisticsArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
};


export type QueryGetAssignableFileResourcesArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  superiorID: Scalars['String'];
  subordinateIDs?: Maybe<Array<Scalars['String']>>;
  fileFilter?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetOrganizationLicenseArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAllAssignableLicensesExArgs = {
  realmName: Scalars['String'];
  superiorID?: Maybe<Scalars['String']>;
  subordinatesIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAllAssignablePacketLicensesExArgs = {
  realmNames: Array<Maybe<Scalars['String']>>;
  superior: Scalars['String'];
};


export type QueryGetLicensePacketInformationArgs = {
  licenseString: Scalars['String'];
};


export type QueryGetRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
};


export type QueryGetAccessibleRolesArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  state: StateOfRole;
};


export type QueryGetAppUserRolesArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  superiorID: Scalars['String'];
  subordinateIDs: Array<Scalars['String']>;
  userLicenses: Array<UserLicenseGroupInput>;
};


export type QueryGetAppRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetAppAssignableMetaPermissionArgs = {
  organizationID: Scalars['String'];
  editRoleID?: Maybe<Scalars['String']>;
  appName: Scalars['String'];
  editableBy: Array<Scalars['String']>;
  assignableBy: Array<Scalars['String']>;
};


export type QueryGetUserArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  fileFilter?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUserSpecialPermissionsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetUserSimpleArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
};


export type QueryGetAccessibleUsersArgs = {
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
};


export type QueryGetAccessibleSuperiorsArgs = {
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  licenses: Array<Maybe<SingleUserLicenseInput>>;
};


export type QueryGetAssignableSubordinatesArgs = {
  realmName: Scalars['String'];
  licenses: Array<Maybe<SingleUserLicenseInput>>;
  superiorID: Scalars['String'];
  assignableLicenses: Array<AssignableLicenseInput>;
};


export type QueryGetAccessibleSuperiorsExUserArgs = {
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
};


export type QueryGetAccessibleUsersRoleArgs = {
  realmName: Scalars['String'];
  appName: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
};


export type QueryIsRootAdminArgs = {
  realmName?: Maybe<Scalars['String']>;
};


export type QueryIsRootAdminExArgs = {
  realmName: Array<Maybe<Scalars['String']>>;
};


export type QueryGetUserInfoArgs = {
  username: Scalars['String'];
};

/** organization independent information on a (registered) user */
export type RegisteredUserInfo = {
  __typename?: 'RegisteredUserInfo';
  /** license requirements for this user */
  licenseUser: LicenseUser;
  /** whether the user is already registered */
  registered: Scalars['Boolean'];
};

export type RequiredResourceScope = {
  __typename?: 'RequiredResourceScope';
  resourceID: Scalars['String'];
  requiredScopes: Array<Maybe<Scalars['String']>>;
};

/**
 * Meta information related to the resource. This is intended to provide
 * helpfull information for a user which is assigning resources to a user
 */
export type ResourceMetaInformation = {
  __typename?: 'ResourceMetaInformation';
  /** Data and time of the resource e.g. when it was measured we recommend RFC 3339 for the string format */
  dateTime?: Maybe<Scalars['String']>;
  /** Version of software / device which created the resource */
  version?: Maybe<Scalars['String']>;
  /** state of the resource e.g. active, discarded, ... */
  state?: Maybe<StateOfResource>;
  /** Date and time when the resource was registered */
  registerDateTime?: Maybe<Scalars['String']>;
};

/**
 * Meta information related to the resource. This is intended to provide
 * helpfull information for a user which is assigning resources to a user
 */
export type ResourceMetaInformationInput = {
  /** Data and time of the resource e.g. when it was measured we recommend RFC 3339 for the string format */
  dateTime: Scalars['String'];
  /** Version of software / device which created the resource */
  version: Scalars['String'];
  /** state of the resource e.g. active, discarded, ... */
  state?: Maybe<StateOfResource>;
};

/**
 *  type ResourceScopeDependencies {
 *     scopeID: String!
 *     requiredResourceDependencies: [RequiredResourceScope]!
 * }
 */
export type ResourceScope = {
  __typename?: 'ResourceScope';
  scopeID: Scalars['String'];
  scopeDisplayKey: Scalars['String'];
  scopeDescriptionKey: Scalars['String'];
  /** whether the scope is actually assigned to the role (getRole returns all scopes even if the role does not have it) */
  scopeAssigned: Scalars['Boolean'];
  scopeAssignRequired?: Maybe<Scalars['Boolean']>;
  scopeDependencies?: Maybe<Array<RequiredResourceScope>>;
};

export type Role = {
  __typename?: 'Role';
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  roleDisplayKey?: Maybe<Scalars['String']>;
  roleDescriptionKey?: Maybe<Scalars['String']>;
  rolePermissions?: Maybe<Array<Permission>>;
  status: StateOfRole;
};

export type RoleDisplayInformation = {
  __typename?: 'RoleDisplayInformation';
  roleDisplayName: Scalars['String'];
  roleDescription: Scalars['String'];
  langKey: Scalars['String'];
};

export type RoleDisplayInput = {
  roleDisplayName: Scalars['String'];
  roleDescription?: Maybe<Scalars['String']>;
  langKey: Scalars['String'];
};

export type RoleInput = {
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  appRoleInfo?: Maybe<Array<RoleDisplayInput>>;
  rolePermissions?: Maybe<Array<PermissionInput>>;
  status: StateOfRole;
  userPermissions?: Maybe<RoleUserPermissionInput>;
};

export type RoleUserPermission = {
  __typename?: 'RoleUserPermission';
  editableBy: Array<Maybe<MinimalUserInfo>>;
  assignableBy: Array<Maybe<MinimalUserInfo>>;
};

export type RoleUserPermissionInput = {
  editableBy: Array<Maybe<Scalars['String']>>;
  assignableBy: Array<Maybe<Scalars['String']>>;
};

/** license assigned to a user */
export type SingleUserLicense = {
  __typename?: 'SingleUserLicense';
  /** id of the license packet to which this license belongs */
  licensePacketID: Scalars['String'];
  /** id of the license group (application), e.g. leak-reporter-id */
  licenseGroupID: Scalars['String'];
  /** id of the type of the license e.g. user-id or guest-id */
  licenseTypeID: Scalars['String'];
  /** date until the license is valid */
  expirationDate: Scalars['String'];
  /** if null or regular a license of the cloud licenses. csuser is a free license */
  regularLicense?: Maybe<LicenseUser>;
};

/** license assigned to a user */
export type SingleUserLicenseInput = {
  /** id of the license packet to which this license belongs */
  licensePacketID: Scalars['String'];
  /** id of the license group (application), e.g. leak-reporter-id */
  licenseGroupID: Scalars['String'];
  /** id of the type of the license e.g. user-id or guest-id */
  licenseTypeID: Scalars['String'];
  /** if null or regular the license is taken from the available cloud licenses. For the value csuser no license is taken - this is however only allowed for corresponding user. */
  regularLicense?: Maybe<LicenseUser>;
};

export type SpecialPermissions = {
  __typename?: 'SpecialPermissions';
  roleID: Scalars['String'];
  roleName: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
};

export enum StateOfResource {
  /** normal resource state */
  Active = 'active',
  /** resource was moved to trash - not deleted yet */
  Intrash = 'intrash'
}

export enum StateOfRole {
  /** normal file state */
  Active = 'active',
  /** file was moved to trash - not deleted yet */
  Intrash = 'intrash'
}

export enum StateOfUser {
  /** normal user state */
  Active = 'active',
  /** user was moved to trash - not deleted yet */
  Intrash = 'intrash'
}

/** App specific user roles */
export type UpAppRole = {
  __typename?: 'UPAppRole';
  /** name of the app to which the role list corresponds */
  appName: Scalars['String'];
  /** roles granted to the user for the given app */
  roles: Array<Maybe<UpAppRoleRole>>;
};

export type UpAppRoleRole = {
  __typename?: 'UPAppRoleRole';
  /** name of the role */
  roleName: Scalars['String'];
  /** key of the role, null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export enum UrpRequirment {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
  Forbidden = 'FORBIDDEN'
}

export type UserAccessPermission = {
  __typename?: 'UserAccessPermission';
  access: AccessPermission;
  assignPermissionDeps: Array<AccessPermission>;
};

/** Various information about a created user */
export type UserInfo = {
  __typename?: 'UserInfo';
  /** organizationUserId of the user */
  orgUserID: Scalars['String'];
  /** name of the user */
  username?: Maybe<Scalars['String']>;
  /** email address of the user */
  email: Scalars['String'];
  /** information on whether the user is extern */
  extern: ExternType;
  /** company information */
  company: Scalars['String'];
  /** arbitrary note */
  note?: Maybe<Scalars['String']>;
  /** date and time in when the user logged in the last time (in RFC 3339) */
  lastLogIn?: Maybe<Scalars['String']>;
  /** whether the user is already registered */
  registered: Scalars['Boolean'];
};

/** Various information about a user */
export type UserInfoInput = {
  /** email of the user */
  email: Scalars['String'];
  /** username of the user */
  username: Scalars['String'];
  /** information on whether the user is extern */
  extern: ExternType;
  /** arbitrary note */
  note?: Maybe<Scalars['String']>;
};

export type UserLicenseGroupInput = {
  licenseGroupID: Scalars['String'];
  licenseTypeID: Scalars['String'];
};

/** information about a user along with the access rights on it */
export type UserResourcePermission = {
  __typename?: 'UserResourcePermission';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<MinimalUserInfo>;
  /** permissions of the caller on the user */
  userPermission: Array<AccessPermission>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** permissible action scopes: e.g view, edit, delete  */
  scopes: Array<Maybe<Scalars['String']>>;
  subordinateUsers: Array<MinimalUserInfo>;
  /** adduser or adduserandrole or none */
  assignableUserPermission: Array<UserAccessPermission>;
};

/** information about a user along with the access rights on it */
export type UserResourcePermissionEx = {
  __typename?: 'UserResourcePermissionEx';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<Scalars['String']>;
  /** permissions of the caller on the user */
  userPermission: Array<Maybe<Scalars['String']>>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** users which may be assigned as subordinate */
  includesPermissionsOfUser: Array<Maybe<Scalars['String']>>;
  /** whether the user can be a subordinate */
  isManageable: Scalars['Boolean'];
};

/**
 * information about a user along with the access rights on it
 * and additional relations to an existing user
 */
export type UserResourcePermissionExUser = {
  __typename?: 'UserResourcePermissionExUser';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<Scalars['String']>;
  /** permissions of the caller on the user */
  userPermission: Array<Maybe<Scalars['String']>>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** if this user has at least the rights of the existing user */
  isFullSuperior: Scalars['Boolean'];
  /** if the user is a subordinate (or subordinate of subordinate) */
  isSubordinate: Scalars['Boolean'];
};

/** information about a user along with the access rights on it */
export type UserResourcePermissionRole = {
  __typename?: 'UserResourcePermissionRole';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<Scalars['String']>;
  /** permissions of the caller on the user */
  userPermission: Array<Maybe<Scalars['String']>>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** subordinates of the user */
  subordinates: Array<Maybe<Scalars['String']>>;
  /** consistency constraint for a given role */
  required: UrpRequirment;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions explicitly assigned to the user. That means this does not
 * necessarily include all resources the user has access to. If for example the user has
 * permissions on a resource type within that directory, the corresponding resources
 * will not be listed (unlike the were also explicitly granted and not via the directory).
 */
export type UserState = {
  __typename?: 'UserState';
  /** various user related information */
  userInfo?: Maybe<UserInfo>;
  /** ids of the licenses assigned to the user */
  licenses: Array<SingleUserLicense>;
  /** whether the user is enabled (can login or not) */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<MinimalUserInfo>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<MinimalUserInfo>;
  /** permissions of the users in the given app */
  appStates: Array<AppState>;
  userPermission: Array<AccessPermission>;
  assignableUserPermission: Array<AccessPermission>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicense>;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions which are explicity assigned to the user. Note
 * that by giving the user access to directories (or rather access to scopes
 * on certain types of resources with that directories) the user gets
 * access to resources which don't have to be specified in the resources field.
 */
export type UserStateInput = {
  /** information on the user, like email, extern, ... */
  userInfo: UserInfoInput;
  /** ids of the licenses assigned to the user */
  licenses: Array<Maybe<SingleUserLicenseInput>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<Scalars['String']>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<Maybe<Scalars['String']>>;
  /** permissions of the users in the given app */
  appStates: Array<Maybe<AppStateInput>>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicenseInput>;
  /** adduser or adduserandrole or none */
  userPermission?: Maybe<Array<Scalars['String']>>;
  userAssignablePermission: Array<Scalars['String']>;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions explicitly assigned to the user. That means this does not
 * necessarily include all resources the user has access to. If for example the user has
 * permissions on a resource type within that directory, the corresponding resources
 * will not be listed (unlike the were also explicitly granted and not via the directory).
 */
export type UserStateSimple = {
  __typename?: 'UserStateSimple';
  /** various user related information */
  userInfo?: Maybe<UserInfo>;
  /** ids of the licenses assigned to the user */
  licenses: Array<SingleUserLicense>;
  /** whether the user is enabled (can login or not) */
  enabled: Scalars['Boolean'];
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<Scalars['String']>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<Scalars['String']>;
  /** permissions of the users in the given app */
  appStates: Array<AppStateSimple>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicense>;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions which are explicity assigned to the user. Note
 * that by giving the user access to directories (or rather access to scopes
 * on certain types of resources with that directories) the user gets
 * access to resources which don't have to be specified in the resources field.
 */
export type UserStateSimpleInput = {
  /** information on the user, like email, extern, ... */
  userInfo: UserInfoInput;
  /** ids of the licenses assigned to the user */
  licenses: Array<Maybe<SingleUserLicenseInput>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<Scalars['String']>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<Maybe<Scalars['String']>>;
  /** permissions of the users in the given app */
  appStates: Array<Maybe<AppStateSimpleInput>>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicenseInput>;
};

export type CreateUserMutationVariables = Exact<{
  realmName: Scalars['String'];
  userState: UserStateInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUser'>
);

export type UpdateUserMutationVariables = Exact<{
  realmName: Scalars['String'];
  username: Scalars['String'];
  userState: UserStateInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type CreateUserSimpleMutationVariables = Exact<{
  realmName: Scalars['String'];
  userStateSimpleInput: UserStateSimpleInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type CreateUserSimpleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserSimple'>
);

export type UpdateUserSimpleMutationVariables = Exact<{
  realmName: Scalars['String'];
  username: Scalars['String'];
  userStateSimpleInput: UserStateSimpleInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserSimpleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserSimple'>
);

export type UpdateUserStateMutationVariables = Exact<{
  realmName: Scalars['String'];
  username: Scalars['String'];
  state: StateOfUser;
}>;


export type UpdateUserStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserState'>
);

export type DeleteUserMutationVariables = Exact<{
  realmName: Scalars['String'];
  username: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type CreateRoleMutationVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  orgRolePerm: RoleInput;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRole'>
);

export type UpdateRoleMutationVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  orgRolePerm: RoleInput;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRole'>
);

export type UpdateRoleStateMutationVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
  state: StateOfRole;
}>;


export type UpdateRoleStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRoleState'>
);

export type DeleteRoleMutationVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRole'>
);

export type GetUserQueryVariables = Exact<{
  realmName: Scalars['String'];
  username: Scalars['String'];
  fileTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'UserState' }
    & Pick<UserState, 'enabled' | 'state'>
    & { userInfo?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'orgUserID' | 'username' | 'email' | 'extern' | 'company' | 'note' | 'lastLogIn' | 'registered'>
    )>, userPermission: Array<(
      { __typename?: 'AccessPermission' }
      & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
    )>, assignableUserPermission: Array<(
      { __typename?: 'AccessPermission' }
      & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
    )>, licenses: Array<(
      { __typename?: 'SingleUserLicense' }
      & Pick<SingleUserLicense, 'licensePacketID' | 'licenseGroupID' | 'licenseTypeID' | 'expirationDate' | 'regularLicense'>
    )>, superior?: Maybe<(
      { __typename?: 'MinimalUserInfo' }
      & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
    )>, subordinates: Array<(
      { __typename?: 'MinimalUserInfo' }
      & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
    )>, appStates: Array<(
      { __typename?: 'AppState' }
      & Pick<AppState, 'appID'>
      & { roles: Array<(
        { __typename?: 'ASAppRole' }
        & Pick<AsAppRole, 'roleID' | 'roleName' | 'displayNameKey'>
      )>, assignableRoles: Array<(
        { __typename?: 'ASAppRole' }
        & Pick<AsAppRole, 'roleID' | 'roleName' | 'displayNameKey'>
      )>, editableRoles: Array<(
        { __typename?: 'ASAppRole' }
        & Pick<AsAppRole, 'roleID' | 'roleName' | 'displayNameKey'>
      )>, filePermission: Array<(
        { __typename?: 'FilePermission' }
        & Pick<FilePermission, 'fileID' | 'parentFileID' | 'filename' | 'filetypeID'>
        & { metaInfo?: Maybe<(
          { __typename?: 'ResourceMetaInformation' }
          & Pick<ResourceMetaInformation, 'dateTime' | 'version' | 'state' | 'registerDateTime'>
        )> }
      )> }
    )>, assignableLicenses: Array<(
      { __typename?: 'AssignableLicense' }
      & Pick<AssignableLicense, 'licensePacketId' | 'licenseGroupID' | 'licenseTypeID' | 'licenseID'>
    )> }
  )> }
);

export type GetOrganizationLicenseQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetOrganizationLicenseQuery = (
  { __typename?: 'Query' }
  & { getOrganizationLicense?: Maybe<(
    { __typename?: 'OrganizationLicense' }
    & Pick<OrganizationLicense, 'organizationLicenseId' | 'organizationName' | 'activationDate' | 'expirationDate'>
    & { licenseGroups: Array<Maybe<(
      { __typename?: 'LicenseGroup' }
      & Pick<LicenseGroup, 'groupId' | 'groupname' | 'displayNameKey'>
      & { licenseTypes: Array<Maybe<(
        { __typename?: 'LicenseType' }
        & Pick<LicenseType, 'licenseTypeId' | 'licenseType' | 'displayNameKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
        & { licensePackets: Array<Maybe<(
          { __typename?: 'CloudLicensePacket' }
          & Pick<CloudLicensePacket, 'packetId' | 'packetName' | 'numberOfLicenses' | 'assignedLicenses' | 'reservedLicenses'>
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetAccessibleUsersQueryVariables = Exact<{
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
}>;


export type GetAccessibleUsersQuery = (
  { __typename?: 'Query' }
  & { getAccessibleUsers?: Maybe<Array<Maybe<(
    { __typename?: 'UserResourcePermission' }
    & Pick<UserResourcePermission, 'enabled' | 'state' | 'scopes'>
    & { userInfo: (
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'orgUserID' | 'username' | 'email' | 'extern' | 'company' | 'note' | 'lastLogIn' | 'registered'>
    ), superior?: Maybe<(
      { __typename?: 'MinimalUserInfo' }
      & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
    )>, userPermission: Array<(
      { __typename?: 'AccessPermission' }
      & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
    )>, appRoles: Array<Maybe<(
      { __typename?: 'UPAppRole' }
      & Pick<UpAppRole, 'appName'>
      & { roles: Array<Maybe<(
        { __typename?: 'UPAppRoleRole' }
        & Pick<UpAppRoleRole, 'roleName' | 'displayNameKey'>
      )>> }
    )>>, assignableUserPermission: Array<(
      { __typename?: 'UserAccessPermission' }
      & { access: (
        { __typename?: 'AccessPermission' }
        & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
      ) }
    )> }
  )>>> }
);

export type ValidateUserInfoQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type ValidateUserInfoQuery = (
  { __typename?: 'Query' }
  & { getUserInfo: (
    { __typename?: 'RegisteredUserInfo' }
    & Pick<RegisteredUserInfo, 'licenseUser' | 'registered'>
  ) }
);

export type GetOrganizationalLicenseQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetOrganizationalLicenseQuery = (
  { __typename?: 'Query' }
  & { getOrganizationLicense?: Maybe<(
    { __typename?: 'OrganizationLicense' }
    & Pick<OrganizationLicense, 'organizationLicenseId' | 'organizationName' | 'activationDate'>
    & { licenseGroups: Array<Maybe<(
      { __typename?: 'LicenseGroup' }
      & Pick<LicenseGroup, 'groupname' | 'displayNameKey'>
      & { licenseTypes: Array<Maybe<(
        { __typename?: 'LicenseType' }
        & Pick<LicenseType, 'licenseType' | 'displayNameKey' | 'numberOfLicenses' | 'assignedLicenses'>
      )>> }
    )>> }
  )> }
);

export type GetAccessibleUsersRoleQueryVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
}>;


export type GetAccessibleUsersRoleQuery = (
  { __typename?: 'Query' }
  & { getAccessibleUsersRole?: Maybe<Array<Maybe<(
    { __typename?: 'UserResourcePermissionRole' }
    & Pick<UserResourcePermissionRole, 'superior' | 'userPermission' | 'enabled' | 'state' | 'subordinates' | 'required'>
    & { userInfo: (
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'username' | 'email' | 'extern' | 'company' | 'note' | 'lastLogIn' | 'registered'>
    ), appRoles: Array<Maybe<(
      { __typename?: 'UPAppRole' }
      & Pick<UpAppRole, 'appName'>
      & { roles: Array<Maybe<(
        { __typename?: 'UPAppRoleRole' }
        & Pick<UpAppRoleRole, 'roleName' | 'displayNameKey'>
      )>> }
    )>> }
  )>>> }
);

export type GetAccessibleSuperiorsQueryVariables = Exact<{
  realmName: Scalars['String'];
  state: StateOfUser;
  licenses: Array<Maybe<SingleUserLicenseInput>> | Maybe<SingleUserLicenseInput>;
}>;


export type GetAccessibleSuperiorsQuery = (
  { __typename?: 'Query' }
  & { getAccessibleSuperiors: Array<Maybe<(
    { __typename?: 'UserResourcePermission' }
    & Pick<UserResourcePermission, 'enabled' | 'state'>
    & { userInfo: (
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'orgUserID' | 'username' | 'email' | 'extern' | 'company' | 'note' | 'lastLogIn' | 'registered'>
    ), superior?: Maybe<(
      { __typename?: 'MinimalUserInfo' }
      & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
    )>, userPermission: Array<(
      { __typename?: 'AccessPermission' }
      & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
    )>, appRoles: Array<Maybe<(
      { __typename?: 'UPAppRole' }
      & Pick<UpAppRole, 'appName'>
      & { roles: Array<Maybe<(
        { __typename?: 'UPAppRoleRole' }
        & Pick<UpAppRoleRole, 'roleName' | 'displayNameKey'>
      )>> }
    )>>, subordinateUsers: Array<(
      { __typename?: 'MinimalUserInfo' }
      & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
    )>, assignableUserPermission: Array<(
      { __typename?: 'UserAccessPermission' }
      & { access: (
        { __typename?: 'AccessPermission' }
        & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
      ), assignPermissionDeps: Array<(
        { __typename?: 'AccessPermission' }
        & Pick<AccessPermission, 'roleID' | 'roleDisplayKey' | 'roleDescriptionKey'>
      )> }
    )> }
  )>> }
);

export type GetAccessibleSuperiorsExUserQueryVariables = Exact<{
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
}>;


export type GetAccessibleSuperiorsExUserQuery = (
  { __typename?: 'Query' }
  & { getAccessibleSuperiorsExUser: Array<Maybe<(
    { __typename?: 'UserResourcePermissionExUser' }
    & Pick<UserResourcePermissionExUser, 'superior' | 'userPermission' | 'enabled' | 'state' | 'isFullSuperior' | 'isSubordinate'>
    & { userInfo: (
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'username' | 'email' | 'extern' | 'company' | 'note' | 'lastLogIn' | 'registered'>
    ), appRoles: Array<Maybe<(
      { __typename?: 'UPAppRole' }
      & Pick<UpAppRole, 'appName'>
      & { roles: Array<Maybe<(
        { __typename?: 'UPAppRoleRole' }
        & Pick<UpAppRoleRole, 'roleName' | 'displayNameKey'>
      )>> }
    )>> }
  )>> }
);

export type GetAllAssignableLicensesExQueryVariables = Exact<{
  realmName: Scalars['String'];
  superior?: Maybe<Scalars['String']>;
  subordinates?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAllAssignableLicensesExQuery = (
  { __typename?: 'Query' }
  & { getAllAssignableLicensesEx?: Maybe<(
    { __typename?: 'ActivatedCloudLicense' }
    & Pick<ActivatedCloudLicense, 'organizationName' | 'forbidden' | 'required'>
    & { licensePackets?: Maybe<Array<Maybe<(
      { __typename?: 'ActivatedLicensePacket' }
      & Pick<ActivatedLicensePacket, 'id' | 'packetName' | 'expirationDate' | 'forbidden' | 'required'>
      & { licenseGroups?: Maybe<Array<Maybe<(
        { __typename?: 'ActivatedLicenseGroup' }
        & Pick<ActivatedLicenseGroup, 'id' | 'groupname' | 'displayNameKey' | 'descriptionKey' | 'forbidden' | 'required'>
        & { licenseTypes?: Maybe<Array<Maybe<(
          { __typename?: 'ActivatedLicenseType' }
          & Pick<ActivatedLicenseType, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'forbidden' | 'required'>
          & { licenses?: Maybe<Array<Maybe<(
            { __typename?: 'ActivatedLicense' }
            & Pick<ActivatedLicense, 'id' | 'reserved' | 'userId' | 'required'>
          )>>> }
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type GetAccessibleRolesQueryVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  state: StateOfRole;
}>;


export type GetAccessibleRolesQuery = (
  { __typename?: 'Query' }
  & { getAccessibleRoles: Array<Maybe<(
    { __typename?: 'AppRole' }
    & Pick<AppRole, 'isCustomRole' | 'userAccessScopes'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'roleId' | 'roleName' | 'roleDisplayKey' | 'roleDescriptionKey' | 'status'>
      & { rolePermissions?: Maybe<Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'resourceId' | 'resourceDisplayKey' | 'resourceDescriptionKey'>
        & { resourceScopes: Array<(
          { __typename?: 'ResourceScope' }
          & Pick<ResourceScope, 'scopeID' | 'scopeDisplayKey' | 'scopeDescriptionKey' | 'scopeAssigned' | 'scopeAssignRequired'>
        )> }
      )>> }
    ), appRoleInfo?: Maybe<Array<(
      { __typename?: 'RoleDisplayInformation' }
      & Pick<RoleDisplayInformation, 'roleDisplayName' | 'roleDescription' | 'langKey'>
    )>> }
  )>> }
);

export type GetAppRoleQueryVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
}>;


export type GetAppRoleQuery = (
  { __typename?: 'Query' }
  & { getAppRole?: Maybe<(
    { __typename?: 'AppRole' }
    & Pick<AppRole, 'isCustomRole' | 'userAccessScopes'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'roleId' | 'roleName' | 'roleDisplayKey' | 'roleDescriptionKey' | 'status'>
      & { rolePermissions?: Maybe<Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'resourceId' | 'groupID' | 'indexID' | 'resourceDisplayKey' | 'resourceDescriptionKey'>
        & { resourceScopes: Array<(
          { __typename?: 'ResourceScope' }
          & Pick<ResourceScope, 'scopeID' | 'scopeDisplayKey' | 'scopeDescriptionKey' | 'scopeAssigned'>
        )> }
      )>> }
    ), appRoleInfo?: Maybe<Array<(
      { __typename?: 'RoleDisplayInformation' }
      & Pick<RoleDisplayInformation, 'roleDisplayName' | 'roleDescription' | 'langKey'>
    )>>, userPermissions: (
      { __typename?: 'RoleUserPermission' }
      & { editableBy: Array<Maybe<(
        { __typename?: 'MinimalUserInfo' }
        & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
      )>>, assignableBy: Array<Maybe<(
        { __typename?: 'MinimalUserInfo' }
        & Pick<MinimalUserInfo, 'orgUserID' | 'email'>
      )>> }
    ) }
  )> }
);

export type GetAppStatisticsQueryVariables = Exact<{
  organizationID: Scalars['String'];
  appName: Scalars['String'];
}>;


export type GetAppStatisticsQuery = (
  { __typename?: 'Query' }
  & { getAppStatistics?: Maybe<Array<(
    { __typename?: 'CompanyStats' }
    & Pick<CompanyStats, 'companyName' | 'noOfUsers' | 'noOfProjects' | 'noOfBuildings'>
  )>> }
);

export type GetAssignableFileResourcesQueryVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  superiorID: Scalars['String'];
  subordinateIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  fileFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAssignableFileResourcesQuery = (
  { __typename?: 'Query' }
  & { getAssignableFileResources?: Maybe<Array<(
    { __typename?: 'FilePermissionExSimple' }
    & Pick<FilePermissionExSimple, 'fileID' | 'parentFileID' | 'filename' | 'filetypeID' | 'required' | 'superiorHasAccess' | 'selectable'>
    & { metaInfo?: Maybe<(
      { __typename?: 'ResourceMetaInformation' }
      & Pick<ResourceMetaInformation, 'state'>
    )> }
  )>> }
);

export type GetAppUserRolesQueryVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  superiorID: Scalars['String'];
  subordinateIDs: Array<Scalars['String']> | Scalars['String'];
  userLicenses: Array<UserLicenseGroupInput> | UserLicenseGroupInput;
}>;


export type GetAppUserRolesQuery = (
  { __typename?: 'Query' }
  & { getAppUserRoles: Array<(
    { __typename?: 'AppUserAssignRole' }
    & Pick<AppUserAssignRole, 'isCustomRole' | 'required' | 'appUserAssignRequired' | 'appUserEditableRoles' | 'appUserAssignableRoles'>
    & { appUserRole: (
      { __typename?: 'AppUserRole' }
      & Pick<AppUserRole, 'roleID' | 'roleName' | 'roleDisplayKey' | 'roleDescriptionKey' | 'status'>
      & { appRoleInfo?: Maybe<Array<(
        { __typename?: 'RoleDisplayInformation' }
        & Pick<RoleDisplayInformation, 'roleDisplayName' | 'roleDescription' | 'langKey'>
      )>> }
    ) }
  )> }
);

export type GetAppAssignableMetaPermissionQueryVariables = Exact<{
  realmName: Scalars['String'];
  appName: Scalars['String'];
  editableBy: Array<Scalars['String']> | Scalars['String'];
  assignableBy: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAppAssignableMetaPermissionQuery = (
  { __typename?: 'Query' }
  & { getAppAssignableMetaPermission: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'resourceId' | 'resourceForbidden' | 'resourceDisplayKey' | 'resourceDescriptionKey' | 'groupID' | 'indexID'>
    & { resourceScopes: Array<(
      { __typename?: 'ResourceScope' }
      & Pick<ResourceScope, 'scopeID' | 'scopeDisplayKey' | 'scopeDescriptionKey' | 'scopeAssigned' | 'scopeAssignRequired'>
      & { scopeDependencies?: Maybe<Array<(
        { __typename?: 'RequiredResourceScope' }
        & Pick<RequiredResourceScope, 'resourceID' | 'requiredScopes'>
      )>> }
    )> }
  )> }
);


export const CreateUserDocument = gql`
    mutation CreateUser($realmName: String!, $userState: UserStateInput!, $sendEmail: Boolean) {
  createUser(realmName: $realmName, userState: $userState, sendEmail: $sendEmail)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      userState: // value for 'userState'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($realmName: String!, $username: String!, $userState: UserStateInput!, $sendEmail: Boolean) {
  updateUser(
    realmName: $realmName
    username: $username
    userState: $userState
    sendEmail: $sendEmail
  )
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      username: // value for 'username'
 *      userState: // value for 'userState'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserSimpleDocument = gql`
    mutation CreateUserSimple($realmName: String!, $userStateSimpleInput: UserStateSimpleInput!, $sendEmail: Boolean) {
  createUserSimple(
    realmName: $realmName
    userState: $userStateSimpleInput
    sendEmail: $sendEmail
  )
}
    `;
export type CreateUserSimpleMutationFn = Apollo.MutationFunction<CreateUserSimpleMutation, CreateUserSimpleMutationVariables>;

/**
 * __useCreateUserSimpleMutation__
 *
 * To run a mutation, you first call `useCreateUserSimpleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSimpleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSimpleMutation, { data, loading, error }] = useCreateUserSimpleMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      userStateSimpleInput: // value for 'userStateSimpleInput'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useCreateUserSimpleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSimpleMutation, CreateUserSimpleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSimpleMutation, CreateUserSimpleMutationVariables>(CreateUserSimpleDocument, options);
      }
export type CreateUserSimpleMutationHookResult = ReturnType<typeof useCreateUserSimpleMutation>;
export type CreateUserSimpleMutationResult = Apollo.MutationResult<CreateUserSimpleMutation>;
export type CreateUserSimpleMutationOptions = Apollo.BaseMutationOptions<CreateUserSimpleMutation, CreateUserSimpleMutationVariables>;
export const UpdateUserSimpleDocument = gql`
    mutation UpdateUserSimple($realmName: String!, $username: String!, $userStateSimpleInput: UserStateSimpleInput!, $sendEmail: Boolean) {
  updateUserSimple(
    realmName: $realmName
    username: $username
    userState: $userStateSimpleInput
    sendEmail: $sendEmail
  )
}
    `;
export type UpdateUserSimpleMutationFn = Apollo.MutationFunction<UpdateUserSimpleMutation, UpdateUserSimpleMutationVariables>;

/**
 * __useUpdateUserSimpleMutation__
 *
 * To run a mutation, you first call `useUpdateUserSimpleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSimpleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSimpleMutation, { data, loading, error }] = useUpdateUserSimpleMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      username: // value for 'username'
 *      userStateSimpleInput: // value for 'userStateSimpleInput'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useUpdateUserSimpleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSimpleMutation, UpdateUserSimpleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSimpleMutation, UpdateUserSimpleMutationVariables>(UpdateUserSimpleDocument, options);
      }
export type UpdateUserSimpleMutationHookResult = ReturnType<typeof useUpdateUserSimpleMutation>;
export type UpdateUserSimpleMutationResult = Apollo.MutationResult<UpdateUserSimpleMutation>;
export type UpdateUserSimpleMutationOptions = Apollo.BaseMutationOptions<UpdateUserSimpleMutation, UpdateUserSimpleMutationVariables>;
export const UpdateUserStateDocument = gql`
    mutation UpdateUserState($realmName: String!, $username: String!, $state: StateOfUser!) {
  updateUserState(realmName: $realmName, username: $username, state: $state)
}
    `;
export type UpdateUserStateMutationFn = Apollo.MutationFunction<UpdateUserStateMutation, UpdateUserStateMutationVariables>;

/**
 * __useUpdateUserStateMutation__
 *
 * To run a mutation, you first call `useUpdateUserStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStateMutation, { data, loading, error }] = useUpdateUserStateMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      username: // value for 'username'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateUserStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStateMutation, UpdateUserStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStateMutation, UpdateUserStateMutationVariables>(UpdateUserStateDocument, options);
      }
export type UpdateUserStateMutationHookResult = ReturnType<typeof useUpdateUserStateMutation>;
export type UpdateUserStateMutationResult = Apollo.MutationResult<UpdateUserStateMutation>;
export type UpdateUserStateMutationOptions = Apollo.BaseMutationOptions<UpdateUserStateMutation, UpdateUserStateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($realmName: String!, $username: String!) {
  deleteUser(realmName: $realmName, username: $username)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($realmName: String!, $appName: String!, $orgRolePerm: RoleInput!) {
  createRole(organizationID: $realmName, appName: $appName, role: $orgRolePerm)
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      orgRolePerm: // value for 'orgRolePerm'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($realmName: String!, $appName: String!, $orgRolePerm: RoleInput!) {
  updateRole(organizationID: $realmName, appName: $appName, role: $orgRolePerm)
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      orgRolePerm: // value for 'orgRolePerm'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateRoleStateDocument = gql`
    mutation UpdateRoleState($realmName: String!, $appName: String!, $roleID: String!, $state: StateOfRole!) {
  updateRoleState(
    organizationID: $realmName
    appName: $appName
    roleID: $roleID
    state: $state
  )
}
    `;
export type UpdateRoleStateMutationFn = Apollo.MutationFunction<UpdateRoleStateMutation, UpdateRoleStateMutationVariables>;

/**
 * __useUpdateRoleStateMutation__
 *
 * To run a mutation, you first call `useUpdateRoleStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleStateMutation, { data, loading, error }] = useUpdateRoleStateMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      roleID: // value for 'roleID'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateRoleStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleStateMutation, UpdateRoleStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleStateMutation, UpdateRoleStateMutationVariables>(UpdateRoleStateDocument, options);
      }
export type UpdateRoleStateMutationHookResult = ReturnType<typeof useUpdateRoleStateMutation>;
export type UpdateRoleStateMutationResult = Apollo.MutationResult<UpdateRoleStateMutation>;
export type UpdateRoleStateMutationOptions = Apollo.BaseMutationOptions<UpdateRoleStateMutation, UpdateRoleStateMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($realmName: String!, $appName: String!, $roleID: String!) {
  deleteRole(organizationID: $realmName, appName: $appName, roleID: $roleID)
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetUserDocument = gql`
    query GetUser($realmName: String!, $username: String!, $fileTypes: [String!]) {
  getUser(realmName: $realmName, username: $username, fileFilter: $fileTypes) {
    userInfo {
      orgUserID
      username
      email
      extern
      company
      note
      lastLogIn
      registered
    }
    userPermission {
      roleID
      roleDisplayKey
      roleDescriptionKey
    }
    assignableUserPermission {
      roleID
      roleDisplayKey
      roleDescriptionKey
    }
    licenses {
      licensePacketID
      licenseGroupID
      licenseTypeID
      expirationDate
      regularLicense
    }
    enabled
    state
    superior {
      orgUserID
      email
    }
    subordinates {
      orgUserID
      email
    }
    appStates {
      appID
      roles {
        roleID
        roleName
        displayNameKey
      }
      assignableRoles {
        roleID
        roleName
        displayNameKey
      }
      editableRoles {
        roleID
        roleName
        displayNameKey
      }
      filePermission {
        fileID
        parentFileID
        filename
        filetypeID
        metaInfo {
          dateTime
          version
          state
          registerDateTime
        }
      }
    }
    assignableLicenses {
      licensePacketId
      licenseGroupID
      licenseTypeID
      licenseID
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      username: // value for 'username'
 *      fileTypes: // value for 'fileTypes'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetOrganizationLicenseDocument = gql`
    query GetOrganizationLicense($realmName: String!) {
  getOrganizationLicense(realmName: $realmName) {
    organizationLicenseId
    organizationName
    activationDate
    expirationDate
    licenseGroups {
      groupId
      groupname
      displayNameKey
      licenseTypes {
        licenseTypeId
        licenseType
        displayNameKey
        numberOfLicenses
        assignedLicenses
        numberOfReservedLicenses
        licensePackets {
          packetId
          packetName
          numberOfLicenses
          assignedLicenses
          reservedLicenses
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationLicenseQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLicenseQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetOrganizationLicenseQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationLicenseQuery, GetOrganizationLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationLicenseQuery, GetOrganizationLicenseQueryVariables>(GetOrganizationLicenseDocument, options);
      }
export function useGetOrganizationLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationLicenseQuery, GetOrganizationLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationLicenseQuery, GetOrganizationLicenseQueryVariables>(GetOrganizationLicenseDocument, options);
        }
export type GetOrganizationLicenseQueryHookResult = ReturnType<typeof useGetOrganizationLicenseQuery>;
export type GetOrganizationLicenseLazyQueryHookResult = ReturnType<typeof useGetOrganizationLicenseLazyQuery>;
export type GetOrganizationLicenseQueryResult = Apollo.QueryResult<GetOrganizationLicenseQuery, GetOrganizationLicenseQueryVariables>;
export const GetAccessibleUsersDocument = gql`
    query GetAccessibleUsers($realmName: String!, $state: StateOfUser, $username: String!) {
  getAccessibleUsers(realmName: $realmName, state: $state, username: $username) {
    userInfo {
      orgUserID
      username
      email
      extern
      company
      note
      lastLogIn
      registered
    }
    superior {
      orgUserID
      email
    }
    userPermission {
      roleID
      roleDisplayKey
      roleDescriptionKey
    }
    appRoles {
      appName
      roles {
        roleName
        displayNameKey
      }
    }
    enabled
    state
    assignableUserPermission {
      access {
        roleID
        roleDisplayKey
        roleDescriptionKey
      }
    }
    scopes
  }
}
    `;

/**
 * __useGetAccessibleUsersQuery__
 *
 * To run a query within a React component, call `useGetAccessibleUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleUsersQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      state: // value for 'state'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetAccessibleUsersQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>(GetAccessibleUsersDocument, options);
      }
export function useGetAccessibleUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>(GetAccessibleUsersDocument, options);
        }
export type GetAccessibleUsersQueryHookResult = ReturnType<typeof useGetAccessibleUsersQuery>;
export type GetAccessibleUsersLazyQueryHookResult = ReturnType<typeof useGetAccessibleUsersLazyQuery>;
export type GetAccessibleUsersQueryResult = Apollo.QueryResult<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>;
export const ValidateUserInfoDocument = gql`
    query ValidateUserInfo($username: String!) {
  getUserInfo(username: $username) {
    licenseUser
    registered
  }
}
    `;

/**
 * __useValidateUserInfoQuery__
 *
 * To run a query within a React component, call `useValidateUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateUserInfoQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useValidateUserInfoQuery(baseOptions: Apollo.QueryHookOptions<ValidateUserInfoQuery, ValidateUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateUserInfoQuery, ValidateUserInfoQueryVariables>(ValidateUserInfoDocument, options);
      }
export function useValidateUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateUserInfoQuery, ValidateUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateUserInfoQuery, ValidateUserInfoQueryVariables>(ValidateUserInfoDocument, options);
        }
export type ValidateUserInfoQueryHookResult = ReturnType<typeof useValidateUserInfoQuery>;
export type ValidateUserInfoLazyQueryHookResult = ReturnType<typeof useValidateUserInfoLazyQuery>;
export type ValidateUserInfoQueryResult = Apollo.QueryResult<ValidateUserInfoQuery, ValidateUserInfoQueryVariables>;
export const GetOrganizationalLicenseDocument = gql`
    query GetOrganizationalLicense($realmName: String!) {
  getOrganizationLicense(realmName: $realmName) {
    organizationLicenseId
    organizationName
    activationDate
    licenseGroups {
      groupname
      displayNameKey
      licenseTypes {
        licenseType
        displayNameKey
        numberOfLicenses
        assignedLicenses
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationalLicenseQuery__
 *
 * To run a query within a React component, call `useGetOrganizationalLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationalLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationalLicenseQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetOrganizationalLicenseQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationalLicenseQuery, GetOrganizationalLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationalLicenseQuery, GetOrganizationalLicenseQueryVariables>(GetOrganizationalLicenseDocument, options);
      }
export function useGetOrganizationalLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationalLicenseQuery, GetOrganizationalLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationalLicenseQuery, GetOrganizationalLicenseQueryVariables>(GetOrganizationalLicenseDocument, options);
        }
export type GetOrganizationalLicenseQueryHookResult = ReturnType<typeof useGetOrganizationalLicenseQuery>;
export type GetOrganizationalLicenseLazyQueryHookResult = ReturnType<typeof useGetOrganizationalLicenseLazyQuery>;
export type GetOrganizationalLicenseQueryResult = Apollo.QueryResult<GetOrganizationalLicenseQuery, GetOrganizationalLicenseQueryVariables>;
export const GetAccessibleUsersRoleDocument = gql`
    query GetAccessibleUsersRole($realmName: String!, $appName: String!, $roleName: String) {
  getAccessibleUsersRole(
    realmName: $realmName
    appName: $appName
    roleName: $roleName
  ) {
    userInfo {
      username
      email
      extern
      company
      note
      lastLogIn
      registered
    }
    superior
    userPermission
    appRoles {
      appName
      roles {
        roleName
        displayNameKey
      }
    }
    enabled
    state
    subordinates
    required
  }
}
    `;

/**
 * __useGetAccessibleUsersRoleQuery__
 *
 * To run a query within a React component, call `useGetAccessibleUsersRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleUsersRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleUsersRoleQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useGetAccessibleUsersRoleQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleUsersRoleQuery, GetAccessibleUsersRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleUsersRoleQuery, GetAccessibleUsersRoleQueryVariables>(GetAccessibleUsersRoleDocument, options);
      }
export function useGetAccessibleUsersRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleUsersRoleQuery, GetAccessibleUsersRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleUsersRoleQuery, GetAccessibleUsersRoleQueryVariables>(GetAccessibleUsersRoleDocument, options);
        }
export type GetAccessibleUsersRoleQueryHookResult = ReturnType<typeof useGetAccessibleUsersRoleQuery>;
export type GetAccessibleUsersRoleLazyQueryHookResult = ReturnType<typeof useGetAccessibleUsersRoleLazyQuery>;
export type GetAccessibleUsersRoleQueryResult = Apollo.QueryResult<GetAccessibleUsersRoleQuery, GetAccessibleUsersRoleQueryVariables>;
export const GetAccessibleSuperiorsDocument = gql`
    query GetAccessibleSuperiors($realmName: String!, $state: StateOfUser!, $licenses: [SingleUserLicenseInput]!) {
  getAccessibleSuperiors(
    realmName: $realmName
    state: $state
    licenses: $licenses
  ) {
    userInfo {
      orgUserID
      username
      email
      extern
      company
      note
      lastLogIn
      registered
    }
    superior {
      orgUserID
      email
    }
    userPermission {
      roleID
      roleDisplayKey
      roleDescriptionKey
    }
    appRoles {
      appName
      roles {
        roleName
        displayNameKey
      }
    }
    subordinateUsers {
      orgUserID
      email
    }
    assignableUserPermission {
      access {
        roleID
        roleDisplayKey
        roleDescriptionKey
      }
      assignPermissionDeps {
        roleID
        roleDisplayKey
        roleDescriptionKey
      }
    }
    enabled
    state
  }
}
    `;

/**
 * __useGetAccessibleSuperiorsQuery__
 *
 * To run a query within a React component, call `useGetAccessibleSuperiorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleSuperiorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleSuperiorsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      state: // value for 'state'
 *      licenses: // value for 'licenses'
 *   },
 * });
 */
export function useGetAccessibleSuperiorsQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleSuperiorsQuery, GetAccessibleSuperiorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleSuperiorsQuery, GetAccessibleSuperiorsQueryVariables>(GetAccessibleSuperiorsDocument, options);
      }
export function useGetAccessibleSuperiorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleSuperiorsQuery, GetAccessibleSuperiorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleSuperiorsQuery, GetAccessibleSuperiorsQueryVariables>(GetAccessibleSuperiorsDocument, options);
        }
export type GetAccessibleSuperiorsQueryHookResult = ReturnType<typeof useGetAccessibleSuperiorsQuery>;
export type GetAccessibleSuperiorsLazyQueryHookResult = ReturnType<typeof useGetAccessibleSuperiorsLazyQuery>;
export type GetAccessibleSuperiorsQueryResult = Apollo.QueryResult<GetAccessibleSuperiorsQuery, GetAccessibleSuperiorsQueryVariables>;
export const GetAccessibleSuperiorsExUserDocument = gql`
    query GetAccessibleSuperiorsExUser($realmName: String!, $state: StateOfUser, $username: String!) {
  getAccessibleSuperiorsExUser(
    realmName: $realmName
    state: $state
    username: $username
  ) {
    userInfo {
      username
      email
      extern
      company
      note
      lastLogIn
      registered
    }
    superior
    userPermission
    appRoles {
      appName
      roles {
        roleName
        displayNameKey
      }
    }
    enabled
    state
    isFullSuperior
    isSubordinate
  }
}
    `;

/**
 * __useGetAccessibleSuperiorsExUserQuery__
 *
 * To run a query within a React component, call `useGetAccessibleSuperiorsExUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleSuperiorsExUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleSuperiorsExUserQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      state: // value for 'state'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetAccessibleSuperiorsExUserQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleSuperiorsExUserQuery, GetAccessibleSuperiorsExUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleSuperiorsExUserQuery, GetAccessibleSuperiorsExUserQueryVariables>(GetAccessibleSuperiorsExUserDocument, options);
      }
export function useGetAccessibleSuperiorsExUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleSuperiorsExUserQuery, GetAccessibleSuperiorsExUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleSuperiorsExUserQuery, GetAccessibleSuperiorsExUserQueryVariables>(GetAccessibleSuperiorsExUserDocument, options);
        }
export type GetAccessibleSuperiorsExUserQueryHookResult = ReturnType<typeof useGetAccessibleSuperiorsExUserQuery>;
export type GetAccessibleSuperiorsExUserLazyQueryHookResult = ReturnType<typeof useGetAccessibleSuperiorsExUserLazyQuery>;
export type GetAccessibleSuperiorsExUserQueryResult = Apollo.QueryResult<GetAccessibleSuperiorsExUserQuery, GetAccessibleSuperiorsExUserQueryVariables>;
export const GetAllAssignableLicensesExDocument = gql`
    query GetAllAssignableLicensesEx($realmName: String!, $superior: String, $subordinates: [String!]) {
  getAllAssignableLicensesEx(
    realmName: $realmName
    superiorID: $superior
    subordinatesIDs: $subordinates
  ) {
    organizationName
    licensePackets {
      id
      packetName
      expirationDate
      licenseGroups {
        id
        groupname
        displayNameKey
        descriptionKey
        licenseTypes {
          id
          licenseType
          displayNameKey
          descriptionKey
          licenses {
            id
            reserved
            userId
            required
          }
          forbidden
          required
        }
        forbidden
        required
      }
      forbidden
      required
    }
    forbidden
    required
  }
}
    `;

/**
 * __useGetAllAssignableLicensesExQuery__
 *
 * To run a query within a React component, call `useGetAllAssignableLicensesExQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignableLicensesExQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignableLicensesExQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      superior: // value for 'superior'
 *      subordinates: // value for 'subordinates'
 *   },
 * });
 */
export function useGetAllAssignableLicensesExQuery(baseOptions: Apollo.QueryHookOptions<GetAllAssignableLicensesExQuery, GetAllAssignableLicensesExQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAssignableLicensesExQuery, GetAllAssignableLicensesExQueryVariables>(GetAllAssignableLicensesExDocument, options);
      }
export function useGetAllAssignableLicensesExLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssignableLicensesExQuery, GetAllAssignableLicensesExQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAssignableLicensesExQuery, GetAllAssignableLicensesExQueryVariables>(GetAllAssignableLicensesExDocument, options);
        }
export type GetAllAssignableLicensesExQueryHookResult = ReturnType<typeof useGetAllAssignableLicensesExQuery>;
export type GetAllAssignableLicensesExLazyQueryHookResult = ReturnType<typeof useGetAllAssignableLicensesExLazyQuery>;
export type GetAllAssignableLicensesExQueryResult = Apollo.QueryResult<GetAllAssignableLicensesExQuery, GetAllAssignableLicensesExQueryVariables>;
export const GetAccessibleRolesDocument = gql`
    query getAccessibleRoles($realmName: String!, $appName: String!, $state: StateOfRole!) {
  getAccessibleRoles(organizationID: $realmName, appName: $appName, state: $state) {
    role {
      roleId
      roleName
      roleDisplayKey
      roleDescriptionKey
      rolePermissions {
        resourceId
        resourceDisplayKey
        resourceDescriptionKey
        resourceScopes {
          scopeID
          scopeDisplayKey
          scopeDescriptionKey
          scopeAssigned
          scopeAssignRequired
        }
      }
      status
    }
    isCustomRole
    appRoleInfo {
      roleDisplayName
      roleDescription
      langKey
    }
    userAccessScopes
  }
}
    `;

/**
 * __useGetAccessibleRolesQuery__
 *
 * To run a query within a React component, call `useGetAccessibleRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleRolesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetAccessibleRolesQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleRolesQuery, GetAccessibleRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleRolesQuery, GetAccessibleRolesQueryVariables>(GetAccessibleRolesDocument, options);
      }
export function useGetAccessibleRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleRolesQuery, GetAccessibleRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleRolesQuery, GetAccessibleRolesQueryVariables>(GetAccessibleRolesDocument, options);
        }
export type GetAccessibleRolesQueryHookResult = ReturnType<typeof useGetAccessibleRolesQuery>;
export type GetAccessibleRolesLazyQueryHookResult = ReturnType<typeof useGetAccessibleRolesLazyQuery>;
export type GetAccessibleRolesQueryResult = Apollo.QueryResult<GetAccessibleRolesQuery, GetAccessibleRolesQueryVariables>;
export const GetAppRoleDocument = gql`
    query getAppRole($realmName: String!, $appName: String!, $roleID: String!) {
  getAppRole(organizationID: $realmName, appName: $appName, roleID: $roleID) {
    role {
      roleId
      roleName
      roleDisplayKey
      roleDescriptionKey
      rolePermissions {
        resourceId
        groupID
        indexID
        resourceDisplayKey
        resourceDescriptionKey
        resourceScopes {
          scopeID
          scopeDisplayKey
          scopeDescriptionKey
          scopeAssigned
        }
      }
      status
    }
    isCustomRole
    appRoleInfo {
      roleDisplayName
      roleDescription
      langKey
    }
    userAccessScopes
    userPermissions {
      editableBy {
        orgUserID
        email
      }
      assignableBy {
        orgUserID
        email
      }
    }
  }
}
    `;

/**
 * __useGetAppRoleQuery__
 *
 * To run a query within a React component, call `useGetAppRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppRoleQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useGetAppRoleQuery(baseOptions: Apollo.QueryHookOptions<GetAppRoleQuery, GetAppRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppRoleQuery, GetAppRoleQueryVariables>(GetAppRoleDocument, options);
      }
export function useGetAppRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppRoleQuery, GetAppRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppRoleQuery, GetAppRoleQueryVariables>(GetAppRoleDocument, options);
        }
export type GetAppRoleQueryHookResult = ReturnType<typeof useGetAppRoleQuery>;
export type GetAppRoleLazyQueryHookResult = ReturnType<typeof useGetAppRoleLazyQuery>;
export type GetAppRoleQueryResult = Apollo.QueryResult<GetAppRoleQuery, GetAppRoleQueryVariables>;
export const GetAppStatisticsDocument = gql`
    query getAppStatistics($organizationID: String!, $appName: String!) {
  getAppStatistics(organizationID: $organizationID, appName: $appName) {
    companyName
    noOfUsers
    noOfProjects
    noOfBuildings
  }
}
    `;

/**
 * __useGetAppStatisticsQuery__
 *
 * To run a query within a React component, call `useGetAppStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppStatisticsQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      appName: // value for 'appName'
 *   },
 * });
 */
export function useGetAppStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetAppStatisticsQuery, GetAppStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppStatisticsQuery, GetAppStatisticsQueryVariables>(GetAppStatisticsDocument, options);
      }
export function useGetAppStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppStatisticsQuery, GetAppStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppStatisticsQuery, GetAppStatisticsQueryVariables>(GetAppStatisticsDocument, options);
        }
export type GetAppStatisticsQueryHookResult = ReturnType<typeof useGetAppStatisticsQuery>;
export type GetAppStatisticsLazyQueryHookResult = ReturnType<typeof useGetAppStatisticsLazyQuery>;
export type GetAppStatisticsQueryResult = Apollo.QueryResult<GetAppStatisticsQuery, GetAppStatisticsQueryVariables>;
export const GetAssignableFileResourcesDocument = gql`
    query getAssignableFileResources($realmName: String!, $appName: String!, $superiorID: String!, $subordinateIDs: [String!], $fileFilter: [String!]) {
  getAssignableFileResources(
    organizationID: $realmName
    appName: $appName
    superiorID: $superiorID
    subordinateIDs: $subordinateIDs
    fileFilter: $fileFilter
  ) {
    fileID
    parentFileID
    filename
    filetypeID
    metaInfo {
      state
    }
    required
    superiorHasAccess
    selectable
  }
}
    `;

/**
 * __useGetAssignableFileResourcesQuery__
 *
 * To run a query within a React component, call `useGetAssignableFileResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignableFileResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignableFileResourcesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      superiorID: // value for 'superiorID'
 *      subordinateIDs: // value for 'subordinateIDs'
 *      fileFilter: // value for 'fileFilter'
 *   },
 * });
 */
export function useGetAssignableFileResourcesQuery(baseOptions: Apollo.QueryHookOptions<GetAssignableFileResourcesQuery, GetAssignableFileResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignableFileResourcesQuery, GetAssignableFileResourcesQueryVariables>(GetAssignableFileResourcesDocument, options);
      }
export function useGetAssignableFileResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignableFileResourcesQuery, GetAssignableFileResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignableFileResourcesQuery, GetAssignableFileResourcesQueryVariables>(GetAssignableFileResourcesDocument, options);
        }
export type GetAssignableFileResourcesQueryHookResult = ReturnType<typeof useGetAssignableFileResourcesQuery>;
export type GetAssignableFileResourcesLazyQueryHookResult = ReturnType<typeof useGetAssignableFileResourcesLazyQuery>;
export type GetAssignableFileResourcesQueryResult = Apollo.QueryResult<GetAssignableFileResourcesQuery, GetAssignableFileResourcesQueryVariables>;
export const GetAppUserRolesDocument = gql`
    query getAppUserRoles($realmName: String!, $appName: String!, $superiorID: String!, $subordinateIDs: [String!]!, $userLicenses: [UserLicenseGroupInput!]!) {
  getAppUserRoles(
    organizationID: $realmName
    appName: $appName
    superiorID: $superiorID
    subordinateIDs: $subordinateIDs
    userLicenses: $userLicenses
  ) {
    appUserRole {
      roleID
      roleName
      roleDisplayKey
      roleDescriptionKey
      status
      appRoleInfo {
        roleDisplayName
        roleDescription
        langKey
      }
    }
    isCustomRole
    required
    appUserAssignRequired
    appUserEditableRoles
    appUserAssignableRoles
  }
}
    `;

/**
 * __useGetAppUserRolesQuery__
 *
 * To run a query within a React component, call `useGetAppUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppUserRolesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      superiorID: // value for 'superiorID'
 *      subordinateIDs: // value for 'subordinateIDs'
 *      userLicenses: // value for 'userLicenses'
 *   },
 * });
 */
export function useGetAppUserRolesQuery(baseOptions: Apollo.QueryHookOptions<GetAppUserRolesQuery, GetAppUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppUserRolesQuery, GetAppUserRolesQueryVariables>(GetAppUserRolesDocument, options);
      }
export function useGetAppUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppUserRolesQuery, GetAppUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppUserRolesQuery, GetAppUserRolesQueryVariables>(GetAppUserRolesDocument, options);
        }
export type GetAppUserRolesQueryHookResult = ReturnType<typeof useGetAppUserRolesQuery>;
export type GetAppUserRolesLazyQueryHookResult = ReturnType<typeof useGetAppUserRolesLazyQuery>;
export type GetAppUserRolesQueryResult = Apollo.QueryResult<GetAppUserRolesQuery, GetAppUserRolesQueryVariables>;
export const GetAppAssignableMetaPermissionDocument = gql`
    query getAppAssignableMetaPermission($realmName: String!, $appName: String!, $editableBy: [String!]!, $assignableBy: [String!]!) {
  getAppAssignableMetaPermission(
    organizationID: $realmName
    appName: $appName
    editableBy: $editableBy
    assignableBy: $assignableBy
  ) {
    resourceId
    resourceForbidden
    resourceDisplayKey
    resourceDescriptionKey
    resourceScopes {
      scopeID
      scopeDisplayKey
      scopeDescriptionKey
      scopeAssigned
      scopeAssignRequired
      scopeDependencies {
        resourceID
        requiredScopes
      }
    }
    groupID
    indexID
  }
}
    `;

/**
 * __useGetAppAssignableMetaPermissionQuery__
 *
 * To run a query within a React component, call `useGetAppAssignableMetaPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppAssignableMetaPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppAssignableMetaPermissionQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      appName: // value for 'appName'
 *      editableBy: // value for 'editableBy'
 *      assignableBy: // value for 'assignableBy'
 *   },
 * });
 */
export function useGetAppAssignableMetaPermissionQuery(baseOptions: Apollo.QueryHookOptions<GetAppAssignableMetaPermissionQuery, GetAppAssignableMetaPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppAssignableMetaPermissionQuery, GetAppAssignableMetaPermissionQueryVariables>(GetAppAssignableMetaPermissionDocument, options);
      }
export function useGetAppAssignableMetaPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppAssignableMetaPermissionQuery, GetAppAssignableMetaPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppAssignableMetaPermissionQuery, GetAppAssignableMetaPermissionQueryVariables>(GetAppAssignableMetaPermissionDocument, options);
        }
export type GetAppAssignableMetaPermissionQueryHookResult = ReturnType<typeof useGetAppAssignableMetaPermissionQuery>;
export type GetAppAssignableMetaPermissionLazyQueryHookResult = ReturnType<typeof useGetAppAssignableMetaPermissionLazyQuery>;
export type GetAppAssignableMetaPermissionQueryResult = Apollo.QueryResult<GetAppAssignableMetaPermissionQuery, GetAppAssignableMetaPermissionQueryVariables>;