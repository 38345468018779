import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStepLabelStyles = makeStyles(() =>
  createStyles({
    labelContainer: {
      top: -65,
      position: 'relative' as const,
    },
  }),
)

export const standardStepper = {
  width: '100%',
  textAlign: 'center' as const,
  paddingBottom: '20px',
}

export const standardStepperInner = {
  width: '50%',
  textAlign: 'center' as const,
  margin: 'auto',
}

export const standardStep = {
  textTransform: 'uppercase' as const,
}

export const selectableStep = {
  ...standardStep,
  cursor: 'pointer' as const,
}
