import { MenuProps } from '../User.Styles'
import { Grid, TextField, MenuItem } from '@material-ui/core'
import { useManageUserContext } from '../UserContext/UserProvider'
import { useAssignableRolesList } from '../../../hooks/roleHooks'
import { AppState, AppUserAssignRole, AsAppRole, StateOfRole, UserLicenseGroupInput } from '../../../../api/iam.models'
import React, { useEffect, useMemo, useState } from 'react'
import { ActionType } from '../UserContext/userActions'
import { useTranslation } from 'react-i18next/'
import { getTranslatedValue, getUserName } from '../../../helpers/utils'
import { SelectedSingleUserLicense } from '../UserContext/initialState'

type RoleSelectionProps = {
  appName: string
  selectedLicenseInfo?: SelectedSingleUserLicense
}

const SelectAppRoles: React.FC<RoleSelectionProps> = ({ appName, selectedLicenseInfo }: RoleSelectionProps) => {
  const { state, dispatch } = useManageUserContext()
  const { t } = useTranslation('common')

  const [roleOptions, setRoleOptions] = useState<AppUserAssignRole[]>([])
  const [rolesAssignableOptions, setRoleAssignableOptions] = useState<AppUserAssignRole[]>([])
  const [rolesEditableOptions, setRoleEditableOptions] = useState<AppUserAssignRole[]>([])
  const currentAppRoleState = React.useMemo((): AppState => {
    return state.appStates.find((apS) => apS?.appID === appName) as AppState
  }, [appName, state.appStates, selectedLicenseInfo?.licenseTypeName])

  const _licenseTypes = React.useMemo(() => {
    return state.licenses.map(
      (lt) => ({ licenseGroupID: lt.licenseGroupID, licenseTypeID: lt?.licenseTypeID } as UserLicenseGroupInput),
    )
  }, [state.licenses])
  const { rolesAssignable } = useAssignableRolesList(
    appName,
    StateOfRole.Active,
    state.subordinates.map((u) => u.orgUserID) as string[],
    state.superior?.orgUserID as string,
    _licenseTypes,
  )

  const disableEditRole = useMemo((): boolean => {
    return !!state.userPermission?.some((p) => ['add-user-id', 'none-id'].includes(p.roleID))
  }, [state.userPermission])

  const disableAssignRoles = useMemo((): boolean => {
    return (
      selectedLicenseInfo?.licenseTypeName === 'guest' || !!state.userPermission?.some((p) => p.roleID === 'none-id')
    )
  }, [state.userPermission, selectedLicenseInfo])

  useEffect(() => {
    if (selectedLicenseInfo?.licenseTypeName === 'guest' && rolesAssignable?.length) {
      const guestAsAppRole = {
        roleID: rolesAssignable[0].appUserRole.roleID,
        roleName: rolesAssignable[0].appUserRole.roleName ?? '',
        displayNameKey: rolesAssignable[0].appUserRole.roleDisplayKey ?? '',
      }
      dispatch({
        type: ActionType.UpdateAppStateInput,
        payload: {
          ...currentAppRoleState,
          roles: [guestAsAppRole],
          assignableRoles: [],
          editableRoles: [],
        },
      })
    }
  }, [selectedLicenseInfo?.licenseTypeName, rolesAssignable])

  useEffect(() => {
    if (rolesAssignable?.length && !roleOptions.length) {
      setRoleOptions(rolesAssignable)
      if (currentAppRoleState.roles.length) {
        // const assignReq =
        //   rolesAssignable.find((rl) => rl.appUserRole.roleID === currentAppRoleState.roles[0].roleID)
        //     ?.appUserAssignRequired ?? []
        // const assignOptions = rolesAssignable.filter(
        //   (rl) =>
        //     assignReq.includes(rl.appUserRole.roleID) || rl.appUserRole.roleID === currentAppRoleState.roles[0].roleID,
        // )
        // console.log(assignOptions)
        //const assignReq = roleOptions.find((rl) => rl.appUserRole.roleID === currentAppRoleState.roles[0].roleID)?.appUserAssignRequired ?? []
        const rlAssignableArray =
          rolesAssignable.find((rl) => rl.appUserRole.roleID === currentAppRoleState.roles[0].roleID)
            ?.appUserAssignableRoles ?? []
        const assignOptions = rolesAssignable.filter((rl) => rlAssignableArray.includes(rl.appUserRole.roleID))
        setRoleAssignableOptions(assignOptions)
        const roleEditOptions = assignOptions.filter(
          (rl) => rl.isCustomRole && currentAppRoleState.roles[0].roleID !== rl.appUserRole.roleID,
        )
        setRoleEditableOptions(roleEditOptions)
      }
    }
  }, [rolesAssignable?.length])

  useEffect(() => {
    if (
      roleOptions.length &&
      !state.appStates.find((ap) => ap.appID === appName)?.roles.length &&
      state.userPermission.length
    ) {
      const initCurrentAppState = state.appStates.find((ap) => ap.appID === appName) as AppState
      const defaultInitAppRole = roleOptions[0]

      const initAppRole: AsAppRole = {
        roleID: defaultInitAppRole.appUserRole.roleID,
        roleName: defaultInitAppRole.appUserRole.roleName ?? '',
        displayNameKey: defaultInitAppRole.appUserRole.roleDisplayKey ?? '',
      }

      const initDefaultAppAssignOptions = roleOptions.filter((ro) =>
        defaultInitAppRole.appUserAssignRequired.includes(ro.appUserRole.roleID),
      )
      setRoleAssignableOptions([
        ...initDefaultAppAssignOptions,
        ...roleOptions.filter((rl) => rl.appUserRole.roleID === initAppRole.roleID),
      ])
      const initAppAssignRoles = state.userPermission.some((p) => p.roleID !== 'none-id')
        ? initDefaultAppAssignOptions.map((ar) => ({
            roleID: ar.appUserRole.roleID,
            roleName: ar.appUserRole.roleName,
            displayNameKey: ar.appUserRole.roleDisplayKey,
          }))
        : []
      dispatch({
        type: ActionType.UpdateAppStateInput,
        payload: {
          ...initCurrentAppState,
          roles: [initAppRole],
          assignableRoles: initAppAssignRoles,
        },
      })
    }
  }, [roleOptions.length, state])

  const changeAppRole = (value: string) => {
    const selectedAppState = state.appStates.find((apps) => apps?.appID === appName) as AppState
    const selectedAppRole = roleOptions.find((rl) => rl.appUserRole.roleID === value)

    const assignReq = roleOptions.find((rl) => rl.appUserRole.roleID === value)?.appUserAssignRequired ?? []
    const rlAssignableArray = roleOptions.find((rl) => rl.appUserRole.roleID === value)?.appUserAssignableRoles ?? []
    const assignOptions = roleOptions.filter((rl) => rlAssignableArray.includes(rl.appUserRole.roleID))
    const assignAppRoles =
      roleOptions
        .filter((rl) => assignReq.includes(rl.appUserRole.roleID))
        .map((rl) => ({
          roleID: rl.appUserRole.roleID,
          roleName: rl.appUserRole.roleName ?? '',
          displayNameKey: rl.appUserRole.roleDisplayKey,
        })) ?? []
    setRoleAssignableOptions(assignOptions)
    setRoleEditableOptions(assignOptions.filter((rl) => rl.appUserRole.roleID !== value && rl.isCustomRole))
    dispatch({
      type: ActionType.UpdateAppStateInput,
      payload: {
        ...selectedAppState,
        roles: [
          {
            roleID: value,
            roleName: selectedAppRole?.appUserRole.roleName ?? '',
            displayNameKey: selectedAppRole?.appUserRole.roleDisplayKey ?? '',
          },
        ],
        assignableRoles: !disableAssignRoles ? assignAppRoles : [],
        editableRoles: [],
      },
    })
  }

  const changeAppAssignableRoles = (roleIDs: string[]) => {
    const selectedAppState = state.appStates.find((apps) => apps?.appID === appName) as AppState
    const updatedAssignRoles =
      roleOptions
        .filter((rl) => roleIDs.includes(rl.appUserRole.roleID))
        .map(
          (rl) =>
            ({
              roleID: rl.appUserRole.roleID,
              roleName: rl.appUserRole.roleName ?? '',
              displayNameKey: !rl.isCustomRole ? rl.appUserRole.roleDisplayKey : '',
            } as AsAppRole),
        ) ?? []
    dispatch({
      type: ActionType.UpdateAppStateInput,
      payload: {
        ...selectedAppState,
        assignableRoles: updatedAssignRoles,
      },
    })
  }

  const changeAppEditableRoles = (name: string, values: string[]) => {
    const selectedAppState = state.appStates.find((apps) => apps?.appID === appName) as AppState
    const updatedAssignRoles =
      roleOptions
        .filter((rl) => values.includes(rl.appUserRole.roleID))
        .map(
          (rl) =>
            ({
              roleID: rl.appUserRole.roleID,
              roleName: rl.appUserRole.roleName ?? '',
              displayNameKey: !rl.isCustomRole ? rl.appUserRole.roleDisplayKey : '',
            } as AsAppRole),
        ) ?? []
    dispatch({
      type: ActionType.UpdateAppStateInput,
      payload: {
        ...selectedAppState,
        editableRoles: updatedAssignRoles,
      },
    })
    /*  const tempEditRoles = rolesEditableOptions.reduce(
      (roleAgg: AsAppRoleInput[], op) =>
        values.includes(op.roleName)
          ? [...roleAgg, { roleName: op.roleName, displayNameKey: op.displayNameKey }]
          : roleAgg,
      [],
    )
    //setCurrentAppEditRoles(values)
    dispatch({
      type: ActionType.UpdateAppStateInput,
      payload: { ...currentAppRoleState, [name]: tempEditRoles } as AppStateSimpleInput,
    }) */
  }

  /* 


  const rolesAssigned: string[] = React.useMemo(() => {
    return [
      ...currentAppRoleState.assignableRoles.map((ar) => ar?.roleName as string),
      ...currentAppRoleState.editableRoles.map((er) => er?.roleName as string),
    ]
  }, [currentAppRoleState.assignableRoles, currentAppRoleState.editableRoles]) */

  return (
    <>
      <Grid item container spacing={2} alignContent="stretch" direction="column">
        {/* <ProgressBar show={rolesAssignableLoading} /> */}
        <Grid item>
          <TextField
            id={`outlined-role`}
            select
            label={t('labels.role', { username: getUserName(state?.userInfo?.email ?? '') })}
            type="text"
            fullWidth
            name="roles"
            required
            //disabled={state.licenses.some((l) => l?.licenseType === 'guest')}
            onChange={({ target }) => changeAppRole(target.value)}
            variant="outlined"
            SelectProps={{
              value: currentAppRoleState.roles.find((rl) => rl.roleID)?.roleID ?? '',
              MenuProps: MenuProps,
            }}
          >
            {roleOptions.map((option) => (
              <MenuItem key={option.appUserRole.roleID} value={option.appUserRole.roleID}>
                {option.appUserRole.roleDisplayKey
                  ? getTranslatedValue(appName, option.appUserRole.roleDisplayKey, t)
                  : option.appUserRole.roleName}{' '}
                {option.required ? ' *' : ''}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id="role-assignable"
            label={t('labels.assignableRoles', { username: getUserName(state?.userInfo?.email ?? '') })}
            select
            type="text"
            fullWidth
            // required={rolesAssignableOptions.some((x) => x.assignRequired)}
            // error={
            //   !disableAssignRoles &&
            //   rolesAssignableOptions.some((x) => x.assignRequired && !rolesAssigned.includes(x.roleName))
            // }
            name="assignableRoles"
            disabled={disableAssignRoles}
            onChange={({ target }) => changeAppAssignableRoles(target.value as unknown as string[])}
            variant="outlined"
            SelectProps={{
              value: currentAppRoleState.assignableRoles.map((ar) => ar.roleID),
              multiple: true,
              MenuProps: MenuProps,
            }}
          >
            {rolesAssignableOptions.map((option) => (
              <MenuItem key={option.appUserRole.roleID} value={option.appUserRole.roleID}>
                {option.appUserRole.roleDisplayKey
                  ? getTranslatedValue(appName, option.appUserRole.roleDisplayKey, t)
                  : option.appUserRole.roleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id="role-editable"
            label={t('labels.editableRoles', { username: getUserName(state?.userInfo?.email ?? '') })}
            select
            // error={
            //   !disableEditRole &&
            //   rolesEditableOptions.some((x) =>
            //     currentAppRoleState.editableRoles.some((e) => x.editRequired && x.roleName !== e?.roleName),
            //   )
            // }
            disabled={disableEditRole}
            type="text"
            fullWidth
            name="editableRoles"
            onChange={({ target }) => changeAppEditableRoles(target.name, target.value as unknown as string[])}
            variant="outlined"
            SelectProps={{
              value: currentAppRoleState.editableRoles.map((er) => er.roleID as string),
              MenuProps: MenuProps,
              multiple: true,
            }}
          >
            {rolesEditableOptions.map((editOption) => (
              <MenuItem
                key={editOption.appUserRole.roleID}
                value={editOption.appUserRole.roleID}
                // disabled={
                //   currentAppRoleState.roles.some((rl) => rl.roleID === editOption.appUserRole.roleID) ||
                //   currentAppRoleState.assignableRoles.some((rl) => rl.roleID === editOption.appUserRole.roleID)
                // }
              >
                {editOption.appUserRole.roleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  )
}

export default SelectAppRoles
