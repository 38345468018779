import { Button, Chip, FormControl, Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { MenuProps } from '../UserManagement/User.Styles'
import { tableStyles } from '../UserManagement/UsersTables'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import SearchIcon from '@material-ui/icons/Search'
import { DataItemAction, DataTable, DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme'
type TableProps = {
  rowData: DataTableRow[]
  columnData: DataTableColumn[]
  edit?: boolean
  view?: boolean
  remove?: boolean
  actionCall?: (id: string, action: DataItemAction) => void
}
const ResourcesTable: React.FC<TableProps> = memo(
  ({ rowData, columnData, edit = false, view = false, remove = false, actionCall }: TableProps) => {
    const filterInitialValues = {
      company: '',
      app: '',
    }
    const [searchCompany, setSearchCompany] = useState<string>('')
    const [selectiveRows, setSelectiveRows] = useState<DataTableRow[]>([])
    const [filterValues, setFilterValues] = useState(filterInitialValues)
    const classes = tableStyles()
    const { t } = useTranslation('common')
    const columnLabels = columnData.map((column) => t(column.label))

    useEffect(() => {
      if (rowData.length) {
        setSelectiveRows(rowData)
      }
    }, [rowData])

    const uniqueOptions = (index: number) => {
      const someArray = rowData.map((row) => row.values[index])
      return Array.from(new Set(someArray))
    }

    const renderProps = (selected: unknown) => (
      <div className={classes.chips}>
        <Chip label={selected as string} className={classes.chip} />
      </div>
    )

    const resetFilter = () => {
      setFilterValues(filterInitialValues)
      setSelectiveRows(rowData)
    }
    const appOptions = uniqueOptions(1)
    //const companyOptions = uniqueOptions(0)

    const changeSelect = (selectString: string, index: number) => {
      const tempRowData = rowData.filter((rows) => rows.values[index] === selectString)
      const tempSelectiveRows = selectiveRows.filter((rows) => rows.values[index] === selectString)
      const filteredRows = tempSelectiveRows.length !== 0 ? tempSelectiveRows : tempRowData
      switch (index) {
        case 0:
          setFilterValues({ app: '', company: selectString })
          break
        case 1:
          setFilterValues({ app: selectString, company: '' })
          break
      }
      setSelectiveRows(filteredRows)
    }
    return (
      <div>
        <form>
          <br />
          <Grid item container justifyContent="flex-start">
            <Grid item xs={12}>
              <TextField
                //className={classes.searchField}
                id="outlined-search"
                label={columnLabels[0]}
                type="search"
                fullWidth
                variant="outlined"
                value={searchCompany}
                onChange={({ target }) => {
                  setSearchCompany(target.value as string)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <br />
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  label={columnLabels[1]}
                  select
                  value={filterValues.app}
                  variant="standard"
                  onChange={({ target }) => changeSelect(target.value as string, 1)}
                  SelectProps={{
                    renderValue: renderProps,
                    MenuProps: MenuProps,
                  }}
                >
                  {appOptions.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                onClick={() => resetFilter()}
                style={{ marginTop: '10px' }}
              >
                <AutorenewIcon /> Reset
              </Button>
            </Grid>
          </Grid>
        </form>
        <DataTable
          columns={columnData}
          rows={selectiveRows}
          allowedActions={{ sortable: true, viewable: view, deletable: remove, editable: edit }}
          filterString={searchCompany}
          filterColumn={0}
          onButtonClicked={actionCall && actionCall}
        />
      </div>
    )
  },
)
ResourcesTable.displayName = 'ResourcesTable'
export default ResourcesTable
