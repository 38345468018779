import SupervisoryAccountIcon from '@material-ui/icons/SupervisorAccount'
//import BusinessIcon from '@material-ui/icons/Business'
import MemoryIcon from '@material-ui/icons/Memory'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { SvgIcon } from '@material-ui/core'
//import AppsIcon from '@material-ui/icons/Apps'
// import InfoIcon from '@material-ui/icons/Info'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import OpacityOutlinedIcon from '@material-ui/icons/OpacityOutlined'
import { environment } from './environment'

type DrawerItems = {
  text: string
  link: string
  Icon: typeof SvgIcon
}[]

const drawerItems: DrawerItems = [
  {
    text: 'Home',
    link: '',
    Icon: HomeOutlinedIcon,
  },
  {
    text: 'manageusers',
    link: 'manageusers',
    Icon: SupervisoryAccountIcon,
  },
  /* {
    text: 'manageorganization',
    link: 'manageorganization',
    Icon: BusinessIcon,
  }, */
  {
    text: 'manageresources',
    link: 'manageresources',
    Icon: MemoryIcon,
  },
  {
    text: 'manageroles',
    link: 'manageroles',
    Icon: AssignmentIndIcon,
  },
  {
    text: 'cs-leakage',
    link: environment.LEAKAGE,
    Icon: OpacityOutlinedIcon,
  },
  /* {
    text: 'manageapps',
    link: 'manageapps',
    Icon: AppsIcon,
  },
  {
    text: 'appinfo',
    link: 'appinfo',
    Icon: InfoIcon,
  }, */
  {
    text: 'bin',
    link: 'bin',
    Icon: DeleteOutlineIcon,
  },
]
export default drawerItems
