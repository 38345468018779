import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next/'
import {
  ActivatedCloudLicense,
  ActivatedLicenseGroup,
  ActivatedLicensePacket,
  ActivatedLicensePacketInput,
  AssignedRoleInput,
  GetAccessibleUsersDocument,
  MinimalUserInfo,
  SingleUserLicenseInput,
  StateOfUser,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetAccessibleSuperiorsLazyQuery,
  useGetAccessibleSuperiorsQuery,
  useGetAccessibleUsersQuery,
  useGetAccessibleUsersRoleQuery,
  useGetAllAssignableLicensesExQuery,
  useGetUserQuery,
  UserResourcePermission,
  UserResourcePermissionEx,
  UserResourcePermissionExUser,
  UserState,
  UserStateInput,
  useUpdateUserMutation,
  useUpdateUserStateMutation,
} from '../../api/iam.models'
import { useAuthContext } from '../components/partials'
import { getOrganization } from '../helpers/manageTokens'
import { useGetLicenseList } from './organizationHooks'
import { getErrorMessage, useGetErrorMessage } from './TranslationHelperHook'
import { useOrganizations } from './useOranizations'

export type UsersInfoResponse = {
  accessibleUsers?: UserResourcePermission[]
  usersFetchingError?: string
  usersFetching?: boolean
  refetch: () => void
}

export const useLang = (): string => {
  const { i18n } = useTranslation('common')
  const lang = i18n.language
  return lang
}

export const useAccessibleUsers = (userState: StateOfUser, userName?: string): UsersInfoResponse => {
  const { username, t } = useAuthContext()

  const { data, loading, error, refetch } = useGetAccessibleUsersQuery({
    variables: { realmName: getOrganization(), state: userState, username: userName ?? username },
    skip: !getOrganization() || !username,
  })
  const errorMessage = error ? getErrorMessage(error, t) ?? error?.message : ''
  return {
    accessibleUsers: data?.getAccessibleUsers as UserResourcePermission[],
    usersFetchingError: errorMessage,
    usersFetching: loading,
    refetch: refetch,
  }
}

export const useDeletedUsers = (): UsersInfoResponse => {
  const { username, t } = useAuthContext()

  const { data, loading, error, refetch } = useGetAccessibleUsersQuery({
    variables: { realmName: getOrganization(), state: StateOfUser.Intrash, username: username },
    skip: !getOrganization() || !username,
  })
  const errorMessage = error ? getErrorMessage(error, t) ?? error?.message : ''
  return {
    accessibleUsers: data?.getAccessibleUsers as UserResourcePermission[],
    usersFetchingError: errorMessage,
    usersFetching: loading,
    refetch: refetch,
  }
}
type UserRes = {
  usersList?: string[]
  usersloading?: boolean
}
export const useAssignableUsers = (username: string): UserRes => {
  const { accessibleUsers, usersFetching } = useAccessibleUsers(StateOfUser.Active, username)
  const updatedUsers = React.useMemo(() => {
    return accessibleUsers?.reduce((users: string[], currentUser) => [...users, currentUser.userInfo.email], [])
  }, [accessibleUsers, usersFetching])
  return { usersList: updatedUsers, usersloading: usersFetching }
}
type RolesEditableBy = {
  rolesEditableByUsersList?: MinimalUserInfo[]
  editableByLoading?: boolean
}

export const useUsersRoleEditableBy = (): RolesEditableBy => {
  const { username } = useAuthContext()
  const { accessibleUsers, usersFetching } = useAccessibleUsers(StateOfUser.Active, username)
  const editableBy = React.useMemo(
    () =>
      accessibleUsers?.reduce(
        (users: MinimalUserInfo[], curRes) =>
          curRes?.userPermission.some((currApp) => currApp.roleID === 'add-user-and-role-id')
            ? ([...users, { orgUserID: curRes.userInfo.orgUserID, email: curRes.userInfo.email }] as MinimalUserInfo[])
            : users,
        [],
      ),
    [usersFetching, accessibleUsers],
  )
  //console.log('editableBy', editableBy)
  return {
    rolesEditableByUsersList: editableBy,
    editableByLoading: usersFetching,
  }
}
type RolesAssignableBy = {
  rolesAssignableByUsersList?: MinimalUserInfo[]
  assignableByLoading?: boolean
}
export const useUsersRoleAssignableBy = (appName: string, oldRoleName?: string): RolesAssignableBy => {
  const { username } = useAuthContext()
  const { data } = useGetAccessibleUsersRoleQuery({
    variables: {
      realmName: getOrganization(),
      appName: appName,
      roleName: oldRoleName,
    },
    skip: !oldRoleName || !getOrganization() || !appName,
  })

  const { accessibleUsers, usersFetching } = useAccessibleUsers(StateOfUser.Active, username)
  const assignableBy = React.useMemo(
    () =>
      accessibleUsers?.reduce(
        (users: MinimalUserInfo[], curRes) =>
          curRes?.userPermission.some((currApp) => currApp.roleID !== 'none-id')
            ? ([...users, { orgUserID: curRes.userInfo.orgUserID, email: curRes.userInfo.email }] as MinimalUserInfo[])
            : users,
        [],
      ),
    [accessibleUsers],
  )

  //console.log(`assignableVy`, assignableBy)

  return {
    rolesAssignableByUsersList: assignableBy,
    assignableByLoading: usersFetching,
  }
}
type SuperiorsList = {
  superiorsList?: string[]
  superiorsLoading?: boolean
}
export const useAssignableSuperiors = (): SuperiorsList => {
  const { username } = useAuthContext()
  const [fetched, setFetched] = useState<boolean>(false)
  const [superOptions, setSuperOptions] = useState<string[]>([])
  const { data, loading } = useGetAccessibleUsersQuery({
    variables: { realmName: getOrganization(), state: StateOfUser.Active, username: username },
    skip: !getOrganization() || !username || fetched,
  })

  React.useEffect(() => {
    if (!fetched && !loading && data) {
      const fetchedUsers = data?.getAccessibleUsers?.reduce(
        (users: string[], currentUser) =>
          currentUser?.appRoles.some((ap) => ap?.appName === 'iam' && ap?.roles.some((r) => r?.roleName !== 'none')) &&
          currentUser.userInfo.registered
            ? [...users, currentUser.userInfo.email]
            : users,
        [],
      )
      setSuperOptions(fetchedUsers as string[])
      setFetched(true)
    }
  }, [loading, data])
  return { superiorsList: superOptions, superiorsLoading: loading }
}

type SubordinatesList = {
  subordinatesList?: string[]
  subordinatesLoading?: boolean
}
export const useAssignableSubordintates = (username?: string): SubordinatesList => {
  const [subordinates, setSubordinates] = React.useState<string[]>()
  const [dataFetched, setDataFetched] = React.useState<boolean>(false)
  const { data, loading } = useGetAccessibleUsersQuery({
    variables: { realmName: getOrganization(), state: StateOfUser.Active, username: `${username}` },
    skip: !getOrganization() || !username || dataFetched,
  })

  React.useEffect(() => {
    if (dataFetched) {
      setDataFetched(!dataFetched)
    }
  }, [username])
  return { subordinatesList: subordinates, subordinatesLoading: loading }
}

export type UserInfoResponse = {
  userInfo?: UserState
  userInfoError?: string
  userInfoLoading?: boolean
}

export const useGetUserInfo = (userID: string): UserInfoResponse => {
  const { t } = useAuthContext()

  const { data, loading, error } = useGetUserQuery({
    variables: {
      realmName: getOrganization(),
      username: userID,
      fileTypes: ['company-id', 'group-id', 'root-id'],
    },
    skip: !getOrganization() || !userID,
  })

  const userInfoMemo = React.useMemo((): UserState | undefined => {
    if (data?.getUser) {
      return data.getUser
    }
  }, [data?.getUser])
  // const [fetched, setFetched] = useState(false)
  // const { data, loading, error } = useGetUserSimpleQuery({
  //   variables: { realmName: getOrganization(), username: userName },
  //   skip: !userName || !getOrganization() || fetched,
  // })
  // useEffect(() => {
  //   if (data?.getUserSimple) {
  //     setFetched(!fetched)
  //   }
  // }, [data?.getUserSimple])

  return {
    userInfoLoading: loading,
    userInfo: userInfoMemo,
    userInfoError: error?.message,
  }
}
export type UserMutationResponse = {
  createOrUpdateUserResp?: boolean | null
  userCreateUpdateError?: string
  updatingUser: boolean
  confirmUserCreateOrUpdate: (userInput: UserSimpleInput) => Promise<void>
}

export type UserSimpleInput = {
  userStateInput: UserStateInput
  inviteUser?: boolean
}
export const useCreateSimpleUser = (): UserMutationResponse => {
  const { username, t } = useAuthContext()
  const [simpleUserInputFn, { data, loading, error }] = useCreateUserMutation()

  const submitForm = async (userInput: UserSimpleInput) => {
    try {
      //console.log('-----submit user input----- \n', userInput)
      await simpleUserInputFn({
        variables: {
          realmName: getOrganization(),
          userState: userInput.userStateInput,
          sendEmail: userInput.inviteUser,
        },

        refetchQueries: [
          {
            query: GetAccessibleUsersDocument,
            variables: { realmName: getOrganization(), state: StateOfUser.Active, username: username },
          },
        ],
      })
    } catch (er) {
      console.error('error creating user', er)
    }
  }
  return {
    createOrUpdateUserResp: data?.createUser,
    userCreateUpdateError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    updatingUser: loading,
    confirmUserCreateOrUpdate: submitForm,
  }
}

export const useUpdateUserSimple = (userName: string): UserMutationResponse => {
  const [simpleUserInputFn, { data, loading, error }] = useUpdateUserMutation()
  const { username, t } = useAuthContext()

  const submitForm = async (userInput: UserSimpleInput) => {
    //console.log('-----submit user input----- \n', userInput)
    try {
      await simpleUserInputFn({
        variables: {
          realmName: getOrganization(),
          username: userName,
          userState: userInput.userStateInput,
          sendEmail: userInput.inviteUser,
        },
      })
    } catch (err) {
      console.error('error submitting simpleUser', err)
    }
  }
  return {
    createOrUpdateUserResp: data?.updateUser,
    userCreateUpdateError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    updatingUser: loading,
    confirmUserCreateOrUpdate: submitForm,
  }
}

export type UserMoveToFromTrashResponse = {
  confirmUserToFromTrash: (userName: string) => Promise<void>
  userMoved?: boolean | null
  userMovingError?: string
  userMovingToOrFrom?: boolean
}

export const useMoveUserToFromTrash = (userState: StateOfUser): UserMoveToFromTrashResponse => {
  const [userToFromTrashFn, { data, error, loading }] = useUpdateUserStateMutation()
  const { username, t } = useAuthContext()

  const submitForm = async (userName: string) => {
    try {
      //console.log('-----moving user-----', userName)
      await userToFromTrashFn({
        variables: {
          realmName: getOrganization(),
          username: userName,
          state: userState,
        },
        refetchQueries: [
          {
            query: GetAccessibleUsersDocument,
            variables: { realmName: getOrganization(), state: userState, username: username },
          },
        ],
      })
    } catch (err) {
      console.error('---error moving user-----', err)
    }
  }
  return {
    confirmUserToFromTrash: submitForm,
    userMoved: data?.updateUserState,
    userMovingError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    userMovingToOrFrom: loading,
  }
}

export type DeleteUserResponse = {
  confirmUserDeletion: (userName: string) => Promise<void>
  deletedUser?: boolean | null
  deleteUserError?: string
  deletingUser?: boolean
}

export const useDeleteUser = (): DeleteUserResponse => {
  const { username, t } = useAuthContext()
  const [deleteUserFn, { data, loading, error }] = useDeleteUserMutation()

  const submitForm = async (userName: string) => {
    try {
      //console.log('-----deleting user-----')
      await deleteUserFn({
        variables: {
          realmName: getOrganization(),
          username: userName,
        },
        refetchQueries: [
          {
            query: GetAccessibleUsersDocument,
            variables: { realmName: getOrganization(), state: StateOfUser.Intrash, username: username },
          },
        ],
      })
    } catch (err) {
      console.error('----error delete user----', err)
    }
  }
  return {
    confirmUserDeletion: submitForm,
    deletedUser: data?.deleteUser,
    deleteUserError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    deletingUser: loading,
  }
}

export const useCanCreateUser = (): boolean => {
  const accessibleOrgs = useOrganizations()
  const { daysRemaining } = useGetLicenseList()
  //console.log({ accessibleOrgs })
  const createUserRoles = ['adduser', 'adduserandrole']
  const ifCanCreateUser = React.useMemo(() => {
    const canCreateUser = false
    if (accessibleOrgs.length && getOrganization() && daysRemaining && daysRemaining > 0) {
      const userAppAccessRoles = accessibleOrgs.find((o) => o.realmName === getOrganization())?.appRoles
      return !!userAppAccessRoles
        ?.find((app) => app.appName === 'am')
        ?.roles.some((role) => createUserRoles.includes(role.roleName))
    }

    return canCreateUser
  }, [accessibleOrgs])
  return ifCanCreateUser
}

export const useCanCreateCompany = (appName: string): boolean => {
  // const { data, loading } = useGetMetaAndSpecialResourcesQuery({
  //   variables: { realmName: getOrganization(), appName: appName },
  //   skip: !getOrganization() || !appName,
  // })

  // const specialPermNames = ['srcompany', 'company-data']
  // const ifCanCreateCompany = React.useMemo(() => {
  //   return !!data?.getMetaAndSpecialResources?.some(
  //     (specialPerm) =>
  //       specialPermNames.includes(`${specialPerm?.name}`) && specialPerm?.accessibleScopes.includes('write'),
  //   )
  // }, [data?.getMetaAndSpecialResources, appName, loading])

  return true
}

export type SuperiorSubordinateOptions = {
  superiorOption: string
  subordinateOptions: string[]
}

type SuperSubOptionRes = {
  superSubOptions?: SuperiorSubordinateOptions[]
  superSubOptionsError?: string
  superSubOptionsLoading?: boolean
}

export const useGetSuperiorSubordinateOptions = (
  assigned_roles?: [AssignedRoleInput],
  licensePackets?: [ActivatedLicensePacketInput],
): SuperSubOptionRes => {
  const { username, t } = useAuthContext()
  const [fetched, setFetched] = useState<boolean>(false)
  const [crSuperSubor, setCrSuperSubor] = useState<SuperiorSubordinateOptions[]>([])
  // const { data, loading, error } = useGetAccessibleUsersExQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     username: username,
  //     state: StateOfUser.Active,
  //     assignableLicenses: licensePackets
  //       ? { organizationName: getOrganization(), licensePackets: licensePackets }
  //       : undefined,
  //     assignedRoles: assigned_roles,
  //   },
  //   skip: !getOrganization() || !username || fetched,
  // })

  // React.useEffect(() => {
  //   if (data?.getAccessibleUsersEx && !fetched && !loading) {
  //     const tempList = data.getAccessibleUsersEx?.reduce(
  //       (aggSupSub: SuperiorSubordinateOptions[], cUsrP) =>
  //         cUsrP?.enabled &&
  //         cUsrP.appRoles.some((ap) => ap?.appName === 'iam' && ap.roles.some((apr) => apr?.roleName !== 'none'))
  //           ? [
  //               ...aggSupSub,
  //               {
  //                 superiorOption: cUsrP.userInfo.email as string,
  //                 subordinateOptions: cUsrP.includesPermissionsOfUser as string[],
  //               },
  //             ]
  //           : aggSupSub,
  //       [],
  //     )
  //     setCrSuperSubor(tempList)
  //     setFetched(true)
  //   }
  // }, [data?.getAccessibleUsersEx])
  return {
    //superSubOptionsError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    superSubOptionsLoading: false,
    superSubOptions: crSuperSubor,
  }
}
type SuperiorsRes = {
  possibleSuperiors?: UserResourcePermission[]
  superiorsError?: string
}
export const useGetSuperiorOptions = (licenses: [SingleUserLicenseInput], editUserId?: string): SuperiorsRes => {
  const { t } = useAuthContext()
  const [fetched, setFetched] = useState<boolean>(false)
  const [fetchedSuperiors, setFetchedSuperiors] = useState<UserResourcePermission[]>([])
  const { data, error } = useGetAccessibleSuperiorsQuery({
    variables: {
      realmName: getOrganization(),
      state: StateOfUser.Active,
      licenses: licenses,
    },
    skip: !getOrganization() || fetched || !!editUserId,
  })
  // const superiorsEx = useGetAccessibleSuperiorsExUserQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     state: StateOfUser.Active,
  //     username: `${editUserId}`,
  //   },
  //   skip: !getOrganization() || fetched || !editUserId,
  // })
  //const errorMessage = getErrorMessage(error || superiorsEx.error, t) ?? (error?.message || superiorsEx.error?.message)
  useEffect(() => {
    if (data?.getAccessibleSuperiors.length && !editUserId) {
      setFetchedSuperiors(data.getAccessibleSuperiors as UserResourcePermission[])
      setFetched(true)
      console.log('---reloading---')
    }
    // if (superiorsEx.data?.getAccessibleSuperiorsExUser.length && editUserId) {
    //   //console.log('---reloading---', editUserId)

    //   setFetchedSuperiors(superiorsEx.data?.getAccessibleSuperiorsExUser as UserResourcePermissionExUser[])
    //   setFetched(true)
    // }
  }, [data?.getAccessibleSuperiors.length])

  return {
    possibleSuperiors: fetchedSuperiors,
    //superiorsError: errorMessage,
  }
}
type SubordinatesRes = {
  subOdinateUsers?: UserResourcePermissionEx[]
  subOrdinatesError?: string
}
export const useGetSubordinateOptions = (
  superior?: string,
  assigned_roles?: AssignedRoleInput[],
  licensePackets?: [ActivatedLicensePacketInput],
): SubordinatesRes => {
  const { t } = useAuthContext()
  const [fetched, setFetched] = useState<boolean>(false)
  const [users, setUsers] = useState<UserResourcePermissionEx[]>([])
  // const { data, error } = useGetAccessibleSubordinatesQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     superiorName: `${superior}`,
  //     state: StateOfUser.Active,
  //     assignableLicenses: licensePackets
  //       ? { organizationName: getOrganization(), licensePackets: licensePackets }
  //       : undefined,
  //     assignedRoles: assigned_roles,
  //   },
  //   skip: !getOrganization() || !superior || fetched,
  // })

  // useEffect(() => {
  //   if (data?.getAccessibleSubordinates.length /* && !fetched */) {
  //     setFetched(!fetched)
  //     setUsers(data?.getAccessibleSubordinates as UserResourcePermissionEx[])
  //   }
  // }, [data?.getAccessibleSubordinates, assigned_roles])

  return {
    subOdinateUsers: users,
    //subOrdinatesError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
  }
}

export type AssignRoleRes = {
  rolesAssignable?: []
  rolesAssignableError?: string
}
export const useGetAppAssignableRoles = (
  superior?: string,
  subordinates?: string[],
  licenseTypes?: string[],
): AssignRoleRes => {
  const { username, t } = useAuthContext()
  const [roles, setRoles] = useState<[]>([])
  //const _username = superior ?? username
  // const { data, error } = useGetAccessibleRolesExQuery({
  //   variables: {
  //     appName: 'iam',
  //     state: StateOfRole.Active,
  //     realmName: getOrganization(),
  //     username: superior ?? username,
  //     subordinates: subordinates ?? [],
  //     licenseTypes: licenseTypes,
  //   },
  //   skip: !getOrganization() || !superior,
  // })

  // useEffect(() => {
  //   if (data?.getAccessibleRolesEx?.length && superior) {
  //     setRoles(data.getAccessibleRolesEx.filter((rlx) => rlx?.scopes.includes('assign')) as OrganizationRoleEx[])
  //   }
  // }, [data?.getAccessibleRolesEx, superior, subordinates?.length])
  return {
    //rolesAssignableError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    rolesAssignable: roles,
  }
}

export type IAMAssignRoleRes = {
  rolesAssignable?: []
  rolesAssignableError?: string
}

export const useGetIamAssignableRoles = (superior: string, appName = 'iam'): IAMAssignRoleRes => {
  const { t } = useAuthContext()
  const [assignPerms, setAssignPerms] = useState<[]>([])
  // const { data, error } = useGetAccessibleRolesQuery({
  //   variables: {
  //     appName: appName,
  //     state: StateOfRole.Active,
  //     realmName: getOrganization(),
  //     username: superior,
  //   },
  //   skip: !getOrganization() || !superior,
  // })

  // useEffect(() => {
  //   if (data?.getAccessibleRoles?.length) {
  //     /* const temp = data?.getAccessibleRolesEx?.length
  //       ? (data?.getAccessibleRolesEx?.filter(
  //           (rlx) => rlx?.roleName && rlx?.scopes.includes('assign'),
  //         ) as OrganizationRoleEx[])
  //       : [] */

  //     setAssignPerms(data.getAccessibleRoles.filter((ax) => ax?.scopes.includes('assign')) as OrganizationRoleEx[])
  //   }
  // }, [data?.getAccessibleRoles?.length, superior])
  return {
    //rolesAssignableError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    rolesAssignable: assignPerms,
  }
}

export type LazyRolesRes = {
  fetchRoles: (superior: string) => void
  rolesAssignable?: [] /* OrganizationRoleEx[] */
  rolesAssignableError?: string
}

export const useGetIAMAppRoles = (): LazyRolesRes => {
  // const [getRolesEx, { data, error }] = useGetAccessibleRolesExLazyQuery()
  // const [iamAppRoles, setIamAppRoles] = useState<OrganizationRoleEx[]>([])
  // const fetchIAMRoles = (superior: string) => {
  //   getRolesEx({
  //     variables: {
  //       appName: 'iam',
  //       state: StateOfRole.Active,
  //       realmName: getOrganization(),
  //       username: superior,
  //       subordinates: [],
  //       licenseTypes: [],
  //     },
  //   })
  // }
  // useEffect(() => {
  //   if (data?.getAccessibleRolesEx?.length) {
  //     setIamAppRoles(data?.getAccessibleRolesEx as OrganizationRoleEx[])
  //   }
  // }, [data?.getAccessibleRolesEx?.length])

  // if (error) {
  //   console.error('er', error.message)
  // }
  return {
    fetchRoles: (su: string) => {
      return
    },
    rolesAssignable: [],
  }
}

export type Subordinates = {
  fetchSubordinates: (
    superior: string,
    assigned_roles: AssignedRoleInput[],
    licensePackets?: ActivatedLicensePacketInput[],
  ) => void
  subOdinateUsers?: UserResourcePermissionEx[]
  subOrdinatesError?: string
}

export const useFetchSubordinates = (): Subordinates => {
  const [subUsers, setSubUsers] = useState<UserResourcePermissionEx[]>([])
  // const [getUsers, { data, error }] = useGetAccessibleSubordinatesLazyQuery()
  // const getSubUser = (
  //   superior: string,
  //   assigned_roles: AssignedRoleInput[],
  //   licensePackets?: ActivatedLicensePacketInput[],
  // ) => {
  //   getUsers({
  //     variables: {
  //       realmName: getOrganization(),
  //       superiorName: superior,
  //       state: StateOfUser.Active,
  //       assignableLicenses: licensePackets
  //         ? { organizationName: getOrganization(), licensePackets: licensePackets }
  //         : undefined,
  //       assignedRoles: assigned_roles,
  //     },
  //   })
  // }
  // useEffect(() => {
  //   if (data?.getAccessibleSubordinates.length) {
  //     setSubUsers(data.getAccessibleSubordinates as UserResourcePermissionEx[])
  //   }
  // }, [data?.getAccessibleSubordinates.length])

  // if (error) {
  //   console.error('err', error.message)
  // }

  return {
    fetchSubordinates: (su: string, assigrol: AssignedRoleInput[]) => {
      return
    },
    subOdinateUsers: subUsers,
    //subOrdinatesError: error?.message,
  }
}
type SuperOps = {
  fetchSuperiors: () => void
  possibleSuperiors?: UserResourcePermissionExUser[] | UserResourcePermission[]
  superiorsError?: string
}

export const useFetchSuperiorOptions = (licenses: SingleUserLicenseInput[], editUserId?: string): SuperOps => {
  const [fetchedSuperiors, setFetchedSuperiors] = useState<UserResourcePermissionExUser[]>([])
  const [fetchLazySuper, { data, error }] = useGetAccessibleSuperiorsLazyQuery()
  // const [fetchLazySupersEx, { data: superiorsEx, error: superExr }] = useGetAccessibleSuperiorsExUserLazyQuery()
  // const errorMessage = useGetErrorMessage(error || superExr) ?? (error?.message || superExr?.message)
  // useEffect(() => {
  //   if (data?.getAccessibleSuperiors.length && !editUserId) {
  //     setFetchedSuperiors(data.getAccessibleSuperiors as UserResourcePermissionExUser[])
  //     //setFetched(true)
  //   }
  //   if (superiorsEx?.getAccessibleSuperiorsExUser.length && editUserId) {
  //     setFetchedSuperiors(superiorsEx?.getAccessibleSuperiorsExUser as UserResourcePermissionExUser[])
  //     //setFetched(true)
  //   }
  // }, [data?.getAccessibleSuperiors.length, superiorsEx?.getAccessibleSuperiorsExUser.length])
  // //console.log('---reloading---')

  // const getSuperiors = () => {
  //   if (editUserId) {
  //     //console.log('---reloading---', editUserId)
  //     fetchLazySupersEx({
  //       variables: {
  //         realmName: getOrganization(),
  //         state: StateOfUser.Active,
  //         username: editUserId,
  //       },
  //     })
  //   } else {
  //     //console.log('---reloading---')
  //     fetchLazySuper({
  //       variables: {
  //         realmName: getOrganization(),
  //         state: StateOfUser.Active,
  //         licenses: licenses,
  //       },
  //     })
  //   }
  // }

  return {
    fetchSuperiors: () => {
      return
    },
    possibleSuperiors: fetchedSuperiors,
    //superiorsError: errorMessage,
  }
}

export type AccessPermissionsInput = {
  licenses: SingleUserLicenseInput[]
  //assignedIAMRoleInput: AssignedRoleInput[]
  superiorName?: string
  editUserId?: string
}

export type AccessPermissionsOutput = {
  superiorUserOptions: UserResourcePermission[]
  subOrdinateOptions: UserResourcePermissionEx[]
  //iamAppRoleOptions: OrganizationRoleEx[]
  iamAppRoleOptions: []
  accessPermissionsLoading?: boolean
}

export const useAccessPermissions = ({
  licenses,
  superiorName = '',
  editUserId,
}: AccessPermissionsInput): AccessPermissionsOutput => {
  const [fetched, setFetched] = useState<boolean>(false)
  const [fetchedSuperiors, setFetchedSuperiors] = useState<UserResourcePermission[]>([])
  const { data } = useGetAccessibleSuperiorsQuery({
    variables: {
      realmName: getOrganization(),
      state: StateOfUser.Active,
      licenses: licenses,
    },
    skip:
      !getOrganization() ||
      !licenses.length ||
      licenses.some((l) => !l.licenseGroupID || !l.licenseTypeID || !l.licensePacketID),
  })
  // const superiorsEx = useGetAccessibleSuperiorsExUserQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     state: StateOfUser.Active,
  //     username: `${editUserId}`,
  //   },
  //   skip: !getOrganization() || !editUserId,
  // })

  // const iamAppRoles = useGetAccessibleRolesExQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     appName: 'iam',
  //     username: superiorName,
  //     state: StateOfRole.Active,
  //     subordinates: [],
  //     licenseTypes: [],
  //   },
  //   skip: !getOrganization() || !superiorName,
  // })
  //const [getSubordinates, subordinatesData] = useGetAccessibleSubordinatesLazyQuery()
  useEffect(() => {
    if (data?.getAccessibleSuperiors.length) {
      setFetchedSuperiors(data.getAccessibleSuperiors as UserResourcePermission[])
    }
    // if (superiorsEx.data?.getAccessibleSuperiorsExUser.length && editUserId) {
    //   setFetchedSuperiors(superiorsEx.data?.getAccessibleSuperiorsExUser as UserResourcePermissionExUser[])
    // }
  }, [data?.getAccessibleSuperiors.length])

  return {
    superiorUserOptions: fetchedSuperiors,
    iamAppRoleOptions: [],
    subOrdinateOptions: [],
    //accessPermissionsLoading: iamAppRoles.loading,
  }
}

type ActivatedLicenseResponse = {
  fetchingActivatedLicenses?: boolean
  activatedCloudLicensesError?: string
  activatedLicenses?: ActivatedCloudLicense
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAssignLicensePackets = (superior: string, subordinates: string[]): ActivatedLicenseResponse => {
  const { t } = useAuthContext()
  const { data, loading, error } = useGetAllAssignableLicensesExQuery({
    variables: {
      realmName: getOrganization(),
      superior: superior,
      subordinates: subordinates,
    },
    skip: !getOrganization() || !superior,
  })
  const activatedOrgLicenses = useMemo(() => {
    if (data?.getAllAssignableLicensesEx?.licensePackets?.length) {
      const activatedLicenses = data.getAllAssignableLicensesEx
      const sortedLicPackets = {
        ...activatedLicenses,
        licensePackets: activatedLicenses?.licensePackets
          ?.map(
            (p) =>
              ({
                ...p,
                licenseGroups: p?.licenseGroups
                  ? p.licenseGroups
                      .map(
                        (g) =>
                          ({
                            ...g,
                            licenseTypes: g?.licenseTypes
                              ? g.licenseTypes.sort((a, b) =>
                                  (a?.licenseType ?? '') > (b?.licenseType ?? '') ? -1 : 1,
                                )
                              : undefined,
                          } as ActivatedLicenseGroup),
                      )
                      .sort((a, b) => ((a?.groupname ?? '') > (b.groupname ?? '') ? -1 : 1))
                  : undefined,
              } as ActivatedLicensePacket),
          )
          .sort((a, b) => ((a.packetName ?? '') > (b?.packetName ?? '') ? -1 : 1)),
      } as ActivatedCloudLicense
      return sortedLicPackets
    }
  }, [data?.getAllAssignableLicensesEx])
  return {
    fetchingActivatedLicenses: loading,
    activatedCloudLicensesError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    activatedLicenses: activatedOrgLicenses,
  }
}
