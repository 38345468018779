import React, { createContext, useContext, useReducer } from 'react'
import { RoleInput } from '../../../../api/iam.models'
import { RoleStateInputActions } from './roleActions'
import { initRoleSimpleInput } from './roleInputInitState'
import { roleInputReducer } from './roleReducer'

interface RoleManagementContextProps {
  children: React.ReactNode
  editRoleId?: string
  editRoleState?: RoleInput
}

interface RoleManagementContextType {
  state: RoleInput
  editRoleId?: string
  editRoleState?: RoleInput
  dispatch: React.Dispatch<RoleStateInputActions>
}

export const RoleManagementProvider = createContext<RoleManagementContextType>({
  state: initRoleSimpleInput,
  editRoleState: undefined,
  dispatch: () => undefined,
})

export const useRoleManagementContext = (): RoleManagementContextType => useContext(RoleManagementProvider)
const RoleProvider: React.FC<RoleManagementContextProps> = ({
  children,
  editRoleState,
  editRoleId,
}: RoleManagementContextProps) => {
  const roleReducerState = editRoleState ? editRoleState : initRoleSimpleInput
  const [roleInpState, dispatch] = useReducer(roleInputReducer, roleReducerState)
  return (
    <RoleManagementProvider.Provider
      value={{ state: roleInpState, dispatch: dispatch, editRoleId: editRoleId, editRoleState: editRoleState }}
    >
      {children}
    </RoleManagementProvider.Provider>
  )
}

export default RoleProvider
