import { FileSystemType } from '../hooks/helperhooks/transformToFileHierarchy'

export const collectSubGroupIds = (groups: FileSystemType[], initialIDs: string[], parentID?: string) => {
  groups.forEach((gp) => {
    if (gp.parentFileID === parentID) {
      const flatGrps = flattenSubGroups([gp])
      flatGrps.forEach((gp) => initialIDs.push(gp.fileID))
    } else {
      gp.subFiles && collectSubGroupIds(gp.subFiles, initialIDs, parentID)
    }
  })
  return initialIDs
}

export const flattenSubGroups = (displayGroup: FileSystemType[]): FileSystemType[] => {
  let flatGroups: FileSystemType[] = []

  flatGroups = displayGroup.flat()
  displayGroup.forEach((fp) => {
    if (fp.subFiles?.length) {
      const subRes = flattenSubGroups(fp.subFiles)
      const filterSb = subRes.filter((sg) => sg.fileID && sg.fileID)
      flatGroups.push(...filterSb)
    }
  })
  return flatGroups
}

export const getCompanyFilePath = (groups: FileSystemType[], initialPath: string[], parentID?: string) => {
  groups.forEach((gp) => {
    if (gp.fileID === parentID) {
      initialPath.push(gp.fileName)
      gp.parentFileID && getCompanyFilePath(groups, initialPath, gp.parentFileID)
    }
  })
  return initialPath
}
