import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useOrganizations } from './useOranizations'
import {
  CompanyStats,
  //DirectoryStatistics,
  LicenseGroup,
  LicenseUser,
  useGetAllAssignableLicensesExQuery,
  useGetAppStatisticsQuery,
  //useGetDirectoryStatisticsQuery,
  useGetOrganizationLicenseQuery,
} from '../../api/iam.models'
import { CreateOrUpdateCompanyMutation, UpdateCompany, useCreateOrUpdateCompanyMutation } from '../../api/ld.models'

import { getOrganization } from '../helpers/manageTokens'
import { ROUTES } from '../helpers/routes'
import { convertToLocaleDate, getRemainingDays } from '../helpers/utils'
import { useGetErrorMessage } from './TranslationHelperHook'

export type PacketGroupTypes = {
  licenseType: string
  licenseTypeKey: string
  typeId: string
  totalSize: number
  available: number
  regularLicense?: LicenseUser
}
export type PacketGroup = {
  groupName: string
  displayName: string
  groupId: string
  licenseTypes: PacketGroupTypes[]
}
export type AssignablePacketLicenses = {
  packetId: string
  packetName: string
  expiryDate?: string
  packetGroups: PacketGroup[]
}

type LicenceRes = {
  appsList?: LicenseGroup[]
  err?: string
}

export const useAssignableApps = (): LicenceRes => {
  const selectedOrganization = getOrganization()

  const { data, error, loading } = useGetOrganizationLicenseQuery({
    variables: { realmName: selectedOrganization },
    skip: !selectedOrganization,
  })
  const errorMessage = useGetErrorMessage(error) ?? error?.message

  const apps = React.useMemo(() => {
    return data?.getOrganizationLicense?.licenseGroups
  }, [data, loading])

  return {
    appsList: apps as LicenseGroup[],
    err: errorMessage,
  }
}

type LicenseListResponse = {
  licenseTypes?: string[]
  loadingLicenses?: boolean
  licenseTypeserrorMessage?: string
}

export const useLicenseList = (appName: string): LicenseListResponse => {
  const { data, error, loading } = useGetOrganizationLicenseQuery({
    variables: { realmName: getOrganization() },
    skip: !getOrganization(),
  })

  const errorMessage = useGetErrorMessage(error) ?? error?.message
  const licenseTypes = data?.getOrganizationLicense?.licenseGroups.reduce(
    (lt: string[], lg) =>
      lg?.groupname === appName ? lg.licenseTypes.map((lts) => (lts?.licenseType ? lts.licenseType : '')) : lt,
    [],
  )

  return {
    licenseTypes: licenseTypes,
    licenseTypeserrorMessage: errorMessage,
    loadingLicenses: loading,
  }
}
type LicensesListResp = {
  licenseGroups?: LicenseGroup[] | null
  expiresOn?: string
  daysRemaining?: number
  error?: string
  licensesLoading?: boolean
}

export const useGetLicenseList = (): LicensesListResp => {
  const { t } = useTranslation('common')
  const accessibleOrgs = useOrganizations()
  const { data, error, loading } = useGetOrganizationLicenseQuery({
    variables: { realmName: getOrganization() },
    skip: !getOrganization(),
  })
  const history = useHistory()
  const errorMessage = useGetErrorMessage(error) ?? error?.message
  const retrievedLicenses = useMemo(() => {
    return data?.getOrganizationLicense?.licenseGroups.length
      ? (data?.getOrganizationLicense?.licenseGroups as LicenseGroup[])
      : []
  }, [data?.getOrganizationLicense])
  useEffect(() => {
    //console.log({ accessibleOrgs })
    if (accessibleOrgs.every((o) => o.licenseExpired)) {
      history.push(ROUTES.licenseExpired)
    }
  }, [data?.getOrganizationLicense])

  //console.log({ retrievedLicenses })

  return {
    licenseGroups: retrievedLicenses,
    expiresOn: convertToLocaleDate(data?.getOrganizationLicense?.expirationDate ?? '', t),
    daysRemaining: getRemainingDays(data?.getOrganizationLicense?.expirationDate),
    error: errorMessage,
    licensesLoading: loading,
  }
}

type CompanyStatsResponse = {
  companyStatsList?: CompanyStats[]
  companyStatsError?: string
  companyStatsLoading?: boolean
}
export const useCompanyStats = (appName: string): CompanyStatsResponse => {
  const { data, loading } = useGetAppStatisticsQuery({
    variables: {
      organizationID: getOrganization(),
      appName: appName,
    },
    skip: !appName || !getOrganization(),
  })

  const companyStats = React.useMemo(() => {
    if (data?.getAppStatistics?.length) {
      return data.getAppStatistics.slice().sort((a, b) => (a.companyName > b.companyName ? 1 : -1))
    }
  }, [data?.getAppStatistics])

  return {
    companyStatsList: companyStats,
    // companyStatsError: error ? getErrorMessage(error, t) ?? error?.message : undefined,
    companyStatsLoading: loading,
  }
}

type CreateCompanyResponse = {
  newCompany?: CreateOrUpdateCompanyMutation | null
  createCompanyerror?: string
  confirmCreateCompany: () => Promise<void>
  creatingCompany?: boolean
}

export const useCreateUpdateCompany = (company: UpdateCompany): CreateCompanyResponse => {
  //const { username } = useAuthContext()
  //console.log('default', defaultSuperUser)
  const [createCompany, { data, loading, error }] = useCreateOrUpdateCompanyMutation()

  const submitCompanyToCreate = async () => {
    try {
      await createCompany({
        variables: {
          realmName: getOrganization(),
          company: company,
        },
      })
      //console.log(defaultSuperUser)
    } catch (e) {
      console.error(`error Creating company`, e)
    }
  }
  return {
    newCompany: data,
    createCompanyerror: error?.message,
    confirmCreateCompany: submitCompanyToCreate,
    creatingCompany: loading,
  }
}

export const useGetAssignablePackets = (): { assignableLicensePacket?: AssignablePacketLicenses[] } => {
  //const { userId } = useAuthContext()
  const { data } = useGetAllAssignableLicensesExQuery({
    variables: {
      realmName: getOrganization(),
      //subordinates: [],
    },
    skip: !getOrganization(),
  })
  const licPacketGroups = useMemo(() => {
    if (data?.getAllAssignableLicensesEx?.licensePackets?.length) {
      const { licensePackets } = data.getAllAssignableLicensesEx
      return licensePackets
        .map((p) => ({
          packetId: p?.id ?? '',
          packetName: p?.packetName ?? '',
          expiryDate: p?.expirationDate,
          packetGroups:
            p?.licenseGroups?.map((pg) => ({
              groupName: pg?.groupname ?? '',
              displayName: pg?.displayNameKey ?? '',
              groupId: pg?.id ?? '',
              licenseTypes: pg?.licenseTypes
                ?.map((lt) => ({
                  licenseType: lt?.licenseType ?? '',
                  licenseTypeKey: lt?.displayNameKey ?? '',
                  typeId: lt?.id ?? '',
                  totalSize: lt?.licenses?.length ?? 0,
                  available: lt?.licenses?.length ? lt.licenses.filter((active) => active?.userId === null).length : 0,
                }))
                .sort((a, b) => (a.licenseType > b.licenseType ? -1 : 1)),
            })) ?? [],
        }))
        .sort((a, b) => (a.packetName > b.packetName ? -1 : 1)) as AssignablePacketLicenses[]
    }
  }, [data?.getAllAssignableLicensesEx?.licensePackets])
  //console.log({ licPacketGroups })
  return {
    assignableLicensePacket: licPacketGroups,
  }
}
