import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};


export type AbsolutePath = {
  __typename?: 'AbsolutePath';
  pathID: Scalars['String'];
  pathName: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  attributeID: Scalars['String'];
  attributeType?: Maybe<AttributeType>;
};

export type AttributeInput = {
  attributeID: Scalars['String'];
  attributeType: AttributeType;
};

export enum AttributeType {
  CompanyAttribute = 'companyAttribute',
  ProjectAttribute = 'projectAttribute',
  BuildingAttribute = 'buildingAttribute',
  GroupAttribute = 'groupAttribute'
}

export type Auditor = {
  __typename?: 'Auditor';
  id: Scalars['ID'];
  display_name: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  _deleted_at?: Maybe<Scalars['String']>;
  isAssigned?: Maybe<Scalars['Boolean']>;
};

export type AuditorInput = {
  id?: Maybe<Scalars['ID']>;
  display_name: Scalars['String'];
  company: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type Building = {
  __typename?: 'Building';
  id: Scalars['ID'];
  name: Scalars['String'];
  _deleted_at?: Maybe<Scalars['String']>;
  places?: Maybe<Array<Place>>;
  assigned?: Maybe<Scalars['Boolean']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
  usedLeakages?: Maybe<Scalars['Int']>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  company: Scalars['String'];
  logo?: Maybe<Scalars['ID']>;
  buildings?: Maybe<Array<Building>>;
  projects?: Maybe<ProjectPage>;
  address?: Maybe<Address>;
  contact?: Maybe<Contact>;
  nProjects: Scalars['Int'];
  nBuildings: Scalars['Int'];
  _deleted_at?: Maybe<Scalars['String']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['String']>;
};

export type CompanyMeta = {
  __typename?: 'CompanyMeta';
  totalElements?: Maybe<Scalars['Int']>;
  rootAccess: Scalars['Boolean'];
  allowedCompanies: Array<Scalars['String']>;
  typePermissionEdit: Scalars['Boolean'];
};

export type CompanyPage = {
  __typename?: 'CompanyPage';
  _meta?: Maybe<CompanyMeta>;
  data?: Maybe<Array<Maybe<Company>>>;
};

export type CompanyType = {
  __typename?: 'CompanyType';
  id: Scalars['ID'];
  parentGroupId?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
  company: Scalars['String'];
  nProjects: Scalars['Int'];
  nBuildings: Scalars['Int'];
  canEdit?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['ID']>;
  _deleted_at?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};


export type ExecutionResultResource = {
  __typename?: 'ExecutionResultResource';
  success?: Maybe<Scalars['Boolean']>;
};

export type Failure = {
  __typename?: 'Failure';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type GroupContent = {
  __typename?: 'GroupContent';
  groups: Array<Maybe<GroupType>>;
  companies: Array<Maybe<CompanyType>>;
  groupInfo?: Maybe<GroupType>;
};

export type GroupInput = {
  groupName: Scalars['String'];
  parentGroupId?: Maybe<Scalars['String']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['String'];
  parentGroupId?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
  groupName: Scalars['String'];
  canEdit?: Maybe<Scalars['Boolean']>;
  canCreateCompany?: Maybe<Scalars['Boolean']>;
  _deleted_at?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  originalImage: Scalars['Boolean'];
  name: Scalars['String'];
  contents: Scalars['String'];
};

export type ImportBuilding = {
  name: Scalars['String'];
};

export type ImportCompany = {
  company: Scalars['String'];
  address?: Maybe<UpdateAddress>;
  contact?: Maybe<UpdateContact>;
  auditor?: Maybe<AuditorInput>;
  buildings?: Maybe<Array<ImportBuilding>>;
  projects?: Maybe<Array<ImportProject>>;
  image?: Maybe<Scalars['String']>;
};

export type ImportImage = {
  name: Scalars['String'];
  contents: Scalars['String'];
};

export type ImportLeakage = {
  building_name: Scalars['String'];
  commF?: Maybe<Scalars['String']>;
  commM?: Maybe<Scalars['String']>;
  commR?: Maybe<Scalars['String']>;
  commRup?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  costBase?: Maybe<Scalars['String']>;
  costRelevantAttributesEdited?: Maybe<Scalars['Boolean']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  distance?: Maybe<Scalars['Float']>;
  gain?: Maybe<Scalars['Float']>;
  gasType?: Maybe<Scalars['String']>;
  imgName?: Maybe<Scalars['String']>;
  imgPath?: Maybe<Scalars['String']>;
  leakTag?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  pressure?: Maybe<Scalars['Float']>;
  resCost?: Maybe<Scalars['Float']>;
  resFlag?: Maybe<Scalars['Float']>;
  resLeakage?: Maybe<Scalars['Float']>;
  resUnitL?: Maybe<Unit>;
  sens2x?: Maybe<Scalars['Int']>;
  sensivity?: Maybe<Scalars['Int']>;
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  unitD?: Maybe<Scalars['String']>;
  unitP?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  unitC?: Maybe<Unit>;
  version?: Maybe<Scalars['String']>;
  lossCosts?: Maybe<Scalars['Float']>;
  ElPr?: Maybe<Scalars['Float']>;
  SpPw?: Maybe<Scalars['Float']>;
  USpPw?: Maybe<Scalars['Float']>;
  SpPwId?: Maybe<Scalars['String']>;
  CMod?: Maybe<Scalars['String']>;
  SigE3?: Maybe<Scalars['String']>;
};

export type ImportProject = {
  costBase?: Maybe<Scalars['Float']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  energyAmount?: Maybe<Scalars['Float']>;
  energyUnit?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  leakages?: Maybe<Array<ImportLeakage>>;
  co2Amount?: Maybe<Scalars['Float']>;
  co2Unit?: Maybe<Unit>;
  energyCost?: Maybe<Scalars['Float']>;
  elPr?: Maybe<Scalars['Float']>;
  spPw?: Maybe<Scalars['Float']>;
  uSpPw?: Maybe<Scalars['Float']>;
  spPwId?: Maybe<Scalars['String']>;
  cMod?: Maybe<Scalars['String']>;
  sigE3?: Maybe<Scalars['String']>;
};

export type LdCoreServiceVersionInformation = {
  __typename?: 'LDCoreServiceVersionInformation';
  /** version of user ld core service */
  version: Scalars['String'];
  /** version in git + commit (git describe) */
  versionGit: Scalars['String'];
  /** timestamp of the build */
  buildTimestamp: Scalars['String'];
};

export type LdHardwareVersion = {
  __typename?: 'LDHardwareVersion';
  id: Scalars['String'];
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  projectName?: Maybe<Scalars['String']>;
};

export type Leakage = {
  __typename?: 'Leakage';
  id: Scalars['ID'];
  commF?: Maybe<Scalars['String']>;
  commM?: Maybe<Scalars['String']>;
  commR?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  costBase?: Maybe<Scalars['String']>;
  costRelevantAttributesEdited?: Maybe<Scalars['Boolean']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  distance?: Maybe<Scalars['Float']>;
  gain?: Maybe<Scalars['Float']>;
  gasType?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Image>>;
  leakTag?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  pressure?: Maybe<Scalars['Float']>;
  resCost?: Maybe<Scalars['Float']>;
  resFlag?: Maybe<Scalars['Float']>;
  resLeakage?: Maybe<Scalars['Float']>;
  resUnitL?: Maybe<Unit>;
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  unitD?: Maybe<Scalars['String']>;
  unitP?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  unitC?: Maybe<Unit>;
  version?: Maybe<Scalars['String']>;
  building?: Maybe<Building>;
  projectId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  priority?: Maybe<Priority>;
  resolvableUnderPressure?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolvedAt?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<Scalars['String']>;
  _deleted_at?: Maybe<Scalars['String']>;
  lossCosts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
};

export type LeakageDescriptionFields = {
  __typename?: 'LeakageDescriptionFields';
  measures: Array<Measure>;
  failures: Array<Failure>;
  replacements: Array<Replacement>;
};

export type LeakageResult = {
  __typename?: 'LeakageResult';
  leakages: Array<Leakage>;
  pageInfo?: Maybe<PageInfo>;
};

export type Link = {
  __typename?: 'Link';
  url: Scalars['String'];
  mimeType: Scalars['String'];
  filename: Scalars['String'];
  description: Scalars['String'];
  extension: Scalars['String'];
};

export type Measure = {
  __typename?: 'Measure';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type Meta = {
  __typename?: 'Meta';
  totalElements?: Maybe<Scalars['Int']>;
};

export type MoveLeakage = {
  ids: Array<Scalars['ID']>;
  groupId?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['ID']>;
  companyID: Scalars['String'];
  copy: Scalars['Boolean'];
};

export type MoveLeakageResult = {
  __typename?: 'MoveLeakageResult';
  companyId: Scalars['String'];
  projectId: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrUpdateAuditor: Scalars['Boolean'];
  softDeleteAuditor: Scalars['Boolean'];
  restoreAuditor: Scalars['Boolean'];
  deleteAuditor: Scalars['Boolean'];
  createBuilding: Building;
  updateBuilding: Building;
  deleteBuilding: ExecutionResultResource;
  softDeleteBuilding: ExecutionResultResource;
  restoreBuilding: ExecutionResultResource;
  mergeBuildings: ExecutionResultResource;
  importCompanies: Array<Company>;
  createOrUpdateCompanies: Array<Company>;
  copyMoveCompanyToGroup: ExecutionResultResource;
  createOrUpdateCompany: Company;
  deleteCompany: ExecutionResultResource;
  softDeleteCompany: ExecutionResultResource;
  deleteCompanyByName: ExecutionResultResource;
  softDeleteCompanyByName: ExecutionResultResource;
  restoreCompany: ExecutionResultResource;
  restoreCompanyById: ExecutionResultResource;
  createGroup: GroupType;
  updateGroup: GroupType;
  deleteGroup: ExecutionResultResource;
  softDeleteGroup: ExecutionResultResource;
  restoreGroup: ExecutionResultResource;
  createTagAttribute?: Maybe<Scalars['ID']>;
  updateTagAttribute: Scalars['Boolean'];
  softDeleteTagAttribute: Scalars['Boolean'];
  deleteTagAttribute: Scalars['Boolean'];
  restoreTagAttribute: Scalars['Boolean'];
  createOrUpdateLeakage?: Maybe<Leakage>;
  deleteLeakage: ExecutionResultResource;
  softDeleteLeakage: ExecutionResultResource;
  softDeleteLeakages: ExecutionResultResource;
  restoreLeakage: ExecutionResultResource;
  moveLeakage?: Maybe<MoveLeakageResult>;
  createMeasure?: Maybe<Measure>;
  updateMeasure: ExecutionResultResource;
  deleteMeasure: ExecutionResultResource;
  createFailure?: Maybe<Failure>;
  updateFailure: ExecutionResultResource;
  deleteFailure: ExecutionResultResource;
  createReplacement?: Maybe<Replacement>;
  updateReplacement: ExecutionResultResource;
  deleteReplacement: ExecutionResultResource;
  deleteLeakageDescription: ExecutionResultResource;
  createPlace: Place;
  updatePlace: Place;
  softDeletePlace: ExecutionResultResource;
  restorePlace: ExecutionResultResource;
  deletePlace: ExecutionResultResource;
  mergePlaces: ExecutionResultResource;
  createOrUpdateProject?: Maybe<Project>;
  deleteProject: ExecutionResultResource;
  softDeleteProject: ExecutionResultResource;
  restoreProject: ExecutionResultResource;
};


export type MutationCreateOrUpdateAuditorArgs = {
  auditor: AuditorInput;
  realmName: Scalars['String'];
};


export type MutationSoftDeleteAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationRestoreAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationCreateBuildingArgs = {
  building: UpdateBuilding;
  realmName: Scalars['String'];
};


export type MutationUpdateBuildingArgs = {
  building: UpdateBuilding;
  realmName: Scalars['String'];
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteBuildingArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestoreBuildingArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationMergeBuildingsArgs = {
  id: Scalars['ID'];
  mergeInto: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationImportCompaniesArgs = {
  companies: Array<ImportCompany>;
  groupId?: Maybe<Scalars['String']>;
  targetCompanyName?: Maybe<Scalars['String']>;
  targetProjectId?: Maybe<Scalars['ID']>;
  targetBuildingName?: Maybe<Scalars['String']>;
  images: Array<ImportImage>;
  realmName: Scalars['String'];
};


export type MutationCreateOrUpdateCompaniesArgs = {
  companies: Array<UpdateCompany>;
  realmName: Scalars['String'];
};


export type MutationCopyMoveCompanyToGroupArgs = {
  companyIDs: Array<Scalars['String']>;
  targetGroupID: Scalars['String'];
  realmName: Scalars['String'];
  copyOnly: Scalars['Boolean'];
};


export type MutationCreateOrUpdateCompanyArgs = {
  company: UpdateCompany;
  realmName: Scalars['String'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteCompanyArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeleteCompanyByNameArgs = {
  name: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type MutationSoftDeleteCompanyByNameArgs = {
  name: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type MutationRestoreCompanyArgs = {
  groupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationRestoreCompanyByIdArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateGroupArgs = {
  group: GroupInput;
  realmName: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['String'];
  group: GroupInput;
  realmName: Scalars['String'];
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteGroupArgs = {
  groupId: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationRestoreGroupArgs = {
  groupId: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationCreateTagAttributeArgs = {
  tagInfo: TagInput;
  realmName: Scalars['String'];
};


export type MutationUpdateTagAttributeArgs = {
  Id: Scalars['ID'];
  tagInfo: TagInput;
  realmName: Scalars['String'];
};


export type MutationSoftDeleteTagAttributeArgs = {
  Id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeleteTagAttributeArgs = {
  Id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestoreTagAttributeArgs = {
  Id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateOrUpdateLeakageArgs = {
  leakage: UpdateLeakage;
  realmName: Scalars['String'];
};


export type MutationDeleteLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteLeakagesArgs = {
  ids: Array<Scalars['ID']>;
  realmName: Scalars['String'];
};


export type MutationRestoreLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationMoveLeakageArgs = {
  params: MoveLeakage;
  realmName: Scalars['String'];
};


export type MutationCreateMeasureArgs = {
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationUpdateMeasureArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteMeasureArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateFailureArgs = {
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationUpdateFailureArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteFailureArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateReplacementArgs = {
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationUpdateReplacementArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteReplacementArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeleteLeakageDescriptionArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreatePlaceArgs = {
  placeInput: PlaceInput;
  realmName: Scalars['String'];
};


export type MutationUpdatePlaceArgs = {
  placeInput: PlaceInput;
  realmName: Scalars['String'];
  placeID: Scalars['ID'];
};


export type MutationSoftDeletePlaceArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestorePlaceArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeletePlaceArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationMergePlacesArgs = {
  sourcePlaceId: Scalars['ID'];
  targetBuildingId: Scalars['ID'];
  targetPlaceId: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateOrUpdateProjectArgs = {
  project: UpdateProject;
  realmName: Scalars['String'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestoreProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  offset: Scalars['Int'];
  count: Scalars['Int'];
  size: Scalars['Int'];
};

export type PageableParam = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type Permissions = {
  __typename?: 'Permissions';
  editBuildings: Scalars['Boolean'];
  addBuildings: Scalars['Boolean'];
  editProjects: Scalars['Boolean'];
  addProjects: Scalars['Boolean'];
  editCompanies: Scalars['Boolean'];
  addCompanies: Scalars['Boolean'];
  importProjects: Scalars['Boolean'];
  exportManagement: Scalars['Boolean'];
  histogram: Scalars['Boolean'];
  database: Scalars['Boolean'];
  project: Scalars['Boolean'];
  company: Scalars['Boolean'];
  Leakage: Scalars['Boolean'];
  documentationRepair: Scalars['Boolean'];
  rootAdmin: Scalars['Boolean'];
  manageGroups: Scalars['Boolean'];
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['ID'];
  name: Scalars['String'];
  building_id: Scalars['String'];
  _deleted_at?: Maybe<Scalars['String']>;
  assigned?: Maybe<Scalars['Boolean']>;
  usedLeakages?: Maybe<Scalars['Int']>;
};

export type PlaceInput = {
  name: Scalars['String'];
  building_id: Scalars['String'];
};

export enum Priority {
  None = 'NONE',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  costBase?: Maybe<Scalars['Float']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  energyAmount?: Maybe<Scalars['Float']>;
  energyUnit?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  Auditor?: Maybe<Auditor>;
  leakages?: Maybe<Array<Leakage>>;
  nLeakages: Scalars['Int'];
  totalLeakage: Scalars['Float'];
  totalCost: Scalars['Float'];
  _deleted_at?: Maybe<Scalars['String']>;
  co2Amount?: Maybe<Scalars['Float']>;
  co2Unit?: Maybe<Unit>;
  energyCost?: Maybe<Scalars['Float']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  elPr?: Maybe<Scalars['Float']>;
  spPw?: Maybe<Scalars['Float']>;
  uSpPw?: Maybe<Scalars['Float']>;
  spPwId?: Maybe<Scalars['String']>;
  cMod?: Maybe<Scalars['String']>;
  sigE3?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
};

export type ProjectMeta = {
  __typename?: 'ProjectMeta';
  canAdd?: Maybe<Scalars['Boolean']>;
};

export type ProjectPage = {
  __typename?: 'ProjectPage';
  projects: Array<Project>;
  meta: ProjectMeta;
};

export type Query = {
  __typename?: 'Query';
  getAuditors: Array<Maybe<Auditor>>;
  getDeletedAuditors: Array<Maybe<Auditor>>;
  getAuditor: Auditor;
  getVersion: LdCoreServiceVersionInformation;
  getPermissions: Permissions;
  /** returns LD500/510 device id and software version */
  getLDVersion?: Maybe<Array<Maybe<LdHardwareVersion>>>;
  deletedBuildings: Array<Building>;
  getBuildingById?: Maybe<Building>;
  buildings: Array<Building>;
  getCompanies: CompanyPage;
  getCompanyById?: Maybe<SingleCompanyResult>;
  getCompany?: Maybe<SingleCompanyResult>;
  deletedCompanies: Array<CompanyType>;
  getGroupId: Scalars['String'];
  getGroupsAndCompanies: GroupContent;
  getRootContent: GroupContent;
  deletedGroups: Array<Maybe<GroupType>>;
  getPathByGroupId?: Maybe<Array<AbsolutePath>>;
  getDeletedTagAttributes: Array<TagAttribute>;
  getTagAttributes: Array<TagAttribute>;
  getAllTagAttributes: Array<TagAttribute>;
  leakage?: Maybe<Leakage>;
  leakagesOfProject?: Maybe<LeakageResult>;
  leakagesOfProjectWithImages?: Maybe<LeakageResult>;
  deletedLeakages?: Maybe<LeakageResult>;
  leakageDescriptionFields: LeakageDescriptionFields;
  measure?: Maybe<Measure>;
  failure?: Maybe<Failure>;
  replacement?: Maybe<Replacement>;
  getPlaces: Array<Maybe<Place>>;
  deletedPlaces: Array<Maybe<Place>>;
  getProject?: Maybe<Project>;
  getProjectsOfCompany: ProjectPage;
  deletedProjects: Array<Project>;
};


export type QueryGetAuditorsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetDeletedAuditorsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type QueryGetPermissionsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetLdVersionArgs = {
  realmName: Scalars['String'];
};


export type QueryDeletedBuildingsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetBuildingByIdArgs = {
  buildingId: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryBuildingsArgs = {
  companyId: Scalars['ID'];
  filterIDs?: Maybe<Array<Scalars['ID']>>;
  placesFilterIDs?: Maybe<Array<Scalars['ID']>>;
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type QueryGetCompaniesArgs = {
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type QueryGetCompanyByIdArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryGetCompanyArgs = {
  groupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  realmName: Scalars['String'];
};


export type QueryDeletedCompaniesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetGroupIdArgs = {
  realmName: Scalars['String'];
  groupPath?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetGroupsAndCompaniesArgs = {
  realmName: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
};


export type QueryGetRootContentArgs = {
  realmName: Scalars['String'];
};


export type QueryDeletedGroupsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetPathByGroupIdArgs = {
  realmName: Scalars['String'];
  groupId: Scalars['String'];
};


export type QueryGetDeletedTagAttributesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetTagAttributesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAllTagAttributesArgs = {
  realmName: Scalars['String'];
};


export type QueryLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryLeakagesOfProjectArgs = {
  projectId: Scalars['ID'];
  params?: Maybe<PageableParam>;
  realmName: Scalars['String'];
};


export type QueryLeakagesOfProjectWithImagesArgs = {
  projectId: Scalars['ID'];
  params?: Maybe<PageableParam>;
  realmName: Scalars['String'];
};


export type QueryDeletedLeakagesArgs = {
  params?: Maybe<PageableParam>;
  realmName: Scalars['String'];
};


export type QueryLeakageDescriptionFieldsArgs = {
  realmName: Scalars['String'];
};


export type QueryMeasureArgs = {
  id: Scalars['ID'];
};


export type QueryFailureArgs = {
  id: Scalars['ID'];
};


export type QueryReplacementArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlacesArgs = {
  building_id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryDeletedPlacesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryGetProjectsOfCompanyArgs = {
  companyId: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryDeletedProjectsArgs = {
  realmName: Scalars['String'];
};

export type Replacement = {
  __typename?: 'Replacement';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export enum Role {
  Customer = 'CUSTOMER'
}

export type SingleCompanyMeta = {
  __typename?: 'SingleCompanyMeta';
  editBuildings: Scalars['Boolean'];
  addBuildings: Scalars['Boolean'];
};

export type SingleCompanyResult = {
  __typename?: 'SingleCompanyResult';
  company: Company;
  meta?: Maybe<SingleCompanyMeta>;
};

export type TagAttribute = {
  __typename?: 'TagAttribute';
  ID: Scalars['ID'];
  name: Scalars['String'];
  attributes: Array<Attribute>;
  _deleted_at?: Maybe<Scalars['String']>;
};

export type TagInput = {
  tagName: Scalars['String'];
  attributes: Array<AttributeInput>;
};

export enum Unit {
  LtrMin = 'LTR_MIN',
  Nm3 = 'NM3',
  KhwCf = 'KHW_CF',
  Scf = 'SCF',
  Bar = 'BAR',
  M = 'M',
  Inch = 'INCH',
  Euro = 'EURO',
  Usd = 'USD',
  R = 'R',
  Psi = 'PSI',
  KwhM3 = 'KWH_M3',
  KwhCf = 'KWH_CF',
  KgKwh = 'KG_KWH',
  LbsKwh = 'LBS_KWH',
  Cf = 'CF',
  Cfm = 'CFM'
}

export type UpdateAddress = {
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type UpdateBuilding = {
  name: Scalars['String'];
  company: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type UpdateCompany = {
  groupId?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  address?: Maybe<UpdateAddress>;
  contact?: Maybe<UpdateContact>;
  image?: Maybe<Scalars['String']>;
};

export type UpdateContact = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export type UpdateLeakage = {
  buildingName: Scalars['String'];
  buildingId: Scalars['ID'];
  project_id: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  commF?: Maybe<Scalars['String']>;
  commM?: Maybe<Scalars['String']>;
  commR?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  costBase?: Maybe<Scalars['String']>;
  costRelevantAttributesEdited?: Maybe<Scalars['Boolean']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  distance?: Maybe<Scalars['Float']>;
  gain?: Maybe<Scalars['Float']>;
  gasType?: Maybe<Scalars['String']>;
  images?: Maybe<Array<UpdateLeakageImage>>;
  leakTag?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  pressure?: Maybe<Scalars['Float']>;
  resCost?: Maybe<Scalars['Float']>;
  resFlag?: Maybe<Scalars['Float']>;
  resLeakage?: Maybe<Scalars['Float']>;
  resUnitL?: Maybe<Unit>;
  sens2x?: Maybe<Scalars['Int']>;
  sensivity?: Maybe<Scalars['Int']>;
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  unitD?: Maybe<Scalars['String']>;
  unitP?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  unitC?: Maybe<Unit>;
  version?: Maybe<Scalars['String']>;
  priority?: Maybe<Priority>;
  resolvableUnderPressure?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolvedAt?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<Scalars['String']>;
  lossCosts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateLeakageImage = {
  fileId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  checked: Scalars['Boolean'];
};

export type UpdateProject = {
  groupId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  AuditorName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  costBase?: Maybe<Scalars['Float']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  energyAmount?: Maybe<Scalars['Float']>;
  energyUnit?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  co2Amount?: Maybe<Scalars['Float']>;
  co2Unit?: Maybe<Unit>;
  energyCost?: Maybe<Scalars['Float']>;
  elPr?: Maybe<Scalars['Float']>;
  spPw?: Maybe<Scalars['Float']>;
  uSpPw?: Maybe<Scalars['Float']>;
  spPwId?: Maybe<Scalars['String']>;
  cMod?: Maybe<Scalars['String']>;
  sigE3?: Maybe<Scalars['String']>;
};

export type UpdateUser = {
  id?: Maybe<Scalars['ID']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  prename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  adress?: Maybe<UpdateAddress>;
};

export type CreateOrUpdateCompanyMutationVariables = Exact<{
  realmName: Scalars['String'];
  company: UpdateCompany;
}>;


export type CreateOrUpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type GetTagAttributesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetTagAttributesQuery = (
  { __typename?: 'Query' }
  & { getTagAttributes: Array<(
    { __typename?: 'TagAttribute' }
    & Pick<TagAttribute, 'ID' | 'name' | '_deleted_at'>
    & { attributes: Array<(
      { __typename?: 'Attribute' }
      & Pick<Attribute, 'attributeID' | 'attributeType'>
    )> }
  )> }
);


export const CreateOrUpdateCompanyDocument = gql`
    mutation CreateOrUpdateCompany($realmName: String!, $company: UpdateCompany!) {
  createOrUpdateCompany(realmName: $realmName, company: $company) {
    id
  }
}
    `;
export type CreateOrUpdateCompanyMutationFn = Apollo.MutationFunction<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>;

/**
 * __useCreateOrUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCompanyMutation, { data, loading, error }] = useCreateOrUpdateCompanyMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateOrUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>(CreateOrUpdateCompanyDocument, options);
      }
export type CreateOrUpdateCompanyMutationHookResult = ReturnType<typeof useCreateOrUpdateCompanyMutation>;
export type CreateOrUpdateCompanyMutationResult = Apollo.MutationResult<CreateOrUpdateCompanyMutation>;
export type CreateOrUpdateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCompanyMutation, CreateOrUpdateCompanyMutationVariables>;
export const GetTagAttributesDocument = gql`
    query GetTagAttributes($realmName: String!) {
  getTagAttributes(realmName: $realmName) {
    ID
    name
    attributes {
      attributeID
      attributeType
    }
    _deleted_at
  }
}
    `;

/**
 * __useGetTagAttributesQuery__
 *
 * To run a query within a React component, call `useGetTagAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagAttributesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetTagAttributesQuery(baseOptions: Apollo.QueryHookOptions<GetTagAttributesQuery, GetTagAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagAttributesQuery, GetTagAttributesQueryVariables>(GetTagAttributesDocument, options);
      }
export function useGetTagAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagAttributesQuery, GetTagAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagAttributesQuery, GetTagAttributesQueryVariables>(GetTagAttributesDocument, options);
        }
export type GetTagAttributesQueryHookResult = ReturnType<typeof useGetTagAttributesQuery>;
export type GetTagAttributesLazyQueryHookResult = ReturnType<typeof useGetTagAttributesLazyQuery>;
export type GetTagAttributesQueryResult = Apollo.QueryResult<GetTagAttributesQuery, GetTagAttributesQueryVariables>;