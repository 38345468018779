import { ApolloError } from '@apollo/client'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next/'
import { getDispName } from '../helpers/manageTokens'

export const getErrorMessage = (err?: ApolloError, t?: TFunction): string | undefined => {
  //console.log({ errObj })
  if (err?.graphQLErrors[0]?.extensions && t) {
    const errObj = err?.graphQLErrors[0]?.extensions
    //console.log({ errObj })
    const errKeys = Object.keys(errObj)

    const possibleObjectvalues = [
      'realmname',
      'realmName',
      'specialResourceName',
      'specialresourcename',
      'appName',
      'appname',
      'clientname',
      'clientName',
      'dependentSRName',
      'missingScopes',
    ]
    if (possibleObjectvalues.some((s) => errKeys.includes(s))) {
      errObj['realmname'] = getDispName()
      errObj['realmName'] = getDispName()
      errObj['rolename'] = errObj.roleName
    }
    const errorObjVal =
      errObj['scope'] ||
      errObj['specialResourceName'] ||
      errObj['missingScopes'] ||
      errObj['resourcetype'] ||
      errObj['expectedtype'] ||
      errObj['dependentSRName'] ||
      undefined

    if (errorObjVal?.split('.').includes('csleakage')) {
      errObj['scope'] = t(`leakage:${errObj.scope}`)
      errObj['specialResourceName'] = t(`leakage:${errObj.specialResourceName}`)
      errObj['missingScopes'] = t(`leakage:${errObj.missingScopes}`)
      errObj['dependentSRName'] = t(`leakage:${errObj.dependentSRName}`)
      errObj['expectedtype'] = t(`leakage:${errObj.expectedtype}`)
      errObj['appname'] = t(`leakage:${errObj.appName}`)
      errObj['appName'] = t(`leakage:${errObj.appName}`)
    }
    return t(`iam:iam.error.${errObj.errorCode}`, errObj)
  }
}

export const useGetErrorMessage = (err?: ApolloError): string | undefined => {
  const { t } = useTranslation('iam')
  const errObj = err?.graphQLErrors[0]?.extensions

  if (errObj) {
    return t(`iam.error.${errObj.errorCode}`, errObj)
  }
}
