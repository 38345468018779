import { FixedActionBar } from '@csinstruments/cs-react-theme'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { standardContentPadding } from '../../../styles/Shared'
import { SimpleActionPage } from '../../components/partials/SimpleActionPage'
import { useManageUserContext } from './UserContext/UserProvider'
import AddUserInfo from './UserForm/AddUserInfo'
import ManageUserAppResources from './UserForm/ManageUserAppResources'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined'
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined'
import { AppState, MinimalUserInfo, UserState, UserStateInput } from '../../../api/iam.models'
import { ActionType } from './UserContext/userActions'
import { useUpdateUserSimple } from '../../hooks/userHooks'
import { ROUTES } from '../../helpers/routes'
import { useHistory } from 'react-router-dom'
import CreateUpdateSummary from './UserForm/CreateUpdateSummary'
import UpdateIcon from '@material-ui/icons/Update'
import { SelectedSingleUserLicense, initUserState, intialSimpleUser } from './UserContext/initialState'
import { useTranslation } from 'react-i18next'
import { StepperWizard } from '../../components/partials/StepperWizard/StepperWizard'
import AccessRights from './UserForm/AccessRights'
import UserCreateEditIntro from './UserForm/UserCreateEditIntro'
import { ProgressBar, PushBack, ResultAlert } from '../../components/partials'
import { transformToUserStateInput } from './UserUtil/transformUserStateToUserStateInput'
type EditUserProp = {
  loading?: boolean
  error?: string
  exSuperiorInfo?: MinimalUserInfo
  exSubordinatesInfo?: MinimalUserInfo[]
}
const EditUserSimple: React.FC<EditUserProp> = ({ loading, exSuperiorInfo, exSubordinatesInfo }: EditUserProp) => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const initialSteps = [
    t('labels.introduction'),
    'Email',
    t('labels.accessRights'),
    t('labels.resources'),
    t('labels.confirm'),
  ]

  const steps = useMemo(() => {
    return initialSteps
  }, [t])

  const [sendEmail, setSendEmail] = useState<boolean>(true)
  const { state, editableState, dispatch, editUserId, registeredUser } = useManageUserContext()
  const [selectedAssignableLicense, setSelectedAssignableLicense] = useState<SelectedSingleUserLicense[]>([])
  const [selectedSingleUserLicenseInfo, setSelectedSingleUserLicenseInfo] = useState<SelectedSingleUserLicense>({
    licensePacketID: '',
    licensePacketName: '',
    licenseGroupID: '',
    licenseGroupName: '',
    licenseTypeID: '',
    licenseTypeName: '',
    licenseTypeKey: '',
  })
  const [superiorInfo, setSuperiorInfo] = useState<MinimalUserInfo>({ orgUserID: '', email: '' })
  const [subordintatesInfo, setSubordintates] = useState<MinimalUserInfo[]>([{ orgUserID: '', email: '' }])

  useEffect(() => {
    if (editableState) {
      dispatch({
        type: ActionType.EditUserSimpleInput,
        payload: { ...editableState },
      })
      if (editableState.assignableLicenses.length) {
        const tmpAssignSelected: SelectedSingleUserLicense[] = editableState.assignableLicenses.map((lp) => ({
          licensePacketID: lp.licensePacketId ?? '',
          licenseGroupID: lp.licenseGroupID ?? '',
          licenseTypeID: lp.licenseTypeID ?? '',
          licensePacketName: '',
          licenseGroupName: '',
          licenseTypeKey: '',
          licenseTypeName: '',
        }))
        setSelectedAssignableLicense(tmpAssignSelected)
      }
    }
    if (exSuperiorInfo) {
      setSuperiorInfo(exSuperiorInfo)
    }
    if (exSubordinatesInfo) {
      setSubordintates(exSubordinatesInfo)
    }
  }, [editableState, exSubordinatesInfo, exSuperiorInfo])

  useEffect(() => {
    if (registeredUser) {
      setSendEmail(false)
    }
  }, [registeredUser])

  const { confirmUserCreateOrUpdate, userCreateUpdateError, updatingUser, createOrUpdateUserResp } =
    useUpdateUserSimple(editUserId as string)

  useEffect(() => {
    if (!updatingUser && createOrUpdateUserResp) {
      history.push(ROUTES.manageusers)
    }
  }, [createOrUpdateUserResp])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 < 0 ? 0 : prevActiveStep - 1))
  }

  const onStepClick = (step: number) => {
    setActiveStep(step)
  }

  const handleReset = () => {
    setActiveStep(0)
    onStepClick(0)
    dispatch({
      type: ActionType.EditUserSimpleInput,
      payload: editableState ?? initUserState,
    })
  }

  const changeSendEmail = useCallback(
    (send: boolean) => {
      setSendEmail(send)
    },
    [sendEmail],
  )

  const clickUpdate = async () => {
    const userStateWithAssignLic: UserState = {
      ...state,
      assignableLicenses: selectedAssignableLicense.map((sl) => ({
        licensePacketId: !sl.licensePacketID ? undefined : sl.licensePacketID,
        licenseGroupID: !sl.licenseGroupID ? undefined : sl.licenseGroupID,
        licenseTypeID: !sl.licenseTypeID ? undefined : sl.licenseTypeID,
      })),
    }
    const saveUser = transformToUserStateInput(userStateWithAssignLic)

    try {
      activeStep === steps.length - 1 &&
        (await confirmUserCreateOrUpdate({ userStateInput: saveUser, inviteUser: sendEmail }))
    } catch (er) {
      console.log('error', er)
    }
  }

  const disableNext = useMemo(() => {
    switch (activeStep) {
      case 1:
        return !state?.userInfo?.email || !state.userInfo.extern
      case 2:
        return (
          !state.licenses.some((l) => l?.licenseGroupID && l?.licenseTypeID) || !state.superior
          //!state.appStates.some((appstate) => appstate?.appID === 'iam' && appstate.roles.length)
        )
      case 3:
        return state.appStates.some((appState) => appState?.appID !== 'iam' && !appState?.roles.length)
      default:
        return false
    }
  }, [state.appStates, state.licenses, state.userInfo, activeStep])

  const disabledStepper = useMemo(() => {
    return [
      false,
      false,
      !state?.userInfo?.email || !state?.userInfo?.extern,
      !state.licenses.some((l) => l?.licenseGroupID && l?.licenseTypeID) ||
        !state.superior ||
        !state.appStates.some((appstate) => appstate?.appID && appstate.roles.length),
      state.appStates.length < 1,
    ]
  }, [state])

  /* const disbleSubmit = useMemo(
    () =>
      !state.userInfo.email ||
      !state.userInfo.extern ||
      !state.licenses.some((l) => l?.groupname && l?.licenseType) ||
      !state.superior ||
      !state.appStates.some((appstate) => appstate?.clientName === 'iam' && appstate.roles.length < 1),
    [state],
  ) */
  //console.log({ state })

  return (
    <>
      <SimpleActionPage>
        <PushBack textHeading={t('manageusers')} />
        <StepperWizard labels={steps} step={activeStep} disabled={disabledStepper} onStepClicked={onStepClick} />
        <>
          <ProgressBar show={loading || updatingUser} />
          <ResultAlert showAlert={!!userCreateUpdateError} alertText={`${userCreateUpdateError}`} severity="error" />
          <div style={standardContentPadding}>
            {activeStep === 0 && <UserCreateEditIntro />}
            {activeStep === 1 && <AddUserInfo />}
            {activeStep === 2 && (
              <AccessRights
                updateSingleUserLicenseInfo={setSelectedSingleUserLicenseInfo}
                selectedSingleUserLicenseInfo={selectedSingleUserLicenseInfo}
                selectedAssignableLicense={selectedAssignableLicense}
                updatedSelAssignLicense={setSelectedAssignableLicense}
              />
            )}
            {activeStep === 3 && (
              <ManageUserAppResources
                userAppState={editableState?.appStates as unknown as AppState[]}
                selectedLicenseInfo={selectedSingleUserLicenseInfo}
              />
            )}
            {activeStep === 4 && (
              <CreateUpdateSummary
                sendEmail={sendEmail}
                changeSendEmail={changeSendEmail}
                superiorUserInfo={superiorInfo}
                subordintateUserInfo={subordintatesInfo}
                selectedLicenseInfo={selectedSingleUserLicenseInfo}
                assingableLicenses={selectedAssignableLicense}
                editStep={onStepClick}
              />
            )}
          </div>
        </>

        <FixedActionBar
          actions={[handleReset, handleBack, activeStep !== 4 ? handleNext : clickUpdate]}
          labels={['Reset', t('labels.back'), activeStep !== 4 ? t('labels.next') : t('labels.save')]}
          largeButtons
          fixedButtonSizes={[2, 2, 2]}
          icons={[
            RotateLeftOutlinedIcon,
            KeyboardBackspaceOutlinedIcon,
            activeStep !== 4 ? ArrowForwardOutlinedIcon : UpdateIcon,
          ]}
          buttonProps={[{}, {}, { solid: true }]}
          disabled={[activeStep === 0, activeStep === 0, updatingUser || disableNext]}
        />
      </SimpleActionPage>
    </>
  )
}

export default EditUserSimple
