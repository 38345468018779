import { ROUTES } from './routes'

const checkForUndefinedVar = (variable: string | undefined) => {
  if (variable && variable === 'undefined') {
    return undefined
  }
  return variable
}

interface API {
  GQL_HTTP_URI: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
}

export interface OpenID {
  CLIENT_ID: string
  REALM: string
  ADAPTER_SCRIPT: string
  URL: string
}

export interface Environment {
  APP_NAME: string
  APP_VERSION: string
  STAGE: string
  WEB_URL: string
  SENTRY_DSN: string
  API: API
  OPENID: OpenID
  LEAKAGE: string
  USER_SERVICE: string
  LD_SERVICE: string
  CSHOME: string
  ORG_API: string
  USER_PREFERENCE_API: string
}

export const environment: Environment = {
  APP_NAME: process.env.REACT_APP_NAME || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  STAGE: checkForUndefinedVar(process.env.REACT_APP_STAGE) || 'local',
  WEB_URL: checkForUndefinedVar(process.env.REACT_APP_WEB_URL) || '',
  SENTRY_DSN: checkForUndefinedVar(process.env.REACT_APP_SENTRY_DSN) || '',
  API: {
    GQL_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
    GQL_WS_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_WS_URI) || '',
    GQL_SCHEMA_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_SCHEMA_URI) || '',
  },
  CSHOME: checkForUndefinedVar(process.env.REACT_APP_CSHOME) || '',
  OPENID: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
    REALM: process.env.REACT_APP_LOGIN_REALM || '',
    URL: process.env.REACT_APP_LOGIN_URL || '',
    ADAPTER_SCRIPT: process.env.REACT_APP_ADAPTER || '',
  },
  LEAKAGE: process.env.REACT_APP_LEAKAGE || '',
  USER_SERVICE: process.env.REACT_APP_USER_SERVICE || '',
  LD_SERVICE: process.env.REACT_APP_LD_GQL_SCHEMA_URI || '',
  ORG_API: process.env.REACT_APP_ORG_API || '',
  USER_PREFERENCE_API: process.env.REACT_APP_USER_PREFERENCE_API || '',
}

export const redirectToHome = environment.WEB_URL + ROUTES.home
