import { ActionWithDescription } from '@csinstruments/cs-react-theme'
import { Grid, Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/'
import { useManageUserContext } from '../UserContext/UserProvider'

const UserCreateEditIntro: React.FC = () => {
  const { t } = useTranslation('common')
  const { editUserId } = useManageUserContext()

  return (
    <>
      <Grid container>
        <Grid item xs={6} lg={4}>
          <Box pr={4}>
            <ActionWithDescription
              heading={!editUserId ? t('heading.createUser') : t('heading.editUser')}
              description={!editUserId ? t('descriptions.createUser') : t('descriptions.editUser')}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default UserCreateEditIntro
