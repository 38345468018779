import { Grid, MenuItem, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { StateOfRole } from '../../../../api/iam.models'
import { useTranslation } from 'react-i18next/'
import { useDeleteRole, useRolesList, useRoleStateChange } from '../../../hooks/roleHooks'
import { useGetAccessibleApps } from '../../../hooks/rolePermissionhooks'

import { DataItemAction, DataTableColumn, DataTableRow } from '../DataTable/DataTableTypes'
import { DataTable, ProgressBar, useAuthContext } from '..'

const RolesTrash: React.FC = () => {
  const { t, i18n } = useTranslation('common')
  const [rows, setRows] = useState<DataTableRow[]>([])
  const { username } = useAuthContext()

  const [appsList, setAppsList] = useState<string[]>([])

  const accessibleAppsList = useGetAccessibleApps()
  //console.log('apps', accessibleAppsList)
  const [appSelected, setAppSelected] = React.useState<string>('')

  const { rolesList, rolesListLoading, reload } = useRolesList(appSelected, StateOfRole.Intrash, username)

  //const [userId, setUserId] = React.useState<string>('')

  const { confirmRoleState, roleMoving } = useRoleStateChange(appSelected ?? accessibleAppsList[0], StateOfRole.Active)
  const { confirmDeleteRole } = useDeleteRole(appSelected ?? accessibleAppsList[0])

  const userColumns: DataTableColumn[] = [
    { label: t('role'), alignment: 'left' },
    { label: t('description'), alignment: 'left' },
    /* { label: t('company'), alignment: 'left' },
    { label: 'Role', alignment: 'left' }, */
  ]

  useEffect(() => {
    if (accessibleAppsList.length && !appSelected) {
      setAppsList(accessibleAppsList)
      setAppSelected(accessibleAppsList[0])
    }
  }, [accessibleAppsList])
  const updateUserRows = () => {
    //console.log('get users:', userData)
    const tempRows =
      rolesList?.map((item) => ({
        id: `${item?.role.roleId}`,
        values: [
          `${item?.role.roleName}`,
          item?.appRoleInfo?.reduce(
            (dec, crd) => (i18n.language === crd?.langKey ? crd.roleDescription : dec),
            '',
          ) as string,
        ],
        scopes: item?.userAccessScopes as string[],
      })) || []
    setRows(tempRows)
  }

  /* if (roleDeleted) {
    console.log('deleted user', userId)
  } */

  const actionCall = (id: string, action: DataItemAction): void => {
    switch (action) {
      case 'delete':
        confirmDeleteRole(id)
        reload()
        break
      case 'restore':
        confirmRoleState(id)
        reload()
        break
    }
  }

  useEffect(() => {
    if (rolesList) {
      updateUserRows()
    }
  }, [rolesList])

  return (
    <>
      <ProgressBar show={rolesListLoading || roleMoving} />
      {/* <ResultAlert
        severity="error"
        showAlert={!!roleDeletingError || !!roleMovingError}
        alertText={`${roleDeletingError || roleMovingError}`}
      /> */}
      <Grid item container spacing={2}>
        <Grid item xs={6} lg={4}>
          <TextField
            variant="outlined"
            select
            value={appSelected}
            onChange={({ target }) => setAppSelected(target.value)}
            fullWidth
          >
            {appsList.map((app, idx) => (
              <MenuItem key={idx} value={app}>
                {app}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {/* {rolesListError && (
        <Typography color="error" variant="subtitle2">
          {rolesListError}
        </Typography>
      )} */}

      <DataTable
        rows={rows}
        columns={userColumns}
        allowedActions={{ sortable: true, deletable: true, restorable: true }}
        onButtonClicked={actionCall}
      />
    </>
  )
}

export default RolesTrash
