import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App'
import { ROUTES } from './helpers/routes'
import { useIamApolloClient } from './hooks/apolloClient'
import ForgotPassword from './pages/Forgotpassword'
import Login from './pages/Login'
import SingleSignOn from './pages/SingleSignOn'
import { AccessDenied, ServerError, SessionExpired } from './components/partials'
import LicenseExpired from './components/partials/LicenseExpired'

export const Main: React.FC = () => {
  const apolloClient = useIamApolloClient()
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <Router>
          <Switch>
            <Route exact path={ROUTES.forgotpassword} render={() => <ForgotPassword />} />
            <Route exact path={ROUTES.login} render={() => <Login />} />
            <Route exact path={ROUTES.accessDenied} render={() => <AccessDenied />} />
            <Route exact path={ROUTES.singleSignIn} render={() => <SingleSignOn />} />
            <Route exact path={ROUTES.serverError} render={() => <ServerError />} />
            <Route exact path={ROUTES.sessionExpired} render={() => <SessionExpired />} />
            <Route exact path={ROUTES.licenseExpired} render={() => <LicenseExpired />} />
            <Route render={() => <App />} />
          </Switch>
        </Router>
      </ApolloProvider>
    </>
  )
}
