import { Box, AppBar, Tabs, Tab } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { paddingTopBottom } from '../../../styles/Shared'
import { useGetAccessibleApps } from '../../hooks/rolePermissionhooks'
import ViewRolePermissions from './ViewRolePermissions'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))
const ViewAppRoles: React.FC = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const appNames = useGetAccessibleApps()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }
  return (
    <div style={paddingTopBottom}>
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0} style={{ maxWidth: '4in' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {appNames.map((app, idx) => (
              <Tab key={idx} label={app} {...a11yProps(idx)} />
            ))}
          </Tabs>
        </AppBar>
        {appNames.map((app, idx) => (
          <div key={idx}>
            <TabPanel value={value} index={idx}>
              <ViewRolePermissions appName={app} />
            </TabPanel>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ViewAppRoles
