//import { DirectoryContentExSimple, useGetDirectoryAllExSimpleSelectQuery } from '../../api/iam.models'
import { useEffect } from 'react'
import { useGetAssignableFileResourcesQuery } from '../../api/iam.models'
import { useAuthContext } from '../components/partials'
import { getOrganization } from '../helpers/manageTokens'
import { getErrorMessage } from './TranslationHelperHook'
import {
  FileSystemType,
  fileExToFileSystemType,
  toTree,
  transformToFileHierarchy,
} from './helperhooks/transformToFileHierarchy'
import React from 'react'
import { getCompanyFilePath } from '../helpers/companyfilter'
export type CompanyFileSystem = FileSystemType & {
  path: string[]
}
export const useDirResources = (
  appName: string,
  superiorID: string,
  subordinates: string[],
): {
  dirResourcesList?: FileSystemType[]
  groups?: FileSystemType[]
  companies?: CompanyFileSystem[]
  dirResourcesLoading?: boolean
  dirResourcesError?: string
  refetch: () => void
} => {
  const { t } = useAuthContext()

  const { data, error, loading, refetch } = useGetAssignableFileResourcesQuery({
    variables: {
      realmName: getOrganization(),
      appName: appName,
      superiorID: superiorID,
      subordinateIDs: subordinates,
      fileFilter: ['company-id', 'group-id', 'root-id'],
    },
    skip: !getOrganization() || !superiorID || !appName,
  })
  const [groupHierarchy, allGroups, companies] = React.useMemo(() => {
    if (data?.getAssignableFileResources?.length) {
      const allFiles = fileExToFileSystemType(data.getAssignableFileResources)
      const groups = allFiles?.filter((f) => f.fileType === 'group-id' || !f.parentFileID) ?? []
      //.map((g) => ({ ...g, parentFileID: g.parentFileID === '0' ? undefined : g.parentFileID }))
      const companies = allFiles?.filter((f) => f.fileType === 'company-id' || !f.parentFileID)
      const groupTree = toTree(groups ?? [])
      const groupHierarchy = groupTree.length
        ? groupTree
        : toTree(
            groups.map((f) => ({
              ...f,
              parentFileID: f.parentFileID === '0' ? undefined : f.parentFileID,
            })),
          )
      //console.log({ groups }, { groupHierarchy }, { companies })
      const companyWithPath = companies?.map((c) => ({
        ...c,
        path: getCompanyFilePath(groups ?? [], [], c.parentFileID).reverse(),
      })) as CompanyFileSystem[]
      //console.log(companyWithPath)

      return [groupHierarchy, groups, companyWithPath]
    }
    return []
  }, [data?.getAssignableFileResources])
  //console.log({ groupHierarchy }, { allGroups }, { companies })
  return {
    dirResourcesList: groupHierarchy,
    groups: allGroups,
    companies: companies,
    //dirResourcesLoading: loading,
    //dirResourcesError: error ? getErrorMessage(error, t) : undefined,
    refetch: refetch,
  }
}
