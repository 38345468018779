import React from 'react'
import EditIcon from '@material-ui/icons/EditOutlined'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestoreOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverOutlined'
import { DataItemAction } from './DataTableTypes'
import { useTranslation } from 'react-i18next'
import { CsButton } from '@csinstruments/cs-react-theme'

export type DataTableButtonProps = {
  condition?: boolean
  title: string
  onButtonClicked?: (id: string) => void
  id: string
  disabledAction?: boolean
  action: DataItemAction
}

export const DataTableButton: React.FC<DataTableButtonProps> = React.memo(
  ({ condition, disabledAction, action, title, id, onButtonClicked }: DataTableButtonProps) => {
    const { t } = useTranslation('common')

    return (
      <>
        {condition && (
          <CsButton
            style={{ marginLeft: '10px !important', marginBottom: '5px !important' }}
            disabled={disabledAction}
            title={t(title)}
            id={id}
            color={'primary'}
            small
            textButton
            onClick={onButtonClicked}
          >
            {action === 'edit' && <EditIcon />}
            {action === 'view' && <VisibilityIcon />}
            {action === 'restore' && <SettingsBackupRestoreIcon />}
            {action === 'delete' && <DeleteForeverIcon />}
          </CsButton>
        )}
      </>
    )
  },
)

DataTableButton.displayName = 'DataTableButton'

export default DataTableButton
