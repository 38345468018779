import { UserState } from '../../../../api/iam.models'
import { initUserState, intialSimpleUser } from './initialState'
import { ActionType, UserStateInputActions } from './userActions'

export const userInputFormReducer = (state: UserState, action: UserStateInputActions): UserState => {
  switch (action.type) {
    case ActionType.AddUserInfoInput:
      return {
        ...state,
        userInfo: action.payload,
      }
    case ActionType.EditUserSimpleInput:
      return action.payload
    case ActionType.UpdateUserInfoIput:
      return {
        ...state,
        userInfo: action.payload,
      }
    case ActionType.UpdateLoginInput:
      return {
        ...state,
        enabled: action.payload,
      }
    case ActionType.UpdateLicenseInput:
      return {
        ...state,
        licenses: action.payload,
      }
    case ActionType.AddLicenseInput:
      return {
        ...state,
        licenses: action.payload,
      }
    case ActionType.UpdateSubInput:
      return {
        ...state,
        subordinates: action.payload,
      }
    case ActionType.UpdateSupInput:
      return {
        ...state,
        superior: action.payload,
      }
    case ActionType.AddAppState:
      return {
        ...state,
        appStates: action.payload,
      }
    case ActionType.UpdateAppStateInput:
      return {
        ...state,
        appStates: state.appStates.some((as) => as?.appID === action.payload.appID)
          ? state.appStates.map((sp) => (sp?.appID === action.payload.appID ? action.payload : sp))
          : [...state.appStates, action.payload],
      }
    case ActionType.ResetUserSimpleInput:
      return { ...initUserState }
    case ActionType.UpdateAssingableLicense:
      return { ...state, assignableLicenses: action.payload }
    default:
      return state
  }
}
