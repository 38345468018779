import React, { useCallback, useState, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { useTranslation } from 'react-i18next/'
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
} from '@material-ui/core'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../components/partials/DataTable/DataTableTypes'
import DataTable from '../../components/partials/DataTable/DataTable'
export interface TableProps {
  rowData: DataTableRow[]
  columnData: DataTableColumn[]
  edit?: boolean
  view?: boolean
  remove?: boolean
  actionCall?: (id: string, action: DataItemAction) => void
}

export const tableStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
    textSize: {
      fontSize: 15,
      width: 100,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    searchField: {
      width: '100vh',
    },
    textField: {
      width: '32.5ch',
    },
    chip: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(-2),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
      padding: theme.spacing(0),
    },
  }),
)

const UsersTables: React.FC<TableProps> = ({
  rowData,
  columnData,
  edit = false,
  view = false,
  remove = false,
  actionCall,
}: TableProps) => {
  const classes = tableStyles()
  const { t } = useTranslation('common')
  const filterInitialValues = {
    extern: '',
    login: '',
    role: '',
    company: '',
  }
  const [filterValues, setFilterValues] = useState(filterInitialValues)
  const columnLabels = columnData.map((column) => t(column.label))
  const [selectiveRows, setSelectiveRows] = useState<DataTableRow[]>(rowData)
  const [searchUser, setSearchUser] = useState<string>('')
  const uniqueOptions = (index: number) => {
    const someArray = rowData.map((row) => row.values[index])
    return Array.from(new Set(someArray))
  }
  //const loginOptions = uniqueOptions(5)
  const roleOptions = uniqueOptions(4)
  const companyOptions = uniqueOptions(3)
  const externOptions = uniqueOptions(2)
  useEffect(() => {
    resetFilter()
    //console.log('row data changed')
  }, [rowData])
  const resetFilter = useCallback(() => {
    setFilterValues(filterInitialValues)
    setSelectiveRows(rowData)
  }, [rowData, actionCall])

  const changeSelect = (selectString: string, index: number) => {
    const tempRowData = rowData.filter((rows) => rows.values[index] === selectString)
    const tempSelectiveRows = selectiveRows.filter((rows) => rows.values[index] === selectString)
    const filteredRows = tempSelectiveRows.length !== 0 ? tempSelectiveRows : tempRowData
    switch (index) {
      case 5:
        setFilterValues({
          ...filterInitialValues,
          login: selectString,
        })
        break
      case 4:
        setFilterValues({
          ...filterInitialValues,
          role: selectString,
        })

        break
      case 3:
        setFilterValues({
          ...filterInitialValues,
          company: selectString,
        })

        break
      case 2:
        setFilterValues({
          ...filterInitialValues,
          extern: selectString,
        })
        break
    }
    setSelectiveRows(filteredRows)
  }
  return (
    <>
      <form>
        <Grid item container justifyContent="flex-start">
          <Grid item xs={12}>
            <TextField
              //className={classes.searchField}
              id="outlined-search"
              label={columnLabels[0]}
              type="search"
              fullWidth
              variant="outlined"
              value={searchUser}
              onChange={({ target }) => {
                setSearchUser(target.value as string)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.textField}
                label={columnLabels[2]}
                select
                value={filterValues.extern}
                variant="standard"
                onChange={({ target }) => changeSelect(target.value as string, 2)}
                /* SelectProps={{
                  renderValue: renderProps,
                  MenuProps: MenuProps,
                }} */
              >
                {externOptions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.textField}
                label={columnLabels[3]}
                select
                variant="standard"
                value={filterValues.company}
                onChange={({ target }) => changeSelect(target.value as string, 3)}
                /* SelectProps={{
                  renderValue: renderProps,
                  MenuProps: MenuProps,
                }} */
              >
                {companyOptions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.textField}
                label={columnLabels[4]}
                select
                variant="standard"
                value={filterValues.role}
                onChange={({ target }) => changeSelect(target.value as string, 4)}
                /* SelectProps={{
                  renderValue: renderProps,
                  MenuProps: MenuProps,
                }} */
              >
                {roleOptions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              onClick={() => resetFilter()}
              style={{ marginTop: '10px' }}
            >
              <AutorenewIcon /> Reset
            </Button>
          </Grid>
        </Grid>
      </form>
      <DataTable
        columns={columnData}
        rows={selectiveRows}
        allowedActions={{ sortable: true, viewable: view, deletable: remove, editable: edit }}
        onButtonClicked={actionCall}
        filterColumn={0}
        filterString={searchUser}
      />
    </>
  )
}

export default UsersTables
