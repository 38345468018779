import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { deleteTokens } from '../../helpers/manageTokens'
import { ActionWithDescription, SimpleActionPage, useAuthContext } from '.'

const ServerError: React.FC = () => {
  const { keyCloak } = useAuthContext()

  const { t } = useTranslation('common')
  const handleOK = (): void => {
    deleteTokens()
    keyCloak?.logout()
  }
  return (
    <SimpleActionPage>
      <Grid item container justifyContent="center">
        <Grid item>
          <ActionWithDescription
            heading={t('error.serverProblem')}
            description={t('error.serverErrorMessage')}
            buttonText={'OK'}
            onClick={handleOK}
          />
        </Grid>
      </Grid>
    </SimpleActionPage>
  )
}

export default ServerError
