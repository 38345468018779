import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next/'
import SimpleActionPage from '../../components/partials/SimpleActionPage'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined'
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined'
import { ActionWithDescription, FixedActionBar } from '@csinstruments/cs-react-theme'
import { Box, Grid } from '@material-ui/core'
import { useRoleManagementContext } from './RoleContext/RoleProvider'
import SaveIcon from '@material-ui/icons/Save'

import OrganizationalInput from './RoleForm/OrganizationalInput'
import SpecialPermissions from './RoleForm/SpecialPermissions'
import { useCreateRole } from '../../hooks/roleHooks'
import { useGetQueryParams } from '../../hooks/helperhooks/routingHooks'
import { ROUTES } from '../../helpers/routes'
import { Redirect } from 'react-router-dom'
import { RoleActionType } from './RoleContext/roleActions'
import { initRoleSimpleInput } from './RoleContext/roleInputInitState'
import { StepperWizard } from '../../components/partials/StepperWizard/StepperWizard'
import { PushBack, ResultAlert } from '../../components/partials'
import { RoleInput } from '../../../api/iam.models'

const CreateSimpleRole: React.FC = () => {
  const { t, i18n } = useTranslation('common')
  const { singleParam } = useGetQueryParams(undefined, 'app')
  const app = singleParam ?? ''
  const { state, dispatch } = useRoleManagementContext()
  const [activeStep, setActiveStep] = React.useState(0)
  const [update, setUpdate] = React.useState<{ error?: string; updated?: boolean }>()

  const steps = useMemo(() => {
    return [t('labels.introduction'), t('labels.description'), t('labels.specialRights')]
  }, [t])

  const { confirmCreateUpdateRole, roleCreatedUpdated, createUpdateRoleError } = useCreateRole()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 < 0 ? 0 : prevActiveStep - 1))
  }

  const onStepClick = (step: number) => {
    setActiveStep(step)
  }

  const handleReset = () => {
    setActiveStep(0)
    onStepClick(0)
    dispatch({
      type: RoleActionType.UpdateOrgRolePermissionInput,
      payload: initRoleSimpleInput,
    })
  }

  const addRole = async () => {
    /* const submitRoleState = {
      ...state,
      metaSpecialResPerms: state.metaSpecialResPerms?.filter((sp) => sp?.accessibleScopes.length),
    } */
    const newRole: RoleInput = {
      ...state,
      roleId: state.roleName + '-id',
      appRoleInfo: !state.appRoleInfo?.length
        ? [
            {
              roleDisplayName: state.roleName,
              roleDescription: '',
              langKey: i18n.language,
            },
          ]
        : state.appRoleInfo,
    }
    try {
      activeStep === steps.length - 1 && (await confirmCreateUpdateRole(app, newRole))
    } catch (er) {
      console.log('error submitting role', er)
    }
  }

  useEffect(() => {
    if (roleCreatedUpdated) {
      setUpdate({ error: createUpdateRoleError, updated: roleCreatedUpdated })
    }
  }, [roleCreatedUpdated, createUpdateRoleError])

  if (update?.updated) {
    return <Redirect to={{ pathname: ROUTES.manageroles }} />
  }
  return (
    <>
      <SimpleActionPage>
        <PushBack textHeading={t('manageroles')} />
        <StepperWizard labels={steps} step={activeStep} onStepClicked={onStepClick} />

        <ResultAlert severity="error" showAlert={!!createUpdateRoleError} alertText={`${createUpdateRoleError}`} />
        {activeStep === 0 && (
          <Grid container>
            <Grid item xs={6} lg={4}>
              <Box pr={4}>
                <ActionWithDescription
                  heading={t('heading.create role')}
                  description={t('descriptions.createRole', { app: app })}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && <OrganizationalInput />}
        {activeStep === 2 && <SpecialPermissions />}

        <FixedActionBar
          actions={[handleReset, handleBack, activeStep !== 2 ? handleNext : addRole]}
          labels={['Reset', t('labels.back'), activeStep !== 2 ? t('labels.next') : t('labels.save')]}
          largeButtons
          fixedButtonSizes={[2, 2, 2]}
          icons={[
            RotateLeftOutlinedIcon,
            KeyboardBackspaceOutlinedIcon,
            activeStep !== 2 ? ArrowForwardOutlinedIcon : SaveIcon,
          ]}
          buttonProps={[{ solid: false }, { solid: false }, { solid: true }]}
        />
      </SimpleActionPage>
    </>
  )
}

export default CreateSimpleRole
