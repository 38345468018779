// import { OrganizationRolePermissionInput, StateOfRole } from '../../../../api/iam.models'

import { RoleInput, StateOfRole } from '../../../../api/iam.models'

export const initRoleSimpleInput: RoleInput = {
  roleId: '',
  roleName: '',
  status: StateOfRole.Active,
  rolePermissions: [],
}
