/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import React from 'react'
import { useTranslation } from 'react-i18next/'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import { paddingTopBottom } from '../../styles/Shared'
import SvgIcon from '@material-ui/icons/PermIdentityOutlined'
import RolesTrash from '../components/partials/roleSubcomponents/RolesTrash'
import UsersTrash from '../components/partials/usersubcomponents/UsersTrash'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))
type TrashTab = {
  label: string
  icon: typeof SvgIcon
}
const Recyclebin: React.FC = () => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const trashTabs: TrashTab[] = [
    {
      label: t('labels.user'),
      icon: PermIdentityOutlinedIcon,
    },
    {
      label: t('labels.roles'),
      icon: AssignmentIndOutlinedIcon,
    },
  ]
  const handleChange = (e: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }
  return (
    <div style={paddingTopBottom}>
      <Typography variant="h3" align="center" color="secondary" gutterBottom>
        {t('bin')}
      </Typography>
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0} style={{ maxWidth: '4in' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {trashTabs.map((tb, idx) => (
              <Tab key={idx} label={tb.label} {...a11yProps(idx)} wrapped icon={<tb.icon fontSize="small" />} />
            ))}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <UsersTrash />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RolesTrash />
        </TabPanel>
      </div>
    </div>
  )
}

export default Recyclebin
