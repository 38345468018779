import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ROUTES } from '../../helpers/routes'

import { useRoleInfo } from '../../hooks/roleHooks'
import { paddingLeftRight, paddingTopBottom, standardContentPadding } from '../../../styles/Shared'
import { useGetQueryParams } from '../../hooks/helperhooks/routingHooks'
import { useTranslation } from 'react-i18next/'
import { useStyles } from '../UserManagement/User.Styles'

import { ProgressBar, PushBack, ResultAlert } from '../../components/partials'
import { getTranslatedValue } from '../../helpers/utils'
import { AppRole } from '../../../api/iam.models'

const RoleInformation: React.FC = () => {
  const getParams = 'app'
  const history = useHistory()
  const classes = useStyles()
  const { id, singleParam } = useGetQueryParams(undefined, getParams)
  const roleid = id !== '_role' ? id : ''
  const appname = singleParam ?? ''
  const [currRolePerm, setCurrRolePerm] = useState<AppRole>()

  useEffect(() => {
    if (!roleid) {
      history.push(ROUTES.manageroles)
    }
  }, [])

  //console.log(`roleid: ${roleid}, appname: ${appname}`)
  const { roleInfo, roleInfoError } = useRoleInfo(appname, roleid)

  const { t, i18n } = useTranslation('common')

  useEffect(() => {
    if (roleInfo) {
      setCurrRolePerm(roleInfo)
    }
  }, [roleInfo, i18n.language])

  /* useEffect(() => {
    if (data?.getSpecialResources?.length && !loading) {
      setMetaSpecPerms(data.getSpecialResources as MetaSpecialResourcePermission[])
    }
  }, [data?.getSpecialResources, loading]) */

  // useEffect(() => {
  //   if (data?.getSpecialResources?.length && !loading && roleInfo?.getRole?.metaSpecialResPerms?.length) {
  //     const checkedPer = roleInfo.getRole.metaSpecialResPerms.flatMap((msr) =>
  //       data.getSpecialResources?.flatMap((gsp) => {
  //         if (gsp?.name === msr?.name) {
  //           return {
  //             ...msr,
  //             checkedScopes: msr?.accessibleScopes.map((msSc) => ({
  //               scope: msSc,
  //               checked: gsp?.accessibleScopes.includes(msSc),
  //             })),
  //           }
  //         }
  //       }),
  //     ) as unknown as MetaspecialChecked[]

  //     setViewRoleScopes(checkedPer.filter((c) => c))
  //   }
  // }, [data?.getSpecialResources, loading, roleInfo?.getRole?.metaSpecialResPerms])
  if (currRolePerm) {
    //console.log('checkedPerms', viewRoleScopes)
    return (
      <>
        <div style={{ ...paddingLeftRight, ...paddingTopBottom, ...standardContentPadding }}>
          <ResultAlert showAlert={!!roleInfoError} alertText={`${roleInfoError}`} />
          <PushBack />
          <Typography variant="h4" color="secondary" align="center">
            {t('heading.overview', {
              role: !currRolePerm.isCustomRole
                ? getTranslatedValue(appname, `${currRolePerm.role.roleDisplayKey}`, t)
                : currRolePerm.role.roleName,
            })}
          </Typography>
          <Container style={{ ...paddingLeftRight, ...paddingTopBottom, ...standardContentPadding }}>
            <Grid item container spacing={3} justifyContent="flex-start" alignItems="center">
              <Grid item xs={5} lg={3}>
                <TextField
                  fullWidth
                  label={t('labels.roleName')}
                  value={
                    !currRolePerm.isCustomRole
                      ? getTranslatedValue(appname, `${currRolePerm.role.roleDisplayKey}`, t)
                      : currRolePerm.role.roleName
                  }
                />
              </Grid>
              <Grid item xs={5} lg={5}>
                <TextField
                  fullWidth
                  label={t('labels.description')}
                  value={
                    !currRolePerm.isCustomRole
                      ? getTranslatedValue(appname, `${currRolePerm.role.roleDescriptionKey}`, t)
                      : currRolePerm.appRoleInfo?.reduce(
                          (v, cr) => (cr?.langKey === i18n.language ? `${cr?.roleDescription}` : v),
                          '',
                        )
                  }
                />
              </Grid>
              {/* <Grid item xs={5} lg={6}>
                <TextField
                  fullWidth
                  label={t('labels.assignableBy')}
                  multiline
                  value={currRolePerm.userPermissions.assignableBy.map((u) => u?.email ?? '') ?? ''}
                />
              </Grid> */}
              {/* <Grid item xs={5} lg={6}>
                <TextField
                  fullWidth
                  label={t('labels.editableBy')}
                  multiline
                  value={currRolePerm.userPermissions.editableBy.map((u) => u?.email ?? '') ?? ''}
                />
              </Grid> */}
              <Grid
                item
                xs={9}
                lg={7}
                spacing={3}
                container
                alignItems="stretch"
                alignContent="stretch"
                justifyContent="space-between"
              ></Grid>
              <Grid item xs={12} />
              <Grid
                item
                spacing={1}
                alignItems="flex-end"
                //justifyContent="space-between"
                container
                className={classes.overview}
              >
                {currRolePerm.role.rolePermissions
                  ?.slice()
                  .sort((a, b) => (a.groupID * 100 + a.indexID > b.groupID * 100 + b.indexID ? 1 : -1))
                  .map((si) => (
                    <Grid key={si?.resourceId} item container xs={8} lg={6} alignItems="center">
                      <Grid item xs lg={7}>
                        <Typography variant="body1" gutterBottom>
                          {getTranslatedValue(appname, `${si.resourceDisplayKey}`, t)}
                        </Typography>
                      </Grid>
                      <Grid item xs lg={2}>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: { enter: 400 } }}
                          title={
                            <Typography variant="subtitle1">
                              {getTranslatedValue(appname, `${si?.resourceDescriptionKey}`, t)}
                            </Typography>
                          }
                          placement="right-end"
                        >
                          <Typography display="inline">
                            <InfoOutlinedIcon fontSize="small" />
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item container alignContent="space-between">
                        {si.resourceScopes
                          .slice()
                          .sort((a, b) => (a.scopeID > b.scopeID ? 1 : -1))
                          .map((resSp) => (
                            <Grid item key={resSp.scopeID}>
                              {resSp.scopeID != 'noaccess-id' ? (
                                <FormControlLabel
                                  label={getTranslatedValue(appname, `${resSp.scopeDisplayKey}`, t)}
                                  control={<Checkbox checked={resSp.scopeAssigned} disableRipple disableTouchRipple />}
                                />
                              ) : (
                                <></>
                              )}
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )
  }
  return <ProgressBar show={!!currRolePerm} />
}

export default RoleInformation
