import React from 'react'
import { DisplayRowProps } from './DataTableTypes'
import TableCell from '@material-ui/core/TableCell'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import AutorenewIcon from '@material-ui/icons/AutorenewOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

export const DataTableRowCells: React.FC<DisplayRowProps> = ({ row, columns }: DisplayRowProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      {row.values
        .filter((r, i) => i < columns.length)
        .map((v, i) => {
          return (
            <TableCell key={v + columns[i].label} align={columns[i].alignment === 'right' ? 'right' : 'left'}>
              {(columns[i].isStatus || columns[i].isUpdateStatus) && v && v !== 'actions.inProgress' && (
                <Tooltip
                  title={columns[i].isStatus ? (t('actions.completed') as string) : (t('updateOverview.ok') as string)}
                >
                  <CheckCircleIcon />
                </Tooltip>
              )}
              {(columns[i].isStatus || columns[i].isUpdateStatus) && (!v || v === 'actions.inProgress') && (
                <Tooltip
                  title={
                    columns[i].isStatus
                      ? (t('actions.inProgress') as string)
                      : (t('updateOverview.deprecated') as string)
                  }
                >
                  <AutorenewIcon />
                </Tooltip>
              )}
              {!columns[i].isStatus && !columns[i].isUpdateStatus && v}
            </TableCell>
          )
        })}
    </>
  )
}

export default DataTableRowCells
