import {
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React, { useEffect, useMemo, useState } from 'react'
import {
  AppState,
  AppStateSimpleInput,
  LicenseUser,
  SingleUserLicense,
  SingleUserLicenseInput,
  useValidateUserInfoQuery,
} from '../../../../api/iam.models'
import {
  AssignablePacketLicenses,
  PacketGroup,
  PacketGroupTypes,
  useGetAssignablePackets,
} from '../../../hooks/organizationHooks'
import { useManageUserContext } from '../UserContext/UserProvider'
import { ActionType } from '../UserContext/userActions'
import { useTranslation } from 'react-i18next'
import { ActionWithDescription } from '../../../components/partials/ActionWithDescription/ActionWithDescription'
import { TFunction } from 'i18next'
import { getOrganization } from '../../../helpers/manageTokens'
import { SelectedSingleUserLicense } from '../UserContext/initialState'
import constants from '../../../../constants/constants'
import { getRemainingDays } from '../../../helpers/utils'
type AppLicenseProps = {
  updateSelectedSingleUserLicense?: (singleUserLic: SelectedSingleUserLicense) => void
  selectedSingleUserLicense?: SelectedSingleUserLicense
}

const { EMAIL_PATTERN } = constants.USER_CREDENTIAL_PATTERN
const getDisplayMenu = (Lictype: PacketGroupTypes | undefined | null, t: TFunction): string => {
  const dispMenu = t(`license:${Lictype?.licenseTypeKey}`)
  const availableLic = t('labels.available') + ' ' + Lictype?.available
  const assignedLic = t('labels.assigned') + ' ' + ((Lictype?.totalSize as number) - (Lictype?.available as number))
  //console.log({ Lictype })
  return dispMenu + ` [${availableLic + ', ' + assignedLic}]`
}
const ApplicationLicenses: React.FC<AppLicenseProps> = ({
  updateSelectedSingleUserLicense,
  selectedSingleUserLicense,
}: AppLicenseProps) => {
  const { state, dispatch, loginEnabled, editUserId } = useManageUserContext()
  //const { appsList } = useAssignableApps()
  const { assignableLicensePacket } = useGetAssignablePackets()
  const [selectedPacketID, setSelectedPacketID] = useState<string>('')
  const [assignableLicenceGroups, setAssignableLicenseGroups] = useState<PacketGroup[]>([])
  //const [currentUserInfo, setCurrentUserInfo] = useState<RegisteredUserInfo>()
  const [t] = useTranslation(['common', 'license'])

  //const [availableApps, setAvailableApps] = useState<LicenseGroup[]>([])
  const [availablePacketLicenses, setAvailablePacketLicenses] = useState<AssignablePacketLicenses[]>([])

  const { licenses } = state

  const { data } = useValidateUserInfoQuery({
    variables: {
      username: state.userInfo?.email ?? '',
    },
    skip: !state.userInfo?.email.match(EMAIL_PATTERN) || !getOrganization(),
  })

  const licenseUser = useMemo(() => {
    if (data?.getUserInfo) {
      //setCurrentUserInfo(data.getRegisteredUserInfo)
      return data.getUserInfo.licenseUser
    }
  }, [data?.getUserInfo])

  useEffect(() => {
    if (assignableLicensePacket?.length) {
      //console.log(assignableLicensePacket)
      setAvailablePacketLicenses(assignableLicensePacket)
      const pkt = assignableLicensePacket[0].packetId
      if (!selectedPacketID) {
        setSelectedPacketID(pkt)
        setAssignableLicenseGroups(assignableLicensePacket[0].packetGroups ?? [])
      }
      if (licenses.some((l) => l?.licensePacketID)) {
        setSelectedPacketID(licenses[0]?.licensePacketID ?? '')
        setAssignableLicenseGroups(
          assignableLicensePacket.find((p) => p.packetId === licenses[0]?.licensePacketID)?.packetGroups ?? [],
        )
        const exLicPack = assignableLicensePacket.find((p) => p.packetId === licenses[0]?.licensePacketID)
        const exLicPackGrp = exLicPack?.packetGroups.find((g) => g.groupId === licenses[0]?.licenseGroupID)
        const exLicType = exLicPackGrp?.licenseTypes.find((typ) => typ.typeId === licenses[0]?.licenseTypeID)
        updateSelectedSingleUserLicense &&
          updateSelectedSingleUserLicense({
            licensePacketID: exLicPack?.packetId ?? '',
            licenseGroupID: exLicPackGrp?.groupId ?? '',
            licenseTypeID: exLicType?.typeId ?? '',
            licenseGroupName: exLicPackGrp?.displayName ?? '',
            licensePacketName: exLicPack?.packetName ?? '',
            licenseTypeName: exLicType?.licenseType ?? '',
            licenseTypeKey: exLicType?.licenseTypeKey ?? '',
          })
      }

      const groupID = assignableLicensePacket[0].packetGroups[0].groupId
      const groupName = assignableLicensePacket[0].packetGroups[0].groupName
      const { licenseTypes } = assignableLicensePacket[0].packetGroups[0]
      const userType = licenseTypes.find((lt) => lt.licenseType === 'user' && lt.available > 0)?.typeId
      const otherType = licenseTypes.find((lt) => lt.licenseType !== 'user' && lt.available > 0)?.typeId
      const licTypID = userType ? userType : otherType ?? ''

      if (!licenses.length) {
        dispatch({
          type: ActionType.AddLicenseInput,
          payload: [
            {
              licenseGroupID: groupID,
              licensePacketID: pkt,
              licenseTypeID: licTypID,
              expirationDate: '',
              regularLicense:
                licenseUser && licenseUser === LicenseUser.Csuser ? LicenseUser.Csuser : LicenseUser.Regular,
            },
          ],
        })
        updateSelectedSingleUserLicense &&
          updateSelectedSingleUserLicense({
            licensePacketID: pkt,
            licenseGroupID: groupID,
            licenseTypeID: licTypID,
            licenseGroupName: assignableLicensePacket[0].packetGroups[0].displayName,
            licensePacketName: assignableLicensePacket[0].packetName,
            licenseTypeName: licenseTypes.find((licTyp) => licTyp.typeId === licTypID)?.licenseType ?? '',
            licenseTypeKey: licenseTypes.find((licTyp) => licTyp.typeId === licTypID)?.licenseTypeKey ?? '',
          })
      }
      const initialisedAppState = state.appStates.some((appState) => appState?.appID === groupName)
        ? state.appStates
        : [
            {
              appID: groupName,
              roles: [],
              assignableRoles: [],
              editableRoles: [],
              filePermission: [],
            },
          ]
      if (!editUserId) {
        dispatch({ type: ActionType.AddAppState, payload: initialisedAppState as AppState[] })
      }
    }
  }, [assignableLicensePacket, licenses, selectedPacketID, licenseUser])

  const handlePacket = (packetID: string) => {
    //console.log({ packetID })
    setSelectedPacketID(packetID)
    const selectedPack = availablePacketLicenses.find((p) => p.packetId === packetID)
    const defaultLicgroup = selectedPack?.packetGroups.find((grp) => grp.licenseTypes.some((lt) => lt.available > 0))
    const possibleLicTypes = selectedPack?.packetGroups.find(
      (grp) => grp.groupId === defaultLicgroup?.groupId,
    )?.licenseTypes
    const defaultLicType = possibleLicTypes?.find((lt) => lt.available > 0)?.typeId ?? ''
    const currLicPacket: SingleUserLicense[] = [
      {
        licenseGroupID: defaultLicgroup?.groupId ?? '',
        licenseTypeID: defaultLicType,
        licensePacketID: packetID,
        regularLicense: licenseUser && licenseUser === LicenseUser.Csuser ? LicenseUser.Csuser : LicenseUser.Regular,
        expirationDate: '',
      },
    ]

    if (selectedSingleUserLicense && updateSelectedSingleUserLicense) {
      updateSelectedSingleUserLicense({
        ...selectedSingleUserLicense,
        licensePacketID: selectedPack?.packetId ?? '',
        licensePacketName: selectedPack?.packetName ?? '',
        licenseGroupID: defaultLicgroup?.groupId ?? '',
        licenseGroupName: defaultLicgroup?.displayName ?? '',
        licenseTypeID: defaultLicType,
        licenseTypeName: possibleLicTypes?.find((ltype) => ltype.typeId === defaultLicType)?.licenseType ?? '',
        licenseTypeKey: possibleLicTypes?.find((ltype) => ltype.typeId === defaultLicType)?.licenseTypeKey ?? '',
      })
    }

    const updatePacketGroups = availablePacketLicenses.find((p) => p.packetId === packetID)?.packetGroups ?? []
    //console.log({ updatePacketGroups })
    setAssignableLicenseGroups(updatePacketGroups)
    dispatch({
      type: ActionType.AddLicenseInput,
      payload: currLicPacket,
    })
    //console.log('currentIam', state.appStates)
  }

  const handleGroupName = (app: string, packtId: string) => {
    const currGroup = licenses?.some(
      (lcinput) => lcinput?.licenseGroupID === app && lcinput.licensePacketID === packtId,
    )
      ? licenses.map((lp) =>
          lp?.licensePacketID === packtId && lp.licenseGroupID === app
            ? {
                licensePacketID: packtId,
                licenseGroupID: '',
                licenseTypeID: '',
                regularLicense: lp.regularLicense,
                expirationDate: '',
              }
            : lp,
        )
      : licenses.map((lp) =>
          lp?.licensePacketID === packtId
            ? {
                licensePacketID: packtId,
                licenseGroupID: app,
                licenseTypeID: '',
                regularLicense: lp.regularLicense,
                expirationDate: '',
              }
            : lp,
        )

    dispatch({
      type: ActionType.AddLicenseInput,
      payload: currGroup as SingleUserLicense[],
    })
    //console.log('currentIam', state.appStates)
    const initialisedAppState = state.appStates.some((appState) => appState?.appID === app)
      ? state.appStates
      : [
          ...state.appStates.filter((appState) => appState?.appID !== app),
          {
            clientName: app,
            roles: [],
            assignableRoles: [],
            editableRoles: [],
            directories: [],
            resources: [],
          },
        ]
    if (loginEnabled || !editUserId) {
      dispatch({ type: ActionType.AddAppState, payload: initialisedAppState as AppState[] })
    }
  }
  //console.log({ licenses })
  const handleLicenseType = (packetId: string, app: string, type: string) => {
    const updateLicense =
      licenses?.map((currL) =>
        currL?.licenseGroupID === app && currL.licensePacketID === packetId
          ? {
              licenseGroupID: app,
              licenseTypeID: type,
              licensePacketID: packetId,
              regularLicense: currL.regularLicense,
              expirationDate: '',
            }
          : currL,
      ) ?? []
    dispatch({
      type: ActionType.EditUserSimpleInput,
      payload: {
        ...state,
        licenses: updateLicense,
      },
    })
    if (selectedSingleUserLicense && updateSelectedSingleUserLicense && availablePacketLicenses.length) {
      const selectedTypes = availablePacketLicenses
        .find((p) => p.packetId === packetId)
        ?.packetGroups.find((grp) => grp.groupId === app)?.licenseTypes
      //console.log({ selectedSingleUserLicense })
      updateSelectedSingleUserLicense({
        ...selectedSingleUserLicense,
        licenseTypeID: type,
        licenseTypeName: selectedTypes?.find((typ) => typ.typeId === type)?.licenseType ?? '',
        licenseTypeKey: selectedTypes?.find((typ) => typ.typeId === type)?.licenseTypeKey ?? '',
      })
    }
  }

  const handleRegularLiceuser = (typeRegular: LicenseUser) => {
    //console.log({ typeRegular })
    const regularType = LicenseUser.Regular
    const updateLicensesInput = licenses.map((l) =>
      l?.regularLicense === typeRegular
        ? { ...l, regularLicense: regularType }
        : { ...l, regularLicense: LicenseUser.Csuser },
    ) as SingleUserLicense[]
    dispatch({
      type: ActionType.UpdateLicenseInput,
      payload: updateLicensesInput,
    })
  }
  const licError = useMemo(() => {
    return availablePacketLicenses.some((p) => p.packetId === selectedPacketID && getRemainingDays(p.expiryDate) <= 0)
  }, [selectedPacketID, availablePacketLicenses])
  return (
    <>
      <Grid item xs={6} lg={6} container spacing={2} justifyContent="space-between" direction="column">
        <Grid item>
          <Box pr={4}>
            <ActionWithDescription
              heading={t('heading.assignLicenses')}
              description={t('descriptions.assignLicenses')}
            />
          </Box>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                value={licenses[0]?.regularLicense ?? ''}
                disabled={licenseUser !== LicenseUser.Csuser}
                name={LicenseUser.Csuser}
                checked={licenses.some((lt) => lt?.regularLicense === LicenseUser.Csuser)}
                onChange={() => {
                  handleRegularLiceuser(LicenseUser.Csuser)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={
              <>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography>{t('labels.csUser')}</Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 500 }}
                      title={<Typography variant="subtitle2">{t('labels.csUserInfo')}</Typography>}
                      placement="top-start"
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Box pr={4}>
            <TextField
              select
              id="packetName"
              variant="outlined"
              label={t('labels.packetName')}
              value={selectedPacketID}
              fullWidth
              onChange={({ target }) => handlePacket(target.value)}
              error={licError}
              helperText={licError && t('license.assignError')}
            >
              {availablePacketLicenses.map((avp) => (
                <MenuItem key={avp.packetId} value={avp.packetId}>
                  {avp.packetName}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>

        <Grid item container spacing={2}>
          {assignableLicenceGroups.map((grp) => (
            <Grid item key={grp.groupId} container spacing={2}>
              <Grid item>
                <Box pr={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={grp.groupName}
                        required
                        disabled={licError}
                        checked={licenses.some((lt) => lt?.licenseGroupID === grp.groupId)}
                        onChange={({ target }) => handleGroupName(selectedPacketID, target.value)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={t(`license:${grp.displayName}`)}
                  />
                </Box>
              </Grid>
              <Grid item container style={{ paddingLeft: '4em' }} spacing={2} direction="column">
                {grp.licenseTypes.map((grpType) => (
                  <Grid item key={grpType.typeId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={grpType.typeId}
                          required
                          disabled={
                            grpType.available <= 0 && !licenses.some((lt) => lt?.regularLicense === LicenseUser.Csuser)
                          }
                          checked={licenses.some(
                            (lt) =>
                              lt?.licenseGroupID === grp.groupId &&
                              lt.licensePacketID === selectedPacketID &&
                              lt.licenseTypeID === grpType.typeId,
                          )}
                          onChange={({ target }) => handleLicenseType(selectedPacketID, grp.groupId, target.value)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography color={grpType.available >= 1 || !!editUserId ? 'inherit' : 'error'}>
                          {getDisplayMenu(grpType, t)}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* {availableApps.map((appl, idx) => (
          <div key={idx + appl.groupname}>
            <Grid item>
              <Box pr={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={appl.groupname}
                      required
                      checked={licenses.some((lt) => lt?.groupname === appl.groupname)}
                      onChange={({ target }) => handleGroupName(target.value)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={t(`license:${appl.displayNameKey}`)}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box pr={4}>
                {licenses.map(
                  (ls, lidx) =>
                    ls?.groupname === appl.groupname && (
                      <TextField
                        key={lidx + ls.groupname}
                        variant="outlined"
                        type="text"
                        name="userType"
                        label={t('labels.userType')}
                        value={ls.licenseType}
                        required={!!ls.groupname}
                        onChange={({ target }) => handleLicenseType(appl.groupname, target.value)}
                        fullWidth
                        select
                      >
                        {appl.licenseTypes.map((lt) => (
                          <MenuItem
                            key={`${lt?.licenseType}`}
                            value={lt?.licenseType}
                            disabled={!!(lt && lt.numberOfLicenses === lt.assignedLicenses)}
                          >
                            {getDisplayMenu(lt, t)}
                          </MenuItem>
                        ))}
                      </TextField>
                    ),
                )}
              </Box>
            </Grid>
          </div>
        ))} */}
      </Grid>
    </>
  )
}

export default ApplicationLicenses
