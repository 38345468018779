import React, { FC, ReactElement, useEffect, useState } from 'react'
import {
  selectableStep,
  standardStep,
  standardStepper,
  standardStepperInner,
  useStepLabelStyles,
} from './StepperStyles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from 'react-i18next'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { StepIconProps } from '@material-ui/core'

type StepperProps = {
  labels: string[]
  onStepClicked?: (step: number) => void
  step: number
  disabled?: boolean[]
}

const StepperWizardStepIcon: FC<StepIconProps> = ({ completed, active }: StepIconProps) => {
  return <FiberManualRecordIcon color={completed || active ? 'inherit' : 'disabled'} />
}

export const StepperWizard: FC<StepperProps> = ({
  labels,
  onStepClicked,
  step,
  disabled,
}: StepperProps): ReactElement => {
  const { t } = useTranslation('common')
  const classes = useStepLabelStyles()
  const [activeStep, setActiveStep] = useState<number | undefined>(step)

  const clickedStep = (step: number): void => {
    if (!disabled || !disabled[step]) {
      setActiveStep(step)
      if (onStepClicked) {
        onStepClicked(step)
      }
    }
  }
  useEffect(() => {
    if (!(step < 0)) {
      setActiveStep(step)
    }
  }, [step])

  return (
    <div style={standardStepper}>
      <div style={standardStepperInner}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {labels.map((label, i) => (
            <Step key={label}>
              <StepLabel
                onClick={() => clickedStep(i)}
                classes={classes}
                StepIconComponent={StepperWizardStepIcon}
                style={disabled && disabled[i] ? standardStep : selectableStep}
              >
                {t(label)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  )
}

export default StepperWizard
