import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
//import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded'
//import { CsButton } from '@csinstruments/cs-react-theme'
// import { environment } from '../../helpers/environment'
// import { getOrganization } from '../../helpers/manageTokens'
import LicenseOfferPopUp from './LicenseOfferPopUp'
type RenewLicenseProps = {
  days: number
  appName?: string
  offerRequested: boolean
}
const RenewLicense: React.FC<RenewLicenseProps> = ({ days, appName, offerRequested }: RenewLicenseProps) => {
  const { t } = useTranslation('common')

  // const redirectToCSID = () => {
  //   const redirectLink = environment.USER_SERVICE + `/offer-request?realmName=${getOrganization()}`
  //   window.open(redirectLink, '_blank')
  // }

  if (days <= 0) {
    return (
      <Typography variant="body1" color="error">
        {t('error.licenseExpired', { app: appName?.toLocaleUpperCase() })}
      </Typography>
    )
  }
  return (
    <>
      <LicenseOfferPopUp daysRemaining={days} isOfferRequested={offerRequested} />
      <Typography align="justify" gutterBottom>
        {t('labels.expiresIn', { days: days })}
      </Typography>
    </>
  )
}

export default RenewLicense
