import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
// import {
//   LanguageString,
//   LanguageStringInput,
//   MetaSpecialResourcePermissionInput,
//   OrganizationRole,
//   OrganizationRolePermission,
//   OrganizationRolePermissionInput,
//   StateOfRole,
// } from '../../../api/iam.models'
import { useGetQueryParams } from '../../hooks/helperhooks/routingHooks'
import { useRoleInfo } from '../../hooks/roleHooks'
import CreateSimpleRole from './CreateSimpleRole'
import EditSimpleRole from './EditSimpleRole'
import { RoleInput, RoleUserPermissionInput } from '../../../api/iam.models'
import RoleManagementProvider from './RoleContext/RoleProvider'

const RoleMaster: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { singleParam } = useGetQueryParams(undefined, 'app')
  const app = singleParam ?? ''
  const roleid = id !== '_role' ? id : ''
  const { roleInfo, roleInfoError, roleInfoLoading } = useRoleInfo(app, roleid)

  const [curRoleInfo, setCurRoleInfo] = useState<RoleInput>()

  const updateRoleInfo = () => {
    if (roleInfo) {
      const tempRole = {
        roleId: roleInfo.role.roleId,
        roleName: roleInfo.role.roleName,
        status: roleInfo.role.status,
        appRoleInfo:
          roleInfo.appRoleInfo?.map((ap) => ({
            langKey: ap.langKey,
            roleDisplayName: ap.roleDisplayName,
            roleDescription: ap.roleDescription,
          })) ?? [],
        userPermissions: {
          editableBy: roleInfo.userPermissions.editableBy.map((u) => u?.orgUserID),
          assignableBy: roleInfo.userPermissions.assignableBy.map((u) => u?.orgUserID),
        },
        rolePermissions:
          roleInfo.role.rolePermissions?.map((rlp) => ({
            resourceId: rlp.resourceId,
            scopeId: rlp.resourceScopes.filter((rlps) => rlps.scopeAssigned).map((scp) => scp.scopeID),
          })) ?? [],
      } as RoleInput

      setCurRoleInfo(tempRole)
    }
  }

  React.useEffect(() => {
    if (roleInfo) {
      //console.log('currentRoleInfo', roleInfo)
      updateRoleInfo()
    }
  }, [roleInfo])
  return (
    <>
      <RoleManagementProvider editRoleState={curRoleInfo} editRoleId={roleid}>
        {roleid ? <EditSimpleRole isLoading={roleInfoLoading} isError={roleInfoError} /> : <CreateSimpleRole />}
      </RoleManagementProvider>
    </>
  )
}

export default RoleMaster
