import React, { useState, FormEvent } from 'react'
import clsx from 'clsx'
import {
  Button,
  FormControl,
  Grid,
  Link,
  TextField,
  FormGroup,
  InputAdornment,
  Paper,
  Container,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import constant from '../../constants/constants'
import { ROUTES } from '../helpers/routes'
import { accessTokenVar, loginStatus } from '../helpers/cache'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LockIcon from '@material-ui/icons/Lock'
import { handleChange } from '../helpers/eventHandler'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(0, 3),
    },
    paper: {
      maxWidth: 400,
      padding: theme.spacing(2),
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      flexWrap: 'nowrap',
    },
    link: {
      alignContent: 'right',
      position: 'relative',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: '25ch',
    },
  }),
)

export interface LoginData {
  email: string
  password: string
  showPassword: boolean
}
const loginPlaceholder: LoginData = {
  email: 'example@domain.com',
  password: '!Yourpin123',
  showPassword: false,
}
const { EMAIL_PATTERN, PASSWORD_PATTERN } = constant.USER_CREDENTIAL_PATTERN

const Login: React.FC = () => {
  const [state, setState] = useState(loginPlaceholder)
  const classes = useStyles()
  const { t } = useTranslation('common')

  const history = useHistory()

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault()
    const { email, password } = state
    console.log('loginDetails: \n', email, password)
    accessTokenVar('helloToken')
    loginStatus() && history.push(ROUTES.home)
  }
  const handleClickShowPassword = (): void => {
    setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }))
  }

  const handleResetpassword = () => {
    history.push(ROUTES.forgotpassword)
  }

  const loginValidation = (): boolean => {
    return !state.email.match(EMAIL_PATTERN) || !state.password.match(PASSWORD_PATTERN)
  }

  return (
    <Container>
      <Grid container justifyContent="center" alignContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {t('welcome.title')}
          </Typography>
          <div className={classes.root}>
            <form onSubmit={handleSubmit}>
              <Paper elevation={2} className={classes.paper}>
                <FormGroup>
                  <FormControl className={clsx(classes.margin)}>
                    <TextField
                      id="user-email"
                      label={t('login.username')}
                      placeholder={state.email}
                      name="email"
                      value={state.email}
                      onChange={(e) => {
                        handleChange(e, setState)
                      }}
                      required
                      error={!state.email.match(EMAIL_PATTERN)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountBoxIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>

                  <FormControl className={clsx(classes.margin)}>
                    <TextField
                      id="user-password"
                      label={t('login.password')}
                      placeholder={state.password}
                      type={state.showPassword ? 'text' : 'password'}
                      name="password"
                      value={state.password}
                      onChange={(e) => {
                        handleChange(e, setState)
                      }}
                      required
                      error={!state.password.match(PASSWORD_PATTERN)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                              {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <Grid container justifyContent="space-evenly" alignItems="baseline">
                    <Grid item>
                      <Button type="submit" variant="contained" color="secondary" disabled={loginValidation()}>
                        {t('login.login')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Link
                        component="button"
                        className={classes.link}
                        color="secondary"
                        align="right"
                        onClick={handleResetpassword}
                      >
                        {t('login.forgot')}
                      </Link>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Paper>
            </form>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Login
