import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useManageUserContext } from '../UserContext/UserProvider'
import { useStyles } from '../User.Styles'
import { useAssignableApps } from '../../../hooks/organizationHooks'
import { getTranslatedValue, getUserName } from '../../../helpers/utils'
//import EditIcon from '@material-ui/icons/Edit'
import { AsAppRole, FilePermissionExSimple, MinimalUserInfo, StateOfResource } from '../../../../api/iam.models'
import { CsButtonText } from '@csinstruments/cs-react-theme'
import { SelectedSingleUserLicense } from '../UserContext/initialState'
import { transformToFileHierarchy } from '../../../hooks/helperhooks/transformToFileHierarchy'
type ConfirmationProps = {
  sendEmail: boolean
  changeSendEmail: (send: boolean) => void
  editStep: (number: number) => void
  selectedLicenseInfo?: SelectedSingleUserLicense
  superiorUserInfo?: MinimalUserInfo
  subordintateUserInfo?: MinimalUserInfo[]
  assingableLicenses: SelectedSingleUserLicense[]
}

const CreateUpdateSummary: React.FC<ConfirmationProps> = ({
  sendEmail,
  changeSendEmail,
  editStep,
  selectedLicenseInfo,
  superiorUserInfo,
  subordintateUserInfo,
  assingableLicenses,
}: ConfirmationProps) => {
  const { state, registeredUser } = useManageUserContext()
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { appsList } = useAssignableApps()

  const displayRoles = (clientName?: string, appRoles?: AsAppRole[]): string => {
    return appRoles
      ?.map((rl) =>
        rl?.displayNameKey && clientName ? getTranslatedValue(clientName, rl.displayNameKey, t) : rl?.roleName,
      )
      .slice()
      .join(', ') as string
  }
  //console.log('state', state)

  const leakAppState = useMemo(() => {
    return state.appStates.find((ap) => ap?.appID === 'leak-reporter')
  }, [state.appStates])

  // const iamAppRole = displayRoles('iam', state.userPermission)

  // const iamAppAssignRoles = displayRoles('iam', leakAppState?.assignableRoles)

  const leakAppRoles = displayRoles(leakAppState?.appID, leakAppState?.roles)

  const leakAppAssignRoles = displayRoles(leakAppState?.appID, leakAppState?.assignableRoles)

  const leakAppEditRoles = displayRoles(leakAppState?.appID, leakAppState?.editableRoles)

  // const licenseState = useMemo((): SingleUserLicenseInput => {
  //   const _lic = appsList?.find((l) => leakAppState.clientName === l?.groupname)
  //   return {
  //     groupname: _lic?.displayNameKey as string,
  //     licenseType: _lic?.licenseTypes?.find((lt) => state.licenses.some((sl) => sl?.licenseType === lt?.licenseType))
  //       ?.displayNameKey,
  //   } as SingleUserLicenseInput
  // }, [state.licenses, appsList])

  return (
    <>
      <Grid item container spacing={2} alignItems="center">
        <Grid item lg={3}>
          <CsButtonText onClick={() => editStep(1 as number)}>
            <Typography variant="h6" color="secondary">
              {t('heading.loginCredentials')}
            </Typography>

            {/* <EditIcon fontSize="small" /> */}
          </CsButtonText>
        </Grid>
        <Grid item container spacing={2} xs lg={8} className={classes.overview}>
          <Grid item lg={4} container spacing={1} direction="column">
            <Grid item onClick={() => editStep(1)}>
              <Typography variant="subtitle2" color="textSecondary">
                {'Email: ' + ' '}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="subtitle2" color="primary">
                {state?.userInfo?.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container lg={4} spacing={1} direction="column">
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                {t('labels.origin') + ' '}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="subtitle2" color="primary">
                {state?.userInfo?.extern === 'extern' ? t('labels.externLabel') : t('labels.internLabel')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />

      <Grid item container spacing={2} alignItems="center">
        <Grid item xs lg={3}>
          <CsButtonText onClick={() => editStep(2 as number)}>
            <Typography variant="h6" color="secondary">
              {t('labels.accessRights')}
            </Typography>

            {/* <EditIcon fontSize="small" /> */}
          </CsButtonText>
        </Grid>
        <Grid item container spacing={2} lg={8} direction="column" className={classes.overview}>
          <Grid item container spacing={2}>
            <Grid item lg={4} container spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.appLicense') + ' '}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {t(`license:${selectedLicenseInfo?.licenseGroupName ?? ''}`)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={4} spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.appLicenseType')}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {t(`license:${selectedLicenseInfo?.licenseTypeKey ?? ''}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg />
          <Grid item container spacing={2}>
            <Grid item lg={4} container spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.superior') + ' '}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {state.superior?.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={4} spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.iamEditableRights', { username: getUserName(state?.userInfo?.email ?? '') }) + ' '}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {state.userPermission
                    .map((rl) => getTranslatedValue('iam', rl.roleDisplayKey, t))
                    .slice()
                    .join(', ')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg />
          <Grid item container spacing={2}>
            <Grid item lg={4} container spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.subordinates') + ' '}
                </Typography>
              </Grid>
              {state.subordinates?.map((subordinate) => (
                <Grid item key={subordinate.orgUserID}>
                  <Typography variant="subtitle2" color="primary">
                    {subordinate.email}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid item container lg={4} spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.iamAssignableRights', { username: getUserName(state?.userInfo?.email ?? '') }) + ' '}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {state.assignableUserPermission
                    .map((rl) => getTranslatedValue('iam', rl.roleDisplayKey, t))
                    .slice()
                    .join(', ')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={4} spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.packetTypes')}
                </Typography>
              </Grid>

              <Grid item>
                {assingableLicenses?.map((pkt, ix) => (
                  <Typography key={pkt?.licensePacketName + ix} variant="subtitle2" color="primary" gutterBottom>
                    {pkt?.licensePacketName} {!pkt.licenseGroupName ? '' : ' ' + pkt.licenseGroupName}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs lg={3}>
          <CsButtonText onClick={() => editStep(3 as number)}>
            <Typography variant="h6" color="secondary">
              {t('labels.resources')}
            </Typography>

            {/* <EditIcon fontSize="small" /> */}
          </CsButtonText>
        </Grid>
        <Grid item container spacing={2} lg={8} direction="column" className={classes.overview}>
          <Grid item container spacing={2}>
            <Grid item lg={4} container spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {'App:' + ' leak-reporter'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                {t('labels.role', { username: getUserName(state?.userInfo?.email ?? '') }) + ' '}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="subtitle2" color="primary">
                {leakAppRoles}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg />
          <Grid item container spacing={2}>
            <Grid item container lg={4} spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.assignableRoles', { username: getUserName(state?.userInfo?.email ?? '') }) + ' '}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {leakAppAssignRoles}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container lg={4} spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.editableRoles', { username: getUserName(state?.userInfo?.email ?? '') }) + ' '}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {leakAppEditRoles}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg />
          <Grid item container spacing={2}>
            <Grid item lg={4} spacing={1} container direction="column" onClick={() => editStep(3)}>
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('labels.company')}
                </Typography>
              </Grid>
              {leakAppState?.filePermission
                ?.slice()
                .sort((a, b) => ((a?.filename as string) > (b?.filename as string) ? 1 : -1))
                // .filter((f) => f.filetypeID === 'company-id')
                .map((comp) => (
                  <Grid item key={comp?.fileID as string}>
                    <Typography
                      variant="subtitle2"
                      color={comp.metaInfo?.state != StateOfResource.Intrash ? 'primary' : 'error'}
                    >
                      {comp.filename ? comp.filename : comp.filetypeID === 'root-id' ? t('labels.all') : ''}
                      {comp.metaInfo?.state === StateOfResource.Intrash ? t('warning.inTrash') : ''}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
            <Grid item container lg={4} spacing={1} direction="column">
              {/* <Grid item>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  {t('labels.projects')}
                </Typography>
              </Grid>
              {leakAppState?.filePermission
                ?.slice()
                .sort((a, b) => ((a?.filename as string) > (b?.filename as string) ? 1 : -1))
                .filter((f) => f.filetypeID === 'project-id')
                .map((someRes) => (
                  <Grid item key={someRes?.fileID as string}>
                    <Typography variant="subtitle2" color="primary" gutterBottom>
                      {someRes?.filename}
                    </Typography>
                  </Grid>
                ))} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!registeredUser && (
        <Grid item xs={4} lg={3}>
          <Box pr={3}>
            <FormControlLabel
              control={
                <Checkbox
                  //value={inviteUser}
                  checked={sendEmail}
                  onChange={({ target }) => changeSendEmail(target.checked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={t('labels.invite')}
            />
          </Box>
        </Grid>
      )}
    </>
  )
}

export default CreateUpdateSummary
