import React, { useEffect, useMemo, useState } from 'react'
import SimpleActionPage from '../../components/partials/SimpleActionPage'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined'
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined'
import { ActionWithDescription, FixedActionBar } from '@csinstruments/cs-react-theme'
import SaveIcon from '@material-ui/icons/Save'
import { useRoleManagementContext } from './RoleContext/RoleProvider'

import OrganizationalInput from './RoleForm/OrganizationalInput'
import SpecialPermissions from './RoleForm/SpecialPermissions'
import { useUpdateRole } from '../../hooks/roleHooks'
import { useGetQueryParams } from '../../hooks/helperhooks/routingHooks'
import { RoleActionType } from './RoleContext/roleActions'
import { Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next/'
import { ROUTES } from '../../helpers/routes'
import { Redirect } from 'react-router-dom'
import { initRoleSimpleInput } from './RoleContext/roleInputInitState'
import { StepperWizard } from '../../components/partials/StepperWizard/StepperWizard'
import { ProgressBar, PushBack, ResultAlert } from '../../components/partials'

type EditRoleProps = {
  isLoading?: boolean
  isError?: string
}
const EditSimpleRole: React.FC<EditRoleProps> = ({ isLoading, isError }: EditRoleProps) => {
  const { id, singleParam } = useGetQueryParams([], 'app')
  const appName = singleParam ?? ''
  const { state, editRoleState, dispatch } = useRoleManagementContext()
  const { t, i18n } = useTranslation('common')
  const [update, setUpdate] = useState<{ error?: string; updated?: boolean }>()
  const [activeStep, setActiveStep] = useState(0)

  const steps = useMemo(() => {
    return [t('labels.introduction'), t('labels.description'), t('labels.specialRights')]
  }, [t])

  const { confirmUpdate, roleupdated, UpdateRoleError } = useUpdateRole(id)

  useEffect(() => {
    if (editRoleState) {
      dispatch({
        type: RoleActionType.UpdateOrgRolePermissionInput,
        payload: { ...state, ...editRoleState },
      })
    }
  }, [editRoleState])

  const updateRole = async () => {
    const updatedRole = {
      ...state,
      roleId: state.roleName + '-id',
      appRoleInfo: !state.appRoleInfo?.length
        ? [
            {
              roleDisplayName: state.roleName,
              roleDescription: '',
              langKey: i18n.language,
            },
          ]
        : state.appRoleInfo,
    }
    try {
      activeStep === steps.length - 1 && (await confirmUpdate(appName, updatedRole))
    } catch (er) {
      console.log('error updating role', er)
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 < 0 ? 0 : prevActiveStep - 1))
  }

  const onStepClick = (step: number) => {
    setActiveStep(step)
  }

  const handleReset = () => {
    setActiveStep(0)
    onStepClick(0)
    dispatch({
      type: RoleActionType.UpdateOrgRolePermissionInput,
      payload: {
        ...(editRoleState ?? initRoleSimpleInput),
      },
    })
  }

  useEffect(() => {
    if (roleupdated) {
      setUpdate({ updated: roleupdated })
    }
  }, [roleupdated])

  if (update?.updated) {
    return <Redirect to={{ pathname: ROUTES.manageroles }} />
  }

  return (
    <>
      <SimpleActionPage>
        <PushBack textHeading={t('manageroles')} />
        <StepperWizard labels={steps} step={activeStep} onStepClicked={onStepClick} />
        <ProgressBar show={isLoading} />

        <ResultAlert
          severity="error"
          showAlert={!!isError || !!UpdateRoleError}
          alertText={`${UpdateRoleError || isError}`}
        />
        {activeStep === 0 && (
          <Grid container>
            <Grid item xs={6} lg={4}>
              <Box pr={4}>
                <ActionWithDescription
                  heading={t('heading.editRole')}
                  description={t('descriptions.editRole', { role: state.roleName, app: appName })}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && <OrganizationalInput />}
        {activeStep === 2 && <SpecialPermissions />}

        <FixedActionBar
          actions={[handleReset, handleBack, activeStep !== 2 ? handleNext : updateRole]}
          labels={['Reset', t('labels.back'), activeStep !== 2 ? t('labels.next') : t('labels.save')]}
          largeButtons
          fixedButtonSizes={[2, 2, 2]}
          icons={[
            RotateLeftOutlinedIcon,
            KeyboardBackspaceOutlinedIcon,
            activeStep !== 2 ? ArrowForwardOutlinedIcon : SaveIcon,
          ]}
          buttonProps={[{ solid: false }, { solid: false }, { solid: true }]}
          /* disabled={[
            activeStep === 0,
            false,
            (activeStep === 1 && !state.roleName) ||
              (activeStep === 2 &&
                (state.organizationRole.description.some((d) => d?.language === i18n.language && !d?.value) ||
                  !!updatingRole)),
          ]} */
        />
      </SimpleActionPage>
    </>
  )
}

export default EditSimpleRole
