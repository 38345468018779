import { ApolloClient, DefaultOptions, HttpLink, ApolloLink, from, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { cache } from '../helpers/cache'
import * as Sentry from '@sentry/browser'
import { useAuthContext } from '../components/partials'
import { getApolloClientURI } from '../helpers/clientRoutes'

const updateTokenError = (reason?: unknown) => {
  try {
    Sentry.captureException(new Error('Error updating accessToken'), (scope) => {
      scope.setTransactionName('could not fetch new Access token')
      scope.setExtra('session expired', reason)
      //console.log('session expired please login again')
      return scope
    })
  } catch (e) {
    console.error('failed to send event to sentry', e)
  }
}

export const useIamApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const { isAuthenticated, keyCloak } = useAuthContext()
  const tkn = async () => {
    if (isAuthenticated && keyCloak?.isTokenExpired(5)) {
      try {
        const refreshed = await keyCloak?.updateToken(5)
        if (refreshed) {
          return keyCloak?.token as string
        }
      } catch (err: unknown) {
        //console.log('token updating error', err)
        keyCloak.clearToken()
        updateTokenError(err)
        //keyCloak?.logout({ redirectUri: `${WEB_URL}${ROUTES.sessionExpired}` })
      }
    }
    return keyCloak?.token as string
  }
  const httpLink = new HttpLink({
    uri: (operation) => {
      return getApolloClientURI(operation.operationName)
    },
    credentials: 'same-origin',
  })
  const authLink: ApolloLink = setContext(async (_, { headers }) => {
    const _accessTkn = await tkn()
    return {
      headers: {
        ...headers,
        authorization: isAuthenticated ? `Bearer ${_accessTkn}` : '',
      },
    }
  })

  const errorLink: ApolloLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      //console.log('error', graphQLErrors)
      return forward(operation)
    }
    if (networkError) {
      //console.log('network Error', networkError.message)
      return forward(operation)
    }
  })

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  return new ApolloClient({
    name: 'csi-iam-client',
    version: '3.4.0',
    link: from([errorLink, authLink, httpLink]),
    queryDeduplication: false,
    cache,
    defaultOptions,
  })
}
