import { RoleInput } from '../../../../api/iam.models'
import { RoleActionType, RoleStateInputActions } from './roleActions'

export const roleInputReducer = (state: RoleInput, action: RoleStateInputActions): RoleInput => {
  switch (action.type) {
    case RoleActionType.UpdateOrgRoleInput:
      return action.payload
    case RoleActionType.UpdateRoleName:
      return { ...state, roleName: action.payload }
    case RoleActionType.UpdateEditableBy:
      return {
        ...state,
        userPermissions: {
          editableBy: action.payload,
          assignableBy: state.userPermissions?.assignableBy as string[],
        },
      }
    case RoleActionType.UpdateAssignableBy:
      return {
        ...state,
        userPermissions: {
          editableBy: state.userPermissions?.editableBy as string[],
          assignableBy: action.payload,
        },
      }
    case RoleActionType.UpdateMetaSpecialPermInput:
      return {
        ...state,
        rolePermissions: action.payload,
      }
    case RoleActionType.UpdateAppDisplayInfo:
      return {
        ...state,
        appRoleInfo: action.payload,
      }
    case RoleActionType.UpdateOrgRolePermissionInput:
      return action.payload
    default:
      return state
  }
}
