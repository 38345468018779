import React, { useEffect, useRef, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'

type DataTableCheckboxProps = {
  checkboxClicked: (id: string) => void
  id: string
  selectedAll: boolean
  filterString?: string
  nRows?: number
}

export const DataTableCheckbox: React.FC<DataTableCheckboxProps> = React.memo(
  ({ checkboxClicked, id, selectedAll, filterString, nRows }: DataTableCheckboxProps) => {
    const [selected, setSelected] = useState<boolean>(false)
    const prevSelectedAll = useRef<boolean>(false)
    const prevFilterString = useRef<string>('')
    const prevNRows = useRef<number>(0)

    const clicked = (): void => {
      setSelected((prev) => !prev)
      checkboxClicked(id)
    }

    useEffect(() => {
      if (!prevSelectedAll.current && selectedAll && !selected) {
        setSelected(true)
      } else if (prevSelectedAll.current && !selectedAll && selected) {
        setSelected(false)
      }

      if (!prevSelectedAll.current && selectedAll) {
        prevSelectedAll.current = true
      } else if (prevSelectedAll && !selectedAll) {
        prevSelectedAll.current = false
      }
    }, [selectedAll, selected])

    useEffect(() => {
      if (prevNRows.current !== nRows) {
        if (selected) {
          checkboxClicked(id)
        }
        setSelected(false)
        prevNRows.current = nRows ?? 0
      }
    }, [nRows, checkboxClicked, id, selected])

    useEffect(() => {
      if (prevFilterString.current !== filterString) {
        if (selected) {
          checkboxClicked(id)
        }
        setSelected(false)
        prevFilterString.current = filterString ?? ''
      }
    }, [filterString, checkboxClicked, id, selected])

    return (
      <TableCell padding="checkbox">
        <Checkbox onClick={clicked} checked={selected} />
      </TableCell>
    )
  },
)

DataTableCheckbox.displayName = 'DataTableCheckbox'

export default DataTableCheckbox
