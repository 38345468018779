import React, { useEffect, useState } from 'react'
import { StateOfUser, UserResourcePermission } from '../../../api/iam.models'
import { ConfirmationModal, FixedActionBar } from '@csinstruments/cs-react-theme'
import { generatePath, useHistory } from 'react-router-dom'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { ROUTES } from '../../helpers/routes'
import { useTranslation } from 'react-i18next/'
import UsersTables from './UsersTables'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../components/partials/DataTable/DataTableTypes'
import { useAccessibleUsers, useCanCreateUser, useMoveUserToFromTrash } from '../../hooks/userHooks'
import { ProgressBar, ResultAlert } from '../../components/partials'
import { getTranslatedValue } from '../../helpers/utils'
import { TFunction } from 'i18next'

const convertToUserTableRows = (t: TFunction, accessibleUsers?: UserResourcePermission[]): DataTableRow[] => {
  const rows =
    accessibleUsers
      ?.slice()
      .sort((a, b) => (a.userInfo.email > b.userInfo.email ? 1 : -1))
      .map((item, ix) => ({
        id: item?.userInfo.orgUserID || `${ix} `,
        values: [
          item?.userInfo.email ?? '-',
          item?.userInfo.extern === 'extern' ? t('labels.externLabel') : t('labels.internLabel'),
          item?.userInfo.company ?? '-',

          item.userPermission.reduce(
            (uRole, crRole) => (crRole ? getTranslatedValue('iam', crRole.roleDisplayKey, t) : uRole),
            '',
          ) ?? '',
          // item?.appRoles.reduce(
          //   (apInfo, crApp) =>
          //     crApp?.appName === 'iam'
          //       ? `${crApp.roles.map((rl) => getTranslatedValue(crApp.appName, `${rl?.displayNameKey}`, t))}`
          //       : apInfo,
          //   '',
          // ) ?? '',
          item?.appRoles.reduce((apInfo, crApp) => (crApp?.appName !== 'iam' ? t(`${crApp?.appName}`) : apInfo), '') ??
            '',
          item?.appRoles.reduce(
            (apRoleInfo, crApp) =>
              crApp?.appName !== 'iam'
                ? `${crApp?.roles.map((rl) =>
                    rl?.displayNameKey ? getTranslatedValue(crApp.appName, `${rl?.displayNameKey}`, t) : rl?.roleName,
                  )}`
                : apRoleInfo,
            '',
          ) ?? '',
          item?.userInfo.registered ? 'actions.completed' : 'actions.inProgress',
          item?.enabled ? 'actions.completed' : 'actions.inProgress',
        ],
        scopes: !item.scopes.length
          ? undefined
          : item.scopes.includes('edit')
          ? ([...item.scopes, 'delete'] as string[])
          : ([...item.scopes] as string[]),
      })) || ([] as DataTableRow[])
  return rows
}

const GetUsers: React.FC = () => {
  const { accessibleUsers, usersFetchingError, usersFetching, refetch } = useAccessibleUsers(StateOfUser.Active)
  const { t } = useTranslation('common')
  const [deleteUserID, setDeleteUserID] = useState<string>('')
  const [deleteAction, setDeleteAction] = useState(false)
  const [userTableRows, setTableUserRows] = useState<DataTableRow[]>([])
  const history = useHistory()

  const { confirmUserToFromTrash, userMoved, userMovingToOrFrom, userMovingError } = useMoveUserToFromTrash(
    StateOfUser.Intrash,
  )

  const ifCanCreateUser = useCanCreateUser()
  const tableScopes = ifCanCreateUser ? { edit: true, remove: true } : {}

  const deleteClick = (): void => {
    confirmUserToFromTrash(deleteUserID)
    refetch()
  }
  const columns: DataTableColumn[] = [
    { label: 'Email', alignment: 'left' },
    { label: t('labels.origin'), alignment: 'left' },
    { label: t('company'), alignment: 'left' },
    { label: t('heading.iamRoles'), alignment: 'left' },
    { label: t('labels.application'), alignment: 'left' },
    { label: t('role'), alignment: 'left' },
    { label: t('labels.userRegistered'), alignment: 'left', isStatus: true },
    { label: t('Login'), alignment: 'left', isStatus: true },
  ]
  const handleClick = (userID: string, action: DataItemAction): void => {
    //const userID = userTableRows?.reduce((name: DataTableRow, row) => (row.id === id ? row : name))?.id as string

    switch (action) {
      case 'delete':
        setDeleteUserID(userID)
        setDeleteAction(true)
        break
      case 'view':
        history.push(generatePath(ROUTES.viewUser, { id: userID }))
        break
      case 'edit':
        history.push(generatePath(ROUTES.usermaster, { id: userID }) + '?edit=true')
        break
    }
  }
  const handleCreateUser = () => {
    history.push(generatePath(ROUTES.usermaster, { id: 'user' }))
  }

  useEffect(() => {
    const userRows = convertToUserTableRows(t, accessibleUsers)
    setTableUserRows(userRows)
  }, [userMoved, userMovingToOrFrom, accessibleUsers, usersFetching, t])

  return (
    <>
      <ProgressBar show={usersFetching} />
      <ResultAlert
        showAlert={!!usersFetchingError || !!userMovingError}
        alertText={(usersFetchingError ?? '') || (userMovingError ?? '')}
        severity="error"
      />

      <ConfirmationModal
        handleConfirm={deleteClick}
        handleClose={() => setDeleteAction(false)}
        open={deleteAction}
        textCancel={t('actions.cancel')}
        textConfirm={'OK'}
        textMain={t('confirmation.textmain')}
        textHeading={t('confirmation.textheading')}
      />

      <UsersTables rowData={userTableRows} columnData={columns} {...tableScopes} view actionCall={handleClick} />
      <FixedActionBar
        actions={[handleCreateUser]}
        labels={[t('actions.add', { type: t('user') })]}
        largeButtons
        fixedButtonSizes={[2]}
        icons={[PersonAddIcon]}
        buttonProps={[{ solid: true }]}
        conditions={[ifCanCreateUser]}
      />
    </>
  )
}

export default GetUsers
