import { TokenOrganizationAccess } from '../../interfaces/TokenParams'
import { useAuthContext } from '../components/partials'
import { Base64 } from 'js-base64'

import { isLicenseExpired } from '../helpers/utils'
import { getOrganization, setDisplayOrg } from '../helpers/manageTokens'
import { useEffect } from 'react'

export interface OrganizationPermissions extends TokenOrganizationAccess {
  licenseExpired: boolean
}

export const useOrganizations = (): OrganizationPermissions[] => {
  const { keyCloak, selectRealm } = useAuthContext()
  let resultOrg: OrganizationPermissions[] = []
  try {
    //console.log({ keyCloak })
    const parsedToken = JSON.parse(Base64.decode((keyCloak?.token ?? '').split('.')[1]))
    const organization_access = parsedToken.organizations as OrganizationPermissions[]

    resultOrg = organization_access.map((o) => ({
      ...o,
      licenseExpired: isLicenseExpired(o.expirationDate),
    }))
  } catch (e) {
    console.error(e)
    //return []
  }

  useEffect(() => {
    if (resultOrg.length) {
      if (resultOrg.some((o) => o.realmName === getOrganization() && !o.licenseExpired)) {
        setDisplayOrg(resultOrg.find((o) => o.realmName === getOrganization())?.displayName)
      } else {
        const validOrgs = resultOrg.filter((o) => o.realmName && !o.licenseExpired)
        if (validOrgs.length) {
          selectRealm(validOrgs[0].realmName)
          setDisplayOrg(validOrgs[0].displayName)
        }
      }
    }
  }, [])

  return resultOrg
}
