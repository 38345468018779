import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Default (pre configured) role */
export type AppRole = {
  __typename?: 'AppRole';
  /** name of the role */
  roleName: Scalars['String'];
  /** key in the translation file to get displayname which should be shown */
  displayNameKey: Scalars['String'];
  /** key in the translation file to get a description for the role */
  descriptionKey: Scalars['String'];
};

/** Default (pre configured) role */
export type AppRoleInput = {
  /** name of the role */
  roleName: Scalars['String'];
  /** key in the translation file to get displayname which should be shown */
  displayNameKey: Scalars['String'];
  /** key in the translation file to get a description for the role */
  descriptionKey: Scalars['String'];
  /** if the a user is assigned this role the roles in dependecies must be assigned as assignable to the user. E.g. if the user is admin at least guest and user should be assignable. */
  dependencies: Array<Maybe<Scalars['String']>>;
  /** if the given role is assinagble by a user then also the roles in assignDependencies must be assignable */
  assignDependencies: Array<Maybe<Scalars['String']>>;
};

export type CloudLicenseInfo = {
  __typename?: 'CloudLicenseInfo';
  /** cloud users */
  cloudLicenseUsers: Array<Maybe<CloudLicenseUser>>;
  /** organizations */
  cloudLicenseOrganizations: Array<Maybe<CloudLicenseOrganization>>;
};

export type CloudLicenseOrganization = {
  __typename?: 'CloudLicenseOrganization';
  /** id of the realm */
  realmId: Scalars['String'];
  /** displayname of the organization */
  displayName: Scalars['String'];
};

export type CloudLicenseUser = {
  __typename?: 'CloudLicenseUser';
  /** id of the user (as in user profile) */
  userId: Scalars['String'];
  /** email address of the user */
  email: Scalars['String'];
  organizationLicenses: Array<Maybe<UserOrganizationLicense>>;
};

/** A default resource of a registerd meta resource. E.g. a project 1 could have been automatically created whan an organization is activated" */
export type DefaultResource = {
  __typename?: 'DefaultResource';
  /** arbitrary but unique name of the resource, e.g. project1 */
  name: Scalars['String'];
  /** type corresponds to a meta resource e.g. project */
  type: Scalars['String'];
  /** scopes which the default roles can access (the scopes correspond to that of the meta resource) */
  roleScopes: Array<Maybe<RoleScopes>>;
};

/** A default resource of a registerd meta resource. E.g. it is possible to automatically create a project 1 when an organization is activated" */
export type DefaultResourceInput = {
  /** arbitrary but unique name of the resource, e.g. project1 */
  name: Scalars['String'];
  /** type must correspond to a meta resource e.g. project */
  type: Scalars['String'];
  /** scopes which the default roles can access (the scopes correspond to that of the meta resource) */
  roleScopes: Array<Maybe<RoleScopesInput>>;
};

/** statistical information on the directories in the organization */
export type DirectoryStatistics = {
  __typename?: 'DirectoryStatistics';
  /** name of the directory e.g. Bosch */
  directoryName: Scalars['String'];
  /** number of users that have view access to the directory */
  numberOfUsers: Scalars['Int'];
  /** subdirectory statistics */
  directoryTypes: Array<Maybe<DirectoryTypeStatistics>>;
  /** statistics on resources within this directory */
  resourceTypes: Array<Maybe<ResourceTypeStatistics>>;
};

/** number of directories of a cetain type (e.g. building) within a directory */
export type DirectoryTypeStatistics = {
  __typename?: 'DirectoryTypeStatistics';
  /** type of the directory e.g. building */
  directoryType: Scalars['String'];
  /** number of directories of that type */
  numberOfDirectories: Scalars['Int'];
};

export type IamVersionInformation = {
  __typename?: 'IAMVersionInformation';
  /** version of iam */
  iamVersion: Scalars['String'];
  /** timestamp of the iam build */
  iamBuildTimestamp: Scalars['String'];
  /** version of used keycloak */
  keycloakVersion: Scalars['String'];
};

/**
 * structure which contains the language along with the value of the
 * text in that language
 */
export type LanguageString = {
  __typename?: 'LanguageString';
  /** language code e.g. de or en */
  language: Scalars['String'];
  /** value of the string in the corresponding language */
  value: Scalars['String'];
};

/**
 * structure which contains the language along with the value of the
 * text in that language
 */
export type LanguageStringInput = {
  /** language code e.g. de or en */
  language: Scalars['String'];
  /** value of the string in the corresponding language */
  value: Scalars['String'];
};

export enum LicenseUser {
  /** regular user */
  Regular = 'regular',
  /** cs user - no license required */
  Csuser = 'csuser'
}

export type MrtdPathElement = {
  __typename?: 'MRTDPathElement';
  /** name of the directory */
  directoryName: Scalars['String'];
  /** number of occurences of this path element 1 or * */
  quantifier: Scalars['String'];
};

export type MrtdPathElementInput = {
  /** name of the directory */
  directoryName: Scalars['String'];
  /** number of occurences of this path element 1 or * */
  quantifier: Scalars['String'];
};

/**
 * a meta resource is a kind of template for the dynamic creation of new resources.
 * An example is the meta resource project. If this meta resource was registered
 * then it is possible for users to dynamically create new project resources, e.g.
 * project1, project2, ...
 */
export type MetaResource = {
  __typename?: 'MetaResource';
  /** name of the meta resource e.g. project */
  name: Scalars['String'];
  /** scopes the meta resource provides */
  scopes: Array<Maybe<Scalars['String']>>;
  /** type - shoudle be meta */
  type: Scalars['String'];
  /** scopes which the default roles are allowed to access e.g. the admin role. Note that here the meta scopes add,delete,manage are given */
  roleScopes?: Maybe<Array<Maybe<RoleScopes>>>;
};

/**
 * a meta resource is a kind of template for the dynamic creation of new resources.
 * An example is the meta resource project. By registering this it is possible for
 * users to dynamically create new project resources, e.g. project1, project2, ...
 */
export type MetaResourceInput = {
  /** name of the meta resource e.g. project */
  name: Scalars['String'];
  /** scopes the meta resource provides */
  scopes: Array<Maybe<Scalars['String']>>;
  /** type - shoudle be meta */
  type: Scalars['String'];
  /** scopes which the default roles are allowed to access e.g. the admin role. Note that here the meta scopes add,delete,manage must be used */
  roleScopes?: Maybe<Array<Maybe<RoleScopesInput>>>;
};

/** if the scope on the special resource is given automatically grant also the meta resources scopes given in this structure */
export type MetaResourceScopeMap = {
  __typename?: 'MetaResourceScopeMap';
  /** one of the scopes available in the special resource */
  scopeName: Scalars['String'];
  /** list of meta resources and scopes which should be granted when the scopeName scope is granted */
  metaResourceScopes: Array<Maybe<MetaResourceScopes>>;
};

/** if the scope on the special resource is given automatically grant also the meta resources scopes given in this structure */
export type MetaResourceScopeMapInput = {
  /** one of the scopes available in the special resource */
  scopeName: Scalars['String'];
  /** list of meta resources and scopes which should be granted when the scopeName scope is granted */
  metaResourceScopes: Array<Maybe<MetaResourceScopesInput>>;
};

/** meta resources and scopes which should be granted */
export type MetaResourceScopes = {
  __typename?: 'MetaResourceScopes';
  /** name of the meta resource on which rights will be granted */
  metaResourceName: Scalars['String'];
  /** scopes on the meta resource to be granted */
  scopes: Array<Maybe<Scalars['String']>>;
};

/** meta resources and scopes which should be granted */
export type MetaResourceScopesInput = {
  /** name of the meta resource on which rights will be granted */
  metaResourceName: Scalars['String'];
  /** scopes on the meta resource to be granted */
  scopes: Array<Maybe<Scalars['String']>>;
};

/**
 * a type directory defineds a directory along with the type in the directory hierarchy. If for
 * example the structure should be /company/building then there must be a type directory for root
 * (empty string), one for company and one for building. Only the type specified here are then
 * allowed to create resources (and directories) in a certain path.
 */
export type MetaResourceTypeDirectory = {
  __typename?: 'MetaResourceTypeDirectory';
  /** type of the directory e.g. building */
  directoryName?: Maybe<Scalars['String']>;
  /** path where the directory type is located */
  path?: Maybe<Array<Maybe<MrtdPathElement>>>;
  /** scopes the directory type provides */
  scopes: Array<Maybe<Scalars['String']>>;
  /** type - shoudle be directory */
  type: Scalars['String'];
  /** scopes which the default roles are allowed to access e.g. the admin role. Note that here the scopes view,edit,assign,add,delete,manage must be used */
  roleScopes?: Maybe<Array<Maybe<RoleScopes>>>;
  /** number of allowed recursion */
  quantifier: Scalars['String'];
};

/**
 * a type directory defineds a directory along with the type in the directory hierarchy. If for
 * example the structure should be /company/building then there must be a type directory for root
 * (empty string), one for company and one for building. Only the type specified here are then
 * allowed to create resources (and directories) in a certain path.
 */
export type MetaResourceTypeDirectoryInput = {
  /** type of the directory e.g. building */
  directoryName?: Maybe<Scalars['String']>;
  /** path where the directory type is located */
  path?: Maybe<Array<Maybe<MrtdPathElementInput>>>;
  /** scopes the directory type provides */
  scopes: Array<Maybe<Scalars['String']>>;
  /** type - shoudle be directory */
  type: Scalars['String'];
  /** scopes which the default roles are allowed to access e.g. the admin role. Note that here the scopes view,edit,assign,add,delete,manage must be used */
  roleScopes?: Maybe<Array<Maybe<RoleScopesInput>>>;
  /** number of allowed recursion */
  quantifier: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * register a user with the given email and returns the user Id. The user still
   * has to register normally but will then get this id. (only for development purposes)
   */
  registerUserId: Scalars['String'];
  /** Email is the admin email address which maps the user to a registered user. If not specified the email from the access token is used. */
  activateOrganization?: Maybe<Scalars['String']>;
  /**
   * activate a license packet for an exisiting organization. The user with the given email will be created as local admin (but with
   * no access to existing companies/projects). RealmId must be id of the realm for which the license packet was created. Packetname
   * can be arbitrary string. Licenses can be marked as reserved so that they can not be assigned freely - should be false for now.
   */
  activateLicensePacket?: Maybe<Scalars['String']>;
  /**
   * Update organization / cloud license (usually used to update a trial license). Note the invoker
   * must be the root admin of the organization. That is each user can only update the trial license
   * he has created.
   */
  updateOrganizationLicense?: Maybe<Scalars['String']>;
  /** change the organization (realm) name shown (does not correspond to the keycloak realmname) */
  changeDisplayName?: Maybe<Scalars['Boolean']>;
  /** deactivate organization - all data will be removed */
  deactivateOrganization?: Maybe<Scalars['Boolean']>;
  /** sends verification Email to new email address. Only if the link in the verification email is clicked will the email address changed. */
  changeEmail?: Maybe<Scalars['Boolean']>;
  /** updates user Language preference */
  updateLangaugePreference?: Maybe<Scalars['Boolean']>;
  /** updates IAM organization preference */
  updatePreferredOrganization?: Maybe<Scalars['Boolean']>;
  /** update Packet Name. Packet Name must be unique per organization. */
  changePacketName?: Maybe<Scalars['String']>;
};


export type MutationRegisterUserIdArgs = {
  email: Scalars['String'];
};


export type MutationActivateOrganizationArgs = {
  licenseString: Scalars['String'];
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};


export type MutationActivateLicensePacketArgs = {
  licenseString: Scalars['String'];
  realmId: Scalars['String'];
  packetName: Scalars['String'];
  email: Scalars['String'];
  reserved: Scalars['Boolean'];
};


export type MutationUpdateOrganizationLicenseArgs = {
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationChangeDisplayNameArgs = {
  realmName: Scalars['String'];
  displayName: Scalars['String'];
};


export type MutationDeactivateOrganizationArgs = {
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  email: Scalars['String'];
  redirectUri: Scalars['String'];
};


export type MutationUpdateLangaugePreferenceArgs = {
  lang: Scalars['String'];
};


export type MutationUpdatePreferredOrganizationArgs = {
  realmName: Scalars['String'];
};


export type MutationChangePacketNameArgs = {
  realmName: Scalars['String'];
  packetId: Scalars['String'];
  packetName: Scalars['String'];
};

export type OrganizationAccess = {
  __typename?: 'OrganizationAccess';
  /** name of the realm - name used by iam */
  realmName: Scalars['String'];
  /** name of the organization which should be displayed to the user - instead of realmName */
  displayName: Scalars['String'];
  /** username in the realm */
  username: Scalars['String'];
  /** list of roles the user has in this realm */
  appRoles: Array<Maybe<UpAppRole>>;
  /** licenses assigned to the user */
  licenses: Array<Maybe<SingleUserLicense>>;
};

/** element (directory) of a path along with the type */
export type PathElement = {
  __typename?: 'PathElement';
  /** name of the path element e.g. Bosch */
  name: Scalars['String'];
  /** corresponding type (meta), e.g. Company, null only for root directory */
  type?: Maybe<Scalars['String']>;
};

/** element (directory) of a path along with the type */
export type PathElementInput = {
  /** name of the path element e.g. Bosch */
  name: Scalars['String'];
  /** corresponding type (meta), e.g. Company */
  type?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** get IAM version information */
  getVersion: IamVersionInformation;
  /** get the organizations to which the user has access */
  getOrganizationAccess?: Maybe<Array<Maybe<OrganizationAccess>>>;
  /**
   * returns true if the invoker is the root admin (false otherwise). Only the root
   * admin should be allowed to clear database.
   */
  isRootAdmin: Scalars['Boolean'];
  /** get information about registration status and license requirements */
  getRegisteredUserInfo: RegisteredUserInfo;
  /** get statistical information about the companys and their projects */
  getDirectoryStatistics?: Maybe<Array<Maybe<DirectoryStatistics>>>;
  getCloudLicenseInfo: CloudLicenseInfo;
};


export type QueryIsRootAdminArgs = {
  realmName?: Maybe<Scalars['String']>;
};


export type QueryGetRegisteredUserInfoArgs = {
  username: Scalars['String'];
};


export type QueryGetDirectoryStatisticsArgs = {
  realmName: Scalars['String'];
  appName: Scalars['String'];
  path: Array<Maybe<PathElementInput>>;
  type: Scalars['String'];
};

/** organization independent information on a (registered) user */
export type RegisteredUserInfo = {
  __typename?: 'RegisteredUserInfo';
  /** license requirements for this user */
  licenseUser: LicenseUser;
  /** whether the user is already registered */
  registered: Scalars['Boolean'];
};

/** number of resources of a certain type (e.g. project) withing a directory */
export type ResourceTypeStatistics = {
  __typename?: 'ResourceTypeStatistics';
  /** type of the resource e.g. project */
  resourceType: Scalars['String'];
  /** number of resources of that type */
  numberOfResources: Scalars['Int'];
};

/** Scopes which a role e.g. admin can accesss */
export type RoleScopes = {
  __typename?: 'RoleScopes';
  /** name of the role e.g. admin */
  name: Scalars['String'];
  /** scope which the role is allowed to access e.g. view */
  scopes: Array<Maybe<Scalars['String']>>;
  /** meta scopes which the role is allowed to access e.g. add */
  metaScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Scopes which a role e.g. admin can accesss */
export type RoleScopesInput = {
  /** name of the role e.g. admin */
  name: Scalars['String'];
  /** scope which the role is allowed to access e.g. view */
  scopes: Array<Maybe<Scalars['String']>>;
  /** meta scopes which the role is allowed to access e.g. add */
  metaScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Scope name along with a description for it */
export type ScopeDescription = {
  __typename?: 'ScopeDescription';
  /** name of the scope */
  scope: Scalars['String'];
  /** name of the scope in different languages */
  displayNameKey: Scalars['String'];
  /** description of the scope in different languages */
  descriptionKey: Scalars['String'];
};

/** Scope name along with a description for it */
export type ScopeDescriptionInput = {
  /** name of the scope */
  scope: Scalars['String'];
  /** name of the scope in different languages */
  displayNameKey: Scalars['String'];
  /** description of the scope in different languages */
  descriptionKey: Scalars['String'];
};

/** license assigned to a user */
export type SingleUserLicense = {
  __typename?: 'SingleUserLicense';
  /** name of the group (application), e.g. leak-reporter */
  groupname: Scalars['String'];
  /** type of the license e.g. user or guest */
  licenseType: Scalars['String'];
  /** date until the license is valid */
  expirationDate: Scalars['String'];
  /** id of the license packet to which this license belongs */
  packetId: Scalars['String'];
  /** if null or regular a license of the cloud licenses. csuser is a free license */
  regularLicense?: Maybe<LicenseUser>;
};

/** A special resource which can be used to manage software features. Unlike a meta resource no new resources can be created of this type. */
export type SpecialResource = {
  __typename?: 'SpecialResource';
  /** arbitrary but unique name of the resource */
  name: Scalars['String'];
  /** display names of the resource e.g. en:Histogram, de:Histogram */
  displayNameKey: Scalars['String'];
  /** description of this special resource */
  descriptionKey: Scalars['String'];
  /** scopes along with their description */
  scopeDescriptions: Array<Maybe<ScopeDescription>>;
  /** type can be arbitrary but should be unique */
  type: Scalars['String'];
  /** whether one (single) or serveral scopes can be selected for the permission */
  selectionType: Scalars['String'];
  /** scopes which the default roles can access */
  roleScopes?: Maybe<Array<Maybe<RoleScopes>>>;
  /** dependent meta resource scopes which are automatically granted along with a scope */
  metaResourceScopeMap: Array<Maybe<MetaResourceScopeMap>>;
  /** dependent special resource scopes which are automatically granted along with a scope */
  dependencies: Array<Maybe<MetaResourceScopeMap>>;
  /** id of the group (number from 0 to n) to which the special resource belongs */
  groupId: Scalars['Int'];
  /** index within the group (order for display) */
  index: Scalars['Int'];
};

/** A special resource which can be used to manage software features. Unlike a meta resource no new resources can be created of this type. */
export type SpecialResourceInput = {
  /** arbitrary but unique name of the resource */
  name: Scalars['String'];
  /** display names of the resource e.g. en:Histogram, de:Histogram */
  displayNameKey: Scalars['String'];
  /** description of this special resource */
  descriptionKey: Scalars['String'];
  /** scopes along with their description */
  scopeDescriptions: Array<Maybe<ScopeDescriptionInput>>;
  /** type can be arbitrary but should be unique */
  type: Scalars['String'];
  /** whether one (single) or serveral scopes can be selected for the permission */
  selectionType: Scalars['String'];
  /** scopes which the default roles can access */
  roleScopes?: Maybe<Array<Maybe<RoleScopesInput>>>;
  /** dependent meta resource scopes which should be automatically granted along with a scope */
  metaResourceScopeMap: Array<Maybe<MetaResourceScopeMapInput>>;
  /** dependent special resource scopes which should be automatically granted along with a scope */
  dependencies: Array<Maybe<MetaResourceScopeMapInput>>;
  /** id of the group (number from 0 to n) to which the special resource belongs */
  groupId: Scalars['Int'];
  /** index within the group (order for display) */
  index: Scalars['Int'];
};

/** App specific user roles */
export type UpAppRole = {
  __typename?: 'UPAppRole';
  /** name of the app to which the role list corresponds */
  appName: Scalars['String'];
  /** roles granted to the user for the given app */
  roles: Array<Maybe<UpAppRoleRole>>;
};

export type UpAppRoleRole = {
  __typename?: 'UPAppRoleRole';
  /** name of the role */
  roleName: Scalars['String'];
  /** key of the role, null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type UserOrganizationLicense = {
  __typename?: 'UserOrganizationLicense';
  /** id of the realm */
  realmId: Scalars['String'];
  /** id of the user in the organization (as in license server) */
  orgUserId: Scalars['String'];
  isRootAdmin?: Maybe<Scalars['Boolean']>;
};

export type UpdateLangaugePreferenceMutationVariables = Exact<{
  lang: Scalars['String'];
}>;


export type UpdateLangaugePreferenceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLangaugePreference'>
);

export type ChangeOrgPreferenceMutationVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type ChangeOrgPreferenceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePreferredOrganization'>
);


export const UpdateLangaugePreferenceDocument = gql`
    mutation updateLangaugePreference($lang: String!) {
  updateLangaugePreference(lang: $lang)
}
    `;
export type UpdateLangaugePreferenceMutationFn = Apollo.MutationFunction<UpdateLangaugePreferenceMutation, UpdateLangaugePreferenceMutationVariables>;

/**
 * __useUpdateLangaugePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateLangaugePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLangaugePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLangaugePreferenceMutation, { data, loading, error }] = useUpdateLangaugePreferenceMutation({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateLangaugePreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLangaugePreferenceMutation, UpdateLangaugePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLangaugePreferenceMutation, UpdateLangaugePreferenceMutationVariables>(UpdateLangaugePreferenceDocument, options);
      }
export type UpdateLangaugePreferenceMutationHookResult = ReturnType<typeof useUpdateLangaugePreferenceMutation>;
export type UpdateLangaugePreferenceMutationResult = Apollo.MutationResult<UpdateLangaugePreferenceMutation>;
export type UpdateLangaugePreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateLangaugePreferenceMutation, UpdateLangaugePreferenceMutationVariables>;
export const ChangeOrgPreferenceDocument = gql`
    mutation changeOrgPreference($realmName: String!) {
  updatePreferredOrganization(realmName: $realmName)
}
    `;
export type ChangeOrgPreferenceMutationFn = Apollo.MutationFunction<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>;

/**
 * __useChangeOrgPreferenceMutation__
 *
 * To run a mutation, you first call `useChangeOrgPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrgPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrgPreferenceMutation, { data, loading, error }] = useChangeOrgPreferenceMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useChangeOrgPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>(ChangeOrgPreferenceDocument, options);
      }
export type ChangeOrgPreferenceMutationHookResult = ReturnType<typeof useChangeOrgPreferenceMutation>;
export type ChangeOrgPreferenceMutationResult = Apollo.MutationResult<ChangeOrgPreferenceMutation>;
export type ChangeOrgPreferenceMutationOptions = Apollo.BaseMutationOptions<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>;