import { BaseTextFieldProps, Grid, TextField } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface TextProps extends BaseTextFieldProps {
  label: string
  value?: string
  columns?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  mdColumns?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  aloneInRow?: boolean
  displayOnly?: boolean
  name?: string
  changeHandler?: (name: string, value: string) => void
  multiline?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  onlyNumbers?: boolean
}

export const TextInputField: React.FC<TextProps> = ({
  value,
  name,
  required,
  mdColumns,
  changeHandler,
  displayOnly,
  columns,
  label,
  multiline,
  variant,
  error,
  helperText,
  ...props
}: TextProps) => {
  const { t } = useTranslation('common')
  const shownLabel = useMemo(() => t(label), [t, label])
  const changeHandle = useCallback(
    (name: string, val: string) => {
      //console.log('name', name, 'val', val)
      changeHandler && changeHandler(name, val)
    },
    [changeHandler],
  )

  return (
    <>
      <Grid
        item
        xs={columns ? columns : 6}
        md={mdColumns ? mdColumns : columns ? columns : 6}
        container
        alignContent="stretch"
      >
        <TextField
          style={{ paddingBottom: '1em' }}
          {...props}
          multiline={multiline}
          required={required}
          variant={variant ? variant : multiline ? 'outlined' : 'standard'}
          rows={multiline ? 4 : 1}
          name={name}
          onChange={({ target }) => changeHandle(target.name as string, target.value as string)}
          value={value}
          disabled={displayOnly}
          fullWidth={true}
          label={shownLabel}
          error={error}
          helperText={helperText}
        />
      </Grid>
    </>
  )
}
