import { Box, Grid, makeStyles, Tab, Tabs, Theme } from '@material-ui/core'
import React from 'react'
import { AppState, AppStateSimple } from '../../../../api/iam.models'
import { useManageUserContext } from '../UserContext/UserProvider'
import UserAppResources from './UserAppResources'
import { SelectedSingleUserLicense } from '../UserContext/initialState'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  )
}

function TabProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))
type ManageUserProps = {
  userAppState?: AppState[]
  selectedLicenseInfo?: SelectedSingleUserLicense
}
const ManageUserAppResources: React.FC<ManageUserProps> = ({ userAppState, selectedLicenseInfo }: ManageUserProps) => {
  const { state } = useManageUserContext()
  //console.log({ state })

  const classes = useStyles()
  const assignedApps = state.appStates?.reduce(
    (apnames: string[], appstate) =>
      appstate?.appID && appstate?.appID !== 'iam' ? [appstate.appID, ...apnames] : apnames,
    [],
  )

  const [value, setValue] = React.useState(0)
  const handleChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue)
  }
  //console.log({ assignedApps })

  return (
    <>
      <Grid item container alignContent="stretch" justifyContent="flex-start">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            variant="fullWidth"
            className={classes.tabs}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {assignedApps?.map((app, idx) => (
              <Tab key={idx} label={app as string} {...TabProps(idx)} />
            ))}
          </Tabs>
        </Grid>

        <Grid item container justifyContent="center" alignItems="stretch" spacing={2}>
          {assignedApps?.map((app, idx) => (
            <TabPanel key={idx} value={value} index={idx}>
              <UserAppResources
                appName={app as string}
                userAppState={userAppState}
                selectedLicenseInfo={selectedLicenseInfo}
              />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default ManageUserAppResources
