import { ActionWithDescription, CsButtonSecondary } from '@csinstruments/cs-react-theme'
import { Grid, Box, TextField } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { RoleActionType } from '../RoleContext/roleActions'
import { useRoleManagementContext } from '../RoleContext/RoleProvider'
import MenuItem from '@material-ui/core/MenuItem'
import { TextInputField } from '../../../components/partials/inputElements/TextInputField'
import { useUsersRoleAssignableBy, useUsersRoleEditableBy } from '../../../hooks/userHooks'
import { RoleDisplayInput } from '../../../../api/iam.models'
import { useGetQueryParams } from '../../../hooks/helperhooks/routingHooks'
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
const OrganizationalInput: React.FC = () => {
  const [t, i18n] = useTranslation(['common', 'iam', 'leakage', 'network'])

  const data = useUsersRoleEditableBy()

  const { singleParam } = useGetQueryParams(undefined, 'app')
  const app = singleParam ?? ''

  const { state, dispatch, editRoleId } = useRoleManagementContext()

  const { rolesAssignableByUsersList } = useUsersRoleAssignableBy(app, editRoleId)

  const [additional, setAdditional] = useState<boolean>(false)
  const [description, setDescription] = useState<RoleDisplayInput>({
    langKey: i18n.language,
    roleDescription: '',
    roleDisplayName: '',
  })

  const usersList = React.useMemo(() => {
    return data.rolesEditableByUsersList ? data.rolesEditableByUsersList : []
  }, [data.rolesEditableByUsersList])

  const assignableByList = React.useMemo(() => {
    return rolesAssignableByUsersList ?? []
  }, [rolesAssignableByUsersList])

  const changeDescription = useCallback(
    (value: string) => {
      console.log({ description })
      const curDescripition: RoleDisplayInput = description
        ? { ...description, roleDescription: value }
        : { langKey: i18n.language, roleDescription: value, roleDisplayName: state.roleName }
      setDescription(curDescripition)
      dispatch({
        type: RoleActionType.UpdateAppDisplayInfo,
        payload:
          state.appRoleInfo?.length && state.appRoleInfo?.some((sd) => sd?.langKey === curDescripition.langKey)
            ? state.appRoleInfo.map((cr) => (cr.langKey === curDescripition.langKey ? curDescripition : cr))
            : [curDescripition],
      })
    },
    [description],
  )

  React.useEffect(() => {
    if (state.appRoleInfo?.length && !description.roleDescription) {
      const langDesc = state.appRoleInfo.find((inf) => inf.langKey === i18n.language)
      langDesc && setDescription(langDesc)
    }
  }, [])

  const changeRoleName = (name: string, value: string) => {
    name &&
      dispatch({
        type: RoleActionType.UpdateRoleName,
        payload: value,
      })
  }

  return (
    <>
      <Grid item container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid item xs={6} lg={5}>
          <Box pr={4}>
            <ActionWithDescription
              heading={t('heading.organizationRole', { app: app.toLocaleUpperCase() })}
              description={t('descriptions.organizationRole', { app: app })}
            />
            <TextInputField
              columns={6}
              mdColumns={11}
              required
              variant="outlined"
              name="roleName"
              margin="normal"
              label={editRoleId ? t('labels.newroleName') : t('labels.roleName')}
              error={!state.roleName}
              value={state.roleName}
              changeHandler={changeRoleName}
            />
          </Box>
        </Grid>
        <Grid item xs={6} lg={2} />
        <Grid item xs={6} lg={5}>
          <Box pr={4}>
            <ActionWithDescription
              heading={t('heading.roleDescription')}
              description={t('descriptions.roleDescription', { app: app })}
            />
            <TextField
              fullWidth
              variant="outlined"
              name="description"
              margin="normal"
              multiline
              label={t('labels.description')}
              //error={!description?.value.length}
              value={description?.roleDescription}
              onChange={({ target }) => changeDescription(target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CsButtonSecondary color="primary" onClick={() => setAdditional(!additional)}>
            {additional ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
            {t('labels.moreSetting')}
          </CsButtonSecondary>
        </Grid>
        <Grid item xs={12} md={8} lg={12} />
        {additional && (
          <>
            <Grid item xs={6} lg={5}>
              <Box pr={9}>
                <TextField
                  label={t('labels.editableBy')}
                  variant="outlined"
                  type="text"
                  //helperText={state.assignableBy.some((u) => state.editableBy.includes(u)) && t('error.editableby')}
                  //error={state.assignableBy.some((u) => state.editableBy.includes(u))}
                  select
                  name="EditableBy"
                  fullWidth
                  value={state.userPermissions?.editableBy ?? []}
                  SelectProps={{
                    multiple: true,
                    onChange: ({ target }) =>
                      dispatch({ type: RoleActionType.UpdateEditableBy, payload: target.value as string[] }),
                  }}
                >
                  {usersList.length ? (
                    usersList.map((usr) => (
                      <MenuItem key={usr.orgUserID} value={usr.orgUserID}>
                        {usr.email}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={undefined}>{''}</MenuItem>
                  )}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={6} lg={2} />
            <Grid item xs={6} lg={5}>
              <Box pr={4}>
                <TextField
                  label={t('labels.assignableBy')}
                  variant="outlined"
                  type="text"
                  select
                  //helperText={state.editableBy.some((u) => state.assignableBy.includes(u)) && t('error.editableby')}
                  name="AssignableBy"
                  fullWidth
                  value={state.userPermissions?.assignableBy ?? []}
                  SelectProps={{
                    multiple: true,
                    onChange: ({ target }) =>
                      dispatch({ type: RoleActionType.UpdateAssignableBy, payload: target.value as string[] }),
                  }}
                >
                  {assignableByList.map((usr) => (
                    <MenuItem key={usr.orgUserID} value={usr.orgUserID}>
                      {usr.email}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default OrganizationalInput
