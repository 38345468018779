import React, { useState, useCallback } from 'react'
import { FollowDrawer, NavigationDrawer } from '@csinstruments/cs-react-theme'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import drawerItems from '../helpers/drawerData'
import { ManageOrganization, ManageResources, ManageUsers, ManageRoles } from './index'

import ViewUserPermissions from './UserManagement/ViewUserPermissions'

import { ROUTES } from '../helpers/routes'
//import { AppInfo } from '../components'
import RoleInformation from './RoleManagement/RoleInformation'
import Home from './Home'
import { completeContainer, innerPageContainer, mainPageContainer, outerPageContainer } from '../helpers/globalstyles'
import Recyclebin from './Recyclebin'

import UserMaster from './UserManagement/UserMaster'
import RoleMaster from './RoleManagement/RoleMaster'
//import { LicenseExpiredBanner } from '../components/partials/LicenseExpiredBanner'
import { NavBreadcrumbs } from '../components/partials'
import IAMAppTop from '../components/partials/Navigation/IAMAppTop'
import { useOrganizations } from '../hooks/useOranizations'
import { getOrganization } from '../helpers/manageTokens'

export const sessionState = false
const Dashboard: React.FC = () => {
  const [open, setOpen] = useState(false)

  const history = useHistory()

  const location = useLocation()
  const Organizations = useOrganizations()

  const activeTab: string = React.useMemo(() => {
    const selected = drawerItems.filter((drawerItem) => location.pathname.includes(drawerItem.link))
    return selected.length ? selected[selected.length - 1].link : ''
  }, [location])

  const appItemClick = useCallback(
    (path: string): void => {
      //console.log('path', path)
      if (!Organizations.find((o) => o.realmName === getOrganization())?.licenseExpired) {
        if (path.startsWith('http' || 'https')) {
          window.open(path, '_blank')
        } else {
          history.push('/' + path)
        }
      }
    },
    [history, Organizations],
  )
  if (!Organizations.length) {
    return <Redirect to={{ pathname: ROUTES.accessDenied }} />
  }

  return (
    <div style={completeContainer}>
      <IAMAppTop />
      <NavigationDrawer
        onDrawerAction={() => setOpen(!open)}
        onLinkClicked={appItemClick}
        entries={drawerItems}
        activeLink={activeTab}
      />
      <FollowDrawer open={open}>
        <div style={outerPageContainer}>
          <div style={innerPageContainer}>
            <div style={mainPageContainer}>
              <NavBreadcrumbs />
              {/* <LicenseExpiredBanner /> */}
              <Switch>
                <Route exact path={ROUTES.home} component={Home} />
                <Route exact path={ROUTES.manageusers} component={ManageUsers} />
                <Route exact path={ROUTES.manageorganization} component={ManageOrganization} />
                <Route exact path={ROUTES.manageresources} component={ManageResources} />
                <Route exact path={ROUTES.manageroles} component={ManageRoles} />

                <Route exact path={ROUTES.bin} component={Recyclebin} />
                {/* <Route exact path={ROUTES.info} component={AppInfo} /> */}
                <Route exact path={ROUTES.roleview} component={RoleInformation} />
                <Route exact path={ROUTES.rolemaster} component={RoleMaster} />
                <Route exact path={ROUTES.viewUser} component={ViewUserPermissions} />
                <Route exact path={ROUTES.usermaster} component={UserMaster} />
              </Switch>
            </div>
          </div>
        </div>
      </FollowDrawer>
    </div>
  )
}
export default Dashboard
