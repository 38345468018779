/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ChangeEvent, SetStateAction } from 'react'

export const handleChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  setFn: React.Dispatch<SetStateAction<any>>,
) => {
  event.preventDefault()
  const { name, value } = event.target
  setFn((prevState: any) => ({
    ...prevState,
    [name]: value,
  }))
}
