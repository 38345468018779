import { Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
type HomeScreenDesc = {
  name: string
  organization: string
}
const ExampleTrans: React.FC<HomeScreenDesc> = ({ name, organization }: HomeScreenDesc) => {
  return (
    <>
      <Typography variant="body1">
        <Trans
          i18nKey="common:descriptions.homeScreen" // optional -> fallbacks to defaults if not provided
          defaults="Hello <0>{{user}}<0>. Here you can administer <1>{{organization}}<1> users, roles, resources" // optional defaultValue
          values={{ user: name, organization: organization }}
          // eslint-disable-next-line react/jsx-key
          components={[<strong></strong>, <strong> </strong>]}
        />
      </Typography>
    </>
  )
}

export default ExampleTrans
