/* eslint-disable @typescript-eslint/no-explicit-any */
import { InMemoryCache, makeVar } from '@apollo/client'
//import { TypedTypePolicies } from '../../../apollo-iam-helpers'
//import { convertToLocaleDate } from './utils'

/* const transformDate = {
  read: (date: string) =>
    convertToLocaleDate(date) === 'Invalid Date' ? 'No Recent Activity' : convertToLocaleDate(date),
} */

export const cache: InMemoryCache = new InMemoryCache()
type accessToken = string
type UserID = string
type LoggedIn = boolean
export const KCinstance = makeVar<any>(undefined)
export const accessTokenVar = makeVar<accessToken>('')
export const userId = makeVar<UserID>('')
const loggedIn = makeVar<LoggedIn>(false)
export const loginStatus = (): boolean => {
  return loggedIn(accessTokenVar().length !== 0)
}
