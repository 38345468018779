import { CsButton } from '@csinstruments/cs-react-theme'
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Grid } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '.'
import { redirectToHome } from '../../helpers/environment'
import { deleteTokens, getDispName } from '../../helpers/manageTokens'
import { useGetAssignablePackets } from '../../hooks/organizationHooks'
import { useOrganizations } from '../../hooks/useOranizations'

const LicenseExpired: React.FC = () => {
  const { t } = useTranslation('common')
  const { keyCloak, globalRealmName, username } = useAuthContext()
  const permOrg = useOrganizations()
  const { assignableLicensePacket } = useGetAssignablePackets()

  const clickContact = () => {
    //window.open(environment.USER_SERVICE + '/personalinfo', '_blank')
    window.open('https://www.cs-instruments.com/', '_self')
  }

  console.log({ permOrg }, { assignableLicensePacket })

  const clickCancel = () => {
    deleteTokens()
    keyCloak?.logout({ redirectUri: redirectToHome })
    //keyCloak?.clearToken()
  }

  // const clickActivateLicense = () => {
  //   //console.log('activating')
  //   window.open(`${environment.USER_SERVICE}/activatelicense`, '_self')
  // }

  const userPack = useMemo(() => {
    if (!permOrg.length || !assignableLicensePacket?.length) return

    return assignableLicensePacket.find(
      (asp) => asp.packetId === permOrg.find((o) => globalRealmName)?.licenses[0].licensePacketID,
    )
  }, [permOrg.length, assignableLicensePacket?.length])

  return (
    <>
      <Dialog
        open
        onClose={clickCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h4" align="center" color="error" gutterBottom>
            {t('error.expiredLicense')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid item container spacing={2} direction="column">
            <Grid item xs>
              <Typography variant="subtitle1" align="center" gutterBottom>
                {t('error.extendMessage')}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" color="error" align="center" gutterBottom>
                {` ${username}, ${t('company')}: ${getDispName()} `}
                {userPack?.packetName && `, ${t('labels.packetName') + ':' + userPack?.packetName}`}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" align="center" gutterBottom>
                {t('error.expiredInfo')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container alignItems="center" alignContent="flex-start" spacing={2}>
            <Grid item xs md lg>
              <CsButton onClick={clickCancel} solid fullwidth>
                {t('actions.cancel')}
              </CsButton>
            </Grid>

            <Grid item xs md lg>
              {/* <CsButton onClick={clickActivateLicense} solid fullwidth color="secondary">
                {t('actions.activate')}
              </CsButton> */}
            </Grid>

            <Grid item xs md lg>
              <CsButton onClick={clickContact} solid fullwidth color="secondary">
                {t('actions.contact')}
              </CsButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LicenseExpired
