/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react'
import { useEffect } from 'react'
import { Permission, useGetAppAssignableMetaPermissionQuery } from '../../api/iam.models'
import { TokenOrganizationAccess } from '../../interfaces/TokenParams'
import { useAuthContext } from '../components/partials'

import { getOrganization } from '../helpers/manageTokens'

import { useOrganizations } from './useOranizations'

interface RoleFetchReturn {
  myRole?: string
  roleResponse?: undefined
  roleError?: string
  roleLoading: boolean
}
interface GetRolePermissions {
  myRole?: string
  accessibleRoles?: string[]
  fetchError?: string
  fetching?: boolean
}

interface GetOrgRolePerms {
  myRole: string
  orgRolePermission?: undefined
  fetchOrgError?: string
  orgRoleFetching?: boolean
}

interface AppRolesResponse {
  appAccessibleRoles?: undefined
  appAccessibleRoleserror?: string
  appRolesLoading?: boolean
}
export const useGetMyRole = (appName?: string): string => {
  //const { keyCloak } = useAuthContext()
  let myRole = ''
  //console.log('organ', JSON.parse(keyCloak.tokenParsed.organizations))
  const orgs = useOrganizations()
  useEffect(() => {
    try {
      if (orgs) {
        myRole = orgs.reduce(
          (role: string, currOrg: TokenOrganizationAccess) =>
            currOrg.realmName === getOrganization()
              ? (currOrg?.appRoles?.filter((appRole) => appRole?.appName === appName)[0]?.roles[0] as unknown as string)
              : role,
          '',
        )
      }
    } catch (e) {
      console.error('error', e)
    }
  }, [orgs])
  //console.log('myRole', myRole)
  return myRole
}
export const useGetAccessibleApps = (): string[] => {
  const orgs = useOrganizations()

  const userAccessibleApps = React.useMemo(() => {
    if (orgs.length) {
      const myOrg = orgs.find((o) => o.realmName === getOrganization())
      const myOrgApps = myOrg?.appRoles.filter((apr) => apr.appID !== 'am-id')
      return myOrgApps?.map((app) => app.appName) ?? []
    }
    return []
  }, [orgs])

  return userAccessibleApps
}
export const useGetMetaResourcesInfo = (appName?: string): RoleFetchReturn => {
  const myRole = useGetMyRole(appName ?? '')

  // const { data, loading, error } = useGetSpecialResourcesQuery({
  //   variables: { realmName: getOrganization(), appName: appName ?? 'iam' },
  //   skip: !getOrganization(),
  // })
  // const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    myRole: myRole,
    roleLoading: false,
  }
}

export const useGetAppRoles = (appName: string): AppRolesResponse => {
  const { username } = useAuthContext()

  // const { data, error, loading } = useGetAccessibleRolesQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     appName: appName,
  //     username: username,
  //     state: 'active' as StateOfRole,
  //   },
  //   skip: !appName || !getOrganization() || !username,
  // })
  // const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    // appAccessibleRoles: data,
    // appAccessibleRoleserror: errorMessage,
    // appRolesLoading: loading,
  }
}

export const useGetMyRolePermissions = (appName?: string): GetRolePermissions => {
  const myRole = useGetMyRole(appName)
  //const { username } = useAuthContext()

  // const { data, error, loading } = useGetAccessibleRolesQuery({
  //   variables: {
  //     realmName: getOrganization(),
  //     appName: appName ?? '',
  //     username: username,
  //     state: 'active' as StateOfRole,
  //   },
  //   skip: !myRole || !getOrganization() || !username,
  // })
  // const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    myRole: myRole,
    // accessibleRoles: data?.getAccessibleRoles as [OrganizationRole],
    // fetchError: errorMessage,
    // fetching: loading,
  }
}

export const useGetMyOrgPermissions = (roleName: string, appName: string): GetOrgRolePerms => {
  // const myRole = useGetMyRole('')
  // const { data, error, loading } = useGetRoleQuery({
  //   variables: { realmName: getOrganization(), appName: appName },
  //   skip: !myRole || !appName || !roleName,
  // })
  //const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    myRole: '',
    //orgRolePermission: data?.getRole as OrganizationRolePermission,
    // fetchOrgError: errorMessage,
    // orgRoleFetching: loading,
  }
}

export const useGetMetaSpeRes = () => {
  // const [getMetaSpecialResources, getMetaSpecialResourcesResp] = useGetMetaAndSpecialResourcesLazyQuery()
  // return {
  //   metaSpecRes: getMetaSpecialResources,
  //   metaSpecResRes: getMetaSpecialResourcesResp?.data?.getMetaAndSpecialResources,
  // }
}

type SpecialResourcesResponse = {
  // specialResourcesData?: MetaSpecialResourcePermission[] | MetaSpecialResourcePermissionEx[]
  specialResourcesData?: Permission[]
  dataFetcherror?: string
  dataLoading: boolean
}

export const useGetSpecMetaResources = (
  preconfig: boolean,
  appName: string,
  roleId?: string,
  editableBy?: string[],
  assign_by?: string[],
): SpecialResourcesResponse => {
  const editRoleName = roleId ?? ''
  const assignableBy = assign_by?.length ? assign_by : []
  const globalRealmName = getOrganization()

  const { data, loading } = useGetAppAssignableMetaPermissionQuery({
    variables: {
      realmName: globalRealmName,
      appName: appName,
      editableBy: editableBy ?? [],
      assignableBy: assignableBy,
    },
    //skip: !editRoleName,
  })

  // const metaAndSpecResResonse = useGetMetaAndSpecialResourcesQuery({
  //   variables: { realmName: globalRealmName, appName: appName },
  //   skip: !preconfig || !globalRealmName || !!roleId,
  // })
  // const specialResResponse = useGetSpecialResourcesQuery({
  //   variables: { realmName: globalRealmName, appName: appName },
  //   skip: preconfig || !!roleId,
  // })
  // //
  // if (preconfig) {
  //   const { data, loading, error } = metaAndSpecResResonse
  //   const errorMessage = useGetErrorMessage(error) ?? error?.message
  //   return {
  //     specialResourcesData: data?.getMetaAndSpecialResources as MetaSpecialResourcePermission[],
  //     dataLoading: loading,
  //     dataFetcherror: errorMessage,
  //   }
  // }
  // if (roleId) {
  //   return {
  //     specialResourcesData: specialResReq?.getSpecialResourcesRoleReq as MetaSpecialResourcePermissionEx[],
  //     dataLoading: specialResLoading,
  //   }
  // }
  // const { data, loading, error } = specialResResponse
  // const errorMessage = useGetErrorMessage(error) ?? error?.message
  return {
    specialResourcesData: data?.getAppAssignableMetaPermission.length
      ? (data.getAppAssignableMetaPermission as Permission[])
      : undefined,
    dataLoading: loading,
    //dataFetcherror: errorMessage,
  }
}
